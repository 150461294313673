import { warnInDev } from '../../../../common/utils/reactUtils';
import {
	useFollowDomainMutation,
	useUnfollowDomainMutation,
} from '../../../api';
import { mergeErrorStates, mergeLoadingStates } from '../../../api/helpers';
import {
	UserContext,
	userIsFollowing,
} from '../../../authentication/types/userTypes';
import FollowButton from 'features/forceGraphs/components/common/FollowButton';
import { FunctionComponent } from 'react';

interface DomainFollowButtonProps {
	userContext: UserContext | null;
	domainId: number | null;
	domainLabel: string | null;
}

const DomainFollowButton: FunctionComponent<DomainFollowButtonProps> = ({
	userContext,
	domainId,
	domainLabel,
}) => {
	const [followDomain, followResults] = useFollowDomainMutation();

	const [unfollowDomain, unfollowResults] = useUnfollowDomainMutation();

	const error = mergeErrorStates(followResults, unfollowResults);

	const isLoading = mergeLoadingStates(followResults, unfollowResults);

	const objectAlreadyFollowed = userContext
		? userIsFollowing({ userContext })
		: false;

	const handleFollow = () => {
		if (!domainId) {
			warnInDev(
				'attempted to follow domain before a domain ID was available',
				'warn'
			);
			return;
		}

		followDomain({ domainId });
	};

	const handleUnfollow = () => {
		if (!domainId) {
			warnInDev(
				'attempted to follow domain before a domain ID was available',
				'warn'
			);
			return;
		}

		unfollowDomain({ domainId });
	};

	return (
		<FollowButton
			error={error}
			loading={isLoading}
			handleFollow={handleFollow}
			handleUnfollow={handleUnfollow}
			objectIdentifier={domainLabel}
			objectAlreadyFollowed={objectAlreadyFollowed}
		/>
	);
};

export default DomainFollowButton;
