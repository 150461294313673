import PaperButton from '../../../../common/PaperButton';
import {
	PopoverControlsGrid,
	DropdownIcon,
} from '../../../../common/Popover/styledComponents';
import RouterLink from '../../../../common/RouterLink';
import { UserContext } from '../../../authentication/types/userTypes';
import DomainFollowButton from './DomainFollowButton';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { FunctionComponent, MouseEventHandler } from 'react';
import styled from 'styled-components';

interface ControlsProps {
	toggleDropdown: MouseEventHandler;
	pathToProfile: string;
	menuOpen: boolean;
	domainId: number | null;
	domainLabel: string | null;
	userContext: UserContext | null;
}

const StyledSquare = styled.div`
	width: ${(p) => p.theme.spacing(3)};
	height: ${(p) => p.theme.spacing(3)};
	background-color: ${(p) => p.theme.palette.primary.main};
`;

const DomainPopoverControls: FunctionComponent<ControlsProps> = ({
	toggleDropdown,
	pathToProfile,
	menuOpen,
	domainId,
	domainLabel,
	userContext,
}) => {
	return (
		<PopoverControlsGrid>
			<DomainFollowButton
				userContext={userContext}
				domainLabel={domainLabel}
				domainId={domainId}
			/>
			<RouterLink to={pathToProfile}>
				<PaperButton>
					Explore
					{/*<StyledSquare/>*/}
					{/*<FontAwesomeIcon icon={faCube}></FontAwesomeIcon>*/}
				</PaperButton>
			</RouterLink>
			<PaperButton onClick={toggleDropdown}>
				<DropdownIcon
					className={clsx(menuOpen && 'popover-menu-open')}
					icon={faEllipsisH}
				/>
			</PaperButton>
		</PopoverControlsGrid>
	);
};

export default DomainPopoverControls;
