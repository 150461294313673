import IconButton from '../../../common/IconButton';
import { List, ListItemContent, ListItem } from '../../../common/List';
import PopoverBase from '../../../common/Popover/PopoverBase';
import Typography from '../../../common/Typography';
import {
	useCreateAccountAdminMutation,
	useCreateAccountGovernorMutation,
} from '../../api';
import { parseQueryError } from '../../api/helpers';
import { composeName } from '../helpers';
import useFilteredAccountMembers from '../hooks/useFilteredAccountMembers';
import { AccountAuthorityLevel } from '../types/MemberManagementTypes';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { sortByString } from 'common/utils/functionUtils';
import { FunctionComponent, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';

// No hook for 'member' level since we can't promote to it.
const selectMutation = (authorityLevel: 'admin' | 'governor') => {
	switch (authorityLevel) {
		case 'admin':
			return useCreateAccountAdminMutation;
		case 'governor':
			return useCreateAccountGovernorMutation;
	}
};

interface AccountPromoteButtonProps {
	authorityLevel: AccountAuthorityLevel;
	accountId: number;
}

const AccountPromoteButton: FunctionComponent<AccountPromoteButtonProps> = ({
	authorityLevel,
	accountId,
}) => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	const combinedMembers = sortByString(
		useFilteredAccountMembers(accountId, authorityLevel),
		(usr) => usr.lastName
	);

	const [menuOpen, setMenuOpen] = useState(false);

	const [promote, promotionResult] = selectMutation(
		authorityLevel as 'admin' | 'governor'
	)();

	const renderMemberList = () => {
		if (combinedMembers.length === 0) {
			return <ListItem>No available users</ListItem>;
		}

		return (
			<>
				{combinedMembers.map((usr) => (
					<ListItem
						button
						key={usr._id}
						onClick={() => {
							if (promotionResult.isLoading) {
								return;
							}

							promote({ accountId, body: { userId: usr._id } });
						}}
					>
						{composeName(usr)}
					</ListItem>
				))}
				{promotionResult.isLoading && (
					<ListItem>
						<ListItemContent>
							<Typography color="primary">Updating...</Typography>
						</ListItemContent>
					</ListItem>
				)}
				{promotionResult.isError && (
					<ListItem>
						<ListItemContent>
							<Typography color="error">
								{parseQueryError(promotionResult.error).message}
							</Typography>
						</ListItemContent>
					</ListItem>
				)}
			</>
		);
	};

	return (
		<>
			<IconButton
				ref={setAnchorEl}
				icon={faPlus}
				tooltip={`add account ${authorityLevel}`}
				showTooltip={!menuOpen}
				aria-label={`add account ${authorityLevel}`}
				onClick={() => setMenuOpen((p) => !p)}
			/>
			<PopoverBase
				open={menuOpen}
				anchorEl={anchorEl}
				placement="bottom-start"
			>
				<ClickAwayListener onClickAway={() => setMenuOpen(false)}>
					<List style={{ maxHeight: '300px', overflowY: 'auto' }}>
						{renderMemberList()}
					</List>
				</ClickAwayListener>
			</PopoverBase>
		</>
	);
};

export default AccountPromoteButton;
