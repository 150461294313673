import Button from 'common/Button';
import FlexContainer from 'common/FlexContainer';
import { FormResults } from 'common/Form';
import Heading from 'common/Heading';
import Typography from 'common/Typography';
import { useAcceptTOSMutation } from 'features/api';
import useTOSAccepted from 'features/authentication/hooks/useTOSAccepted';
import { FunctionComponent } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const StyledForm = styled.form`
	padding: ${(p) => p.theme.spacing(2)};
	padding-bottom: 0;
	max-width: 80%;
	display: flex;
`;

const StyledMain = styled.div`
	background-color: ${(p) => p.theme.palette.darkBaby};
	width: 100%;
	height: 100vh;
	color: ${(p) => p.theme.palette.lightBaby};
`;

const StyledContainer = styled(FlexContainer)`
	margin: auto;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;

	padding-top: ${(p) => 2 * p.theme.navHeight}px;
	width: 60%;
	gap: ${(p) => p.theme.spacing(4)};

	@media screen and (max-width: 600px) {
		width: 80%;
	}
	@media screen and (max-width: ${(p) => p.theme.breakpoints.values.md}px) {
		width: 70%;
	}

	@media screen and (max-width: ${(p) => p.theme.breakpoints.values.sm}px) {
		width: 100%;
	}
`;

const StyledCheckbox = styled.span`
	background-color: #29323c !important;
	color: #29323c !important;

	&:hover input ~ .checkmark {
		background-color: ${(p) => p.theme.palette.oldManGray} !important;
	}
	&:checked ~ .checkmark {
		background-color: #29323c;
	}
	&:after {
		border-color: ${(p) => p.theme.palette.primary.main} !important;
	}
`;

// const StyledContainer = styled.div`
// 	padding: ${(p) => p.theme.spacing(2)};
// 	width: 50%;
// 	border-radius: ${(p) => p.theme.spacing(0.5)};
// 	border: 1px solid ${(p) => p.theme.palette.divider};

interface AcceptanceFormValues {
	acceptTerms: boolean;
}

const AcceptanceForm: FunctionComponent = () => {
	const navigate = useNavigate();

	const { register, watch, handleSubmit } = useForm<AcceptanceFormValues>();

	const acceptanceState = watch('acceptTerms');

	const [acceptTOS, acceptanceResult] = useAcceptTOSMutation();

	const onSubmit: SubmitHandler<AcceptanceFormValues> = () => {
		//   TODO: figure out why I can't call this w/out an argument according to TS
		acceptTOS(null);
	};

	const tosAccepted = useTOSAccepted();

	const onSuccess = () => navigate('/onboarding');

	//  Shenanigans here: IF successfully updating TOS busts the 'getUser' cache and the query
	// reruns before the timeout
	//  of the 'onSuccess' callback completes, we need to keep rendering FormResults even though 'tosAccepted' on the user
	//  object is true and there's nothing to see--if we unmount FormResults, the useEffect cleanup function in
	// FormResults runs and onSuccess never gets executed
	if (tosAccepted) {
		return (
			<FormResults
				{...acceptanceResult}
				onSuccess={onSuccess}
				onSuccessDelay={1000}
			/>
		);
	}

	return (
		<div style={{margin: '0 auto', width: '200px'}}>
		<StyledForm
			aria-label="accept terms of service"
			onSubmit={handleSubmit(onSubmit)}
		>
			<FlexContainer justifyContent="space-between" alignItems="center">
				<label
					htmlFor="accept-terms-box"
					style={{
						paddingRight: '8px',
						position: 'relative',
						fontSize: '14px',
						top: '2px',
						width: '100px'
					}}
					className="container"
				>
					<span style={{
						display: 'inline-block',
						position: 'relative',
						top: '4px',
						color: '#c1c9d2',
						fontWeight: '500'
					}}>
					I Accept
					</span>
					<input
						id="accept-terms-box"
						{...register('acceptTerms', {
							required: 'Please accept terms to continue',
						})}
						type="checkbox"
					/>
					<StyledCheckbox className="checkmark" />
				</label>
				<Button
					style={{fontSize: '12px', width: '75px', color: '#29323c'}}
					disabled={!acceptanceState}>
					Next
				</Button>
			</FlexContainer>
			<FormResults
				{...acceptanceResult}
				onSuccess={onSuccess}
				onSuccessDelay={1000}
			/>
		</StyledForm>
			</div>
	);
};

const StyledTOSBox = styled.section`
	overflow-y: auto;
	max-height: 60vh;
	width: 33vw;
	margin: auto;
	padding: ${(p) => p.theme.spacing(2)};
	background-color: ${(p) => p.theme.palette.lightBaby};
	border-radius: ${(p) => p.theme.spacing(0.5)};
	color: ${(p) => p.theme.palette.darkBaby};
	font-weight: 700 !important;
`;

const TOSPage: FunctionComponent = () => {
	return (
		<StyledMain>
			<StyledContainer flexDirection="column">
				<Heading
					style={{
						textAlign: 'center',
						color: '#f9fbfd',
					}}
					component="h2"
				>
					Terms of Service
				</Heading>
				<StyledTOSBox>
					<Typography paragraph>
						Lorem ipsum dolor sit, amet consectetur adipisicing
						elit. Beatae nisi molestias assumenda neque ut?
						Repudiandae amet placeat itaque esse modi autem
						assumenda doloribus exercitationem quasi, quidem labore,
						delectus voluptatibus ad officiis nam. Non odit
						excepturi blanditiis ipsa provident omnis reprehenderit,
						porro rerum suscipit unde sunt, veniam enim natus velit
						aut adipisci soluta ut quae. Iste, voluptatem ducimus
						corporis repudiandae asperiores provident, iure deserunt
						ullam magnam sequi fugit quisquam illo labore placeat
						dignissimos veniam modi doloribus quis. Blanditiis,
						alias officia dolore repellendus magni est earum
						pariatur consequuntur aperiam perferendis tenetur
						cupiditate quia similique unde voluptates id rerum odio
						suscipit quaerat harum, excepturi eligendi cum!
						Assumenda, ipsa distinctio.
						<br />
						<br />
						Accusamus illum quaerat aspernatur eveniet, voluptate
						alias suscipit veniam nostrum unde debitis aperiam
						tempore excepturi minus quasi nisi, aut nesciunt sunt
						aliquid dicta magni vitae aliquam vero. Aut fuga
						delectus, ut eveniet laborum eum ipsa provident, totam
						quibusdam facilis voluptates! Esse dignissimos nihil
						culpa dolores laudantium libero minus tempore? Mollitia,
						molestias cumque itaque obcaecati magni omnis!
						Distinctio dicta ea voluptatem quidem repellat
						perspiciatis unde, sunt, iusto perferendis, iste porro
						enim facere totam quo accusamus nobis debitis minus
						natus officiis voluptatibus aliquid architecto quibusdam
						non? Ullam iste ratione porro numquam nobis non
						incidunt, distinctio provident.
						<br />
						<br />
						Lorem ipsum dolor sit, amet consectetur adipisicing
						elit. Beatae nisi molestias assumenda neque ut?
						Repudiandae amet placeat itaque esse modi autem
						assumenda doloribus exercitationem quasi, quidem labore,
						delectus voluptatibus ad officiis nam. Non odit
						excepturi blanditiis ipsa provident omnis reprehenderit,
						porro rerum suscipit unde sunt, veniam enim natus velit
						aut adipisci soluta ut quae. Iste, voluptatem ducimus
						corporis repudiandae asperiores provident, iure deserunt
						ullam magnam sequi fugit quisquam illo labore placeat
						dignissimos veniam modi doloribus quis. Blanditiis,
						alias officia dolore repellendus magni est earum
						pariatur consequuntur aperiam perferendis tenetur
						cupiditate quia similique unde voluptates id rerum odio
						suscipit quaerat harum, excepturi eligendi cum!
						Assumenda, ipsa distinctio.
						<br />
						<br />
						Accusamus illum quaerat aspernatur eveniet, voluptate
						alias suscipit veniam nostrum unde debitis aperiam
						tempore excepturi minus quasi nisi, aut nesciunt sunt
						aliquid dicta magni vitae aliquam vero. Aut fuga
						delectus, ut eveniet laborum eum ipsa provident, totam
						quibusdam facilis voluptates! Esse dignissimos nihil
						culpa dolores laudantium libero minus tempore? Mollitia,
						molestias cumque itaque obcaecati magni omnis!
						Distinctio dicta ea voluptatem quidem repellat
						perspiciatis unde, sunt, iusto perferendis, iste porro
						enim facere totam quo accusamus nobis debitis minus
						natus officiis voluptatibus aliquid architecto quibusdam
						non? Ullam iste ratione porro numquam nobis non
						incidunt, distinctio provident.
					</Typography>
				</StyledTOSBox>
				<AcceptanceForm />
			</StyledContainer>
		</StyledMain>
	);
};

export default TOSPage;
