import currencyData from './currencyData';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'common/Button';
import Container from 'common/Container';
import FlexContainer from 'common/FlexContainer';
import Heading from 'common/Heading';
import Paper from 'common/Paper';
import Typography from 'common/Typography';
import { format } from 'date-fns';
import useActiveAccountData from 'features/ontology/hooks/useActiveAccountData';
import {
	FunctionComponent,
	useEffect,
	useState,
	FormEvent,
	ReactNode,
} from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import styled from 'styled-components';

const StyledProfileItem = styled(FlexContainer)`
	padding: ${(p) => p.theme.spacing(3, 2)};
	align-items: center;
	justify-content: space-between;
`;

const StyledList = styled.ul`
	width: 100%;
	list-style-type: none;
`;

const StyledPlaceholder = styled(StyledProfileItem)`
	border-bottom: 1px solid ${(p) => p.theme.palette.divider};
`;

// TODO: Depending on how db formats datetime, we may want this to read
// user's preferred timezone and format accordingly.  Docs are here:
// https://date-fns.org/docs/Getting-Started
const formatDateString = (dateString: string) =>
	format(new Date(dateString), 'yyyy-MM-dd');

const StyledPaper = styled(Paper)`
	padding: ${(p) => p.theme.spacing(1)};
`;

interface TimezoneTooltipProps {
	children?: ReactNode;
}

const TimezoneTooltip: FunctionComponent<TimezoneTooltipProps> = ({
	children,
}) => {
	// TODO: pry don't want this dependency--use Popper component instead,
	// since we have to have it for other things anyway.
	const {
		getArrowProps,
		getTooltipProps,
		setTooltipRef,
		setTriggerRef,
		visible,
	} = usePopperTooltip();

	return (
		<>
			<FontAwesomeIcon
				style={{
					marginLeft: '8px',
					cursor: 'pointer',
				}}
				icon={faQuestionCircle}
				forwardedRef={setTriggerRef}
			/>
			{visible && (
				<StyledPaper
					ref={setTooltipRef as any}
					{...getTooltipProps({ className: 'tooltip-container' })}
				>
					<div {...getArrowProps({ className: 'tooltip-arrow' })} />
					<Typography>{children}</Typography>
				</StyledPaper>
			)}
		</>
	);
};

const AccountProfile: FunctionComponent = () => {
	// TODO: make this robust
	// const { account } = useParams() as { account: string };

	const [tzs, setTzs] = useState([]);

	// placeholder submission handlers
	const onTZSubmit = (e: FormEvent) => {
		e.preventDefault();
		// @ts-ignore
		const fd = new FormData(e.target);

		for (let [key, val] of fd) {
			console.log('key:', key, 'val:', val);
		}
	};

	const onCurrencySubmit = (e: FormEvent) => {
		e.preventDefault();
		// @ts-ignore
		const fd = new FormData(e.target);

		for (let [key, val] of fd) {
			console.log('key:', key, 'val:', val);
		}
	};

	// fetch files to populate timezone select
	useEffect(() => {
		fetch('https://worldtimeapi.org/api/timezone')
			.then((res) => res.json())
			.then(setTzs);
	}, []);

	// fetch files to populate currency select
	useEffect(() => {
		fetch('https://worldtimeapi.org/api/timezone')
			.then((res) => res.json())
			.then(setTzs);
	}, []);

	// Don't run the query if we're not navigated to right page
	const { isLoading, isFetching, activeItem } = useActiveAccountData();

	if (activeItem) {
		const { label, currency, timezone, created } = activeItem;

		return (
			<Container>
				<StyledList>
					<li>
						<StyledPlaceholder key="title">
							<div>
								<Heading component="h2">{label}</Heading>
								<Typography>
									<span style={{ paddingRight: '8px' }}>
										Created:
									</span>
									{formatDateString(created)}
								</Typography>
							</div>
						</StyledPlaceholder>
					</li>
					<li>
						<StyledPlaceholder key="account-admin">
							<div>
								<Heading component="h3">Administrator</Heading>
								<Typography>
									UNVAILABLE UNDER CURRENT API
								</Typography>
							</div>
						</StyledPlaceholder>
					</li>
					<li>
						<StyledPlaceholder key="account-default-timezone">
							<div>
								<Heading component="h3">
									Default Timezone
									<TimezoneTooltip>
										Select a default timezone
									</TimezoneTooltip>
								</Heading>
								<Typography>{timezone}</Typography>
								<form
									onSubmit={onTZSubmit}
									id="update-timezone"
								>
									<select name="timezone">
										{tzs.map((tz) => (
											<option value={tz} key={tz}>
												{tz}
											</option>
										))}
									</select>
								</form>
							</div>
							<Button type="submit" form="update-timezone">
								Update
							</Button>
						</StyledPlaceholder>
					</li>
					<li>
						<StyledPlaceholder key="account-default-currency">
							<div>
								<Heading component="h3">
									Default Currency
								</Heading>
								<Typography>{currency}</Typography>
								<form
									onSubmit={onCurrencySubmit}
									id="update-currency"
								>
									<select name="currency">
										{currencyData.map((cd) => (
											<option
												key={cd.code}
												value={cd.code}
											>{`${cd.code}, ${cd.currency_name}`}</option>
										))}
									</select>
								</form>
							</div>
							<Button type="submit" form="update-currency">
								Update
							</Button>
						</StyledPlaceholder>
					</li>
				</StyledList>
			</Container>
		);
	}

	if (isLoading || isFetching) {
		return (
			<StyledList>
				<li>
					<StyledPlaceholder key="title">
						<Heading component="h2">Loading...</Heading>
					</StyledPlaceholder>
				</li>
			</StyledList>
		);
	}

	return null;
};

export default AccountProfile;
