import Button from 'common/Button';
import FlexContainer from 'common/FlexContainer';
import { FormBody, FormHeading, FormResults } from 'common/Form';
import Textbox from 'common/Textbox';
import Typography from 'common/Typography';
import theme from 'common/theme/theme';
import { isValidEmail } from 'common/utils/typeGuards';
import { useRequestPWResetMutation } from 'features/api';
import { FunctionComponent } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import styled from 'styled-components';

interface PWResetRequestFormValues {
	email: string;
}

const StyledLogo = styled.img`
	width: 100%;
	max-height: 30vh;
	margin-top: ${(p) => p.theme.spacing(1)};
`;

const StyledMain = styled.div`
	background-color: ${(p) => p.theme.palette.darkBaby};
	width: 100%;
	height: 100vh;
	color: ${(p) => p.theme.palette.lightBaby};
`;

const StyledContainer = styled(FlexContainer)`
	margin: auto;
	position: fixed;
	top: 200px;
	left: 0;
	right: 0;

	padding-top: ${(p) => 2 * p.theme.navHeight}px;
	width: 40%;
	gap: ${(p) => p.theme.spacing(4)};

	@media screen and (max-width: 600px) {
		width: 80%;
	}
`;

const RequestPWResetPage: FunctionComponent = () => {
	const { handleSubmit, register, formState } =
		useForm<PWResetRequestFormValues>({
			defaultValues: {
				email: '',
			},
		});

	const [requestReset, requestResetResult] = useRequestPWResetMutation();

	const onSubmit: SubmitHandler<PWResetRequestFormValues> = (vals) => {
		requestReset(vals);
	};

	return (
		<StyledMain>
			<StyledContainer>
				<FormBody
					style={{
						padding: '15px 25px',
						marginRight: '-55px',
					}}
					onSubmit={handleSubmit(onSubmit)}
					aria-labelledby="password-reset-request-heading"
				>
					<FormHeading
						component="h2"
						id="password-reset-request-heading"
						// backgroundVariant="paper"
					>
						Reset Password
					</FormHeading>
					<hr
						style={{
							border: `.5px solid ${theme.palette.slickRick}`,
							width: '295px',
							position: 'relative',
							right: '25px',
							marginTop: '10px',
							marginBottom: '10px',
						}}
					/>
					<Typography color="primary" variant="caption">
						Send code via email
					</Typography>
					<Textbox
						style={{
							height: '40px',
						}}
						labelText="Email Address"
						error={formState.errors.email}
						{...register('email', {
							required: 'A valid email address is required',
							validate: (v) =>
								isValidEmail(v)
									? true
									: 'A valid email address is required',
						})}
					/>
					<hr
						style={{
							border: `.5px solid ${theme.palette.slickRick}`,
							width: '295px',
							position: 'relative',
							right: '25px',
							marginTop: '20px',
							marginBottom: '10px',
						}}
					/>
					<Button
						style={{
							color: theme.palette.oldManGray,
							backgroundColor: theme.palette.darkBaby,
							borderColor: theme.palette.drapeGray,
							height: '42px',
							width: '100%',
						}}
						type="submit"
						disabled={
							requestResetResult.isSuccess ||
							requestResetResult.isLoading
						}
					>
						Continue
					</Button>
					<FormResults
						{...requestResetResult}
						validationErrors={{}}
					/>
					{requestResetResult.isSuccess && (
						<Typography>
							An email containing a reset link has been sent to
							the email address provided
						</Typography>
					)}
				</FormBody>
				<StyledLogo
					style={{
						position: 'relative',
					}}
					src="https://storage.googleapis.com/davis-associates.appspot.com/fm-hero-figure.svg"
					role="presentation"
				/>
			</StyledContainer>
		</StyledMain>
	);
};

export default RequestPWResetPage;
