import { useAppDispatch } from '../../../../app/hooks';
import IconButton from '../../../../common/IconButton';
import {
	List,
	ListItem,
	ListItemAvatar,
	ListItemContent,
} from '../../../../common/List';
import Popover from '../../../../common/Popover/PopoverBase';
import Typography from '../../../../common/Typography';
import { pipe } from '../../../../common/utils/functionUtils';
import { setModalType } from '../../state/HUDSlice';
import { ControlButtonWrapper } from '../styledComponents';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faCube } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { canEdit as canEditObject } from 'features/authentication/helpers';
import useActiveAccountData from 'features/ontology/hooks/useActiveAccountData';
import useActiveDomainData from 'features/ontology/hooks/useActiveDomainData';
import { FunctionComponent, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const StyledList = styled(List)``;

const StyledListItem = styled(ListItem)`
	white-space: nowrap;
`;

const mergeClickHandlers = (close: () => void, ...fns: ((x?: any) => void)[]) =>
	fns.map((fn) => () => {
		close();
		fn();
	});

// TODO: These lists could be their own components/files
interface AccountGraphListProps {
	closeMenu: () => void;
}

const AccountGraphList: FunctionComponent<AccountGraphListProps> = ({
	closeMenu,
}) => {
	const appDispatch = useAppDispatch();
	const openCreationForm = () =>
		pipe(setModalType('creationForm'), appDispatch);

	const [openCF] = mergeClickHandlers(closeMenu, openCreationForm);

	return (
		<StyledList>
			<StyledListItem button onClick={openCF}>
				<ListItemAvatar>
					<FontAwesomeIcon icon={faCube} size="sm" />
				</ListItemAvatar>
				<ListItemContent>
					<Typography>Create Domain</Typography>
				</ListItemContent>
			</StyledListItem>
		</StyledList>
	);
};

interface DomainGraphListProps {
	closeMenu: () => void;
}

const DomainGraphList: FunctionComponent<DomainGraphListProps> = ({
	closeMenu,
}) => {
	const appDispatch = useAppDispatch();

	const openSourceBrowser = () =>
		pipe(setModalType('sourceBrowser'), appDispatch);

	const openCreationForm = () =>
		pipe(setModalType('creationForm'), appDispatch);

	const openEntityImport = () =>
		pipe(setModalType('importEntity'), appDispatch);

	const [openSB, openCF, openEI] = mergeClickHandlers(
		closeMenu,
		openSourceBrowser,
		openCreationForm,
		openEntityImport
	);
	return (
		<StyledList>
			<StyledListItem button onClick={openCF}>
				<ListItemAvatar>
					<FontAwesomeIcon icon={faCube} size="sm" />
				</ListItemAvatar>
				<ListItemContent>
					<Typography>Create Entity</Typography>
				</ListItemContent>
			</StyledListItem>
			<StyledListItem button onClick={openSB}>
				<ListItemAvatar>
					<FontAwesomeIcon icon={faCube} size="sm" />
				</ListItemAvatar>
				<ListItemContent>
					<Typography>Import Dataset</Typography>
				</ListItemContent>
			</StyledListItem>
			<StyledListItem button onClick={openSB}>
				<ListItemAvatar>
					<FontAwesomeIcon icon={faCube} size="sm" />
				</ListItemAvatar>
				<ListItemContent>
					<Typography>Import Catalog</Typography>
				</ListItemContent>
			</StyledListItem>
			<StyledListItem button onClick={openEI}>
				<ListItemAvatar>
					<FontAwesomeIcon icon={faCube} size="sm" />
				</ListItemAvatar>
				<ListItemContent>
					<Typography>Import Entity</Typography>
				</ListItemContent>
			</StyledListItem>
		</StyledList>
	);
};

const CreateButton: FunctionComponent = () => {
	const { activeItem: activeAccount } = useActiveAccountData();

	const { activeItem: activeDomain } = useActiveDomainData();

	const [menuOpen, setMenuOpen] = useState(false);

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const { domain, account } = useParams();

	const closeMenu = () => setMenuOpen(false);

	//  if there's not at least a route parameter for 'account', don't show anything
	const acctOrDomainInPath = !!(account || domain);

	const creatable = domain ? 'entity' : account ? 'domain' : '';

	const relevantPermission =
		creatable === 'entity' ? activeDomain : activeAccount;

	const canEdit = !!relevantPermission
		? canEditObject(relevantPermission)
		: false;

	const displaying = canEdit && acctOrDomainInPath;

	const renderList = () =>
		creatable === 'entity' ? (
			<DomainGraphList closeMenu={closeMenu} />
		) : (
			<AccountGraphList closeMenu={closeMenu} />
		);

	return (
		<ControlButtonWrapper displaying={displaying}>
			<IconButton
				ref={setAnchorEl}
				icon={faPlus}
				onClick={() => setMenuOpen((p) => !p)}
				aria-label={`Create a new ${creatable}`}
				key="creation-form"
				size="sm"
				tooltip={`Create ${creatable}`}
				showTooltip={!menuOpen}
				tooltipPlacement="left"
			/>

			<Popover anchorEl={anchorEl} open={menuOpen} placement="left">
				<ClickAwayListener onClickAway={() => setMenuOpen(false)}>
					<div>{renderList()}</div>
				</ClickAwayListener>
			</Popover>
		</ControlButtonWrapper>
	);
};

export default CreateButton;
