import { hasOwnProperty } from '../../../common/utils/typeUtils';
import { UIAccountGraphNode } from './accountGraphTypes';
import { UIDomainGraphNode } from './domainGraphTypes';

/**
 * Append viewport-based, 2D coordinates to objects that do not
 * specify them for themself.  For example, append the 2D midpoint
 * of a link to link object to help position a popover.
 */
export type WithViewportCoordinates<T> = T & {
	viewportX: number;
	viewportY: number;
};

export type UIGraphNode = UIAccountGraphNode | UIDomainGraphNode;

export type SelectedGraphObject = UIGraphNode | UILinkObject | null;

export type SelectedObjectWithVC =
	| WithViewportCoordinates<UIGraphNode>
	| WithViewportCoordinates<UILinkObject>;

/**
 * Minimum contract of link files once it has been processed by 2D graph
 * component.  This is the shape of the link object that
 * react-force-graph-2D will pass to click/hover event handlers.
 */
export interface UILinkObject {
	source: UIGraphNode;
	target: UIGraphNode;
	kind: 'link';
}

/**
 * We add a 'kind' property when objects comes in from network to enable distinguishing
 * between node and edge once the graph is generated.
 */
export const isUINodeObject = (
	object: SelectedGraphObject
): object is UIGraphNode => {
	if (!object) return false;

	if (hasOwnProperty(object, 'kind') && object.kind === 'node') {
		return true;
	}

	return false;
};

/**
 * We add a 'kind' property when objects comes in from network to enable distinguishing
 * between node and edge files once the graph is generated.
 */
export const isUILinkObject = (
	object: SelectedGraphObject
): object is UILinkObject => {
	if (!object) return false;

	if (hasOwnProperty(object, 'kind') && object.kind === 'link') {
		return true;
	}

	return false;
};
