import {
	AccountAuthorityLevel,
	DomainAuthorityLevel,
} from '../types/MemberManagementTypes';
import { faX } from '@fortawesome/free-solid-svg-icons';
import IconButton from 'common/IconButton';
import Spinner from 'common/Spinner';
import { useRemoveUserRoleMutation } from 'features/api';
import { parseQueryError } from 'features/api/helpers';
import { FunctionComponent, useEffect } from 'react';

interface RemovalButtonProps {
	role: AccountAuthorityLevel | DomainAuthorityLevel;
	parentObjectId: number;
	parentObjectType: 'domain' | 'account';
	userId: number;
	setMemberErr: (errMsg: string, id: number) => void;
}

const RemovalButton: FunctionComponent<RemovalButtonProps> = ({
	parentObjectId,
	parentObjectType,
	userId,
	role,
	setMemberErr,
}) => {
	const [remove, removalResult] = useRemoveUserRoleMutation();

	const { error, isLoading } = removalResult;

	const renderButtonContent = () => {
		if (isLoading) {
			return <Spinner />;
		}

		return 'Remove';
	};

	useEffect(() => {
		if (error) {
			setMemberErr(parseQueryError(error).message, userId);
		}
	}, [error, setMemberErr, userId]);

	return (
		<IconButton
			icon={faX}
			size="xs"
			disabled={isLoading || !!error}
			onClick={() =>
				remove({
					parentObjectId,
					parentObjectType,
					userId,
					role,
				} as any)
			}
			tooltip="demote user"
		>
			{renderButtonContent()}
		</IconButton>
	);
};

export default RemovalButton;
