import Button from '../../../common/Button';
import FlexContainer from '../../../common/FlexContainer';
import { FormResults } from '../../../common/Form';
import Heading from '../../../common/Heading';
import Typography from '../../../common/Typography';
import { useDeleteEntityMutation } from '../../api';
import { ForceGraphContext } from '../../forceGraphs/state/GraphContextProvider';
import { SET_POPUP_ELEMENT } from '../../forceGraphs/state/actions';
import {
	StyledFlexContainer,
	StyledPaper,
	StyledDeleteButton,
} from '../../futuremodelActions/common/styledComponents';
import { EntityActionFormProps } from '../common/commonTypes';
import useEntityIsImported from 'features/forceGraphs/hooks/useEntityIsImport';
import {
	FunctionComponent,
	useState,
	MouseEventHandler,
	useContext,
	useLayoutEffect,
} from 'react';

const DeleteEntityForm: FunctionComponent<EntityActionFormProps> = ({
	_id: entityId,
	setOpenAction,
	plural: entityLabel,
}) => {
	const [unknownStateError, setUnknownStateError] = useState<string | null>(
		null
	);

	const [_, graphDispatch] = useContext(ForceGraphContext);

	const [deleteEntity, deletionResult] = useDeleteEntityMutation();

	const importStatus = useEntityIsImported(entityId);

	// if we can't determine whether or not entity is imported for some reason,
	// the system has reached an illogical state, and we should NOT allow user to take
	// a destructive action.
	const canDelete = !(importStatus === 'unknown');

	//  run synchronously so we can use presence/absence of err message in tests
	useLayoutEffect(() => {
		if (!canDelete) {
			return setUnknownStateError(
				'Unable to determine if entity is imported. DELETE forbidden.'
			);
		}

		return setUnknownStateError(null);
	}, [canDelete]);

	const onClick: MouseEventHandler = () => {
		if (canDelete) {
			const { importingDomain, isImport } = importStatus;

			if (isImport) {
				return deleteEntity({
					entityId: entityId,
					isImport: true,
					domainId: importingDomain,
				});
			}

			return deleteEntity({
				entityId,
				isImport,
			});
		}

		return null;
	};

	const onSuccessfulDelete = () =>
		graphDispatch({ type: SET_POPUP_ELEMENT, payload: null });

	return (
		<StyledPaper>
			<StyledFlexContainer flexDirection="column" alignItems="center">
				<Heading component="h2">
					<Typography>
						Are you sure you want to delete {entityLabel}?
					</Typography>
				</Heading>
				<FlexContainer
					style={{ width: '50%' }}
					justifyContent="space-between"
				>
					<Button onClick={() => setOpenAction(null)}>Go Back</Button>
					<StyledDeleteButton onClick={onClick}>
						Delete
					</StyledDeleteButton>
					{unknownStateError && (
						<Typography paragraph color="error">
							{unknownStateError}
						</Typography>
					)}
				</FlexContainer>
				<FormResults
					{...deletionResult}
					validationErrors={{}}
					onSuccess={onSuccessfulDelete}
				/>
			</StyledFlexContainer>
		</StyledPaper>
	);
};

export default DeleteEntityForm;
