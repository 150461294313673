import useAuthStatus from '../authentication/hooks/useAuthStatus';
import FeedPage from './FeedPage';
import TeaserPage from './TeaserPage';
import { FunctionComponent } from 'react';

const LandingPage: FunctionComponent = () => {
	const isAuthenticatedUser = useAuthStatus();

	return isAuthenticatedUser ? <FeedPage /> : <TeaserPage />;
};

export default LandingPage;
