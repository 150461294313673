import Button from '../../../common/Button';
import Container from '../../../common/Container';
import { FormBody, FormHeading, FormResults } from '../../../common/Form';
import Heading from '../../../common/Heading';
import Textbox from '../../../common/Textbox';
import Typography from '../../../common/Typography';
import { useResetPasswordMutation } from '../../api';
import { isQueryHTTPRejection } from '../../api/helpers';
import { QueryErrResult } from '../../api/types';
import React, { FunctionComponent, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Navigate, Link, useSearchParams } from 'react-router-dom';

const is403 = (err: QueryErrResult) => {
	if (isQueryHTTPRejection(err)) {
		return err.status === 403;
	}

	return false;
};

interface ResetFormValues {
	newPassword: string;
	passwordConf: string;
}

const ResetPage: FunctionComponent = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const { handleSubmit, register, formState, watch } =
		useForm<ResetFormValues>({
			defaultValues: {
				newPassword: '',
				passwordConf: '',
			},
		});

	const confValue = watch('passwordConf');

	const [reset, resetResults] = useResetPasswordMutation();

	const onSubmit: SubmitHandler<ResetFormValues> = (vals) => {
		reset({
			password: vals.newPassword,
			resetToken: searchParams.get('resetToken') as string,
		});
	};

	useEffect(() => {
		if (is403(resetResults.error)) {
			setSearchParams({ expired: 'true' });
		}
	}, [resetResults.error, setSearchParams]);

	if (searchParams.get('expired')) {
		return (
			<Container style={{ textAlign: 'center' }}>
				<Heading component="h1" style={{ textAlign: 'center' }}>
					Token expired
				</Heading>

				<Typography paragraph>
					Your password reset link has expired. Please{' '}
					{
						<Link to="/reset-password-request">
							request a new one.
						</Link>
					}
				</Typography>
			</Container>
		);
	}

	if (!searchParams.get('resetToken')) {
		return <Navigate to="/" />;
	}

	return (
		<Container>
			<FormBody
				onSubmit={handleSubmit(onSubmit)}
				aria-labelledby="password-reset-request-heading"
			>
				<FormHeading component="h1" id="password-reset-request-heading">
					Reset Password
				</FormHeading>
				<Textbox
					type="password"
					labelText="Enter New Password"
					error={formState.errors.newPassword}
					{...register('newPassword', {
						required: 'Please input a new password',
						validate: (v) =>
							v === confValue
								? true
								: 'Password and confirmation must match',
					})}
				/>
				<Textbox
					type="password"
					labelText="Confirm New Password"
					error={formState.errors.passwordConf}
					{...register('passwordConf', {
						required: 'Please confirm new password',
					})}
				/>
				<Button type="submit">Submit</Button>
				<FormResults {...resetResults} validationErrors={{}} />
				{resetResults.isSuccess && (
					<Typography>
						Your submission has been accepted. Please{' '}
						{<Link to="/login">login</Link>} to continue.
					</Typography>
				)}
			</FormBody>
		</Container>
	);
};

export default ResetPage;
