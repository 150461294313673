import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faMap, faUser } from '@fortawesome/free-regular-svg-icons';
import { faShareNodes } from '@fortawesome/free-solid-svg-icons';
import SlideToggle, { SlideToggleCheckDescriptor } from 'common/SlideToggle';
import useAvailableViews from 'features/HUD/hooks/useAvailableViews';
import useDispatchableErr from 'features/errorHandling/hooks/useDispatchableErr';
import useViewModeParams from 'features/navigation/hooks/useViewModeParams';
import { ViewMode } from 'features/navigation/types/navigationTypes';
import { FunctionComponent, useMemo } from 'react';

const iconMap: Record<ViewMode, IconProp> = {
	geospatial: faMap,
	profile: faUser,
	graph: faShareNodes,
};

const makeViewCheck = (vm: ViewMode): SlideToggleCheckDescriptor<ViewMode> => ({
	label: vm,
	icon: iconMap[vm],
	value: vm,
	tooltip: `${vm} view`,
});

const ViewToggle: FunctionComponent = () => {
	const dispatchErr = useDispatchableErr();

	const { getViewMode, setViewMode } = useViewModeParams();

	const currentViewMode = getViewMode();

	const availableViews = useAvailableViews();

	const checks = useMemo(
		() => availableViews.map(makeViewCheck),
		[availableViews]
	);

	//  availableViews updates asynchronously in Redux store, so we need to account
	// for initial empty array here.
	return availableViews.length ? (
		<SlideToggle
			activeValue={currentViewMode}
			checkboxes={checks}
			legend="select view mode"
			onCheck={setViewMode}
			onActiveValueMismatch={dispatchErr}
		/>
	) : null;
};

export default ViewToggle;
