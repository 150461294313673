import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetDomainGraphQuery } from 'features/api';
import useActiveDomainData from 'features/ontology/hooks/useActiveDomainData';
import { useMemo } from 'react';

const useEntityIsImported = (entityId: number) => {
	const { activeItem } = useActiveDomainData();

	const { data: graphData } = useGetDomainGraphQuery(
		activeItem ? { domainId: activeItem._id } : skipToken
	);

	return useMemo(() => {
		if (graphData && activeItem) {
			const node = graphData.nodes.find((e) => e._id === entityId);

			if (node === undefined) {
				return 'unknown';
			}

			return {
				isImport: node.isImport,
				importingDomain: activeItem._id,
			};
		}

		return 'unknown';
	}, [graphData, entityId, activeItem]);
};

export default useEntityIsImported;
