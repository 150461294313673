import { ListItem } from '../List';
import Paper from '../Paper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

/** Contains all contents of popover, including slide-down */
export const PopoverCardBody = styled(Paper)`
	flex-direction: 'column';
	max-width: 400px;
	padding-top: 5px;
`;

export const PopoverContentColumns = styled.div`
	display: grid;
	/* MAGIC NUMBERS: px should add up to 400 - 16 to leave room for padding */
	grid-template-columns: 50px 334px;
	padding: ${(p) => p.theme.spacing(1)};
	min-width: 350px;
`;

export const PopoverImage = styled.div`
	width: 85%;
	height: 0;
	border-radius: 50%;
	padding-bottom: 85%;
	margin-left: 5px;
	background-color: #60b3a1;
	background-position: center;
	background-repeat: no-repeat;
`;

export const PopoverHeader = styled.header`
	padding-left: ${(p) => p.theme.spacing(1)};
	position: relative;
	bottom: 5px;
`;

export const PopoverSubhead = styled.div`
	color: ${(p) => p.theme.palette.oldManGray};
`;

export const PopoverContentListItem = styled(ListItem)`
	font-weight: ${(p) => p.theme.typography.fontWeightBold};
	text-transform: capitalize;
`;

export const DropdownIcon = styled(FontAwesomeIcon)`
	transition: transform 100ms;
	transform: rotate(0deg);

	&.popover-menu-open {
		transform: rotate(180deg);
	}
`;

export const PopoverControlsGrid = styled.div`
	display: grid;
	grid-template-columns: 3.5fr 5.5fr 1.5fr;
	grid-gap: ${(p) => p.theme.spacing(0.5)};
	padding: ${(p) => p.theme.spacing(1)};
`;

export const PopoverHeading = styled.h1`
    font-family: ${(p) => p.theme.typography.h1.fontFamily};
	font-size: ${(p) => p.theme.typography.h1.fontSize};
    max-width: '250px';
	text-overflow: 'ellipsis';
	white-space: 'nowrap';
	overflow: 'hidden';
`;
