import { composeName } from '../helpers';
import {
	StyledMemberMgmtHeading,
	StyledHeadingContainer,
} from './styledComponents';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { nanoid } from '@reduxjs/toolkit';
import IconButton from 'common/IconButton';
import { List, ListItemContent, ListItem } from 'common/List';
import PopoverBase from 'common/Popover/PopoverBase';
import Typography from 'common/Typography';
import { sortByString } from 'common/utils/functionUtils';
import { useCreateAccountMemberMutation, useGetUsersQuery } from 'features/api';
import DisplayOnLoad from 'features/api/DisplayOnLoad';
import { extractQueryErrMessage } from 'features/api/helpers';
import { BaseUser } from 'features/authentication/types/userTypes';
import useCombinedAccountMembers from 'features/userManagement/hooks/useCombinedAccountMembers';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';

const getSortableIdentifier = ({ username, lastName, firstName }: BaseUser) =>
	!!lastName
		? lastName
		: !!username
		? username
		: !!firstName
		? firstName
		: null;

interface UserPromotionPanelProps {
	accountId: number;
	canEdit: boolean;
}

const UserPromotionPanel: FunctionComponent<UserPromotionPanelProps> = ({
	accountId,
	canEdit,
}) => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	const { data: users, ...usersLoadingState } = useGetUsersQuery();

	const existingMembers = useCombinedAccountMembers(accountId);

	const filteredUsers = useMemo(() => {
		if (users) {
			// note all Ids belonging to users that already have a role in the organization
			const used = Object.keys(existingMembers).reduce((acc, key) => {
				existingMembers[
					key as 'admins' | 'governors' | 'members'
				].forEach((member) => acc.add(member._id));
				return acc;
			}, new Set<number>());

			//    exclude pre-existing members from user list, and filter out users that
			// don't have any valid identifier
			return sortByString(
				users.filter(
					(usr) =>
						!used.has(usr._id) &&
						typeof getSortableIdentifier(usr) === 'string'
				),
				getSortableIdentifier as (usr: BaseUser) => string
			);
		}

		return [];
	}, [existingMembers, users]);

	const [menuOpen, setMenuOpen] = useState(false);

	const [updateKey, setUpdateKey] = useState(nanoid());

	const [promote, promotionResult] = useCreateAccountMemberMutation();

	// reset mutation state when menu is closed
	useEffect(() => {
		if (!menuOpen) {
			promotionResult.reset();
		}
	}, [menuOpen, promotionResult]);

	//  Update popover position when users list is loaded; otherwise list
	// will get drawn partially offscreen due to content size change.
	useEffect(() => {
		if (usersLoadingState.isSuccess) {
			setUpdateKey(nanoid());
		}
	}, [usersLoadingState.isSuccess]);

	const renderMemberList = () => {
		if (filteredUsers.length === 0) {
			return <ListItem>No available users</ListItem>;
		}

		return (
			<>
				{promotionResult.isLoading && (
					<ListItem>
						<ListItemContent>
							<Typography color="primary">Updating...</Typography>
						</ListItemContent>
					</ListItem>
				)}
				{promotionResult.isError && (
					<ListItem>
						<ListItemContent>
							<Typography color="error">
								{extractQueryErrMessage(promotionResult.error)}
							</Typography>
						</ListItemContent>
					</ListItem>
				)}
				{filteredUsers.map((usr) => (
					<ListItem
						button
						key={usr._id}
						onClick={() => {
							if (promotionResult.isLoading) {
								return;
							}

							promote({
								accountId,
								body: { userId: usr._id },
							});
						}}
					>
						{composeName(usr)}
						<br />
						{usr.username}
					</ListItem>
				))}
			</>
		);
	};

	if (!canEdit) {
		return null;
	}

	return (
		<StyledHeadingContainer
			justifyContent="space-between"
			alignItems="center"
		>
			<StyledMemberMgmtHeading component="h3" id="account-user-promotion">
				Users
			</StyledMemberMgmtHeading>
			<IconButton
				ref={setAnchorEl}
				icon={faPlus}
				tooltip={`add user to account`}
				showTooltip={!menuOpen}
				aria-label={`add user to account`}
				onClick={() => setMenuOpen((p) => !p)}
			/>
			<PopoverBase
				open={menuOpen}
				anchorEl={anchorEl}
				forceUpdateKey={updateKey}
			>
				<ClickAwayListener onClickAway={() => setMenuOpen(false)}>
					<List
						style={{ maxHeight: '300px', overflowY: 'auto' }}
						aria-labelledby="account-user-promotion"
					>
						<DisplayOnLoad {...usersLoadingState}>
							{renderMemberList()}
						</DisplayOnLoad>
					</List>
				</ClickAwayListener>
			</PopoverBase>
		</StyledHeadingContainer>
	);
};

export default UserPromotionPanel;
