import FlexContainer from '../FlexContainer';
import { PopoverContentColumns, PopoverImage } from './styledComponents';
import { FunctionComponent, ReactNode } from 'react';

interface PopoverMainContentProps {
	children?: ReactNode;
}

const PopoverMainContent: FunctionComponent<PopoverMainContentProps> = ({
	children,
}) => {
	return (
		<PopoverContentColumns>
			{/*<FlexContainer justifyContent="start">*/}
			<PopoverImage />
			{/*</FlexContainer>*/}
			<FlexContainer flexDirection="column" style={{ maxWidth: '100%' }}>
				{children}
			</FlexContainer>
		</PopoverContentColumns>
	);
};

export default PopoverMainContent;
