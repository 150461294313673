import FlexContainer from 'common/FlexContainer';
import Typography from 'common/Typography';
import { FunctionComponent } from 'react';
import styled from 'styled-components';

const StyledMain = styled.div`
	background-color: ${(p) => p.theme.palette.darkBaby};
	width: 100%;
	height: 100vh;
	min-height: 600px;
	color: ${(p) => p.theme.palette.common.white};
`;

const StyledContainer = styled(FlexContainer)`
	margin: auto;
	padding-top: ${(p) => 2 * p.theme.navHeight}px;
	width: 40%;
	gap: ${(p) => p.theme.spacing(4)};

	@media screen and (max-width: 1200px) {
		width: 80%;
	}
`;

const StyledLogo = styled.img`
	width: '75%';
	height: '75%';

	margin-top: ${(p) => p.theme.spacing(10)};

	@media screen and (max-width: 600px) {
		width: 90%;
	}
`;

export interface ReserveUserResponse {}

export interface ReserveUserParams {
	email: string;
	username: string;
	firstName: string;
	lastName: string;
}

// type TeaserFormValues = ReserveUserParams;

// interface TGProps {
// 	shouldEnter: boolean;
// }

// const InputTransitionGroup: FunctionComponent<TGProps> = ({
// 	children,
// 	shouldEnter,
// }) => {
// 	const xitionRef = useRef<any>();
// 	return (
// 		<CSSTransition
// 			classNames="swipe-transition"
// 			timeout={300}
// 			in={shouldEnter}
// 			nodeRef={xitionRef}
// 			appear={true}
// 		>
// 			<SwipeInOut ref={xitionRef}>{children}</SwipeInOut>
// 		</CSSTransition>
// 	);
// };

const TeaserPage: FunctionComponent = () => {
	// const {
	// 	formState: { errors },
	// 	register,
	// 	handleSubmit,
	// 	trigger,
	// } = useForm<TeaserFormValues>({
	// 	defaultValues: {
	// 		email: '',
	// 		username: '',
	// 		firstName: '',
	// 		lastName: '',
	// 	},
	// });

	// const [reserve, reserveResult] = useReserveUserMutation();

	// const [formPosition, setFormPosition] = useState(0);

	// const advance = () => {
	// 	setFormPosition((p) => (p + 1) % 3);
	// };

	// const handleClick: MouseEventHandler = async () => {
	// 	const toValidate =
	// 		formPosition === 0
	// 			? 'email'
	// 			: formPosition === 1
	// 			? (['firstName', 'lastName'] as const)
	// 			: 'username';

	// 	const canContinue = await trigger(toValidate);

	// 	const canSubmit = formPosition === 2;

	// 	if (canContinue) {
	// 		if (canSubmit) {
	// 			return handleSubmit((vals) => reserve(vals))();
	// 		}

	// 		return advance();
	// 	}

	// 	return;
	// };

	return (
		<StyledMain>
			<StyledContainer flexDirection="column">
				<StyledLogo
					style={{
						position: 'relative',
						top: 75,
						marginLeft: 'auto',
						marginRight: 'auto',
					}}
					src="https://storage.googleapis.com/davis-associates.appspot.com/futuremodel-teaser.svg"
					alt="Futuremodel logo"
				/>
				{/* {reserveResult.isSuccess ? (
					<FlexContainer justifyContent="center">
						<div>
							<Success size="lg" />
							<Typography
								color="primary"
								style={{ fontSize: '2rem' }}
							>
								You're All Set!
							</Typography>
						</div>
					</FlexContainer>
				) : (
					<StyledForm onSubmit={(e) => e.preventDefault()}>
						<div style={{ overflow: 'hidden' }}>
							<InputTransitionGroup
								shouldEnter={formPosition === 0}
							>
								<TextBox
									placeholder="Your email"
									overrides={{
										root: {
											padding: '.6rem',
										},
									}}
									type="text"
									{...register('email', {
										required:
											'Please enter a valid email address',
										validate: isValidEmail,
									})}
									error={errors.email}
								/>
							</InputTransitionGroup>
							<InputTransitionGroup
								shouldEnter={formPosition === 1}
							>
								<StyledDoubleInput>
									<TextBox
										placeholder="First Name"
										{...register('firstName', {
											required:
												'Please enter your first name',
										})}
										error={errors.firstName}
										overrides={{
											root: {
												padding: '.6rem',
												flex: 1,
											},
										}}
									/>
									<TextBox
										overrides={{
											root: {
												padding: '.6rem',
												flex: 1,
											},
										}}
										error={errors.lastName}
										placeholder="Last Name"
										{...register('lastName', {
											required:
												'Please enter your last name',
										})}
									/>
								</StyledDoubleInput>
							</InputTransitionGroup>
							<InputTransitionGroup
								shouldEnter={formPosition === 2}
							>
								<TextBox
									overrides={{
										root: {
											padding: '.6rem',
										},
									}}
									placeholder="Username"
									{...register('username', {
										required: 'Please enter a username',
									})}
									error={errors.username}
								/>
							</InputTransitionGroup>
						</div>
						<Button
							type="button"
							onClick={handleClick}
							// Limit button height to keep it from blowing up when the inputs have errs
							style={{ maxHeight: '45px' }}
						>
							{formPosition === 0
								? 'Join The List'
								: formPosition === 1
								? 'Continue...'
								: 'Submit'}
						</Button>
						<FormResults {...reserveResult} />
					</StyledForm>
				)} */}
				<StyledLogo
					style={{
						position: 'relative',
						top: '50px',
						width: '100%',
						maxHeight: '30vh',
						minHeight: '100px',
					}}
					src="https://storage.googleapis.com/davis-associates.appspot.com/fm-hero-figure.svg"
					role="presentation"
				/>
				<Typography
					paragraph
					style={{
						color: '#3e4354',
						textAlign: 'center',
						fontSize: '12px',
						position: 'relative',
						top: '50px',
						right: 0,
						left: 0,
					}}
				>
					Futuremodel © 2022
				</Typography>
			</StyledContainer>
		</StyledMain>
	);
};

export default TeaserPage;
