import MemberPanel from './MemberPanel';
import UserPromotionPanel from './UserPromotionPanel';
import {
	StyledMemberMgmtHeading,
} from './styledComponents';
import Typography from 'common/Typography';
import { extractQueryErrMessage, mergeQueryStates } from 'features/api/helpers';
import { canEdit as canEditAccount } from 'features/authentication/helpers';
import useActiveAccountData from 'features/ontology/hooks/useActiveAccountData';
import useActiveDomainData from 'features/ontology/hooks/useActiveDomainData';
import { BaseAccount } from 'features/ontology/types/accountTypes';
import { FunctionComponent } from 'react';

const AccountConsole: FunctionComponent = () => {
	const { activeItem: activeAccount, ...accountQueryRest } =
		useActiveAccountData();

	const { activeItem: activeDomain, ...domainQueryRest } =
		useActiveDomainData();

	const canEdit = activeAccount ? canEditAccount(activeAccount) : false;

	const { isError, error, isLoading, isUninitialized } = mergeQueryStates(
		accountQueryRest,
		domainQueryRest
	);

	if (isError && error) {
		return (
			<Typography color="error">
				{extractQueryErrMessage(error)}
			</Typography>
		);
	}

	if (isLoading || isUninitialized) {
		return <Typography>Loading...</Typography>;
	}

	return (
		<>
			<StyledMemberMgmtHeading component="h2">
				{activeAccount?.label}
			</StyledMemberMgmtHeading>
			<MemberPanel
				canEdit={canEdit}
				authorityLevel="admin"
				contextLevel="account"
				accountId={(activeAccount as BaseAccount)._id}
				domainId={activeDomain?._id}
			/>
			<MemberPanel
				canEdit={canEdit}
				authorityLevel="governor"
				contextLevel="account"
				accountId={(activeAccount as BaseAccount)._id}
				domainId={activeDomain?._id}
			/>
			<MemberPanel
				canEdit={canEdit}
				authorityLevel="member"
				contextLevel="account"
				accountId={(activeAccount as BaseAccount)._id}
				domainId={activeDomain?._id}
			/>
			<UserPromotionPanel
				accountId={(activeAccount as BaseAccount)._id}
				canEdit={canEdit}
			/>
		</>
	);
};

export default AccountConsole;
