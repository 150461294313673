import { List } from '../../../../common/List';
import { useGetEntityQuery } from '../../../api';
import DisplayOnLoad from '../../../api/DisplayOnLoad';
import { BaseEntity } from '../../../ontology/types/entityTypes';
import EntityActionListItem from './EntityActionListItem';
import {
	CREATE_ACTIVITY,
	CREATE_ATTRIBUTE,
	CREATE_CONDITION,
	CREATE_EVENT,
	CREATE_LOCATION,
	CREATE_RESTRICTION,
	CREATE_SPECIALIZATION,
} from 'features/forceGraphs/CONSTANTS';
import { FunctionComponent, useState } from 'react';

interface EntityActionListProps {
	entityId: number;
}

const EntityActionList: FunctionComponent<EntityActionListProps> = ({
	entityId,
}) => {
	// keep track of which action list item has a sub-menu open.  Should
	// only have one open at a time.
	const [actionWithActiveSubmenu, setEntityActionWithActiveSubmenu] =
		useState<string | null>(null);

	const { data: currentEntity, ...loadingState } = useGetEntityQuery({
		entityId,
	});

	return (
		<DisplayOnLoad {...loadingState}>
			<List>
				<EntityActionListItem
					{...(currentEntity as BaseEntity)}
					setOpenEntityAction={setEntityActionWithActiveSubmenu}
					actionTitle={CREATE_CONDITION}
					actionWithActiveSubmenu={actionWithActiveSubmenu}
					restrictionKind="specialization"
				/>
				<EntityActionListItem
					{...(currentEntity as BaseEntity)}
					setOpenEntityAction={setEntityActionWithActiveSubmenu}
					actionTitle={CREATE_ATTRIBUTE}
					actionWithActiveSubmenu={actionWithActiveSubmenu}
					restrictionKind="specialization"
				/>
				<EntityActionListItem
					{...(currentEntity as BaseEntity)}
					setOpenEntityAction={setEntityActionWithActiveSubmenu}
					actionTitle={CREATE_LOCATION}
					actionWithActiveSubmenu={actionWithActiveSubmenu}
					restrictionKind="specialization"
				/>
				<EntityActionListItem
					{...(currentEntity as BaseEntity)}
					setOpenEntityAction={setEntityActionWithActiveSubmenu}
					actionTitle={CREATE_EVENT}
					actionWithActiveSubmenu={actionWithActiveSubmenu}
					restrictionKind="specialization"
				/>
				<EntityActionListItem
					{...(currentEntity as BaseEntity)}
					setOpenEntityAction={setEntityActionWithActiveSubmenu}
					actionTitle={CREATE_SPECIALIZATION}
					actionWithActiveSubmenu={actionWithActiveSubmenu}
					restrictionKind="specialization"
				/>
				<EntityActionListItem
					{...(currentEntity as BaseEntity)}
					setOpenEntityAction={setEntityActionWithActiveSubmenu}
					actionTitle={CREATE_RESTRICTION}
					actionWithActiveSubmenu={actionWithActiveSubmenu}
					restrictionKind="restriction"
				/>
				<EntityActionListItem
					{...(currentEntity as BaseEntity)}
					setOpenEntityAction={setEntityActionWithActiveSubmenu}
					actionTitle={CREATE_ACTIVITY}
					actionWithActiveSubmenu={actionWithActiveSubmenu}
					restrictionKind="restriction"
				/>
			</List>
		</DisplayOnLoad>
	);
};

export default EntityActionList;
