import { StyledLabel, StyledIconContainer } from './styledComponents';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import TooltipBody from 'common/Tooltip';
import VisuallyHidden from 'common/VisuallyHidden';
import { forkRef } from 'common/utils/reactUtils';
import { forwardRef, HTMLAttributes } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';

interface ToggleLabelProps extends HTMLAttributes<HTMLLabelElement> {
	label: string;
	isTouchingSlider: boolean;
	isFinal: boolean;
	isCurrentlySelected: boolean;
	icon: IconProp;
	uniqueId: string;
	tooltip: string;
}

const ToggleLabel = forwardRef<HTMLLabelElement, ToggleLabelProps>(
	(
		{
			label,
			icon,
			uniqueId,
			isTouchingSlider,
			isFinal,
			isCurrentlySelected,
			tooltip,
			...baseLabelProps
		},
		ref
	) => {
		const { setTriggerRef, getTooltipProps, setTooltipRef, visible } =
			usePopperTooltip({
				placement: 'bottom',
			});

		const combinedRef = forkRef(setTriggerRef, ref);

		return (
			<>
				<StyledLabel
					htmlFor={uniqueId}
					className={clsx(isCurrentlySelected && 'is-selected')}
					ref={combinedRef}
					{...baseLabelProps}
					data-testid={`slide-toggle-label-${label}`}
				>
					<VisuallyHidden>
						<span>{label}</span>
					</VisuallyHidden>
					<StyledIconContainer
						className={clsx(
							!isFinal && 'show-divider',
							isTouchingSlider && 'hide-divider'
						)}
					>
						<FontAwesomeIcon icon={icon} size="sm" />
					</StyledIconContainer>
				</StyledLabel>
				{visible && (
					<TooltipBody {...getTooltipProps()} ref={setTooltipRef}>
						{tooltip}
					</TooltipBody>
				)}
			</>
		);
	}
);

export default ToggleLabel;
