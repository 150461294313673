import FlexContainer from '../../../common/FlexContainer';
import Typography from '../../../common/Typography';
import {
	FontAwesomeIcon,
	FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { FunctionComponent } from 'react';
import styled from 'styled-components';

const StyledCard = styled.div`
	display: flex;
	flex-direction: column;
	align-items: 'center';
	/* min-width: 100px;
	max-width: 10%; */
	padding: ${(p) => p.theme.spacing(1)};
	background: transparent;
	cursor: pointer;
	/* border: 1px solid ${(p) => p.theme.palette.divider}; */
	&:hover {
		background: #ffffff20;
	}
`;

const StyledTextDiv = styled.div`
	max-width: 200px;
	text-align: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

interface CardProps extends FontAwesomeIconProps {
	title: string;
	onClick: () => void;
}
const Card: FunctionComponent<CardProps> = ({ icon, title, onClick }) => {
	return (
		<StyledCard role="button" aria-label={title} onClick={onClick}>
			<FlexContainer justifyContent="center">
				<FontAwesomeIcon icon={icon}></FontAwesomeIcon>
			</FlexContainer>
			<StyledTextDiv>
				<Typography id={title}>{title}</Typography>
			</StyledTextDiv>
		</StyledCard>
	);
};

export default Card;
