import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import BreakoutText from 'common/BreakoutText';
import Heading from 'common/Heading';
import { List } from 'common/List';
import PaperButton from 'common/PaperButton';
import {
	PopoverMainContent,
	PopoverHeader,
	PopoverSubhead,
	PopoverCardBody,
	PopoverBottomDrawer,
	PopoverHeading,
	DropdownIcon,
} from 'common/Popover';
import Typography from 'common/Typography';
import { map, pipe } from 'common/utils/functionUtils';
import { groupAttrsForIndividualCard } from 'features/geoSpatial/components/EntityGeospatial/helpers';
import { pluralizeType } from 'features/ontology/helpers/attributeHelpers';
import {
	BaseAttribute,
	AttributeBaseType,
} from 'features/ontology/types/attributeTypes';
import { FunctionComponent, useState } from 'react';
import {
	Popup as MapboxPopup,
	PopupProps as MapboxPopupProps,
} from 'react-map-gl';
import styled from 'styled-components';

const StyledHeading = styled(Heading)`
	font-weight: ${(p) => p.theme.typography.fontWeightBold};
	text-transform: capitalize;
`;

const StyledControlContainer = styled.div`
	padding: ${(p) => p.theme.spacing(1)};
`;

const StyledSectionContainer = styled.div`
	padding-left: ${(p) => p.theme.spacing(4)};
`;

interface DropdownSection {
	type: AttributeBaseType;
	attrs: BaseAttribute[];
}

interface PopupBaseProps {
	datum: Record<string, any>;
	attributes: BaseAttribute[];
}

// NB: not currently used anywhere, but may be resurrected.
// Isolate our own popup's content from the component that renders the content into
// react-map-gl's Popup component.  react-map-gl's Popup requires a MapContext
// above it to render properly, and we'd rather not render that in our integ tests.
export const IndividualCardBase: FunctionComponent<PopupBaseProps> = ({
	datum,
	attributes,
}) => {
	const parentEntityLabel = attributes[0].entity.singular;

	const [drawerOpen, setDrawerOpen] = useState(false);

	const primaryIdentity = attributes.find((a) => a.isPrimary) ?? null;

	const secondaryIdentities =
		attributes.filter(
			(a) => a.type === 'identity' && a._id !== primaryIdentity?._id
		) ?? null;

	const renderSection = (s: DropdownSection) => (
		<StyledSectionContainer key={s.type}>
			<StyledHeading component="h6">
				{pluralizeType(s.type)}
			</StyledHeading>
			<List>
				{s.attrs.map(({ name }) => (
					<BreakoutText
						attrName={name}
						attrValue={String(datum[name])}
						key={name}
						overrides={{ root: { paddingLeft: 0 } }}
					/>
				))}
			</List>
		</StyledSectionContainer>
	);

	return (
		<>
			<PopoverMainContent>
				<PopoverHeader>
					<PopoverHeading>
						{primaryIdentity && `${datum[primaryIdentity.name]}`}
					</PopoverHeading>
					<PopoverSubhead>
						<Typography>{parentEntityLabel}</Typography>
					</PopoverSubhead>
				</PopoverHeader>
			</PopoverMainContent>
			{secondaryIdentities && (
				<List style={{ padding: '8px' }}>
					{secondaryIdentities.map((a) => (
						<BreakoutText
							key={a._id}
							attrName={a.name}
							attrValue={String(datum[a.name])}
							manualPreventScrollbarOverflow
						/>
					))}
				</List>
			)}
			<PopoverBottomDrawer open={drawerOpen}>
				{pipe(
					attributes,
					groupAttrsForIndividualCard,
					map(renderSection)
				)}
			</PopoverBottomDrawer>
			<StyledControlContainer>
				<PaperButton
					onClick={() => setDrawerOpen((p) => !p)}
					data-testid="individual-card-drawer-toggle"
				>
					<DropdownIcon
						icon={faChevronDown}
						className={clsx(drawerOpen && 'popover-menu-open')}
					/>
				</PaperButton>
			</StyledControlContainer>
		</>
	);
};

interface PopupProps extends MapboxPopupProps, PopupBaseProps {}

const Popup: FunctionComponent<PopupProps> = ({
	datum,
	attributes,
	...basePopupProps
}) => {
	return (
		<MapboxPopup {...basePopupProps} maxWidth="2000px">
			{/* We separate PopoverCardBody (a pure presentation component) from IndividualCardBase
        for now b/c we use IndividualCardBase elsewhere inside our regular PopoverBase component, which
        also provides the Paper UI*/}
			<PopoverCardBody>
				<IndividualCardBase datum={datum} attributes={attributes} />
			</PopoverCardBody>
		</MapboxPopup>
	);
};

export default Popup;
