import BottomBar from './components/BottomBar';
import HUDModal from './components/HUDModal';
import IndividualDrawer from './components/IndividualDrawer';
import LeftDrawer from './components/LeftDrawer';
import UserManagementDrawer from './components/UserManagementDrawer';
import { FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';

const HUD: FunctionComponent = () => {
	return (
		<>
			<LeftDrawer />
			<BottomBar />
			<HUDModal />
			<UserManagementDrawer />
			<IndividualDrawer />
			<Outlet />
		</>
	);
};

export default HUD;
