import {
	FilterValidator,
	CatRangeFilter,
	QsParam,
	QtyRangeFilter,
} from './types';
import {
	isAppError,
	isNonEmptyString,
	isNumber,
} from 'common/utils/typeGuards';
import { isNonNullObject } from 'common/utils/typeGuards';
import { hasOwnProperty } from 'common/utils/typeUtils';
import {
	QUERY_STRING_FILTER_PARSING_ERROR,
	CAT_RANGE_FILTER_TYPE,
	QTY_RANGE_FILTER_TYPE,
} from 'features/compositeViews/EntityViews/CONSTANTS';
import { AppError } from 'features/errorHandling/types/errorTypes';

const validateMinMax = (s: unknown) =>
	Array.isArray(s) && s.length === 2 && s.every(isNumber) && s[0] <= s[1];

const validateIncludedValues = (as: unknown) =>
	Array.isArray(as) && as.length > 0 && as.every(isNonEmptyString);

const validate = <T extends Record<string, any>>(
	toValidate: { [K in keyof T]: unknown },
	validator: FilterValidator<T>
) =>
	Object.entries(toValidate).reduce((acc, next) => {
		if (isAppError(acc)) return acc;

		const [k, v] = next;

		if (validator[k](v)) {
			// TODO: fix this type
			//    @ts-ignore
			acc[k] = v;
			return acc;
		}

		return new AppError(QUERY_STRING_FILTER_PARSING_ERROR);
	}, {} as T | AppError);

const qtyRangeValidator: FilterValidator<QtyRangeFilter> = {
	attributeName: isNonEmptyString,
	filterType: (s) => s === 'qtyrange',
	min: isNumber,
	max: isNumber,
};

export const parseQtyRangeFilter = (param: QsParam) => {
	const [attributeName, filterType] = param[0].split(':');

	const [min, max] = param[1].split(',').map(Number);

	const result = {
		attributeName,
		filterType,
		min,
		max,
	};

	return validate<QtyRangeFilter>(result, qtyRangeValidator);
};

export const createQtyRangeFilter =
	(attributeName: string) => (minMax: [number, number]) => {
		const prelims = validate(
			{ attributeName, minMax },
			{ attributeName: isNonEmptyString, minMax: validateMinMax }
		);

		if (isAppError(prelims)) {
			return prelims;
		}

		const [min, max] = minMax;

		const result = {
			searchParamKey: `${attributeName}:qtyrange`,
			searchParamValue: `${min},${max}`,
			filterType: 'qtyrange',
			attributeName,
			min,
			max,
		};

		return result;
	};

const catRangeValidator: FilterValidator<CatRangeFilter> = {
	attributeName: (s) => typeof s === 'string' && s.length > 0,
	filterType: (s) => s === 'catrange',
	includedValues: (as) =>
		Array.isArray(as) &&
		as.every((e) => typeof e === 'string' && e.length > 0),
};

export const parseCatRangeFilter = (param: QsParam) => {
	const [attributeName, filterType] = param[0].split(':');

	// NB: we encodeURIComponent() category names BEFORE we add them
	// to query string filter, so there should be any commas except those
	// we add as delineators AFTER encoding.
	// TODO: decodeURIComponent CAN throw. It is unlikely in this setting,
	// AFAIK.
	const includedValues = param[1].split(',').map(decodeURIComponent);

	const result = {
		attributeName,
		filterType,
		includedValues,
	};

	return validate<CatRangeFilter>(result, catRangeValidator);
};

export const createCatRangeFilter =
	(attributeName: string) => (includedValues: string[]) => {
		const prelims = validate(
			{ attributeName, includedValues },
			{
				attributeName: isNonEmptyString,
				includedValues: validateIncludedValues,
			}
		);

		if (isAppError(prelims)) return prelims;

		try {
			const escapedValues = includedValues.map(encodeURIComponent);

			return {
				searchParamKey: `${attributeName}:catrange`,
				searchParamValue: escapedValues.join(','),
				includedValues: escapedValues,
				attributeName,
				filterType: 'catrange',
			};
		} catch (e) {
			return new AppError(
				isNonNullObject(e) && hasOwnProperty(e, 'message')
					? (e.message as string)
					: 'failure when attempting to URI-encode encode filter values'
			);
		}
	};

export const catRangeKey = (attrName: string) =>
	`${attrName}:${CAT_RANGE_FILTER_TYPE}`;

export const qtyRangeKey = (attrName: string) =>
	`${attrName}:${QTY_RANGE_FILTER_TYPE}`;

// TODO: this code should probably be somewhere else
// const getFilterStrat = (f: AttributeFilter) =>
// 	isQtyRangeFilter(f)
// 		? (v: unknown) => isNumber(v) && f.min <= v && v <= f.max
// 		: isCatRangeFilter(f)
// 		? (v: unknown) => isNonEmptyString(v) && f.includedValues.includes(v)
// 		: () => false;

// export const filterIndividuals = (
// 	preparedData: IDataPreparer,
// 	filters: AttributeFilters
// ) => {
// 	if (filters.length === 0) return preparedData.individuals;

// 	if (!preparedData.isProcessed) {
// 		warnInDev(
// 			'attempting to filter individuals data that has not been processed...',
// 			'error'
// 		);
// 		return preparedData.individuals;
// 	}

// 	const strats = filters.map(getFilterStrat);

// 	return preparedData.individuals.filter((ind) =>
// 		strats.every((strat, i) => {
// 			const filter = filters[i];
// 			const datum =
// 				ind[
// 					preparedData.attributeFields[filter.attributeName]
// 						.referenceAttr.alias
// 				];
// 			return strat(datum);
// 		})
// 	);
// };
