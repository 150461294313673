import {
	TimeInterval,
	eventGroupIntervals,
	EventLineDrawFn,
	Lines,
	EventPoint,
	Transformers,
	EventCardDrawFn,
} from './types';
import { count } from 'common/utils/functionUtils';
import { isoParse } from 'd3-time-format';
import AttributeMeta from 'features/dataPreparation/AttributeMeta';
import DataPreparer from 'features/dataPreparation/DataPreparer';
import { PointDrawFn } from 'features/viz/types';
import styled from 'styled-components';

const StyledPoint = styled.circle`
	fill: ${(p) => p.theme.palette.primary.main};
`;

const StyledLine = styled.path`
	fill: none;
	stroke: ${(p) => p.theme.palette.primary.main};
`;

const getDateMethod = (suffix: Exclude<TimeInterval, 'none'>) =>
	`getUTC${suffix}` as const;

export const getZeroDateArgs = (
	isoString: string,
	resolution: TimeInterval | 'none'
) => {
	const baseDate = isoParse(isoString) as Date;

	//  if no group is to be used, fully resolve the passed-in data
	const sliceTo =
		resolution === 'none'
			? eventGroupIntervals.length
			: eventGroupIntervals.findIndex((el) => el === resolution) + 1;

	return eventGroupIntervals
		.slice(0, sliceTo)
		.map((interval) => baseDate[getDateMethod(interval)]());
};

export const genZeroDate = (args: any[]): number => {
	//  TODO: clean up these types
	//  set UTC Date up to the finest resolution provided in the arguments
	//  @ts-ignore
	return Date.UTC(...args);
};

export const generateLineGroups = (
	preparedData: DataPreparer,
	attrNames: string[],
	interval: TimeInterval | 'none',
	transformers: Transformers
) => {
	const metaMap = attrNames.reduce((acc, name) => {
		const meta = preparedData.getAttributeData(name);

		if (meta === null || meta === undefined) {
			throw new Error(
				`tried to access prepared individual metadata with attribute ${name}, which does not exist.`
			);
		}

		acc[name] = meta;

		return acc;
	}, {} as Record<string, AttributeMeta>);

	const [xAttrName, ...yAttrNames] = attrNames;

	if (interval === 'none') {
		//  don't perform any grouping
		const result: EventPoint[][] = new Array(yAttrNames.length)
			.fill(null)
			.map(() => []);

		const minMaxes = new Map<
			number,
			{ xMin: number; xMax: number; yMax: number }
		>();

		result.forEach((_, i) =>
			minMaxes.set(i, {
				xMin: Number.POSITIVE_INFINITY,
				xMax: Number.NEGATIVE_INFINITY,
				yMax: Number.NEGATIVE_INFINITY,
			})
		);

		preparedData.data.forEach((individual, individualIdx) => {
			yAttrNames.forEach((yAttrName, resultIdx) => {
				const xMeta = metaMap[xAttrName];
				const yMeta = metaMap[yAttrName];
				const { map: mapY } = transformers[yAttrName];

				if (
					xMeta.canUse(individualIdx) &&
					yMeta.canUse(individualIdx)
				) {
					const xDate = isoParse(individual[xAttrName]) as Date;
					const xInMS = xDate.valueOf();
					const naiveY = individual[yAttrName];
					const y = mapY ? mapY(naiveY) : naiveY;

					const minMax = minMaxes.get(resultIdx) as {
						xMin: number;
						xMax: number;
						yMax: number;
					};

					minMaxes.set(resultIdx, {
						xMin: Math.min(minMax.xMin, xInMS),
						xMax: Math.max(minMax.xMax, xInMS),
						yMax: Math.max(minMax.yMax, y),
					});

					result[resultIdx].push({
						pointId: `${xAttrName}-${yAttrName}-${individualIdx}-point`,
						lineIdx: resultIdx,
						x: xDate,
						y,
						originalIndices: [individualIdx],
					});
					return;
				}
				// if either of x and y are invalid, don't create a data point
				return;
			});
		});

		const lines: Lines = result.map((points, resultIdx) => {
			const { xMin, xMax, yMax } = minMaxes.get(resultIdx) as {
				xMin: number;
				xMax: number;
				yMax: number;
			};

			return {
				line: points,
				xMax: new Date(xMax),
				xMin: new Date(xMin),
				yMax,
			};
		});

		return lines;
	}

	// group by timestamp before converting to lines, and aggregate y values
	const result: Map<number, { originalIndices: number[]; ys: any[] }>[] =
		Array(yAttrNames.length)
			.fill(null)
			.map(() => new Map());

	preparedData.data.forEach((individual, individualIdx) => {
		yAttrNames.forEach((yAttrName, resultIdx) => {
			const xMeta = metaMap[xAttrName];
			const yMeta = metaMap[yAttrName];

			if (xMeta.canUse(individualIdx) && yMeta.canUse(individualIdx)) {
				const x: string = individual[xAttrName];
				const roundedX = genZeroDate(getZeroDateArgs(x, interval));
				// apply mapping to individual y value, if one has been provided
				const { map: mapY } = transformers[yAttrName];
				const naiveY: any = individual[yAttrName];
				const y = mapY ? mapY(naiveY) : naiveY;
				const existing = result[resultIdx].get(roundedX);

				if (!existing) {
					result[resultIdx].set(roundedX, {
						originalIndices: [individualIdx],
						ys: [y],
					});
					return;
				}

				existing.originalIndices.push(individualIdx);
				existing.ys.push(y);
				return;
			}
			// if either of x and y are invalid, don't create a data point
			return;
		});
	});

	const lines: Lines = result.map((resultMap, resultIdx) => {
		let lineXMax = Number.NEGATIVE_INFINITY;
		let lineXMin = Number.POSITIVE_INFINITY;
		let lineYMax = Number.NEGATIVE_INFINITY;

		const yAttrName = yAttrNames[resultIdx];
		const { aggregate } = transformers[yAttrName];

		const line = Array.from(resultMap.entries()).map(
			([x, { originalIndices, ys }]) => {
				// use provided aggregator if available, otherwise just count the number of y values
				const yVal = aggregate ? aggregate(ys) : count(ys);

				if (x > lineXMax) {
					lineXMax = x;
				}

				if (x < lineXMin) {
					lineXMin = x;
				}

				if (yVal > lineYMax) {
					lineYMax = yVal;
				}

				const point: EventPoint = {
					pointId: `${xAttrName}-${yAttrName}-${originalIndices[0]}`,
					lineIdx: resultIdx,
					x: new Date(x),
					y: yVal,
					originalIndices,
				};
				return point;
			}
		);

		return {
			line,
			xMax: new Date(lineXMax),
			xMin: new Date(lineXMin),
			yMax: lineYMax,
		};
	});

	return lines;
};

// export const groupLine = (
// 	line: Line,
// 	interval: TimeInterval,
// 	aggregator: (as: number[]) => number
// ): Line =>
// 	Array.from(
// 		line
// 			.reduce((acc, nextPoint) => {
// 				const { x, y } = nextPoint;

// 				const key = genZeroDate(
// 					getZeroDateArgs(x, interval)
// 				).toISOString();

// 				const existing = acc.get(key);

// 				if (existing) {
// 					existing.push(y);
// 					return acc;
// 				}

// 				acc.set(key, [y]);
// 				return acc;
// 			}, new Map<string, number[]>())
// 			.entries()
// 	).map(([key, vals]) => ({ x: key, y: aggregator(vals) }));

// Because line chart needs to draw multiple discrete lines, it accepts slightly
// different fact structure than scatterplot.  Notice return type is an array of arrays--
// each inner array contains the points that belong to a single discrete line.
// export const createEventLines = <
// 	T extends PointEnhancer<string, number, any> | undefined
// >(
// 	keys: string[],
// 	facts: Facts<any>,
// 	preparedData: DataPreparer,
// 	enhancer?: T
// ): T extends PointEnhancer<string, number, infer R>
// 	? R[][]
// 	: Point<string, number>[][] => {
// 	const [xAttrName, ...yAttrNames] = keys;

// 	const result: any = [];

// 	// create an inner array for each y attr, since each y-attribute to be displayed
// 	// belongs to a different line.
// 	for (let i = 0; i < yAttrNames.length; i++) {
// 		result.push([]);
// 	}

// 	// now, distribute pairs of [xvalue, yvalue] to the inner result arrays.
// 	// Call enhancement function (if provided) on each point before adding it.
// 	facts.forEach(({ values, originalIdx }) => {
// 		const [xVal, ...yVals] = values;

// 		yVals.forEach((yVal, i) => {
// 			const point: Point<string, number> = { x: xVal, y: yVal };
// 			result[i].push(
// 				enhancer
// 					? enhancer(
// 							point,
// 							preparedData,
// 							xAttrName,
// 							yAttrNames,
// 							yAttrNames[i],
// 							originalIdx
// 					  )
// 					: point
// 			);
// 		});
// 	});

// 	return result;
// };

// Gather basic metadata and convert strings to dates in a single pass.
// NB: curry this to easily swap out d3 date parser if needed
// const _processEventPoints =
// 	(dateParser: (isoString: string) => Date) => (lines: Lines) => {
// 		let xMin: Date;
// 		let xMax: Date;
// 		let yMax: number;

// 		const output = lines.reduce((acc, nextArr) => {
// 			const nested: ProcessedLine = [];

// 			nextArr.forEach((pt) => {
// 				const date = dateParser(pt.x);

// 				if (xMin === undefined || date < xMin) {
// 					xMin = date;
// 				}

// 				if (xMax === undefined || date > xMax) {
// 					xMax = date;
// 				}

// 				if (yMax === undefined || pt.y > yMax) {
// 					yMax = pt.y;
// 				}

// 				// IMPORTANT: need to pass any extra props through to the output array--these props
// 				// need to make their way to the drawing function!!
// 				nested.push({ ...pt, x: date, y: pt.y });
// 			});

// 			acc.push(nested);

// 			return acc;
// 		}, [] as ProcessedLines);

// 		//   TODO: figure out how to deal with compiler here
// 		//   @ts-ignore
// 		return { xMax, xMin, yMax, processedLines: output };
// 	};

// export const processEventPoints = _processEventPoints(
// 	isoParse as (s: string) => Date
// );

export const drawDefaultPoint: PointDrawFn<Date, number> = ({
	drawX,
	drawY,
	pointId,
}) => (
	<StyledPoint
		cx={drawX}
		cy={drawY}
		r={5}
		opacity={1}
		//   TODO: using nanoid here is not bueno at all.
		key={pointId}
		data-testid="default-point"
	/>
);

export const drawDefaultLine: EventLineDrawFn = ({ path, lineId }) => (
	<StyledLine
		d={path}
		opacity={0.5}
		key={lineId}
		data-testid="default-line"
	/>
);

const shouldBreakLeft = (width: number, position: number) =>
	//  if cursor is more than 2/3 width of drawing surface to the left,
	// break hover tooltip left to keep all of it within drawing area
	position >= width * 0.66;

const shouldBreakUp = (height: number, position: number) =>
	//  if cursor is more than 2/3 width of drawing surface to the left,
	// break hover tooltip left to keep all of it within drawing area
	position >= height * 0.85;

export const drawDefaultCard: EventCardDrawFn = ({
	x,
	y,
	xValue,
	yValue,
	visible,
	drawHeight,
	drawWidth,
}) => {
	const offsetX = shouldBreakLeft(drawWidth, x) ? -60 : 40;
	const finalX = x + offsetX;
	const offsetY = shouldBreakUp(drawHeight, y) ? -40 : 40;
	const finalY = y + offsetY;

	if (visible) {
		return (
			<g transform={`translate(${finalX}, ${finalY})`}>
				<text fill="white">X: {xValue}</text>
				<text y={16} fill="white">
					Y: {yValue}
				</text>
			</g>
		);
	}

	return null;
};
