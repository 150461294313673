import Button from '../../../common/Button';
import FlexContainer from '../../../common/FlexContainer';
import { FormResults } from '../../../common/Form';
import Heading from '../../../common/Heading';
import Typography from '../../../common/Typography';
import { useDeleteDomainMutation } from '../../api';
import { ForceGraphContext } from '../../forceGraphs/state/GraphContextProvider';
import { SET_POPUP_ELEMENT } from '../../forceGraphs/state/actions';
import {
	StyledFlexContainer,
	StyledPaper,
	StyledDeleteButton,
} from '../common/styledComponents';
import {
	Dispatch,
	FunctionComponent,
	MouseEventHandler,
	SetStateAction,
	useContext,
} from 'react';

interface DeleteDomainFormProps {
	domainId: number;
	domainLabel: string;
	setOpenAction: Dispatch<SetStateAction<string | null>>;
}

const DeleteDomainForm: FunctionComponent<DeleteDomainFormProps> = ({
	domainId,
	domainLabel,
	setOpenAction,
}) => {
	const [_, graphDispatch] = useContext(ForceGraphContext);

	const [deleteDomain, deletionResult] = useDeleteDomainMutation();

	const onClick: MouseEventHandler = () => {
		deleteDomain({ domainId });
	};

	const onSuccessfulDelete = () =>
		graphDispatch({ type: SET_POPUP_ELEMENT, payload: null });

	return (
		<StyledPaper>
			<StyledFlexContainer flexDirection="column" alignItems="center">
				<Heading component="h2">
					<Typography>
						Are you sure you want to delete {domainLabel}?
					</Typography>
				</Heading>
				<FlexContainer
					style={{ width: '50%' }}
					justifyContent="space-between"
				>
					<Button onClick={() => setOpenAction(null)}>Go Back</Button>
					<StyledDeleteButton onClick={onClick}>
						Delete
					</StyledDeleteButton>
				</FlexContainer>
				<FormResults
					{...deletionResult}
					validationErrors={{}}
					onSuccess={onSuccessfulDelete}
				/>
			</StyledFlexContainer>
		</StyledPaper>
	);
};

export default DeleteDomainForm;
