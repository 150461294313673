import AccountConsole from './AccountConsole';
import DomainConsole from './DomainConsole';
import Heading from 'common/Heading';
import { ContextParams } from 'features/navigation/types/navigationContextTypes';
import { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';

const getContextLevel = (account?: string, domain?: string) =>
	domain ? 'domain' : account ? 'account' : null;

const UserManagementPanel: FunctionComponent = () => {
	const { account, domain } = useParams<ContextParams>();

	const navContextLevel = getContextLevel(account, domain);

	if (navContextLevel === 'domain') {
		return <DomainConsole />;
	}

	if (navContextLevel === 'account') {
		return <AccountConsole />;
	}

	return <Heading component="h2">This space available</Heading>;
};

export default UserManagementPanel;
