import Heading from 'common/Heading';
import styled from 'styled-components';

export const StyledGraphContainer = styled.div`
	overflow: hidden;
`;

export const StyledHeading = styled(Heading)`
	padding-top: ${(p) => p.theme.navHeight}px;
	color: ${(p) => p.theme.palette.darkerBaby};
	text-align: center;
	position: fixed;
	top: 50%;
`;
