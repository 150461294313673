import { ListItem, ListItemContent } from '../../../../common/List';
import PopoverBase from '../../../../common/Popover/PopoverBase';
import Typography from '../../../../common/Typography';
import CreateAttributeForm from '../../../futuremodelActions/createAttribute/CreateAttributeForm';
import CreateConditionalForm from '../../../futuremodelActions/createConditional/CreateConditionalForm';
import CreateEventForm from '../../../futuremodelActions/createEvent/CreateEventForm';
import CreateLocationForm from '../../../futuremodelActions/createLocation/CreateLocationForm';
import CreateQuestionForm from '../../../futuremodelActions/createQuestion/CreateQuestionForm';
import DeleteEntityForm from '../../../futuremodelActions/deleteEntity/DeleteEntityForm';
import MeltEntityForm from '../../../futuremodelActions/meltEntity/MeltEntityForm';
import PersistEntityForm from '../../../futuremodelActions/persistEntity/PersistEntityForm';
import RemoveImportForm from '../../../futuremodelActions/removeImport/RemoveImportForm';
import UpdateEntityForm from '../../../futuremodelActions/updateEntity/UpdateEntityForm';
import { BaseEntity } from '../../../ontology/types/entityTypes';
import { nanoid } from '@reduxjs/toolkit';
import {
	DESCRIBE_ENTITY,
	DELETE_ENTITY,
	CREATE_ATTRIBUTE,
	MELT_ENTITY,
	PERSIST_ENTITY,
	ASK_QUESTION,
	REMOVE_IMPORT,
	CREATE_CONDITION,
	CREATE_LOCATION,
	CREATE_EVENT,
	CREATE_SPECIALIZATION,
	CREATE_RESTRICTION,
	CREATE_ACTIVITY,
} from 'features/forceGraphs/CONSTANTS';
import CreateActivityForm from 'features/futuremodelActions/createActivity/CreateActivityForm';
import RestrictEntityForm from 'features/futuremodelActions/restrictEntity/RestrictEntityForm';
import { RestrictionKind } from 'features/futuremodelActions/restrictEntity/restrictEntityTypes';
import {
	FunctionComponent,
	MouseEventHandler,
	useState,
	useCallback,
	Dispatch,
	SetStateAction,
} from 'react';

const getForm = (actionTitle: string) => {
	switch (actionTitle) {
		case DESCRIBE_ENTITY:
			return UpdateEntityForm;
		case DELETE_ENTITY:
			return DeleteEntityForm;
		case CREATE_ATTRIBUTE:
			return CreateAttributeForm;
		case MELT_ENTITY:
			return MeltEntityForm;
		case PERSIST_ENTITY:
			return PersistEntityForm;
		case ASK_QUESTION:
			return CreateQuestionForm;
		case REMOVE_IMPORT:
			return RemoveImportForm;
		case CREATE_CONDITION:
			return CreateConditionalForm;
		case CREATE_LOCATION:
			return CreateLocationForm;
		case CREATE_EVENT:
			return CreateEventForm;
		case CREATE_RESTRICTION:
			return RestrictEntityForm;
		case CREATE_SPECIALIZATION:
			return RestrictEntityForm;
		case CREATE_ACTIVITY:
			return CreateActivityForm;
		default:
			return CreateAttributeForm;
	}
};

interface EntityActionListItemProps extends BaseEntity {
	actionWithActiveSubmenu: string | null;
	setOpenEntityAction: Dispatch<SetStateAction<string | null>>;
	actionTitle: string;
	restrictionKind: RestrictionKind;
}

const EntityActionListItem: FunctionComponent<EntityActionListItemProps> = ({
	actionWithActiveSubmenu,
	setOpenEntityAction,
	actionTitle,
	restrictionKind,
	...entity
}) => {
	const [anchorEl, setAnchorEl] = useState<HTMLLIElement | null>(null);

	const [popperUpdateKey, setPopperUpdateKey] = useState<string | null>(null);

	const updatePopperKey = useCallback(() => setPopperUpdateKey(nanoid()), []);

	const submenuOpen = actionWithActiveSubmenu === actionTitle;

	const onItemClick: MouseEventHandler = (e) => {
		e.stopPropagation();
		setOpenEntityAction((p) => {
			if (p && p === actionTitle) {
				return null;
			}

			return actionTitle;
		});
	};

	const AppropriateForm = getForm(actionTitle);

	return (
		<>
			<ListItem ref={setAnchorEl as any} onClick={onItemClick} button>
				<ListItemContent>
					<Typography>{actionTitle}</Typography>
				</ListItemContent>
			</ListItem>

			<PopoverBase
				anchorEl={anchorEl}
				open={submenuOpen}
				offset={0}
				placement="right"
				forceUpdateKey={popperUpdateKey}
			>
				<AppropriateForm
					{...entity}
					updatePopper={updatePopperKey}
					setOpenAction={setOpenEntityAction}
					restrictionKind={restrictionKind}
				/>
			</PopoverBase>
		</>
	);
};

export default EntityActionListItem;
