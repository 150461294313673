import FlexContainer from '../FlexContainer';
import styled from 'styled-components';

export const StyledInputContainer = styled(FlexContainer)`
	padding: ${(p) => p.theme.spacing(0.25)};
	border: 1px solid transparent;
	overflow: auto;
	flex-wrap: no-wrap;
	&:hover {
		cursor: pointer;
		border: 1px solid ${(p) => p.theme.palette.divider};
	}

	&:focus-within {
		border: 1px solid ${(p) => p.theme.palette.divider};
	}
`;

export const StyledInput = styled.input`
	position: relative;
	background-color: transparent;
	font-family: ${(p) => p.theme.typography.body1.fontFamily};
	font-size: ${(p) => p.theme.typography.body1.fontSize};
	font-weight: ${(p) => p.theme.typography.fontWeightRegular};
	color: inherit;
	outline: none;
	border: none;
	padding: ${(p) => p.theme.spacing(0.5)};
	flex-grow: 1;

	&:hover {
		cursor: pointer;
	}

	&:focus,
	&.is-dirty {
		cursor: text;
		/* background-color: ${(p) => p.theme.palette.background.paper};
		color: ${(p) => p.theme.palette.common.white}; */
	}
`;

export const StyledLabel = styled.label`
	padding-right: ${(p) => p.theme.spacing(1)};
	font-family: ${(p) => p.theme.typography.body1.fontFamily};
	font-size: ${(p) => p.theme.typography.body1.fontSize};
	font-weight: ${(p) => p.theme.typography.fontWeightBold};
`;
