import { ListItem, ListItemContent } from 'common/List';
import Spinner from 'common/Spinner';
import Typography from 'common/Typography';
import { useIdentifyEntityMutation } from 'features/api';
import { parseQueryError } from 'features/api/helpers';
import { BaseAttribute } from 'features/ontology/types/attributeTypes';
import { FunctionComponent, MouseEventHandler } from 'react';

interface IdentifyingListItemProps extends BaseAttribute {
	canEdit: boolean;
}

// If entity has no identifying attribute, clicking an attribute immediately launches
// the 'identify' query.
const IdentifyingListItem: FunctionComponent<IdentifyingListItemProps> = ({
	_id,
	plural: label,
	canEdit,
	entity: { _id: entityId },
}) => {
	const [identify, identifyResults] = useIdentifyEntityMutation();

	const loading = identifyResults.isLoading;

	const onClick: MouseEventHandler = () => {
		if (canEdit) {
			return identify({ entityId, attributeId: _id });
		}
		return;
	};

	const text = identifyResults.error
		? parseQueryError(identifyResults.error).message
		: label;

	return loading ? (
		<Spinner />
	) : (
		<ListItem button onClick={onClick}>
			<ListItemContent>
				<Typography>{text}</Typography>
			</ListItemContent>
		</ListItem>
	);
};

export default IdentifyingListItem;
