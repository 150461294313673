import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetAttributesQuery } from 'features/api';
import { mergeQueryStates } from 'features/api/helpers';
import useActiveEntityData from 'features/ontology/hooks/useActiveEntityData';

const useActiveEntityAttributes = () => {
	const { activeItem: activeEntity, ...entityLoadState } =
		useActiveEntityData();

	const { data: attributes, ...attrLoadState } = useGetAttributesQuery(
		activeEntity ? { entityId: activeEntity._id } : skipToken
	);

	const combinedLoadState = mergeQueryStates(entityLoadState, attrLoadState);

	return {
		...combinedLoadState,
		attributes,
	};
};

export default useActiveEntityAttributes;
