import PageRouter from '../features/routing/components/PageRouter';
import Providers from './Providers';
import React from 'react';

function App() {
	return (
		<Providers>
			<PageRouter />
		</Providers>
	);
}

export default App;
