import clsx from 'clsx';
import React, { forwardRef, HTMLAttributes, CSSProperties } from 'react';
import styled from 'styled-components';

interface Props {
	$button?: boolean;
	$highlightColor?: string;
	$background?: 'default' | 'paper';
}

const StyledListItem = styled.li<Props>`
	background: transparent;
	cursor: ${(p) => (p.$button ? 'pointer' : 'inherit')};
	&:hover {
		background: ${(p) =>
			p.$background === 'paper'
				? '#ffffff20'
				: p.theme.palette.darkBaby + 50};
	}
	padding: ${(p) => p.theme.spacing(0.5)};
	display: flex;
	align-items: center;
	align-content: center;
	color: ${(p) => p.theme.palette.oldManGray};

	&.highlight-list-item-hover:hover {
		color: ${(p) => p.$highlightColor || p.theme.palette.primary.main};
	}
`;

export const ListItemControls = styled.div<{
	justifyContent?: CSSProperties['justifyContent'];
}>`
	display: flex;
	flex-basis: 20%;
	align-items: center;
	align-content: center;
	justify-content: ${(p) => p.justifyContent ?? 'flex-end'};
`;

export const ListItemContent = styled.div<{ truncate?: boolean }>`
	/* display: flex;
	justify-content: flex-start; */
	text-overflow: ${(p) => (p.truncate ? 'ellipsis' : 'default')};
	white-space: ${(p) => (p.truncate ? 'nowrap' : 'default')};
	overflow: ${(p) => (p.truncate ? 'hidden' : 'default')};
	flex-grow: 1;
`;

ListItemContent.defaultProps = { truncate: false };

export const ListItemAvatar = styled.div`
	flex: 0 0 10%;
`;

export interface ListItemProps extends HTMLAttributes<HTMLLIElement> {
	button?: boolean;
	highlightOnHover?: boolean;
	highlightColor?: string;
	background?: 'default' | 'paper';
}

export const ListItem = forwardRef<HTMLElement, ListItemProps>(
	(
		{
			background = 'paper',
			children,
			button,
			highlightOnHover,
			className,
			highlightColor,
			...props
		},
		ref
	) => {
		const classes = clsx(
			highlightOnHover && 'highlight-list-item-hover',
			className
		);

		return (
			<StyledListItem
				ref={ref as any}
				$background={background}
				$button={button}
				$highlightColor={highlightColor}
				role={button ? 'button' : undefined}
				className={classes}
				{...props}
			>
				{children}
			</StyledListItem>
		);
	}
);

const StyledList = styled.ul`
	list-style-type: none;
`;

interface ListProps extends HTMLAttributes<HTMLUListElement> {}

export const List = forwardRef<HTMLElement, ListProps>(
	({ children, ...props }, ref) => {
		return (
			<StyledList ref={ref as any} {...props}>
				{children}
			</StyledList>
		);
	}
);
