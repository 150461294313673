import { useAppDispatch } from '../../../../app/hooks';
import Button from '../../../../common/Button';
import {
	FormHeading,
	ModalFormBody,
	FormResults,
} from '../../../../common/Form';
import IconButton from '../../../../common/IconButton';
import Textbox from '../../../../common/Textbox';
import useModalType from '../../../HUD/hooks/useModalType';
import { setModalType } from '../../../HUD/state/HUDSlice';
import { useCreateEntityMutation } from '../../../api';
import useActiveDomainData from '../../../ontology/hooks/useActiveDomainData';
import { FunctionComponent, useEffect, useRef } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';

const StyledIconButton = styled(IconButton)`
	position: absolute;
	top: ${(p) => p.theme.spacing(2)};
	right: ${(p) => p.theme.spacing(2)};
`;
interface EntityCreationFormProps {
	domainId: number | null;
}

// Accept domainId as a prop to avoid needing complicated route matching in tests for this form
export const EntityCreationFormBase: FunctionComponent<
	EntityCreationFormProps
> = ({ domainId }) => {
	const {
		register,
		handleSubmit,
		formState,
		reset: resetForm,
	} = useForm({
		defaultValues: {
			singular: '',
			plural: '',
		},
	});

	const [createDomain, mutationResult] = useCreateEntityMutation({
		selectFromResult: (res) => ({
			isLoading: res.isLoading,
			isSuccess: res.isSuccess,
			error: res.error,
			isError: res.isError,
		}),
	});

	const syncMutationResult = useRef<any>(null);

	const appDispatch = useAppDispatch();

	const modalType = useModalType();

	const creationFormOpen = modalType === 'creationForm';

	useEffect(() => {
		if (formState.isDirty && !creationFormOpen) {
			resetForm();
		}
	}, [formState, creationFormOpen, resetForm]);

	const handleClose = () => {
		if (syncMutationResult.current) {
			syncMutationResult.current.unsubscribe();
			syncMutationResult.current = null;
		}
		appDispatch(setModalType(null));
	};

	const onSubmit: SubmitHandler<{ singular: string; plural: string }> = (
		{ plural, singular },
		e
	) => {
		e?.preventDefault();

		if (domainId === null) {
			console.error(
				'Attempted to submit entity creation form when no active user was matched in URL!'
			);
			return;
		}

		syncMutationResult.current = createDomain({
			singular,
			plural,
			domainId,
		});
	};

	return (
		<ModalFormBody
			aria-labelledby="create-entity-form"
			onSubmit={(e) => {
				e.preventDefault();
			}}
		>
			<FormHeading
				backgroundVariant="paper"
				id="create-entity-form"
				component="h1"
			>
				Create new entity
			</FormHeading>
			<Textbox
				{...register('singular', {
					required: {
						value: true,
						message: 'Singular is required',
					},
				})}
				error={formState.errors.singular}
				labelText="Singular"
			/>
			<Textbox
				{...register('plural', {
					required: {
						value: true,
						message: 'Plural is required',
					},
				})}
				error={formState.errors.plural}
				labelText="Plural"
			/>
			<Button
				onClick={handleSubmit(onSubmit)}
				disabled={domainId === null ? true : false}
			>
				Submit
			</Button>
			<FormResults
				{...mutationResult}
				validationErrors={formState.errors}
				onSuccess={handleClose}
				onSuccessDelay={1500}
			></FormResults>
		</ModalFormBody>
	);
};

const EntityCreationForm: FunctionComponent = () => {
	const { activeItem } = useActiveDomainData();

	const domainId = activeItem ? activeItem._id : null;

	return <EntityCreationFormBase domainId={domainId} />;
};

export default EntityCreationForm;
