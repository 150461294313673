import IconButton from '../../../common/IconButton';
import SourceForm from './SourceForm';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FunctionComponent } from 'react';
import styled from 'styled-components';

const StyledBackIconButton = styled(IconButton)`
	position: absolute;
	top: ${(p) => p.theme.spacing(2)};
	left: ${(p) => p.theme.spacing(2)};
`;

interface FormDisplayProps {
	handleBackRequest: () => void;
	accountId: number | null;
}

const FormDisplay: FunctionComponent<FormDisplayProps> = ({
	handleBackRequest,
	accountId,
}) => {
	return (
		<>
			<StyledBackIconButton
				icon={faArrowLeft}
				onClick={handleBackRequest}
				aria-label="close form"
			/>
			<SourceForm handleClose={handleBackRequest} accountId={accountId} />
		</>
	);
};

export default FormDisplay;
