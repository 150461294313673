import { default as theme } from '../../../../../common/theme/theme';
import useElementSize from 'common/hooks/useSize';
import { StyledLeftDrawerPanel } from 'features/HUD/components/styledComponents';
import DisplayOnLoad from 'features/api/DisplayOnLoad';
import { QueryStateTracker } from 'features/api/helpers';
import useEntitySearchParams from 'features/compositeViews/EntityViews/hooks/useEntitySearchParams';
import { Facts } from 'features/dataPreparation/dataPreparationTypes';
import {
	StyledContentContainer,
	StyledHeading,
} from 'features/geoSpatial/components/styledComponents';
import Histogram from 'features/viz/Histogram';
import { FunctionComponent, useMemo } from 'react';

interface HUDHistoProps extends QueryStateTracker {
	facts: Facts<number>;
	attrName: string;
	attrPlural: string;
}

const HUDHistogramModule: FunctionComponent<HUDHistoProps> = ({
	attrName,
	attrPlural,
	facts,
	...queryState
}) => {
	const { setQtyRangeFilter } = useEntitySearchParams();

	//  don't need any checks on event type for this callback--it's only invoked by the Histogram
	// brush event if the needed data is available and correctly formatted.
	const onBrushEnd = (minMax: [number, number]) => {
		setQtyRangeFilter(attrName, minMax);
	};

	const [size, setSizeTarget] = useElementSize();

	const binCount = useMemo(() => Math.min(30, new Set(facts).size), [facts]);

	return (
		<StyledLeftDrawerPanel
			style={{
				padding: 0,
				display: 'flex',
				flexDirection: 'column',
				overflow: 'hidden',
			}}
		>
			<StyledHeading
				component="h6"
				style={{
					color: theme.palette.oldManGray,
					margin: '5px',
				}}
			>
				{attrPlural}
			</StyledHeading>
			<DisplayOnLoad {...queryState} spinnerDiameter={50}>
				<StyledContentContainer ref={setSizeTarget}>
					<Histogram
						width={size.width}
						height={size.height}
						binCount={binCount}
						facts={facts.map((f) => f.values[0])}
						top={4}
						bottom={4}
						right={4}
						left={4}
						xAxis={false}
						yAxis={false}
						onBrushEnd={onBrushEnd}
					/>
				</StyledContentContainer>
			</DisplayOnLoad>
		</StyledLeftDrawerPanel>
	);
};

export default HUDHistogramModule;
