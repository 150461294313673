import ShareButton from '../ShareButton';
import {
	StyledVizPaper,
	StyledSubmoduleHeader,
	StyledSubmoduleContent,
	StyledSubmoduleFooter,
} from '../styledComponents';
import FlexContainer from 'common/FlexContainer';
import Typography from 'common/Typography';
import useElementSize from 'common/hooks/useSize';
import { isAppError } from 'common/utils/typeGuards';
import DisplayOnLoad from 'features/api/DisplayOnLoad';
import useEntitySearchParams from 'features/compositeViews/EntityViews/hooks/useEntitySearchParams';
import useDispatchableErr from 'features/errorHandling/hooks/useDispatchableErr';
import useActiveIndividualsMeta from 'features/ontology/hooks/useActiveIndividualsMeta';
import Histogram from 'features/viz/Histogram';
import {
	ChangeEvent,
	FunctionComponent,
	useLayoutEffect,
	useMemo,
	useState,
} from 'react';
import styled from 'styled-components';
import { debounce } from 'throttle-debounce';

const StyledSlider = styled.input`
	width: 100%;
`;

const svgId = 'histo-for-capture';

const HistogramModule: FunctionComponent = () => {
	const dispatchError = useDispatchableErr();

	const { getActiveAttributeName } = useEntitySearchParams();

	const [sliderValue, setSliderValue] = useState(1);

	const [binCount, setBinCount] = useState(1);

	const [size, setSizeTarget] = useElementSize();

	const debounced = useMemo(
		() =>
			debounce(250, (v: number) => {
				setBinCount(v);
			}),
		[]
	);

	const attributeName = getActiveAttributeName();

	const { preparedData, ...individualsLoadState } =
		useActiveIndividualsMeta();

	const facts = useMemo(() => {
		if (preparedData && attributeName) {
			const facts = preparedData.facts<number>(attributeName);

			if (isAppError(facts)) {
				dispatchError(facts);
				return [] as number[];
			}

			return facts.reduce(
				(acc, f) => acc.concat(f.values),
				[] as number[]
			);
		}

		return [] as number[];
	}, [attributeName, preparedData, dispatchError]);

	const onSliderChange = (e: ChangeEvent<HTMLInputElement>) => {
		const newVal = parseInt(e.target.value, 10);
		setSliderValue(newVal);
		debounced(newVal);
	};

	const maxThresholds = useMemo(() => new Set(facts).size, [facts]);

	useLayoutEffect(() => {
		// never more than 30 bins, but use the number of unique values in the data
		// if that value is less than 30 (since it never makes sense to use a larger number).
		const target = Math.min(30, maxThresholds);
		setSliderValue(target);
		setBinCount(target);
	}, [maxThresholds]);

	return (
		<StyledVizPaper>
			<StyledSubmoduleHeader>
				<FlexContainer
					justifyContent="space-between"
					alignContent="center"
				>
					<Typography color="cyan">
						Distribution of {attributeName}
					</Typography>
					<Typography color="primary">
						Bin Count: {sliderValue}
					</Typography>
					<ShareButton elementId={svgId} />
				</FlexContainer>
			</StyledSubmoduleHeader>
			<DisplayOnLoad {...individualsLoadState} spinnerDiameter={50}>
				<StyledSubmoduleContent ref={setSizeTarget} id={svgId}>
					<Histogram
						// svgId={svgId}
						width={size.width}
						height={size.height}
						binCount={binCount}
						facts={facts}
					/>
				</StyledSubmoduleContent>
				<StyledSubmoduleFooter>
					<StyledSlider
						onChange={onSliderChange}
						type="range"
						min="1"
						max={maxThresholds}
						value={sliderValue}
					/>
				</StyledSubmoduleFooter>
			</DisplayOnLoad>
		</StyledVizPaper>
	);
};

export default HistogramModule;
