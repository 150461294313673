import IconButton from '../IconButton';
import {
	StyledInputContainer,
	StyledLabel,
} from '../SubtleTextbox/styledComponents';
import Typography from '../Typography';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { forkRef } from 'common/utils/reactUtils';
import {
	forwardRef,
	MouseEventHandler,
	PropsWithChildren,
	useRef,
} from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

export interface SubtleSelectProps extends UseFormRegisterReturn {
	isDirty: boolean;
	label: string;
	onReset?: () => void;
	error?: string;
}

const SubtleSelect = forwardRef<
	HTMLInputElement,
	PropsWithChildren<SubtleSelectProps>
>(({ isDirty, children, onReset, label, error, ...rest }, forwardedRef) => {
	const resetterRef = useRef<HTMLInputElement>();

	const setRefs = forkRef(forwardedRef, resetterRef);

	const onContainerClick: MouseEventHandler<HTMLDivElement> = () => {
		if (resetterRef.current) {
			resetterRef.current.focus();
		}
	};

	return (
		<div>
			<StyledInputContainer
				justifyContent="space-between"
				alignItems="center"
				onClick={onContainerClick}
				data-testid="subtle-input-container"
			>
				<div>
					<StyledLabel htmlFor={`${label}-subtle-input`}>
						{`${label}:`}
					</StyledLabel>
					<select
						id={`${label}-subtle-input`}
						className={clsx(isDirty && 'is-dirty')}
						aria-invalid={Boolean(error)}
						aria-errormessage={`${label}-error-message`}
						// @ts-ignore
						ref={setRefs}
						{...rest}
					>
						{children}
					</select>
				</div>
				{isDirty && (
					<IconButton
						size="xs"
						icon={faTimes}
						aria-label={`reset ${label} field`}
						tooltipPlacement="top"
						tooltip={`reset ${label} field`}
						onClick={(e) => {
							e.preventDefault();
							onReset && onReset();
						}}
					/>
				)}
				<div>
					{error && (
						<Typography color="error" id={`${label}-error-message`}>
							{error}
						</Typography>
					)}
				</div>
			</StyledInputContainer>
		</div>
	);
});

export default SubtleSelect;
