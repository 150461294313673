import Button from '../../../common/Button';
import {
	formFieldHasErrors,
	FormResults,
	genErrorIdFromLabel,
} from '../../../common/Form';
import Typography from '../../../common/Typography';
import { useUpdateEntityMutation } from '../../api';
import { EntityActionFormProps } from '../common/commonTypes';
import { createBasicValidation, emptyStringToNull } from '../common/helpers';
import {
	StyledFlexContainer,
	StyledPaper,
	StyledTextArea,
} from '../common/styledComponents';
import { UpdateEntityFormValues } from './updateEntityTypes';
import { FunctionComponent } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

const UpdateEntityForm: FunctionComponent<EntityActionFormProps> = (props) => {
	const { _id, setOpenAction, singular, plural, definition } = props;

	const { register, handleSubmit, formState } =
		useForm<UpdateEntityFormValues>({
			defaultValues: {
				singular,
				plural,
				definition: definition ?? '',
			},
		});

	const [updateEntity, updateResult] = useUpdateEntityMutation();

	const onSubmit: SubmitHandler<UpdateEntityFormValues> = (vals, e) => {
		e?.preventDefault();
		const body = {
			...vals,
			definition: emptyStringToNull(vals.definition),
		};
		updateEntity({ entityId: _id, body });
	};

	const onSuccessfulUpdate = () => setOpenAction(null);

	return (
		<StyledPaper>
			<form onSubmit={handleSubmit(onSubmit)}>
				<StyledFlexContainer flexDirection="column" alignItems="center">
					<label htmlFor="singular">
						<Typography>
							What should one of this entity be called?
						</Typography>
					</label>
					<input
						type="text"
						{...register(
							'singular',
							createBasicValidation('singular')
						)}
						id="singular"
						aria-errormessage={genErrorIdFromLabel('singular')}
						aria-invalid={formFieldHasErrors('singular', formState)}
					/>

					<label htmlFor="plural">
						<Typography>
							What should many of this entity be called?
						</Typography>
					</label>
					<input
						type="text"
						{...register('plural', createBasicValidation('plural'))}
						id="plural"
						aria-errormessage={genErrorIdFromLabel('plural')}
						aria-invalid={formFieldHasErrors('plural', formState)}
					/>

					<label htmlFor="definition">
						<Typography>Define this entity</Typography>
					</label>
					<StyledTextArea
						{...register('definition')}
						aria-errormessage={genErrorIdFromLabel('definition')}
						aria-invalid={formFieldHasErrors(
							'definition',
							formState
						)}
						id="definition"
					/>
					<FormResults
						{...updateResult}
						validationErrors={formState.errors}
						onSuccess={onSuccessfulUpdate}
						onSuccessDelay={1500}
					/>

					<Button type="submit">Submit</Button>
				</StyledFlexContainer>
			</form>
		</StyledPaper>
	);
};

export default UpdateEntityForm;
