import { composeName } from '../helpers';
import useMemberQueryHook from '../hooks/useMemberQueryHook';
import {
	AccountAuthorityLevel,
	DomainAuthorityLevel,
} from '../types/MemberManagementTypes';
import AccountPromoteButton from './AccountPromoteButton';
import DomainPromoteButton from './DomainPromoteButton';
import RemovalButton from './RemovalButton';
import {
	StyledMemberMgmtHeading,
	StyledHeadingContainer,
	StyledMemberDataListItem,
} from './styledComponents';
import ImageAvatar from 'common/ImageAvatar';
import {
	List,
	ListItemControls,
	ListItemContent,
	ListItemAvatar,
} from 'common/List';
import Spinner from 'common/Spinner';
import Typography from 'common/Typography';
import { parseQueryError } from 'features/api/helpers';
import { BaseUser } from 'features/authentication/types/userTypes';
import { FunctionComponent, useCallback, useState } from 'react';

type AdminPanelProps = {
	domainId?: number;
	accountId: number;
	canEdit: boolean;
} & (
	| { contextLevel: 'account'; authorityLevel: AccountAuthorityLevel }
	| { contextLevel: 'domain'; authorityLevel: DomainAuthorityLevel }
);

const MemberPanel: FunctionComponent<AdminPanelProps> = ({
	domainId,
	accountId,
	contextLevel,
	authorityLevel,
	canEdit,
}) => {
	const objectId = contextLevel === 'account' ? accountId : domainId;

	const {
		data: memberData,
		isLoading,
		isError,
		isUninitialized,
		error,
	} = useMemberQueryHook(contextLevel, authorityLevel, objectId);

	const [memberErr, setMemberErr] = useState<Record<string, string>>({});

	const updateMemberErr = useCallback(
		(msg: string, id: number) => setMemberErr((p) => ({ ...p, [id]: msg })),
		[]
	);

	const renderContent = () => {
		if (isError) {
			return (
				<Typography paragraph color="error">
					{parseQueryError(error).message}
				</Typography>
			);
		}

		if (isLoading || isUninitialized) {
			return <Spinner />;
		}

		return (
			<List aria-labelledby={`${contextLevel}-${authorityLevel}`}>
				{(memberData as BaseUser[]).map((member) => (
					<StyledMemberDataListItem
						background="default"
						key={member._id}
						aria-labelledby={`member-${member._id}`}
					>
						<ListItemAvatar>
							<ImageAvatar
								src={member.avatar?.thumb.url as string}
							/>
						</ListItemAvatar>
						<ListItemContent>
							<div>
								<Typography id={`member-${member._id}`}>
									{composeName(member)}
								</Typography>
							</div>
							<div>
								<Typography variant="body2">
									Joined: {member.memberSince}
									<br />
									Last Seen: {member.lastSeen}
								</Typography>
							</div>
							{!!memberErr[member._id] && (
								<div>
									<Typography color="error">
										{memberErr[member._id]}
									</Typography>
								</div>
							)}
						</ListItemContent>
						{canEdit && (
							<ListItemControls>
								<RemovalButton
									setMemberErr={updateMemberErr}
									role={authorityLevel}
									parentObjectId={objectId as number}
									parentObjectType={contextLevel}
									userId={member._id}
								/>
							</ListItemControls>
						)}
					</StyledMemberDataListItem>
				))}
			</List>
		);
	};

	//  Account "member" is the lowest level of privilege--it makes no sense to 'promote' someone
	// to that level.  So don't show the 'promote' button.
	const renderPromoteButton = () => {
		if (contextLevel === 'account') {
			if (authorityLevel === 'member') {
				return null;
			}

			return (
				<AccountPromoteButton
					accountId={accountId}
					authorityLevel={authorityLevel}
				/>
			);
		}

		if (!domainId) {
			return null;
		}

		return (
			<DomainPromoteButton
				domainId={domainId}
				accountId={accountId}
				authorityLevel={authorityLevel}
			/>
		);
	};

	return (
		<>
			<StyledHeadingContainer
				justifyContent="space-between"
				alignItems="center"
			>
				<StyledMemberMgmtHeading
					component="h3"
					id={`${contextLevel}-${authorityLevel}`}
				>
					{authorityLevel}
				</StyledMemberMgmtHeading>
				{canEdit && renderPromoteButton()}
			</StyledHeadingContainer>
			{renderContent()}
		</>
	);
};

export default MemberPanel;
