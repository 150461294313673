export const DESCRIBE_ENTITY = 'Describe entity';
export const DELETE_ENTITY = 'Delete entity';
export const CREATE_ATTRIBUTE = 'Create attribute';
export const MELT_ENTITY = 'Melt entity';
export const PERSIST_ENTITY = 'Persist entity';
export const ASK_QUESTION = 'Ask question';
export const REMOVE_IMPORT = 'Remove import';
export const CREATE_CONDITION = 'Create condition';
export const CREATE_LOCATION = 'Create location';
export const CREATE_EVENT = 'Create event';
export const CREATE_RESTRICTION = 'Create restriction';
export const CREATE_SPECIALIZATION = 'Create specialization';
export const CREATE_ACTIVITY = 'Create activity';
