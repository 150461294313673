import Button from '../../../common/Button';
import { FormResults } from '../../../common/Form';
import Typography from '../../../common/Typography';
import { useRelateAttrActionMutation } from '../../../features/api';
import { mergeErrorStates } from '../../api/helpers';
import { BaseAttribute } from '../../ontology/types/attributeTypes';
import TargetAttrSelect from '../common/TargetAttrSelect';
import { AttrActionFormProps } from '../common/commonTypes';
import { createBasicValidation } from '../common/helpers';
import {
	StyledFlexContainer,
	StyledPaper,
	StyledTextArea,
} from '../common/styledComponents';
import useAttrActionData from '../hooks/useAttrActionData';
import {
	relateAttrFormDefaults,
	relateAttrFormToPayload,
	genRelateAttrFieldLabels,
} from './relateAttributeHelpers';
import { RelateAttrFormValues } from './relateAttributeTypes';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

const RelateAttributeForm: FunctionComponent<AttrActionFormProps> = ({
	updatePopper,
	...props
}) => {
	const { handleSubmit, register, formState } = useForm<RelateAttrFormValues>(
		{
			defaultValues: relateAttrFormDefaults(props),
		}
	);

	const { _id, entity: sourceEntity } = props;

	const { isLoading, domainAttrs, domainAttrQueryResult } =
		useAttrActionData();

	// User is required to select a target attribute as the first step
	const [targetAttr, setTargetAttr] = useState<
		BaseAttribute | 'deriveNew' | null
	>(null);

	const fieldLabels = genRelateAttrFieldLabels(props, targetAttr);

	// When a target attr is selected, the form gets much bigger, so we need
	// to update the popover position
	useEffect(() => {
		if (targetAttr) {
			updatePopper();
		}
	}, [targetAttr, updatePopper]);

	const selectableAttrs = useMemo(() => {
		if (!domainAttrs) {
			return [];
		}

		// exclude attr if it is 1) same as source attr, 2) belongs to the same entity as source attr,
		// 3) is not of type 'identity'
		return domainAttrs.filter(
			(attr) => attr.type === 'identity' && attr._id !== _id
		);
	}, [domainAttrs, _id]);

	const [relateAttr, relateAttrResults] = useRelateAttrActionMutation();

	const mergedErrResults = mergeErrorStates(
		relateAttrResults,
		domainAttrQueryResult
	);

	const onSubmit: SubmitHandler<RelateAttrFormValues> = (vals, e) => {
		e?.preventDefault();
		relateAttr({
			sourceAttrId: _id,
			body: relateAttrFormToPayload(vals),
		});
	};

	return (
		<StyledPaper>
			<form onSubmit={handleSubmit(onSubmit)}>
				<StyledFlexContainer flexDirection="column" alignItems="center">
					<TargetAttrSelect
						setTargetAttr={setTargetAttr}
						loading={isLoading}
						register={register}
						selectableAttrs={selectableAttrs}
						labelText={fieldLabels.otherId}
					/>
					{targetAttr && sourceEntity && (
						<>
							<label htmlFor="singular">
								<Typography>{fieldLabels.singular}</Typography>
							</label>
							<input
								type="text"
								{...register(
									'singular',
									createBasicValidation('singular')
								)}
								id="singular"
							/>

							<label htmlFor="plural">
								<Typography>{fieldLabels.plural}</Typography>
							</label>
							<input
								type="text"
								{...register(
									'plural',
									createBasicValidation('plural')
								)}
								id="plural"
							/>

							<label htmlFor="definition">
								<Typography>
									{fieldLabels.definition}
								</Typography>
							</label>
							<StyledTextArea
								{...register('definition')}
								id="definition"
							/>

							<Typography>{fieldLabels.isDependent}</Typography>
							<div>
								<input
									id="isDependent-true"
									type="radio"
									value="true"
									{...register('isDependent', {
										required: {
											value: true,
											message:
												'A value for isDependent is required',
										},
									})}
								/>
								<label htmlFor="isDependent-true">
									<Typography>Dependent</Typography>
								</label>
							</div>
							<div>
								<input
									id="isDependent-false"
									type="radio"
									value="false"
									{...register('isDependent', {
										required: {
											value: true,
											message:
												'A value for isDependent is required',
										},
									})}
								/>
								<label htmlFor="isDependent-false">
									<Typography>Independent</Typography>
								</label>
							</div>

							<Typography>{fieldLabels.isExclusive}</Typography>
							<div>
								<input
									id="isExclusive-true"
									type="radio"
									value="true"
									{...register('isExclusive', {
										required: {
											value: true,
											message:
												'A value for isExclusive is required',
										},
									})}
								/>
								<label htmlFor="isExclusive-true">
									<Typography>Exclusive</Typography>
								</label>
							</div>
							<div>
								<input
									id="isExclusive-false"
									type="radio"
									value="false"
									{...register(
										'isExclusive',
										createBasicValidation('isExclusive')
									)}
								/>
								<label htmlFor="isExclusive-false">
									<Typography>Non-Exclusive</Typography>
								</label>
							</div>
							<label htmlFor="type">
								<Typography>{fieldLabels.type}</Typography>
							</label>
							<select
								{...register(
									'type',
									createBasicValidation('type')
								)}
							>
								<option value="relationOf">related to</option>
								<option value="sameAs">same as</option>
								<option value="oppositeOf">opposite of</option>
								<option value="alternateOf">
									alternate of
								</option>
								<option value="specializationOf">
									specialization of
								</option>
								<option value="equivalentTo">
									equivalent to
								</option>
								<option value="partOf">part of</option>
								<option value="componentOf">
									component of
								</option>
								<option value="portionOf">portion of</option>
								<option value="featureOf">feature of</option>
								<option value="memberOf">member of</option>
								<option value="attachedTo">attached to</option>
								<option value="possessedBy">
									possessed by
								</option>
								<option value="influencedBy">
									influenced by
								</option>
							</select>
						</>
					)}
					{targetAttr && <Button type="submit">Submit</Button>}
					<FormResults
						isError={
							domainAttrQueryResult.isError ||
							relateAttrResults.isError
						}
						error={mergedErrResults}
						validationErrors={formState.errors}
					/>
				</StyledFlexContainer>
			</form>
		</StyledPaper>
	);
};

export default RelateAttributeForm;
