import { StyledSubmoduleHeader } from './styledComponents';
import { faShare, faX } from '@fortawesome/free-solid-svg-icons';
import Button from 'common/Button';
import FlexContainer from 'common/FlexContainer';
import { FormResults } from 'common/Form';
import Heading from 'common/Heading';
import IconButton from 'common/IconButton';
import { List, ListItem, ListItemContent } from 'common/List';
import { PopoverBase } from 'common/Popover';
import Textbox from 'common/Textbox';
import Typography from 'common/Typography';
import { useCreateLinkedInShareMutation } from 'features/api';
import {
	initQueryStateTracker,
	mergeQueryStates,
	QueryStateTracker,
} from 'features/api/helpers';
import useOAuthTokenIsValid from 'features/authentication/hooks/useOAuthTokenIsValid';
import useLinkedInCodeRequest from 'features/imageSharing/useLinkedInCodeRequest';
import html2canvas from 'html2canvas';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

const capture = (elementId: string) => {
	const node = document.getElementById(elementId);

	if (!node) {
		return Promise.reject('Unable to find element requested for share');
	}

	return html2canvas(node).then((c) => {
		return new Promise<Blob>((res, rej) => {
			c.toBlob(
				(maybeBlob) => {
					if (maybeBlob === null) {
						return rej('Unable to convert canvas to blob');
					}

					return res(maybeBlob);
				},
				'image/jpg',
				1
			);
		});
	});
};

const StyledForm = styled.form`
	padding: ${(p) => p.theme.spacing(1)};
`;

const StyledFormContainer = styled.div`
	min-width: 240px;
`;

const StyledFormHeader = styled(StyledSubmoduleHeader)`
	display: flex;
	justify-content: space-between;
`;

interface ShareFormValues {
	comment?: string;
	description?: string;
}

interface ShareFormProps {
	elementId: string;
	handleClose: () => void;
}

const ShareForm: FunctionComponent<ShareFormProps> = ({
	elementId,
	handleClose,
}) => {
	const { register, handleSubmit, watch } = useForm<ShareFormValues>({
		defaultValues: {
			comment: '',
			description: '',
		},
	});

	const [wComment, wDescription] = watch(['comment', 'description']);

	const location = useLocation();

	const [localFormState, setLocalFormState] = useState<QueryStateTracker>(
		initQueryStateTracker()
	);

	const { login, ...loginStatus } = useLinkedInCodeRequest();

	const [launchShare, shareStatus] = useCreateLinkedInShareMutation();

	const linkedInTokenValid = useOAuthTokenIsValid('linkedin');

	const doShare = useCallback(
		async ({
			comment,
			description,
			code,
		}: ShareFormValues & { code?: string }) => {
			try {
				const blob = await capture(elementId);

				const fd = new FormData();

				fd.append('imageBlob', blob);

				fd.append('link', location.pathname + location.search);

				if (comment && comment.length > 1) {
					fd.append('comment', comment);
				}

				if (description && description.length > 1) {
					fd.append('description', description);
				}

				if (code) {
					fd.append('code', code);
				}

				launchShare({ body: fd });
			} catch (e) {
				return setLocalFormState((p) => ({
					...p,
					isError: true,
					isLoading: false,
					error: {
						status: 400,
						data: { message: e as string },
					},
				}));
			}
		},
		[elementId, location.pathname, location.search, launchShare]
	);

	const onSubmit: SubmitHandler<ShareFormValues> = async (vals) => {
		setLocalFormState((p) => ({
			...p,
			isLoading: true,
			isUninitialized: false,
		}));

		if (linkedInTokenValid) {
			return doShare(vals);
		}

		return login();
	};

	useEffect(() => {
		if (!linkedInTokenValid && loginStatus.code && loginStatus.isSuccess) {
			// if token was expired, the only way loginStatus could be in success state is
			// if user initiated 'share' submission and completed the login flow.
			// If that's the case, submit the share to api server with code.
			doShare({
				comment: wComment,
				description: wDescription,
				code: loginStatus.code,
			});
		}
	}, [linkedInTokenValid, loginStatus.code, loginStatus.isSuccess, doShare]);

	useEffect(() => {
		if (shareStatus.isSuccess) {
			// finally, if the 'share' request to api server is successful,
			// all loading is done and form should be in a success state.
			setLocalFormState((p) => ({
				...p,
				isSuccess: true,
				isError: false,
				isLoading: false,
				error: undefined,
			}));
		}
	}, [shareStatus.isSuccess]);

	const mergedQueryStates = mergeQueryStates(shareStatus, localFormState);

	useEffect(() => {
		console.log(mergedQueryStates);
	}, [mergedQueryStates]);

	return (
		<StyledFormContainer>
			<StyledFormHeader>
				<Heading component="h5">Share</Heading>
				<IconButton
					icon={faX}
					onClick={() => handleClose()}
					aria-label="close form"
					size="xs"
				/>
			</StyledFormHeader>
			<StyledForm onSubmit={handleSubmit(onSubmit)}>
				<Textbox {...register('comment')} labelText="post comment" />
				<Textbox
					{...register('description')}
					labelText="post description"
				/>
				<FlexContainer justifyContent="center">
					<Button
						disabled={
							mergedQueryStates.isLoading ||
							mergedQueryStates.isError ||
							mergedQueryStates.isSuccess
						}
					>
						Submit
					</Button>
				</FlexContainer>
				<FormResults
					{...mergedQueryStates}
					onSuccess={() => handleClose()}
					onSuccessDelay={1500}
				/>
			</StyledForm>
		</StyledFormContainer>
	);
};

interface ShareButtonProps {
	elementId: string;
}

const ShareButton: FunctionComponent<ShareButtonProps> = ({ elementId }) => {
	const [anchor, setAnchor] = useState<HTMLElement | null>(null);
	const [menuOpen, setMenuOpen] = useState<boolean>(false);
	const [mode, setMode] = useState<'menu' | 'form'>('menu');

	const handleClose = () => {
		setMode('menu');
		setMenuOpen(false);
	};

	const renderContent = () =>
		mode === 'menu' ? (
			<List>
				<ListItem button onClick={() => setMode('form')}>
					<ListItemContent>
						<Typography>Linked In</Typography>
					</ListItemContent>
				</ListItem>
			</List>
		) : (
			<ShareForm elementId={elementId} handleClose={handleClose} />
		);

	return (
		<>
			<IconButton
				icon={faShare}
				tooltip="share"
				size="xs"
				ref={setAnchor}
				onClick={() => setMenuOpen(true)}
				showTooltip={!menuOpen}
			/>
			<PopoverBase anchorEl={anchor} open={menuOpen}>
				{renderContent()}
			</PopoverBase>
		</>
	);
};

export default ShareButton;
