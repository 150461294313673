import { setModalType } from '../state/HUDSlice';
import { useAppDispatch } from 'app/hooks';
import FlexContainer from 'common/FlexContainer';
import Paper from 'common/Paper';
import { FunctionComponent } from 'react';
import styled from 'styled-components';

const StyledSourceContainer = styled(Paper)`
	height: ${(p) => `${p.theme.navHeight}px`};
	/* margin here to keep gap between button and rest of bottom bar */
	margin-right: ${(p) => p.theme.panelGutter}px;
`;

const StyledPanelButton = styled.button`
	border: none;
	box-shadow: none;
	padding: ${(p) => p.theme.spacing(1)};
	width: 100%;
	height: 100%;
	font-family: ${(p) => p.theme.typography.body1.fontFamily};
	font-size: ${(p) => p.theme.typography.body1.fontSize};
	background: transparent;
	border-radius: 4px;
	color: ${(p) => p.theme.palette.common.white};
	&:hover {
		background: ${(p) => p.theme.palette.background.paper};
		cursor: pointer;
	}
`;

const StyledSquare = styled.div`
	width: ${(p) => p.theme.spacing(3)};
	height: ${(p) => p.theme.spacing(3)};
	background-color: ${(p) => p.theme.palette.primary.main};
`;

const SourcePanelButton: FunctionComponent = () => {
	const appDispatch = useAppDispatch();

	const openBrowser = () => appDispatch(setModalType('sourceBrowser'));
	return (
		<>
			<StyledSourceContainer>
				<StyledPanelButton onClick={openBrowser}>
					<FlexContainer>
						<StyledSquare />
						<span
							style={{
								paddingLeft: '22px',
								paddingTop: '.2rem',
								fontSize: '14px',
								letterSpacing: '.1rem',
							}}
						>
							Data Browser
						</span>
					</FlexContainer>
				</StyledPanelButton>
			</StyledSourceContainer>
		</>
	);
};

export default SourcePanelButton;
