import { nanoid } from '@reduxjs/toolkit';
import FlexContainer from 'common/FlexContainer';
import { FormResults } from 'common/Form';
import PaperButton from 'common/PaperButton';
import Popover, { PopoverBaseProps } from 'common/Popover/PopoverBase';
import Textbox from 'common/Textbox';
import Typography from 'common/Typography';
import { isValidEmail } from 'common/utils/typeGuards';
import { useInviteUserMutation } from 'features/api';
import { FunctionComponent, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

interface InviteFormValues {
	email: string;
}

interface InviteMenuProps extends PopoverBaseProps {}

const InviteMenu: FunctionComponent<InviteMenuProps> = (props) => {
	const {
		register,
		handleSubmit,
		formState,
		watch,
		reset: resetForm,
	} = useForm<InviteFormValues>({
		defaultValues: { email: '' },
	});

	const [updateKey, setUpdateKey] = useState(nanoid());

	const email = watch('email');

	const [invite, inviteResults] = useInviteUserMutation();

	const { errors: validationErrs } = formState;

	const onSubmit: SubmitHandler<InviteFormValues> = (vals) => {
		invite(vals);
	};

	const handleClose = () => {
		inviteResults.reset();
		resetForm();
		if (props.handleClickAway) {
			props.handleClickAway();
		}
	};

	useEffect(() => {
		if (inviteResults.isSuccess) {
			setUpdateKey(nanoid());
		}
	}, [inviteResults.isSuccess]);

	return (
		<Popover
			{...props}
			handleClickAway={handleClose}
			forceUpdateKey={updateKey}
		>
			<form
				aria-labelledby="invite-user-form-heading"
				onSubmit={handleSubmit(onSubmit)}
			>
				<FlexContainer
					flexDirection="column"
					alignItems="center"
					style={{ gap: '8px' }}
				>
					<Typography id="invite-user-form-heading">
						Invite User
					</Typography>
					<Textbox
						{...register('email', {
							required: 'Please enter a valid email address',
							validate: isValidEmail,
						})}
						placeholder="Email"
						error={validationErrs.email}
						autoComplete="off"
						labelText="Email Address"
					/>
					<PaperButton disabled={inviteResults.isSuccess}>
						Submit
					</PaperButton>
					<FormResults
						{...inviteResults}
						successMessage={`An invitation has been sent to ${email}.`}
					/>
				</FlexContainer>
			</form>
		</Popover>
	);
};

export default InviteMenu;
