import styled from 'styled-components';

export const StyledFieldset = styled.fieldset`
	border: 0 none;
	background: ${(p) => p.theme.palette.darkBaby} !important;
	margin-right: 0.5rem;
	position: relative;
	top: 3px;
	border-radius: ${(p) => p.theme.spacing(0.5)};
	&:hover {
		background: ${(p) => p.theme.palette.darkBaby} !important;
		color: ${(p) => p.theme.palette.lightBaby};
	}
`;

export const StyledHiddenInput = styled.input`
	position: absolute;
	top: -500vh;
`;

export const StyledLabelsContainer = styled.div`
	display: flex;
	/* create container block for absolutely-positioned elements */
	position: relative;
	height: 100%;

	& div:not(:last-of-type) {
		border-right: 1px solid ${(p) => p.theme.palette.oldManGray};
	}
`;

export const StyledIconContainer = styled.div`
	margin: ${(p) => p.theme.spacing(0.5)} 0;
	display: flex;
	justify-content: center;
	width: 100%;

	&.show-divider {
		border-right: 1px solid ${(p) => p.theme.palette.divider};
	}

	&.hide-divider {
		border-right: 1px solid transparent;
	}
`;

export const StyledLabel = styled.label`
	position: relative;
	background: ${(p) => p.theme.palette.slickRick};

	/* we want the label UI to appear ABOVE the slider UI */
	z-index: 2;
	height: 100%;
	width: 2.4rem;
	display: grid;
	justify-items: center;
	align-items: center;
	cursor: pointer;
	color: ${(p) => p.theme.palette.oldManGray};
	border-radius: ${(p) => p.theme.spacing(0.5)};

	&:hover,
	&.is-selected {
		background: ${(p) => p.theme.palette.darkBaby};
		color: ${(p) => p.theme.palette.lightBaby};
	}

	&.is-selected:hover {
		background: ${(p) => p.theme.palette.darkBaby} !important;
	}
`;

export const StyledSlider = styled.div<{
	leftDisplacement: number;
	labelWidth: number;
}>`
	position: absolute;
	z-index: 1;
	background: ${(p) => p.theme.palette.darkBaby};
	transform: translate(${(p) => p.leftDisplacement - 1}px);
	height: 100%;
	width: ${(p) => p.labelWidth + 1}px;
	border-radius: ${(p) => p.theme.spacing(0.5)};
	transition: transform 0.1s ease-out;
`;
