import Button from '../../../common/Button';
import FlexContainer from '../../../common/FlexContainer';
import {
	formFieldHasErrors,
	FormResults,
	genErrorIdFromLabel,
} from '../../../common/Form';
import IconButton from '../../../common/IconButton';
import Spinner from '../../../common/Spinner';
import Typography from '../../../common/Typography';
import { useCreateConditionalMutation, useGetAttributesQuery } from '../../api';
import { extractQueryErrMessage } from '../../api/helpers';
import { GetEntityAttrsResponse } from '../../ontology/types/attributeTypes';
import { EntityActionFormProps } from '../common/commonTypes';
import { getRestrictionOperators } from '../common/helpers';
import { renderDerivationSubfields } from '../common/jsxHelpers';
import { StyledFlexContainer, StyledPaper } from '../common/styledComponents';
import {
	conditionFormDefaults,
	createConditionalFormDefaults,
	createConditionalFormToPayload,
} from './createConditionalHelpers';
import {
	ConditionFormValues,
	CreateConditionalFormValues,
} from './createConditionalTypes';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FunctionComponent, useEffect } from 'react';
import { SubmitHandler, useForm, useFieldArray } from 'react-hook-form';
import styled from 'styled-components';

const StyledConditionalBox = styled(FlexContainer)`
	border: 4px solid ${(p) => p.theme.palette.divider};
	margin: ${(p) => p.theme.spacing(1)};
`;

const CreateConditionalForm: FunctionComponent<EntityActionFormProps> = (
	props
) => {
	const { _id, setOpenAction } = props;

	const formDefaults = createConditionalFormDefaults();

	const { handleSubmit, register, formState, control, watch, setValue } =
		useForm<CreateConditionalFormValues>({
			defaultValues: formDefaults,
		});

	const { fields, append, remove } =
		useFieldArray<CreateConditionalFormValues>({
			name: 'conditions',
			control,
		});

	const [watchedConditions, watchedStaticElse] = watch([
		'conditions',
		'usingStaticElseValue',
	]);

	const addCondition = () => append(conditionFormDefaults());

	const removeCondition = (index: number) => remove(index);

	const queryRes = useGetAttributesQuery({ entityId: _id });

	//  TODO: this should really be memoized into a single call whenever
	// a subjectId changes, but useForm's 'watch' doesn't play
	// nice with useMemo.
	const getAttrType = (index: number) => {
		if (!queryRes.data) {
			return null;
		}

		const maybeAttr = queryRes.data.find(
			(attr) => attr._id === watchedConditions[index].subjectId
		);

		if (maybeAttr) {
			return maybeAttr.type;
		}

		return null;
	};

	//  TODO: maybe validate on submit rather than closing the form if there are no conditions.
	useEffect(() => {
		if (watchedConditions.length === 0) {
			setOpenAction(null);
		}
	}, [watchedConditions, setOpenAction]);

	const [createConditional, createConditionalResult] =
		useCreateConditionalMutation();

	const onSubmit: SubmitHandler<CreateConditionalFormValues> = (vals, e) => {
		e?.preventDefault();
		createConditional({
			entityId: _id,
			body: createConditionalFormToPayload(vals),
		});
	};

	if (queryRes.isLoading) {
		return (
			<FlexContainer justifyContent="center">
				<Typography paragraph>Loading attributes...</Typography>
				<Spinner />
			</FlexContainer>
		);
	}

	if (queryRes.isError) {
		return (
			<FlexContainer justifyContent="center">
				<Typography color="error" paragraph>
					{extractQueryErrMessage(queryRes.error)}
				</Typography>
			</FlexContainer>
		);
	}

	const attrData = queryRes.data as GetEntityAttrsResponse;

	return (
		<StyledPaper>
			<form onSubmit={handleSubmit(onSubmit)}>
				<StyledFlexContainer flexDirection="column" alignItems="center">
					{renderDerivationSubfields(true, register, formState)}

					{fields.map((fieldData, i) => {
						const baseFieldName = `conditions[${i}]`;

						const constructFieldName = (
							tail: keyof ConditionFormValues
						) => baseFieldName + tail;

						const subjectIdName = constructFieldName('subjectId');
						const referenceIdName =
							constructFieldName('referenceId');
						const refValueName =
							constructFieldName('referenceValue');
						const compTypeName =
							constructFieldName('comparisonType');
						const resIdName = constructFieldName('resultId');
						const resValName = constructFieldName('resultValue');
						const usingStaticRefName = constructFieldName(
							'usingStaticReference'
						);
						const usingStaticResultName =
							constructFieldName('usingStaticResult');

						const subjectAttrType = getAttrType(i);

						return (
							<StyledConditionalBox
								flexDirection="column"
								alignItems="center"
								style={{ gap: '16px', padding: '8px' }}
								key={fieldData.id}
							>
								<FlexContainer
									justifyContent="flex-end"
									style={{ width: '100%' }}
								>
									<IconButton
										size="sm"
										icon={faTimes}
										onClick={() => removeCondition(i)}
									></IconButton>
								</FlexContainer>
								<label htmlFor={subjectIdName}>
									<Typography>
										What attribute should be the subject of
										this condition?
									</Typography>
								</label>
								<select
									{...register(`conditions.${i}.subjectId`, {
										onChange: () => {
											setValue(
												`conditions.${i}.comparisonType`,
												'none'
											);
										},
										valueAsNumber: true,
										validate: (v: string | number) => {
											if (typeof v === 'string') {
												const maybeInt = parseInt(
													v,
													10
												);

												return isNaN(maybeInt)
													? 'Invalid value passed to otherId'
													: maybeInt > 0
													? true
													: 'A subject attribute ID must be selected if you are not using a static files value';
											}

											return v > 0
												? true
												: 'A subject attribute ID must be selected if you are not using a static files value';
										},
									})}
									id={subjectIdName}
									aria-errormessage={genErrorIdFromLabel(
										subjectIdName
									)}
									aria-invalid={formFieldHasErrors(
										'conditions',
										formState
									)}
								>
									{attrData.map((attr) => (
										<option
											value={attr._id}
											key={attr._id}
										>{`${attr.entity.plural}: ${attr.plural}`}</option>
									))}
								</select>

								<label htmlFor={compTypeName}>
									<Typography>
										What operation should be used for this
										comparison?
									</Typography>
								</label>
								{subjectAttrType ? (
									<select
										{...register(
											`conditions.${i}.comparisonType`,
											{
												required:
													'A comparison operator must be selected',
												validate: (v) =>
													v === 'none'
														? 'A comparison operator must be selected'
														: true,
											}
										)}
										id={compTypeName}
										aria-errormessage={genErrorIdFromLabel(
											compTypeName
										)}
										aria-invalid={formFieldHasErrors(
											'conditions',
											formState
										)}
									>
										<option value="none">
											Select a comparison:
										</option>
										{getRestrictionOperators(
											subjectAttrType
										).map(({ operator, displayValue }) => (
											<option
												value={operator}
												key={operator}
											>
												{displayValue}
											</option>
										))}
									</select>
								) : (
									<Typography color="warn">
										Select a Subject Id
									</Typography>
								)}

								<Typography id={usingStaticRefName}>
									Select an attribute or provide a static
									value to serve as the object of this
									comparison
								</Typography>
								<FlexContainer
									style={{ width: '100%', padding: '0 16px' }}
									justifyContent="space-between"
									role="radiogroup"
									aria-labelledby={usingStaticRefName}
									aria-errormessage={genErrorIdFromLabel(
										usingStaticRefName
									)}
									aria-invalid={formFieldHasErrors(
										'conditions',
										formState
									)}
								>
									<div>
										<input
											id={`${usingStaticRefName}-false`}
											type="radio"
											value="false"
											{...register(
												`conditions.${i}.usingStaticReference`,
												{
													required: {
														value: true,
														message:
															'A value for usingStaticReference is required',
													},
												}
											)}
										/>
										<label
											htmlFor={`${usingStaticRefName}-false`}
										>
											<Typography>
												Use attribute
											</Typography>
										</label>
									</div>
									<div>
										<input
											id={`${usingStaticRefName}-true`}
											type="radio"
											value="true"
											{...register(
												`conditions.${i}.usingStaticReference`,
												{
													required: {
														value: true,
														message:
															'A value for usingStaticReference is required',
													},
												}
											)}
										/>
										<label
											htmlFor={`${usingStaticRefName}-true`}
										>
											<Typography>
												Use static value
											</Typography>
										</label>
									</div>
								</FlexContainer>

								{watchedConditions[i].usingStaticReference ===
								'false' ? (
									<>
										<label htmlFor={referenceIdName}>
											<Typography>
												What attribute should be the
												object of this condition?
											</Typography>
										</label>
										<select
											{...register(
												`conditions.${i}.referenceId`,
												{
													valueAsNumber: true,
													validate: (
														v: string | number
													) => {
														if (
															typeof v ===
															'string'
														) {
															const maybeInt =
																parseInt(v, 10);

															return isNaN(
																maybeInt
															)
																? 'Invalid value passed to otherId'
																: maybeInt > 0
																? true
																: 'A subject attribute ID must be selected if you are not using a static files value';
														}

														return v > 0
															? true
															: 'A subject attribute ID must be selected if you are not using a static files value';
													},
												}
											)}
											id={referenceIdName}
											aria-errormessage={genErrorIdFromLabel(
												referenceIdName
											)}
											aria-invalid={formFieldHasErrors(
												'conditions',
												formState
											)}
										>
											{attrData.map((attr) => (
												<option
													value={attr._id}
													key={attr._id}
												>{`${attr.entity.plural}: ${attr.plural}`}</option>
											))}
										</select>
									</>
								) : (
									<>
										<label htmlFor={refValueName}>
											<Typography>
												What value should be the object
												of the comparison?
											</Typography>
										</label>
										<input
											type="text"
											{...register(
												`conditions.${i}.referenceValue`,
												{
													required: {
														value: true,
														message: `Reference value is a required field`,
													},
													maxLength: {
														value: 200,
														message: `A maximum of 200 characters is allowed for singular`,
													},
												}
											)}
											id={refValueName}
											aria-errormessage={genErrorIdFromLabel(
												refValueName
											)}
											aria-invalid={formFieldHasErrors(
												'conditions',
												formState
											)}
										/>
									</>
								)}

								<Typography id={usingStaticResultName}>
									Select an attribute or provide a static
									value that will serve as the result of this
									condition if it evaluates to 'true'
								</Typography>
								<FlexContainer
									style={{ width: '100%', padding: '0 16px' }}
									justifyContent="space-between"
									role="radiogroup"
									aria-labelledby={usingStaticResultName}
									aria-errormessage={genErrorIdFromLabel(
										usingStaticResultName
									)}
									aria-invalid={formFieldHasErrors(
										'conditions',
										formState
									)}
								>
									<div>
										<input
											id={`${usingStaticResultName}-false`}
											type="radio"
											value="false"
											{...register(
												`conditions.${i}.usingStaticResult`,
												{
													required: {
														value: true,
														message:
															'A value for usingStaticResult is required',
													},
												}
											)}
										/>
										<label
											htmlFor={`${usingStaticResultName}-false`}
										>
											<Typography>
												Use attribute
											</Typography>
										</label>
									</div>
									<div>
										<input
											id={`${usingStaticResultName}-true`}
											type="radio"
											value="true"
											{...register(
												`conditions.${i}.usingStaticResult`,
												{
													required: {
														value: true,
														message:
															'A value for usingStaticResult is required',
													},
												}
											)}
										/>
										<label
											htmlFor={`${usingStaticResultName}-true`}
										>
											<Typography>
												Use static value
											</Typography>
										</label>
									</div>
								</FlexContainer>
								{watchedConditions[i].usingStaticResult ===
								'false' ? (
									<>
										<label htmlFor={resIdName}>
											<Typography>
												What attribute should provide
												the result value for this
												condition?{' '}
											</Typography>
										</label>
										<select
											{...register(
												`conditions.${i}.resultId`,
												{
													valueAsNumber: true,
													validate: (
														v: string | number
													) => {
														if (
															typeof v ===
															'string'
														) {
															const maybeInt =
																parseInt(v, 10);

															return isNaN(
																maybeInt
															)
																? 'Invalid value passed to otherId'
																: maybeInt > 0
																? true
																: 'A result attribute ID must be selected if you are not using a static files value';
														}

														return v > 0
															? true
															: 'A result attribute ID must be selected if you are not using a static files value';
													},
												}
											)}
											id={resIdName}
											aria-errormessage={genErrorIdFromLabel(
												resIdName
											)}
											aria-invalid={formFieldHasErrors(
												'conditions',
												formState
											)}
										>
											{attrData.map((attr) => (
												<option
													value={attr._id}
													key={attr._id}
												>{`${attr.entity.plural}: ${attr.plural}`}</option>
											))}
										</select>
									</>
								) : (
									<>
										<label htmlFor={resValName}>
											<Typography>
												What should the resulting value
												of this comparison be if it
												evaluates to true?
											</Typography>
										</label>
										<input
											type="text"
											{...register(
												`conditions.${i}.resultValue`,
												{
													required: {
														value: true,
														message: `Result value is a required field if you are not using an attribute id`,
													},
													maxLength: {
														value: 200,
														message: `A maximum of 200 characters is allowed for singular`,
													},
												}
											)}
											id={resValName}
											aria-errormessage={genErrorIdFromLabel(
												resValName
											)}
											aria-invalid={formFieldHasErrors(
												'conditions',
												formState
											)}
										/>
									</>
								)}
								<FormResults
									validationErrors={
										formState.errors?.conditions
											? formState.errors.conditions[i]
											: undefined
									}
								/>
							</StyledConditionalBox>
						);
					})}

					<Typography id="usingStaticElseValue">
						Select an attribute or provide a static value to furnish
						a value if no condition evaluates to true
					</Typography>
					<FlexContainer
						style={{ width: '100%', padding: '0 16px' }}
						justifyContent="space-between"
						role="radiogroup"
						aria-labelledby="usingStaticElseValue"
						aria-errormessage={genErrorIdFromLabel(
							'usingStaticElseValue'
						)}
						aria-invalid={formFieldHasErrors(
							'conditions',
							formState
						)}
					>
						<div>
							<input
								id={`usingStaticElseValue-false`}
								type="radio"
								value="false"
								{...register(`usingStaticElseValue`, {
									required: {
										value: true,
										message:
											'A value for usingStaticElse is required',
									},
								})}
							/>
							<label htmlFor={`usingStaticElseValue-false`}>
								<Typography>Use attribute</Typography>
							</label>
						</div>
						<div>
							<input
								id={`usingStaticElseValue-true`}
								type="radio"
								value="true"
								{...register(`usingStaticElseValue`, {
									required: {
										value: true,
										message:
											'A value for usingStaticElse is required',
									},
								})}
							/>
							<label htmlFor={`usingStaticElse-true`}>
								<Typography>Use static value</Typography>
							</label>
						</div>
					</FlexContainer>
					{watchedStaticElse === 'false' ? (
						<>
							<label htmlFor="elseId">
								<Typography>Select an attribute</Typography>
							</label>
							<select
								{...register('elseId', {
									valueAsNumber: true,
									validate: (v: string | number) => {
										if (typeof v === 'string') {
											const maybeInt = parseInt(v, 10);

											return isNaN(maybeInt)
												? 'Invalid value passed to otherId'
												: maybeInt > 0
												? true
												: 'An else attribute ID must be selected if you are not using a static files value';
										}

										return v > 0
											? true
											: 'An else attribute ID must be selected if you are not using a static files value';
									},
								})}
								id="elseId"
								aria-errormessage={genErrorIdFromLabel(
									'elseId'
								)}
								aria-invalid={formFieldHasErrors(
									'elseId',
									formState
								)}
							>
								{attrData.map((attr) => (
									<option
										value={attr._id}
										key={attr._id}
									>{`${attr.entity.plural}: ${attr.plural}`}</option>
								))}
							</select>
						</>
					) : (
						<>
							<label htmlFor="elseValue">
								<Typography>Enter a static value</Typography>
							</label>
							<input
								type="text"
								{...register('elseValue', {
									required: {
										value: true,
										message: `Else value is a required field if you are not using an attribute id`,
									},
									maxLength: {
										value: 200,
										message: `A maximum of 200 characters is allowed for singular`,
									},
								})}
								id="elseValue"
								aria-errormessage={genErrorIdFromLabel(
									'elseValue'
								)}
								aria-invalid={formFieldHasErrors(
									'elseValue',
									formState
								)}
							/>
						</>
					)}
					<FlexContainer
						style={{ width: '60%' }}
						justifyContent="space-between"
					>
						<Button type="button" onClick={addCondition}>
							Add Condition
						</Button>
						<Button>Submit</Button>
					</FlexContainer>

					<FormResults
						{...createConditionalResult}
						validationErrors={{}}
					/>
				</StyledFlexContainer>
			</form>
		</StyledPaper>
	);
};

export default CreateConditionalForm;
