import Popover from '../../../../common/Popover/PopoverBase';
import { useGetAccountsQuery, useSignoutMutation } from '../../../api';
import useLogout from '../../../authentication/hooks/useLogout';
import useUserProfile from '../../../authentication/hooks/useUserProfile';
import ProfileAvatar from './ProfileAvatar';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { List, ListItem, ListItemContent } from 'common/List';
import RouterLink from 'common/RouterLink';
import Typography from 'common/Typography';
import { isEmpty } from 'common/utils/typeGuards';
import useProfileImageURL from 'features/authentication/hooks/useProfileImageURL';
import useUserID from 'features/authentication/hooks/useUserID';
import { ContextParams } from 'features/navigation/types/navigationContextTypes';
import InviteMenu from 'features/userManagement/components/InviteMenu';
import { FunctionComponent, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { useParams } from 'react-router-dom';

// const RouterLink = styled(RouterLink)`
// 	padding: ${(p) => p.theme.spacing(1)};
// 	display: inline-block;
// `;

// const ProfileMenuButton = styled.button`
// 	width: 100%;
// 	background: transparent;
// 	border: none;
// 	font-size: inherit;
// 	font-family: inherit;
// 	padding: ${(p) => p.theme.spacing(1)};
// 	cursor: pointer;
// `;

const ProfileMenu: FunctionComponent = () => {
	const { account: currentAccountName } = useParams<ContextParams>();

	const { firstName, invitesRemaining } = useUserProfile();

	const profileImageURL = useProfileImageURL();

	const initial = firstName[0];

	const [referenceEl, setReferenceEl] = useState<HTMLElement | null>(null);

	const [menuOpen, setMenuOpen] = useState(false);

	const [inviteOpen, setInviteOpen] = useState(false);

	const [revokeToken] = useSignoutMutation();

	const doLogout = useLogout();

	const handleSignout = () => {
		revokeToken();
		doLogout();
	};

	const userId = useUserID();

	const { data: accounts } = useGetAccountsQuery(
		isEmpty(userId) ? skipToken : { userId }
	);

	return (
		<>
			<ClickAwayListener
				onClickAway={() => {
					if (menuOpen) {
						setMenuOpen(false);
					}
				}}
			>
				<ProfileAvatar
					initial={initial}
					profileImageURL={profileImageURL}
					onClick={() => setMenuOpen((p) => !p)}
					ref={setReferenceEl}
				/>
			</ClickAwayListener>
			<InviteMenu
				anchorEl={referenceEl}
				open={inviteOpen}
				handleClickAway={() => setInviteOpen(false)}
			/>
			<Popover anchorEl={referenceEl} open={menuOpen} offset={10}>
				<List>
					<RouterLink to="/user/profile">
						<ListItem>
							<ListItemContent>
								<Typography>User Profile</Typography>
							</ListItemContent>
						</ListItem>
					</RouterLink>
					<RouterLink to={`/${currentAccountName}/profile`}>
						<ListItem>
							<ListItemContent>
								<Typography>Account Profile</Typography>
							</ListItemContent>
						</ListItem>
					</RouterLink>
					{accounts &&
						accounts
							.filter((a) => a.name !== currentAccountName)
							.map((a) => (
								<RouterLink to={`/${a.name}`} key={a.name}>
									<ListItem>
										<ListItemContent>
											<Typography>{a.label}</Typography>
										</ListItemContent>
									</ListItem>
								</RouterLink>
							))}
					{invitesRemaining > 0 && (
						<ListItem
							button
							onClick={() => {
								setInviteOpen(true);
								setMenuOpen(false);
							}}
						>
							<ListItemContent>
								<Typography>Send Invite</Typography>
								<br />
								<Typography>{`(${invitesRemaining} remaining)`}</Typography>
							</ListItemContent>
						</ListItem>
					)}
					<RouterLink to="/onboarding">
						<ListItem>
							<ListItemContent>
								<Typography>Help</Typography>
							</ListItemContent>
						</ListItem>
					</RouterLink>
					<ListItem button onClick={handleSignout}>
						<ListItemContent>
							<Typography>Logout</Typography>
						</ListItemContent>
					</ListItem>
				</List>
			</Popover>
		</>
	);
};

export default ProfileMenu;
