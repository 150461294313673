import { useGetDomainsQuery } from '../../api';
import { ContextParams } from '../../navigation/types/navigationContextTypes';
import { BaseDomain } from '../../ontology/types/domainTypes';
import useActiveAccountData from './useActiveAccountData';
import { createSelector } from '@reduxjs/toolkit';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useParams } from 'react-router-dom';

type QueryResponse = Omit<ReturnType<typeof useGetDomainsQuery>, 'refetch'>;

type ActiveDomainName = string | undefined;

const selectActiveDomain = createSelector(
	(res: QueryResponse) => res.data,
	(res: QueryResponse, activeDomainName: ActiveDomainName) =>
		activeDomainName,
	(data: QueryResponse['data'], activeDomainName: ActiveDomainName) =>
		data?.find((domain: BaseDomain) => domain.name === activeDomainName) ??
		null
);

/**
 * @returns RTK Query data with an activeItem property that corresponds to the data for the domain specified
 * in the application URL.
 */
const useActiveDomainData = () => {
	const params = useParams() as ContextParams;

	const { activeItem: activeAccount } = useActiveAccountData();

	const fetchData = useGetDomainsQuery(
		activeAccount ? { accountId: activeAccount._id } : skipToken,
		{
			selectFromResult: (res) => ({
				...res,
				activeItem: selectActiveDomain(
					res,
					params.domain
				) as null | BaseDomain,
			}),
		}
	);

	return fetchData;
};

export default useActiveDomainData;
