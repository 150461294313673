import IconButton from '../../../common/IconButton';
import { warnInDev } from '../../../common/utils/reactUtils';
import {
	useGenDomainFromCatalogMutation,
	useGenDomainFromDatasetMutation,
	useImportCatalogToDomainMutation,
	useImportDatasetToDomainMutation,
} from '../../api';
import { ContextParams } from '../../navigation/types/navigationContextTypes';
import useActiveAccountData from '../../ontology/hooks/useActiveAccountData';
import useActiveDomainData from '../../ontology/hooks/useActiveDomainData';
import { BaseDomain } from '../../ontology/types/domainTypes';
import { selectButtonIcon } from '../helpers';
import { DataCatalogSummary, LiveDataSet } from '../types/dataTypes';
import { canEdit as canEditAccount } from 'features/authentication/helpers';
import { FunctionComponent, MouseEventHandler } from 'react';
import { useParams } from 'react-router-dom';

type Importable = DataCatalogSummary | LiveDataSet;

const isCatalog = (i: Importable): i is DataCatalogSummary =>
	i._object === 'DataCatalog';

const generateParams = (i: Importable, domain: BaseDomain | null) => {
	if (domain) {
		if (isCatalog(i)) {
			const params = {
				domainId: domain._id,
				sourceId: i.internalSourceId,
				catalogName: i.name,
			};

			return params;
		}

		return {
			domainId: domain._id,
			sourceId: i.internalSourceId,
			catalogName: i.catalogName,
			datasetName: i.name,
		};
	}

	if (isCatalog(i)) {
		return { sourceId: i.internalSourceId, catalogName: i.name };
	}
	return {
		sourceId: i.internalSourceId,
		catalogName: i.catalogName,
		datasetName: i.name,
	};
};

const selectHook = (i: Importable, domainIsAvailable: boolean) => {
	if (domainIsAvailable) {
		if (isCatalog(i)) {
			return useImportCatalogToDomainMutation;
		}

		return useImportDatasetToDomainMutation;
	}

	if (isCatalog(i)) {
		return useGenDomainFromCatalogMutation;
	}
	return useGenDomainFromDatasetMutation;
};

interface ImportButtonProps {
	importable: Importable;
}

const ImportButton: FunctionComponent<ImportButtonProps> = ({ importable }) => {
	const { domain: domainInURL } = useParams() as ContextParams;

	const { activeItem: activeAccount } = useActiveAccountData();

	const { activeItem: activeDomain } = useActiveDomainData();

	const canEdit = activeAccount ? canEditAccount(activeAccount) : false;

	const [doImport, importResult] = selectHook(importable, !!domainInURL)();

	const waitingOnDomainLoad = domainInURL && activeDomain === null;

	const resultState = waitingOnDomainLoad
		? { isLoading: true }
		: importResult;

	const handleClick: MouseEventHandler = (e) => {
		e.preventDefault();

		// if this condition is true, there WILL be a domain available, but it hasn't loaded yet.
		// Do nothing on click in this state.
		if (waitingOnDomainLoad) {
			warnInDev(
				'Source import button clicked while domain still loading...',
				'warn'
			);
			return;
		}

		// If true, the last mutation request is still in-process.  Do nothing.
		if (importResult.isLoading) {
			warnInDev(
				'Source import button clicked while last load request still in-proces',
				'warn'
			);
			return;
		}

		// from this call site, generateParams can assume that if activeDomain
		// is null, no domain will ever be available.  This assumption will need
		// to be revisited if generateParams is ever to be called elsewhere.
		const params = generateParams(importable, activeDomain);

		// TODO: these types could probably be made to align
		doImport(params as any);
	};

	if (!canEdit) {
		return null;
	}

	return (
		<IconButton
			size="xs"
			icon={selectButtonIcon(resultState)}
			disabled={resultState.isLoading}
			onClick={handleClick}
		/>
	);
};

export default ImportButton;
