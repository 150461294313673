import {
	renderDerivationSubfields,
	renderOtherIdSelect,
} from '../common/jsxHelpers';
import {
	deriveDurationFormDefaults,
	deriveDurationFormToPayload,
	temporalUnits,
} from './createActivityHelpers';
import { CreateActivityFormValues } from './createActivityTypes';
import Button from 'common/Button';
import FlexContainer from 'common/FlexContainer';
import {
	formFieldHasErrors,
	FormResults,
	genErrorIdFromLabel,
} from 'common/Form';
import Spinner from 'common/Spinner';
import Typography from 'common/Typography';
import { useGetAttributesQuery, useOperateAttrMutation } from 'features/api';
import { mergeErrorStates, extractQueryErrMessage } from 'features/api/helpers';
import { EntityActionFormProps } from 'features/futuremodelActions/common/commonTypes';
import {
	StyledFlexContainer,
	StyledPaper,
} from 'features/futuremodelActions/common/styledComponents';
import useAttrNeighbors from 'features/futuremodelActions/hooks/useAttrNeighbors';
import { BaseAttribute } from 'features/ontology/types/attributeTypes';
import { FunctionComponent, useEffect, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

const CreateActivityForm: FunctionComponent<EntityActionFormProps> = (
	props
) => {
	const { _id: parentEntityId, updatePopper } = props;

	const formDefaults = deriveDurationFormDefaults();

	const { handleSubmit, register, formState, watch, setError } =
		useForm<CreateActivityFormValues>({
			defaultValues: formDefaults,
		});

	const watchedAttrId = watch('attrId');

	const { data: attributes } = useGetAttributesQuery(
		{
			entityId: parentEntityId,
		},
		{
			selectFromResult: (res) => {
				if (res.data) {
					return {
						...res,
						data: res.data.filter((a) => a.type === 'event'),
					};
				}

				return res;
			},
		}
	);

	//  prevent filtering from running every render
	const filter = useMemo(() => {
		return (a: BaseAttribute) => a.type === 'event';
	}, []);

	const queryRes = useAttrNeighbors(
		watchedAttrId === 0 ? null : watchedAttrId,
		filter
	);

	const firstAttribute = useMemo(() => {
		if (attributes) {
			return (
				attributes.find((attr) => attr._id === watchedAttrId) ?? null
			);
		}

		return null;
	}, [watchedAttrId, attributes]);

	useEffect(() => {
		if (queryRes.isSuccess) {
			updatePopper();
		}
	}, [queryRes.isSuccess, updatePopper]);

	const [createActivity, createActivityResult] = useOperateAttrMutation();

	const mergedErrs = mergeErrorStates(queryRes, createActivityResult);

	const onSubmit: SubmitHandler<CreateActivityFormValues> = (vals, e) => {
		e?.preventDefault();

		if (firstAttribute !== null) {
			if (vals.otherId === 0) {
				setError('otherId', {
					message:
						'An attribute to provide the end of activity must be selected',
				});
				return;
			}
			createActivity({
				attrId: firstAttribute._id,
				body: deriveDurationFormToPayload(vals),
			});
		}
	};

	if (queryRes.isLoading) {
		return (
			<FlexContainer justifyContent="center">
				<Typography paragraph>Loading attributes...</Typography>
				<br />
				<Spinner />
			</FlexContainer>
		);
	}

	if (queryRes.isError) {
		return (
			<FlexContainer justifyContent="center">
				<Typography color="error" paragraph>
					{extractQueryErrMessage(queryRes.error)}
				</Typography>
			</FlexContainer>
		);
	}

	return (
		<StyledPaper>
			<form onSubmit={handleSubmit(onSubmit)}>
				<StyledFlexContainer flexDirection="column" alignItems="center">
					<label htmlFor="attrId">
						<Typography id="restrictionId-label">
							Select attribute to provide activity start
						</Typography>
					</label>
					<select
						{...register('attrId', {
							required:
								'An attribute to provide activity start must be selected',
							valueAsNumber: true,
							validate: (v) =>
								v !== 0
									? true
									: 'An attribute to provide activity start must be selected',
						})}
						id="attrId"
						aria-errormessage={genErrorIdFromLabel('attrId')}
						aria-invalid={formFieldHasErrors('attrId', formState)}
					>
						<option value={0}>none</option>
						{attributes &&
							attributes.map((attr) => (
								<option value={attr._id} key={attr._id}>
									{attr.plural}
								</option>
							))}
					</select>

					{renderDerivationSubfields(true, register, formState)}

					{renderOtherIdSelect(
						'otherId',
						register,
						formState,
						queryRes.attrNeighbors
					)}

					<label htmlFor="temporalUnit">
						<Typography>
							Select a temporal unit to use in the creation of
							this attribute:
						</Typography>
					</label>
					<select
						{...register('temporalUnit', {
							required: {
								value: true,
								message: 'Temporal unit is required',
							},
						})}
						aria-errormessage={genErrorIdFromLabel('temporalUnit')}
						aria-invalid={formFieldHasErrors(
							'temporalUnit',
							formState
						)}
						id="temporalUnit"
					>
						{temporalUnits.map((unit) => (
							<option
								value={unit}
								style={{ textTransform: 'capitalize' }}
								key={unit}
							>
								{unit}
							</option>
						))}
					</select>

					<Button type="submit">Submit</Button>

					<FormResults
						isError={!!mergedErrs}
						error={mergedErrs}
						validationErrors={formState.errors}
					/>
				</StyledFlexContainer>
			</form>
		</StyledPaper>
	);
};

export default CreateActivityForm;
