import { getBreadcrumbKinds } from '../../helpers';
import { ContextParams } from '../../types/navigationContextTypes';
import Breadcrumb from './Breadcrumb';
import { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const BreadcrumbsContainer = styled.nav`
	display: flex;
	padding: ${(p) => p.theme.spacing(0, 1)};
`;

const Breadcrumbs: FunctionComponent = () => {
	const params = useParams<ContextParams>();

	const toRender = getBreadcrumbKinds(params);

	return (
		<BreadcrumbsContainer>
			{toRender.map((kind, i) => (
				<Breadcrumb
					key={kind}
					kind={kind}
					isTip={i === toRender.length - 1}
				/>
			))}
		</BreadcrumbsContainer>
	);
};

export default Breadcrumbs;
