import { filterAttrsForYMenu } from './helpers';
import {
	faCheck,
	faY,
	faM,
	faD,
	faH,
	faS,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FlexContainer from 'common/FlexContainer';
import { List, ListItem, ListItemAvatar, ListItemContent } from 'common/List';
import SlideToggle, { SlideToggleCheckDescriptor } from 'common/SlideToggle';
import Typography from 'common/Typography';
import useElementSize from 'common/hooks/useSize';
import theme from 'common/theme/theme';
import { count, sum } from 'common/utils/functionUtils';
import DisplayOnLoad from 'features/api/DisplayOnLoad';
import useEntitySearchParams from 'features/compositeViews/EntityViews/hooks/useEntitySearchParams';
import useDispatchableErr from 'features/errorHandling/hooks/useDispatchableErr';
import useActiveIndividualsMeta from 'features/ontology/hooks/useActiveIndividualsMeta';
import { BaseAttribute } from 'features/ontology/types/attributeTypes';
import {
	StyledAttributeList,
	StyledScatterplotContent,
	StyledSubmoduleHeader,
	StyledVizPaper,
} from 'features/profilePages/EntityProfile/components/styledComponents';
import EventLineChart from 'features/viz/EventLineChart';
import { generateLineGroups } from 'features/viz/EventLineChart/helpers';
import {
	AttributeTransformer,
	EventLineDrawFn,
	Lines,
	TimeInterval,
	Transformers,
} from 'features/viz/EventLineChart/types';
import {
	FunctionComponent,
	MouseEventHandler,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';

export const LINE_POINT_TEST_ID = 'event-line-module-test-id';

export const LINE_POPOVER_TEST_ID = 'event-line-popover-test-id';

const toggleDefs: SlideToggleCheckDescriptor<TimeInterval | 'none'>[] = [
	{
		label: 'year',
		icon: faY,
		value: 'FullYear',
		tooltip: 'years',
	},
	{ label: 'month', icon: faM, value: 'Month', tooltip: 'months' },
	{ label: 'day', icon: faD, value: 'Date', tooltip: 'days' },
	{ label: 'hour', icon: faH, value: 'Hours', tooltip: 'hours' },
	{ label: 'minute', icon: faM, value: 'Minutes', tooltip: 'minutes' },
	{ label: 'second', icon: faS, value: 'Seconds', tooltip: 'seconds' },
	// { label: 'none', icon: faBan, value: 'none', tooltip: 'ungroup' },
];

const availablePointColors = [
	theme.palette.primary.main,
	theme.palette.error.main,
	theme.palette.secondary.main,
];

const getColor = (x: number) =>
	availablePointColors[x % availablePointColors.length];

const EventLineChartModule: FunctionComponent = () => {
	const dispatchError = useDispatchableErr();

	const [size, setSizeTarget] = useElementSize();

	const [timeGrouping, setTimeGrouping] = useState<TimeInterval | 'none'>(
		'FullYear'
	);

	const {
		getActiveAttributeName,
		getAllLineChartY,
		appendLineChartY,
		removeLineChartY,
	} = useEntitySearchParams();

	const attributeName = getActiveAttributeName();

	const yAttrs = getAllLineChartY();

	const makeMenuHandler =
		(attrName: string): MouseEventHandler =>
		() => {
			if (yAttrs.includes(attrName)) {
				return removeLineChartY(attrName);
			}

			return appendLineChartY(attrName);
		};

	const { preparedData, ...individualsLoadState } =
		useActiveIndividualsMeta();

	const primaryAttribute = preparedData
		? (preparedData.attributes.find(
				(a) => a.isPrimary === true
		  ) as BaseAttribute)
		: null;

	// if no attribute for y-axis is set in URL search params,
	// set it to primary id attribute
	useEffect(() => {
		const existingLineChartY = getAllLineChartY();
		if (primaryAttribute && existingLineChartY.length === 0) {
			appendLineChartY(primaryAttribute.name);
		}

		return;
	}, [primaryAttribute, appendLineChartY, getAllLineChartY]);

	const lines = useMemo(() => {
		if (preparedData && attributeName) {
			const transformers: Transformers = yAttrs.reduce(
				(acc, yAttrName) => {
					const { type, isPrimary } = preparedData.attributes.find(
						(a) => a.name === yAttrName
					) as BaseAttribute;

					//  if chosen y-attribute is a quantity, we want to aggregate it by adding all values in a given group together.  If y-attribute
					// is the primary identity, we just want to count all the identities (i.e. the individuals) that fall into a given grouping
					const transform: AttributeTransformer =
						type === 'quantity'
							? { aggregate: sum }
							: isPrimary
							? { aggregate: count, map: () => 1 }
							: {};

					acc[yAttrName] = transform;
					return acc;
				},
				{} as Transformers
			);

			return generateLineGroups(
				preparedData,
				[attributeName, ...yAttrs],
				timeGrouping,
				transformers
			);
		}

		return [] as Lines;
	}, [attributeName, preparedData, yAttrs, timeGrouping]);

	const drawLine: EventLineDrawFn = useCallback(
		({ path, lineId, lineIdx }) => {
			return (
				<path
					d={path}
					fill="none"
					stroke={getColor(lineIdx)}
					key={lineId}
					strokeWidth={1.5}
				/>
			);
		},
		[]
	);

	const getMenuText = () =>
		yAttrs.length > 0
			? {
					color: 'default' as const,
					text: 'Y-Axis',
			  }
			: {
					color: 'error' as const,
					text: 'select Y variable',
			  };

	const menuText = getMenuText();

	return (
		<StyledVizPaper>
			<StyledSubmoduleHeader>
				<FlexContainer justifyContent="space-between">
					{/*<Typography>Line Chart</Typography>*/}
					<SlideToggle
						onActiveValueMismatch={dispatchError}
						activeValue={timeGrouping}
						legend="group events by time period"
						onCheck={setTimeGrouping}
						checkboxes={toggleDefs}
					/>
					<div></div>
				</FlexContainer>
			</StyledSubmoduleHeader>
			<StyledScatterplotContent>
				{/* chart resizeObserver will never be triggered on window resize if 
                container allowed to overflow */}
				<div ref={setSizeTarget} style={{ overflow: 'hidden' }}>
					<DisplayOnLoad
						{...individualsLoadState}
						spinnerDiameter={50}
					>
						<EventLineChart
							width={size.width}
							height={size.height}
							lines={lines}
							drawLine={drawLine}
						/>
					</DisplayOnLoad>
				</div>
				<StyledAttributeList>
					<StyledSubmoduleHeader>
						<Typography color={menuText.color}>
							{menuText.text}
						</Typography>
					</StyledSubmoduleHeader>
					<List
						style={{
							overflowY: 'auto',
							height: '300px',
							paddingTop: '8px',
							paddingBottom: '8px',
						}}
					>
						{primaryAttribute && (
							<ListItem
								button
								aria-current={yAttrs.includes(
									primaryAttribute.name
								)}
								onClick={makeMenuHandler(primaryAttribute.name)}
							>
								{yAttrs.includes(primaryAttribute.name) && (
									<ListItemAvatar>
										<FontAwesomeIcon icon={faCheck} />
									</ListItemAvatar>
								)}
								<ListItemContent>{`Number of ${primaryAttribute.entity.plural}`}</ListItemContent>
							</ListItem>
						)}
						{preparedData &&
							attributeName &&
							filterAttrsForYMenu(
								attributeName,
								preparedData.attributes,
								['quantity']
							).map((a) => {
								const current = getAllLineChartY().includes(
									a.name
								);

								return (
									<ListItem
										button
										key={a.name}
										aria-current={current}
										onClick={makeMenuHandler(a.name)}
									>
										{current && (
											<ListItemAvatar>
												<FontAwesomeIcon
													icon={faCheck}
												/>
											</ListItemAvatar>
										)}
										<ListItemContent>
											{a.singular}
										</ListItemContent>
									</ListItem>
								);
							})}
					</List>
				</StyledAttributeList>
			</StyledScatterplotContent>
		</StyledVizPaper>
	);
};

export default EventLineChartModule;
