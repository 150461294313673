import RouterLink from './RouterLink';
import Typography from './Typography';
import { ListItem } from 'common/List';
import { ATTRIBUTE } from 'features/compositeViews/EntityViews/CONSTANTS';
import { VIEW_MODE_SEARCH_PARAM } from 'features/navigation/CONSTANTS';
import { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import styled, { CSSProperties } from 'styled-components';

const formatAttrName = (s: string) => s.split('-').join(' ');

const StyledLink = styled(RouterLink)`
	&:hover {
		text-decoration: underline;
		color: ${(p) => p.theme.palette.primary.main};
	}
`;

interface BreakoutTextProps {
	attrName: string;
	attrValue: string;
	breakWidth?: number;
	manualPreventScrollbarOverflow?: boolean;
	overrides?: {
		//   'root' = ListItem
		root?: CSSProperties;
	};
}

// NB: basically tested by src/features/geospatial/__tests__/Popup.unit.test.tsx
const BreakoutText: FunctionComponent<BreakoutTextProps> = ({
	attrName,
	attrValue,
	overrides,
	manualPreventScrollbarOverflow = false,
}) => {
	const location = useLocation();

	// TODO: weird case here where we aren't using the EntitySearchParams/ViewModeSearchParams hooks
	const linkParams = new URLSearchParams();
	linkParams.set(ATTRIBUTE, attrName);
	linkParams.set(VIEW_MODE_SEARCH_PARAM, 'profile');

	const linkTo = `${location.pathname}?${linkParams.toString()}`;

	return (
		<ListItem
			style={{
				flexWrap: 'wrap',
				marginRight: manualPreventScrollbarOverflow ? '9px' : 0,
				...overrides?.root,
			}}
		>
			<div>
				<StyledLink to={linkTo} preserveSearchParams={false}>
					<Typography
						style={{
							fontWeight: 'bold',
							paddingRight: '8px',
							textTransform: 'capitalize',
							fontSize: '0.875rem',
							color: '#60B3A1',
						}}
					>
						{formatAttrName(attrName)}:
					</Typography>
				</StyledLink>
			</div>
			<Typography
				paragraph
				style={{
					maxHeight: '300px',
					overflowY: 'auto',
					overflowWrap: 'break-word',
					fontSize: '0.875rem',
				}}
			>
				{attrValue}
			</Typography>
		</ListItem>
	);
};

export default BreakoutText;
