import BreakoutText from 'common/BreakoutText';
import Heading from 'common/Heading';
import { List } from 'common/List';
import {
	PopoverMainContent,
	PopoverHeader,
	PopoverSubhead,
	PopoverHeading,
} from 'common/Popover';
import Typography from 'common/Typography';
import { map, pipe } from 'common/utils/functionUtils';
import {
	sortGroupedAttrs,
	groupAttrs,
	pluralizeType,
	filterSubtypes,
	displayableAttrSubtypes,
} from 'features/ontology/helpers/attributeHelpers';
import {
	BaseAttribute,
	AttributeBaseType,
} from 'features/ontology/types/attributeTypes';
import { FunctionComponent } from 'react';
import styled from 'styled-components';

const StyledHeading = styled(Heading)`
	font-weight: ${(p) => p.theme.typography.fontWeightBold};
	text-transform: capitalize;
	margin-top: 15px;
`;

const StyledSectionContainer = styled.section`
	padding-left: ${(p) => p.theme.spacing(1.5)};
`;

interface AttrTypeSection {
	type: AttributeBaseType;
	attrs: BaseAttribute[];
}

interface PopupBaseProps {
	datum: Record<string, any>;
	attributes: BaseAttribute[];
}

// Isolate our own popup's content from the component that renders the content into
// react-map-gl's Popup component.  react-map-gl's Popup requires a MapContext
// above it to render properly, and we'd rather not render that in our integ tests.
export const IndividualCard: FunctionComponent<PopupBaseProps> = ({
	datum,
	attributes,
}) => {
	const parentEntityLabel = attributes[0].entity.singular;

	const primaryIdentity = attributes.find((a) => a.isPrimary) ?? null;

	const secondaryIdentities =
		attributes.filter(
			(a) => a.type === 'identity' && a._id !== primaryIdentity?._id
		) ?? null;

	const renderSection = (s: AttrTypeSection) => (
		<StyledSectionContainer key={s.type}>
			<StyledHeading component="h6">
				{pluralizeType(s.type)}
			</StyledHeading>
			<hr style={{
				margin: '10px 0 5px',
				borderWidth: 0,
				backgroundImage: 'linear-gradient(#29323c, #29323c)',
				color: '#c1c9d2',
				height: '1px'
			}}/>
			<List>
				{s.attrs.map(({ name }) => (
					<BreakoutText
						attrName={name}
						attrValue={String(datum[name])}
						key={name}
						overrides={{ root: { paddingLeft: 0 } }}
					/>
				))}
			</List>
		</StyledSectionContainer>
	);

	return (
		<>
			<PopoverMainContent>
				<PopoverHeader>
					<PopoverHeading>
						{primaryIdentity && `${datum[primaryIdentity.name]}`}
					</PopoverHeading>
					<PopoverSubhead>
						<Typography>{parentEntityLabel}</Typography>
					</PopoverSubhead>
				</PopoverHeader>
			</PopoverMainContent>
			{secondaryIdentities && (
				<List style={{ padding: '8px' }}>
					{secondaryIdentities.map((a) => (
						<BreakoutText
							key={a._id}
							attrName={a.name}
							attrValue={String(datum[a.name])}
							manualPreventScrollbarOverflow
						/>
					))}
				</List>
			)}
			{pipe(
				attributes.filter((a) => a.type !== 'identity'),
				groupAttrs('base'),
				filterSubtypes(displayableAttrSubtypes),
				sortGroupedAttrs,
				map(renderSection)
			)}
		</>
	);
};

export default IndividualCard;
