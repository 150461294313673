import { Facts } from 'features/dataPreparation/dataPreparationTypes';
import { Point, PointEnhancer } from 'features/viz/types';

export const genPointId = (xattr: string, yattr: string, origIdx: number) =>
	`${xattr}-${yattr}-${origIdx}`;

export function createScatterPoints<
	T extends PointEnhancer<number, number, any>
>(
	keys: string[],
	facts: Facts<number>,
	enhancer?: T
): T extends PointEnhancer<number, number, infer R>
	? R[]
	: Point<number, number>[] {
	const [xAttrName, ...yAttrNames] = keys;
	return facts.reduce((acc, { values, originalIdx }) => {
		const [xVal, ...ys] = values;

		return acc.concat(
			ys.map((yVal, i) => {
				const yAttrName = yAttrNames[i];
				const point = {
					x: xVal,
					y: yVal,
					pointId: genPointId(xAttrName, yAttrName, originalIdx),
				};
				return enhancer
					? enhancer(
							point,
							xAttrName,
							yAttrNames,
							yAttrNames[i],
							originalIdx
					  )
					: point;
			})
		);
	}, [] as any);
}
