import { resolveAttrType } from '../typeGuards/attributeGuards';
import {
	AttributeBaseType,
	BaseAttribute,
	ResolvedAttributeType,
} from '../types/attributeTypes';
import { Individual } from '../types/individualTypes';
import { sortByString } from 'common/utils/functionUtils';

export const attributeTypePriorityMap: Record<AttributeBaseType, number> = {
	identity: 0,
	relation: 1,
	category: 2,
	quantity: 3,
	event: 4,
	location: 5,
	media: 6,
};

export type AttrResolvedTypeMap = Map<ResolvedAttributeType, BaseAttribute[]>;

export type AttrBaseTypeMap = Map<AttributeBaseType, BaseAttribute[]>;

type TypeGroupKind = 'resolved' | 'base';

export type SortedAttrList = {
	type: AttributeBaseType;
	attrs: BaseAttribute[];
}[];

export const groupAttrs =
	(groupKind: TypeGroupKind) =>
	(
		attrs: BaseAttribute[]
	): typeof groupKind extends 'resolved'
		? AttrResolvedTypeMap
		: AttrBaseTypeMap => {
		const grouped = attrs.reduce((acc, next) => {
			const type =
				groupKind === 'resolved' ? resolveAttrType(next) : next.type;

			const existing = acc.get(type);

			if (existing) {
				acc.set(type, [...existing, next]);
				return acc;
			}

			acc.set(type, [next]);
			return acc;
		}, new Map() as AttrResolvedTypeMap);

		//  put each array of attributes in alphabetical order based on their 'singular' field
		grouped.forEach((vals, key, map) =>
			map.set(
				key,
				sortByString(vals, (a) => a.singular)
			)
		);

		return grouped as any;
	};

export const filterSubtypes =
	(toAccept: ResolvedAttributeType[]) => (attrMap: AttrBaseTypeMap) => {
		const acceptable = new Set(toAccept);

		attrMap.forEach((vals, key, map) => {
			const filteredVals = vals.filter((a) =>
				acceptable.has(resolveAttrType(a))
			);

			if (filteredVals.length === 0) {
				map.delete(key);
				return;
			}
			map.set(key, filteredVals);
		});

		return attrMap;
	};

export const sortGroupedAttrs = (grouped: AttrBaseTypeMap): SortedAttrList =>
	Array.from(grouped.entries())
		.map(([key, val]) => ({
			type: key,
			attrs: val,
		}))
		.sort((a, b) => {
			const nameA = attributeTypePriorityMap[
				resolvedTypeToType(a.type)
			] as number;

			const nameB = attributeTypePriorityMap[
				resolvedTypeToType(b.type)
			] as number;

			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}

			// names must be equal
			return 0;
		});

export const resolvedTypeToType = (
	st: ResolvedAttributeType
): AttributeBaseType => {
	const typeMap: Record<ResolvedAttributeType, AttributeBaseType> = {
		latitude: 'location',
		longitude: 'location',
		primaryIdentity: 'identity',
		secondaryIdentity: 'identity',
		ISODateString: 'event',
		distance: 'quantity',
		duration: 'quantity',
		currency: 'quantity',
		ratio: 'quantity',
		rate: 'quantity',
		functional: 'relation',
		nonFunctional: 'relation',
		latlong: 'location',
		event: 'event',
		quantity: 'quantity',
		identity: 'identity',
		category: 'category',
		relation: 'relation',
		location: 'location',
		media: 'media',
	};

	return typeMap[st];
};

export const pluralizeType = (type: AttributeBaseType) =>
	type.slice(-1) === 'y' ? type.slice(0, -1) + 'ies' : type + 's';

export const dealias = (ind: Individual, attrs: BaseAttribute[]) =>
	attrs.reduce(
		(acc, next) => ({ ...acc, [next.name]: ind[next.alias] }),
		{} as Individual
	);

/**These are the attr subtypes we currently show to user in Entity composite views */
export const displayableAttrSubtypes = [
	'category' as const,
	'ISODateString' as const,
	'quantity' as const,
	'latlong' as const,
	'relation' as const,
];
