import Button from '../../../common/Button';
import FlexContainer from '../../../common/FlexContainer';
import {
	formFieldHasErrors,
	FormResults,
	genErrorIdFromLabel,
} from '../../../common/Form';
import Spinner from '../../../common/Spinner';
import Typography from '../../../common/Typography';
import { useOperateAttrMutation } from '../../api';
import { mergeErrorStates, extractQueryErrMessage } from '../../api/helpers';
import { BaseAttribute } from '../../ontology/types/attributeTypes';
import { AttrActionFormProps } from '../common/commonTypes';
import {
	renderReferenceField,
	renderDerivationSubfields,
} from '../common/jsxHelpers';
import { StyledFlexContainer, StyledPaper } from '../common/styledComponents';
import useAttrNeighbors from '../hooks/useAttrNeighbors';
import {
	multiplyAttrFormDefaults,
	multiplyAttrFormToPayload,
} from './multiplyAttributeHelpers';
import { MultiplyAttrFormValues } from './multiplyAttributeTypes';
import { FunctionComponent, useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

const MultiplyAttributeForm: FunctionComponent<AttrActionFormProps> = (
	props
) => {
	const { _id, plural: label, updatePopper } = props;

	const formDefaults = multiplyAttrFormDefaults();

	const { handleSubmit, register, formState, watch } =
		useForm<MultiplyAttrFormValues>({
			defaultValues: formDefaults,
		});

	const [watchedDerivationFlag, watchedUsingReferenceValue] = watch([
		'isDerivation',
		'usingStaticReference',
	]);

	// prevent filtering from running every render
	const filter = useCallback(
		(attr: BaseAttribute) => attr.type === 'quantity',
		[]
	);

	const queryRes = useAttrNeighbors(_id, filter);

	useEffect(() => {
		if (queryRes.isSuccess) {
			updatePopper();
		}
	}, [queryRes.isSuccess, updatePopper]);

	const [multiplyAttr, multiplyAttrResult] = useOperateAttrMutation();

	const mergedErrs = mergeErrorStates(queryRes, multiplyAttrResult);

	const onSubmit: SubmitHandler<MultiplyAttrFormValues> = (vals, e) => {
		e?.preventDefault();
		multiplyAttr({
			attrId: _id,
			body: multiplyAttrFormToPayload(vals),
		});
	};

	if (queryRes.isLoading) {
		return (
			<FlexContainer justifyContent="center">
				<Typography paragraph>Loading attributes...</Typography>
				<Spinner />
			</FlexContainer>
		);
	}

	if (queryRes.isError) {
		return (
			<FlexContainer justifyContent="center">
				<Typography color="error" paragraph>
					{extractQueryErrMessage(queryRes.error)}
				</Typography>
			</FlexContainer>
		);
	}

	return (
		<StyledPaper>
			<form onSubmit={handleSubmit(onSubmit)}>
				<StyledFlexContainer flexDirection="column" alignItems="center">
					<Typography id="isDerivation">
						Transformation will replace {label}, derivation will
						create a new attribute
					</Typography>
					<div
						role="radiogroup"
						aria-labelledby="isDerivation"
						aria-errormessage={genErrorIdFromLabel('isDerivation')}
						aria-invalid={formFieldHasErrors(
							'isDerivation',
							formState
						)}
					>
						<div>
							<input
								id="isDerivation-true"
								type="radio"
								value="true"
								{...register('isDerivation', {
									required: {
										value: true,
										message:
											'A value for isDerivation is required',
									},
								})}
							/>
							<label htmlFor="isDerivation-true">
								<Typography>Use derivation</Typography>
							</label>
						</div>
						<div>
							<input
								id="isDerivation-false"
								type="radio"
								value="false"
								{...register('isDerivation', {
									required: {
										value: true,
										message:
											'A value for isDerivation is required',
									},
								})}
							/>
							<label htmlFor="isDerivation-false">
								<Typography>Use transformation</Typography>
							</label>
						</div>
					</div>

					{renderDerivationSubfields(
						watchedDerivationFlag === 'true',
						register,
						formState
					)}

					<Typography id="usingStaticReference">
						Use another attribute or set a static value to multiply
						with {label}
					</Typography>
					<div
						role="radiogroup"
						aria-labelledby="usingStaticReference"
						aria-errormessage={genErrorIdFromLabel(
							'usingStaticReference'
						)}
						aria-invalid={formFieldHasErrors(
							'usingStaticReference',
							formState
						)}
					>
						<div>
							<input
								id="dv-false"
								type="radio"
								value="false"
								{...register('usingStaticReference', {
									required: {
										value: true,
										message:
											'A value for usingStaticReference is required',
									},
								})}
							/>
							<label htmlFor="dv-false">
								<Typography>Select an attribute</Typography>
							</label>
						</div>
						<div>
							<input
								id="dv-true"
								type="radio"
								value="true"
								{...register('usingStaticReference', {
									required: {
										value: true,
										message:
											'A value for usingStaticReference is required',
									},
								})}
							/>
							<label htmlFor="dv-true">
								<Typography>Set a static value</Typography>
							</label>
						</div>
					</div>

					{renderReferenceField(
						'otherId',
						'otherValue',
						watchedUsingReferenceValue === 'true',
						register,
						queryRes.attrNeighbors,
						formState
					)}

					<Typography id="replaceMissing-label">
						Replace missing values with 0 (does not affect NULLS)?
					</Typography>
					<div
						role="radiogroup"
						aria-labelledby="replaceMissing-label"
						aria-errormessage={genErrorIdFromLabel(
							'replaceMissing'
						)}
						aria-invalid={formFieldHasErrors(
							'replaceMissing',
							formState
						)}
					>
						<div>
							<input
								id="replaceMissing-false"
								type="radio"
								value="false"
								{...register('replaceMissing', {
									required: {
										value: true,
										message:
											'A value for replaceMissing is required',
									},
								})}
							/>
							<label htmlFor="replaceMissing-false">
								<Typography>No</Typography>
							</label>
						</div>
						<div>
							<input
								id="replaceMissing-true"
								type="radio"
								value="true"
								{...register('replaceMissing', {
									required: {
										value: true,
										message:
											'A value for isDependent is required',
									},
								})}
							/>
							<label htmlFor="replaceMissing-true">
								<Typography>Yes</Typography>
							</label>
						</div>
					</div>

					<Button type="submit">Submit</Button>

					<FormResults
						isError={!!mergedErrs}
						error={mergedErrs}
						validationErrors={formState.errors}
					/>
				</StyledFlexContainer>
			</form>
		</StyledPaper>
	);
};

export default MultiplyAttributeForm;
