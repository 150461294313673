import useHUDactive from '../hooks/useHUDActive';
import useViewportDimensions from '../hooks/useViewportDimensions';
import EntityLeftHUD from 'features/geoSpatial/components/EntityLeftHUD';
import useViewModeParams from 'features/navigation/hooks/useViewModeParams';
import { FunctionComponent, ReactNode, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { TransitionStatus, Transition } from 'react-transition-group';
import styled, { DefaultTheme } from 'styled-components';

const short = (theme: DefaultTheme) => theme.transitions.duration.standard;

const ease = (theme: DefaultTheme) => theme.transitions.easing.easeInOut;

const StyledSidePanelContainer = styled.div<{
	viewportHeight: number;
	state: TransitionStatus;
}>`
	opacity: 1;
	transition: transform ${({ theme }) => `${short(theme)}ms ${ease(theme)}`},
		opacity ${({ theme }) => `${short(theme)}ms ${ease(theme)}`};
	transform: ${(p) =>
		`translateX(${
			p.state === 'entered' || p.state === 'entering'
				? '0'
				: -p.theme.drawerWidth
		}px)`};
	position: absolute;
	width: ${(p) => `${p.theme.drawerWidth}px`};
	top: ${(p) => `${p.theme.navHeight}px`};
	background: transparent;
	padding-left: ${(p) => p.theme.panelGutter}px;
	padding-top: ${(p) => p.theme.panelGutter}px;
	display: flex;
	flex-direction: column;
	overflow: visible;
	z-index: ${(p) => p.theme.zIndex.controlSurface};
`;

const StyledPanelContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: ${(p) => `${p.theme.panelGutter}px`};
`;

interface LeftDrawerContainerProps {
	children?: ReactNode;
}

const LeftDrawerContainer: FunctionComponent<LeftDrawerContainerProps> = ({
	children,
}) => {
	// Need this ref to stop react-transition-group from trying to
	// use findDOMNode.
	const transitionRef = useRef<any>();

	const { viewportHeight } = useViewportDimensions();
	const HUDIsActive = useHUDactive();

	return (
		<Transition in={HUDIsActive} timeout={10} nodeRef={transitionRef}>
			{(state) => (
				<StyledSidePanelContainer
					ref={transitionRef}
					viewportHeight={viewportHeight}
					state={state}
					data-testid="hud-sidepanel-container"
				>
					{children}
				</StyledSidePanelContainer>
			)}
		</Transition>
	);
};

const LeftDrawer: FunctionComponent = () => {
	const { entity } = useParams();
	const { getViewMode } = useViewModeParams();

	const renderRealHUD = !!entity && getViewMode() === 'geospatial';
	return (
		<LeftDrawerContainer>
			<StyledPanelContainer>
				{renderRealHUD ? <EntityLeftHUD /> : null}
			</StyledPanelContainer>
		</LeftDrawerContainer>
	);
};

export default LeftDrawer;
