import { ListItemContent, List, ListItemControls } from '../../../common/List';
import Typography from '../../../common/Typography';
import { useGetCatalogDataSetsQuery } from '../../api';
import { renderDropdown } from '../helpers';
import useSourceListItem from '../hooks/useSourceListItem';
import { DataCatalogSummary } from '../types/dataTypes';
import { CardSetter, SourceListItemProps } from '../types/uiTypes';
import ImportButton from './ImportButton';
import { SourceListItem } from './styledComponents';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import clsx from 'clsx';
import { FunctionComponent, useEffect } from 'react';

interface DataCatalogListItemProps
	extends SourceListItemProps,
		DataCatalogSummary {
	onDisplay: DataCatalogSummary;
	cardSetter: CardSetter;
	sourceId: number;
}

const CatalogSummaryListItem: FunctionComponent<DataCatalogListItemProps> = ({
	onDisplay,
	setter,
	currentlySelected,
	cardSetter,
	sourceId,
	...catalogProps
}) => {
	const { queryShouldRun, open, active, onClick, itemName } =
		useSourceListItem({
			onDisplay,
			currentlySelected,
			setter,
		});

	const queryResults = useGetCatalogDataSetsQuery(
		queryShouldRun
			? { sourceId: sourceId, catalogName: onDisplay.name }
			: skipToken
	);

	// pass list back up to the card display component
	useEffect(() => {
		const { isSuccess, data } = queryResults;
		if (isSuccess && data && active) {
			cardSetter(data);
		}
	}, [queryResults, cardSetter, active]);

	return (
		<List>
			<SourceListItem
				button
				className={clsx(active && 'browser-item-is-open')}
				onClick={onClick}
			>
				<ListItemContent>
					<Typography>{itemName}</Typography>
				</ListItemContent>
				<ListItemControls>
					<ImportButton importable={catalogProps} />
				</ListItemControls>
			</SourceListItem>
			{open &&
				renderDropdown({
					...queryResults,
					setter,
					cardSetter,
					currentlySelected,
					sourceId,
				})}
		</List>
	);
};

export default CatalogSummaryListItem;
