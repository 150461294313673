import {
	CreateLocationFormValues,
	CreateLocationPayload,
} from './createLocationTypes';

export const createLocationFormDefaults = (): CreateLocationFormValues => ({
	singular: '',
	plural: '',
	definition: '',
	latitudeId: 0,
	longitudeId: 0,
});

export const createLocationFormToPayload = (
	formValues: CreateLocationFormValues
): CreateLocationPayload => {
	return {
		...formValues,
		definition: formValues.definition === '' ? null : formValues.definition,
	};
};
