import ActionList from './ActionList';
import { ListItem, ListItemContent } from 'common/List';
import PopoverBase from 'common/Popover/PopoverBase';
import Typography from 'common/Typography';
import { BaseAttribute } from 'features/ontology/types/attributeTypes';
import {
	Dispatch,
	FunctionComponent,
	MouseEventHandler,
	SetStateAction,
	useMemo,
	useState,
} from 'react';

interface ExpandingListItemProps extends BaseAttribute {
	canEdit: boolean;
	attrWithActiveSubmenu: number | null;
	setOpenAttribute: Dispatch<SetStateAction<number | null>>;
}

const ExpandingListItem: FunctionComponent<ExpandingListItemProps> = ({
	attrWithActiveSubmenu,
	setOpenAttribute,
	canEdit,
	...attrProps
}) => {
	const { _id, singular: label } = attrProps;

	const [anchorEl, setAnchorEl] = useState<HTMLLIElement | null>(null);

	const submenuOpen = useMemo(
		() => attrWithActiveSubmenu === _id,
		[attrWithActiveSubmenu, _id]
	);

	const onItemClick: MouseEventHandler = (e) => {
		e.stopPropagation();
		if (canEdit) {
			setOpenAttribute((p) => {
				if (p && p === _id) {
					return null;
				}

				return _id;
			});
		}

		return;
	};

	return (
		<>
			<ListItem
				ref={setAnchorEl as any}
				button
				// icon={faCube}
				onClick={onItemClick}
			>
				<ListItemContent>
					<Typography>{label}</Typography>
				</ListItemContent>
			</ListItem>

			<PopoverBase
				anchorEl={anchorEl}
				open={submenuOpen}
				offset={0}
				placement="right"
			>
				<ActionList
					{...attrProps}
					setOpenAttribute={setOpenAttribute}
				/>
			</PopoverBase>
		</>
	);
};

export default ExpandingListItem;
