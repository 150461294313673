import addAttribute from './addAttribute';
import aggregateAttribute from './aggregateAttribute';
import appendAttribute from './appendAttribute';
import { FuturemodelAttrActions } from './common/commonTypes';
import deleteAttribute from './deleteAttribute';
// import deriveDuration from './deriveDuration';
import describeAttribute from './describeAttribute';
import divideAttribute from './divideAttribute';
import loadAttribute from './loadAttribute';
import mergeAttribute from './mergeAttribute';
import multiplyAttribute from './multiplyAttribute';
import prependAttribute from './prependAttribute';
import relateAttribute from './relateAttribute';
// import restrictEntity from './restrictEntity';
import subtractAttribute from './subtractAttribute';

const actionMap: FuturemodelAttrActions = {
	identity: [
		describeAttribute,
		relateAttribute,
		// restrictEntity,
		deleteAttribute,
	],
	nonFunctionalRelation: [
		describeAttribute,
		aggregateAttribute,
		// restrictEntity,
		deleteAttribute,
	],
	functionalRelation: [
		describeAttribute,
		loadAttribute,
		// restrictEntity,
		deleteAttribute,
	],
	category: [
		describeAttribute,
		relateAttribute,
		mergeAttribute,
		appendAttribute,
		prependAttribute,
		// restrictEntity,
		deleteAttribute,
	],
	quantity: [
		describeAttribute,
		relateAttribute,
		addAttribute,
		subtractAttribute,
		multiplyAttribute,
		divideAttribute,
		mergeAttribute,
		// restrictEntity,
		deleteAttribute,
	],
	event: [
		describeAttribute,
		relateAttribute,
		mergeAttribute,
		// deriveDuration,
		deleteAttribute,
	],
	location: [
		describeAttribute,
		relateAttribute,
		mergeAttribute,
		deleteAttribute,
	],
	media: [
		describeAttribute,
		relateAttribute,
		mergeAttribute,
		deleteAttribute,
	],
};

export default actionMap;
