import IconButton from '../../../../common/IconButton';
import Popover from '../../../../common/Popover/PopoverBase';
import EntityActionList from './EntityActionList';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FunctionComponent, useState } from 'react';

const actions = [
	'create condition',
	'create attribute',
	'create location',
	'create event',
];

interface CreationGroupButtonProps {
	entityId: number;
}

const CreationGroupButton: FunctionComponent<CreationGroupButtonProps> = ({
	entityId,
}) => {
	const [menuOpen, setMenuOpen] = useState(false);

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const closeMenu = () => setMenuOpen(false);

	return (
		<>
			<IconButton
				ref={setAnchorEl}
				icon={faPlus}
				baseOpacity='80%'
				onClick={() => setMenuOpen((p) => !p)}
				aria-label="create attribute"
				key="creation-form"
				size="xs"
				tooltip={`create attribute`}
				showTooltip={!menuOpen}
				tooltipPlacement="bottom"
			/>

			<Popover
				anchorEl={anchorEl}
				open={menuOpen}
				handleClickAway={closeMenu}
			>
				<EntityActionList entityId={entityId} />
			</Popover>
		</>
	);
};

export default CreationGroupButton;
