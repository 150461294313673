import { ControlButtonWrapper } from '../styledComponents';
import { faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch } from 'app/hooks';
import IconButton from 'common/IconButton';
import { pipe } from 'common/utils/functionUtils';
import useMemberManagementOpen from 'features/HUD/hooks/useMemberMgmtOpen';
import { toggleRightDrawer } from 'features/HUD/state/HUDSlice';
import { FunctionComponent } from 'react';

const ToggleMemberMgmtButton: FunctionComponent = () => {
	const appDispatch = useAppDispatch();

	const rightDrawerIsOpen = useMemberManagementOpen();

	const toggleDrawer = () => pipe(toggleRightDrawer(), appDispatch);

	// For now, we always display the right drawer toggle button when the HUD is mounted.
	return (
		<ControlButtonWrapper displaying={true}>
			<IconButton
				icon={rightDrawerIsOpen ? faUserGroup : faUserGroup}
				onClick={toggleDrawer}
				aria-label={`${
					rightDrawerIsOpen ? 'close' : 'open'
				} right-hand drawer`}
				size="sm"
				tooltip={`${
					rightDrawerIsOpen ? 'close' : 'open'
				} right-hand drawer`}
				tooltipPlacement="left"
			/>
		</ControlButtonWrapper>
	);
};

export default ToggleMemberMgmtButton;
