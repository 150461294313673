import { filterAccountMembers } from '../helpers';
import { AccountAuthorityLevel } from '../types/MemberManagementTypes';
import useCombinedAccountMembers from './useCombinedAccountMembers';
import { useMemo } from 'react';

const useFilteredAccountMembers = (
	accountId: number,
	authorityLevel: AccountAuthorityLevel
) => {
	const allAccountMembers = useCombinedAccountMembers(accountId);

	return useMemo(
		() => filterAccountMembers(authorityLevel, allAccountMembers),
		[allAccountMembers, authorityLevel]
	);
};

export default useFilteredAccountMembers;
