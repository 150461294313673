import { isNonNullObject } from './typeGuards';
import { DefaultTheme } from 'styled-components';

// type guards and convenience definitions
export const hasOwnProperty = <X extends {}, Y extends PropertyKey>(
	obj: X,
	prop: Y
): obj is X & Record<Y, unknown> => obj.hasOwnProperty(prop);

export const keyExists = <X extends {}, Y extends PropertyKey>(
	u: unknown,
	property: string
): u is X & Record<Y, unknown> =>
	isNonNullObject(u) ? (hasOwnProperty(u, property) ? true : false) : false;

/**
 * Assert that array of strings returned from Object.prototype.keys
 * contains only the keys specified in the object's type.
 */
export const typedKeys = <T extends {}>(o: T): Array<keyof T> =>
	Object.keys(o) as Array<keyof T>;

export interface NonEmptyStringBrand {
	readonly brand: unique symbol;
}

export type NonEmptyString = string & NonEmptyStringBrand;

export interface Tagged {
	readonly __tag: string;
}

export const EMPTY_TAG = '__EMPTY__';

export interface Empty extends Tagged {
	__tag: typeof EMPTY_TAG;
}

export const EMPTY: Empty = { __tag: EMPTY_TAG };

// Style-related types
export type FlexJustifyProperties =
	| 'space-between'
	| 'space-around'
	| 'flex-start'
	| 'flex-end'
	| 'center';

export type FlexDirectionProperties = 'column' | 'row';

export type FlexAlignmentProperties = 'flex-start' | 'flex-end' | 'center';

export type ElementCSSState = 'hover';

export type StyledProps<T> = T & { theme: DefaultTheme };

export interface StyledPropsSelector<T> {
	(state?: ElementCSSState): (p: StyledProps<T>) => string;
}

export type RequireAtLeastOne<T, R extends keyof T = keyof T> = Omit<T, R> &
	{ [P in R]: Required<Pick<T, P>> & Partial<Omit<T, P>> }[R];

export type Nullable<T> = T | null | undefined;

// big thanks to https://stackoverflow.com/questions/41139763/how-to-declare-a-fixed-length-array-in-typescript
// for this one.
export type Tuple<
	T,
	N extends number,
	R extends readonly T[] = []
> = R['length'] extends N ? R : Tuple<T, N, readonly [T, ...R]>;
