import Typography from '../../../common/Typography';
import { mapSourceSummariesToNavList } from '../helpers';
import { GetSourceSummariesResponse } from '../types/dataTypes';
import { SelectedSourceData } from '../types/uiTypes';
import { SourceNavItemList, Setter } from '../types/uiTypes';
import { StyledListItem, StyledSourceNavButton } from './styledComponents';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FunctionComponent } from 'react';
import styled from 'styled-components';

const StyledIcon = styled(FontAwesomeIcon)`
	padding-right: ${(p) => p.theme.spacing(1)};
`;

const StyledListContainer = styled.section`
	border-right: 2px solid ${(p) => p.theme.palette.divider};
	height: 100%;
	overflow: auto;
	padding: ${(p) => p.theme.spacing(1)};
	min-width: 300px;
	max-width: 450px;
`;

const StyledTypography = styled(Typography)`
	display: inline;
	font-weight: ${(p) => p.theme.typography.fontWeightBold};
`;

interface SourceNavProps {
	canEdit: boolean;
	sourceSummaries: GetSourceSummariesResponse;
	setter: Setter;
	currentlySelected: SelectedSourceData;
	toggleFormMode: () => void;
	setCardData: (cardData: SourceNavItemList) => void;
}

const SourceNav: FunctionComponent<SourceNavProps> = ({
	canEdit,
	sourceSummaries,
	setter,
	currentlySelected,
	toggleFormMode,
	setCardData,
}) => {
	return (
		<StyledListContainer>
			<ul>
				{canEdit && (
					<StyledListItem>
						<StyledSourceNavButton onClick={toggleFormMode}>
							<StyledIcon
								icon={faPlus}
								size="sm"
								border={false}
							/>
							<StyledTypography>Data Source</StyledTypography>
						</StyledSourceNavButton>
					</StyledListItem>
				)}
				{mapSourceSummariesToNavList(
					sourceSummaries,
					setter,
					setCardData,
					currentlySelected
				)}
			</ul>
		</StyledListContainer>
	);
};

export default SourceNav;
