import Button from '../../../common/Button';
import FlexContainer from '../../../common/FlexContainer';
import { FormResults } from '../../../common/Form';
import Heading from '../../../common/Heading';
import Typography from '../../../common/Typography';
import { useDeleteAttributeMutation } from '../../api';
import { AttrActionFormProps } from '../common/commonTypes';
import {
	StyledFlexContainer,
	StyledPaper,
	StyledDeleteButton,
} from '../common/styledComponents';
import { FunctionComponent, MouseEventHandler } from 'react';

const DeleteAttributeForm: FunctionComponent<AttrActionFormProps> = (props) => {
	const { _id, plural: label, setOpenAttribute, setOpenAction } = props;

	const [deleteAttr, deletionResult] = useDeleteAttributeMutation();

	const onClick: MouseEventHandler = () => {
		deleteAttr({ attrId: _id });
	};

	const onSuccessfulDelete = () => setOpenAttribute(null);

	return (
		<StyledPaper>
			<StyledFlexContainer flexDirection="column" alignItems="center">
				<Heading component="h2">
					<Typography>
						Are you sure you want to delete {label}?
					</Typography>
				</Heading>
				<FlexContainer
					style={{ width: '50%' }}
					justifyContent="space-between"
				>
					<Button onClick={() => setOpenAction(null)}>Go Back</Button>
					<StyledDeleteButton onClick={onClick}>
						Delete
					</StyledDeleteButton>
				</FlexContainer>
				<FormResults
					{...deletionResult}
					validationErrors={{}}
					onSuccess={onSuccessfulDelete}
					onSuccessDelay={1500}
				/>
			</StyledFlexContainer>
		</StyledPaper>
	);
};

export default DeleteAttributeForm;
