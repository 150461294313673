import Breadcrumbs from '../Breadcrumbs';
import ViewToggle from '../ViewToggle';
import NavLogo from './NavLogo';
import ProfileMenu from './ProfileMenu';
import Button from 'common/Button';
import FlexContainer from 'common/FlexContainer';
import useAuthStatus from 'features/authentication/hooks/useAuthStatus';
import { FunctionComponent } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const StyledHeader = styled.header`
	position: absolute;
	width: 100%;
	height: ${(p) => `${p.theme.navHeight}px`};
	background: transparent;
	z-index: ${(p) => p.theme.zIndex.navPanel};
`;

const StyledNav = styled.nav`
	height: 100%;
	background: transparent;
	padding: 0 ${(p) => p.theme.panelGutter}px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
		-webkit-backdrop-filter: blur(1px);
		backdrop-filter: blur(1px);
	}
`;

const StyledSignin = styled(Button)`
	border: 2px solid transparent;
	&:hover {
		color: ${(p) => p.theme.palette.primary.main};
		border: 2px solid ${(p) => p.theme.palette.primary.main};
		background-color: inherit;
	}
`;

const StyledNavButtons = styled(FlexContainer)`
	flex: 0 1 200px;
`;

const SigninButtons: FunctionComponent = () => {
	const { pathname } = useLocation();

	const navigate = useNavigate();

	const atLanding = !pathname.match(/login/i);

	return (
		<StyledNavButtons justifyContent="flex-end">
			{atLanding && (
				<StyledSignin
					variant="text"
					onClick={() => navigate('/login')}
				>
					Log In
				</StyledSignin>
			)}
		</StyledNavButtons>
	);
};

const NavPanel: FunctionComponent = () => {
	const isAuthenticatedUser = useAuthStatus();

	return (
		<StyledHeader>
			<StyledNav>
				<FlexContainer alignItems="center">
					<NavLogo />
					{isAuthenticatedUser ? <Breadcrumbs /> : null}
				</FlexContainer>
				<FlexContainer
					justifyContent="center"
					//  32px is the height of IconButton @ size "sm"
					style={{ height: '32px',  }}
				>
					<ViewToggle/>
					{isAuthenticatedUser ? <ProfileMenu /> : <SigninButtons />}
				</FlexContainer>
			</StyledNav>
		</StyledHeader>
	);
};

export default NavPanel;
