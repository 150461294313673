import { hasOwnProperty } from '../../../common/utils/typeUtils';
import {
	LiveDataSet,
	DataSourceSummary,
	DataCatalogSummary,
	DataSourceSummaries,
	DataCatalogSummaries,
	LiveDataSets,
} from './dataTypes';

export type SourceBrowserDisplayMode = 'form' | 'display';

/**
 * Union of every type the SourceBrowser can have set as its 'active'
 * source files
 */
export type SelectedSourceData =
	| DataSourceSummaries
	| DataSourceSummary
	| DataCatalogSummary
	| LiveDataSet;

/**
 * Union of every type for which the SourceBrowser renders a line item
 * in the browser panel (left-hand side)
 */
export type SourceNavItemData =
	| DataSourceSummary
	| DataCatalogSummary
	| LiveDataSet;

/**
 * Union of the array types from which we render file browser sub-items
 */
export type SourceNavItemList =
	| DataCatalogSummaries
	| LiveDataSets
	| DataSourceSummaries;

export type Setter = (data: SelectedSourceData) => void;

export type CardSetter = (data: SourceNavItemList) => void;

// We put these typeguards with UI types b/c the UI depends on them,
// even though they pertain to dataTypes
export const isSourceSummaries = (
	u: SelectedSourceData
): u is DataSourceSummaries =>
	Array.isArray(u) && u.length > 0 && isSourceSummary(u[0]);

export const isSourceSummary = (
	u: SelectedSourceData
): u is DataSourceSummary => !Array.isArray(u) && u._object === 'DataSource';

export const isDataCatalogSummary = (
	u: SelectedSourceData
): u is DataCatalogSummary => !Array.isArray(u) && u._object === 'DataCatalog';

export const isDataCatalogSummaries = (
	u: SourceNavItemList
): u is DataCatalogSummaries =>
	Array.isArray(u) && u.length > 0 && u[0]._object === 'DataCatalog';

export const isLiveDataSet = (u: {}): u is LiveDataSet =>
	!Array.isArray(u) &&
	hasOwnProperty(u, '_object') &&
	u._object === 'DataSet';

export const isLiveDataSets = (u: SourceNavItemList): u is LiveDataSets =>
	Array.isArray(u) && u.length > 0 && u[0]._object === 'DataSet';

export interface SourceListItemProps {
	onDisplay: SourceNavItemData;
	setter: Setter;
	currentlySelected: SelectedSourceData;
}
