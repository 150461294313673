import {
	StyledVizPaper,
	StyledSubmoduleContent,
	StyledSubmoduleHeader,
} from '../styledComponents';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch } from 'app/hooks';
import { flow } from 'common/utils/functionUtils';
import { isNonEmptyString, isNumber, isString } from 'common/utils/typeGuards';
import { hasOwnProperty } from 'common/utils/typeUtils';
import { setActiveIndividual } from 'features/HUD/state/HUDSlice';
import DisplayOnLoad from 'features/api/DisplayOnLoad';
import {
	filterSubtypes,
	groupAttrs,
	sortGroupedAttrs,
} from 'features/ontology/helpers/attributeHelpers';
import useActiveIndividualsMeta from 'features/ontology/hooks/useActiveIndividualsMeta';
import {
	resolveAttrType,
	isQuantity,
} from 'features/ontology/typeGuards/attributeGuards';
import { BaseAttribute } from 'features/ontology/types/attributeTypes';
import { Individual } from 'features/ontology/types/individualTypes';
import {
	ChangeEventHandler,
	FunctionComponent,
	ReactNode,
	useMemo,
	useState,
} from 'react';
import styled from 'styled-components';
import { debounce } from 'throttle-debounce';

interface ColumnMeta {
	naiveWidth: number;
	alignRight: boolean;
	header: string;
	name: string;
	showDivider: boolean;
}

interface CellMeta {
	alignRight: boolean;
	showDivider: boolean;
}

const coerceToString = (v: unknown) =>
	isString(v) ? v : isNumber(v) ? v.toString() : null;

const datumMatchesSearchTerm = (term: string) => {
	const tester = new RegExp(term, 'i');

	return (datum: Individual) =>
		Object.values(datum).some((v) => {
			const coerced = coerceToString(v);

			if (coerced === null) {
				return false;
			}

			return tester.test(v);
		});
};

const getMaxCharLength = (lengthData: {
	profile?: { results: { maxLength: number } | { max: number } };
}) => {
	// TODO: hopefully this can be handled more elegantly once type of
	// attribute 'profile' data is firmer.
	if (lengthData.profile && lengthData.profile.results) {
		if (hasOwnProperty(lengthData.profile.results, 'maxLength')) {
			return lengthData.profile.results.maxLength;
		}

		if (hasOwnProperty(lengthData.profile.results, 'max')) {
			return lengthData.profile.results.max.toString().length;
		}
	}

	return 14;
};

const colToColString = (col: ColumnMeta, isPrimaryIdCol: boolean) =>
	isPrimaryIdCol
		? `${col.naiveWidth + 4}ch`
		: `clamp(100px, ${col.naiveWidth}ch, 24ch)`;

const colsToGridCols = (cols: ColumnMeta[]) =>
	cols.map((col, i) => colToColString(col, i === 0)).join(' ');

const StyledIDCell = styled.th<ColumnMeta>`
	display: grid;
	grid-template-columns: 2ch ${(p) => p.naiveWidth + 2}ch;
	padding: ${(p) => p.theme.spacing(1, 2)};
`;

interface IDCellProps extends ColumnMeta {
	className: string;
	showIcon: boolean;
	onClick?: () => void;
	children?: ReactNode;
}

const IDCell: FunctionComponent<IDCellProps> = ({
	onClick,
	showIcon,
	children,
	className,
	...props
}) => {
	return (
		<StyledIDCell {...props} className={className}>
			<div>
				{showIcon && (
					<FontAwesomeIcon
						icon={faEye}
						style={{ cursor: 'pointer' }}
						onClick={onClick}
					/>
				)}
			</div>
			<div
				style={{
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
					textAlign: 'left',
					paddingLeft: '8px',
				}}
			>
				{children}
			</div>
		</StyledIDCell>
	);
};

const StyledCell = styled.td<CellMeta>`
	border-right: ${(p) =>
		p.showDivider
			? `1px solid ${p.theme.palette.divider}`
			: '0 none white'};
	padding: ${(p) => p.theme.spacing(1, 2)};
	text-align: ${(p) => (p.alignRight ? 'right' : 'left')};
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	vertical-align: middle;
	&:hover {
		overflow: visible;
		white-space: normal;
		overflow-wrap: break-word;
	}
`;

const StyledHeaderCell = styled.th<CellMeta>`
	border-right: ${(p) =>
		p.showDivider
			? `1px solid ${p.theme.palette.divider}`
			: '0 none white'};
	padding: ${(p) => p.theme.spacing(1, 2)};
	text-align: ${(p) => (p.alignRight ? 'right' : 'left')};
`;

const StyledRow = styled.tr<{ cols: ColumnMeta[] }>`
	border-bottom: 1px solid ${(p) => p.theme.palette.divider};
	display: grid;
	grid-template-columns: ${(p) => colsToGridCols(p.cols)};

	&:hover {
		background-color: ${(p) => p.theme.palette.darkerBaby};
		color: ${(p) => p.theme.palette.oldManGray};

		& .body-cell:first-child {
			background: ${(p) => p.theme.palette.darkerBaby};
			color: ${(p) => p.theme.palette.oldManGray};
		}
	}

	& .body-cell:first-child {
		position: sticky;
		left: 0;
		z-index: 1;
		background: ${(p) => p.theme.palette.background.paper};
	}
`;

const StyledTableHead = styled.thead`
	position: sticky;
	top: 0;
	z-index: 3;
`;

const StyledHeaderRow = styled.tr<{ cols: ColumnMeta[] }>`
	display: grid;
	grid-template-columns: ${(p) => colsToGridCols(p.cols)};
	background: ${(p) => p.theme.palette.background.paper};

	& .header-cell:first-child {
		position: sticky;
		left: 0;
		z-index: 2;
		background: ${(p) => p.theme.palette.background.paper};
	}
`;

const attrToColumn = (attr: BaseAttribute, isFinalOfType: boolean) => ({
	//  TODO: figure out best way to handle type discrimination for
	// attribute 'profile' property
	naiveWidth: getMaxCharLength(attr as any),
	alignRight: isQuantity(attr),
	header: attr.singular,
	name: attr.name,
	showDivider: isFinalOfType,
});

// NB: intentionally EXCLUDE primay identity from column descriptors:
// we manually extract primary ID in component body to make sure it is always
// first in the list.
const attrsToColumns = flow(
	groupAttrs('base'),
	filterSubtypes([
		'ISODateString',
		'category',
		'quantity',
		'secondaryIdentity',
	]),
	sortGroupedAttrs,
	(sorted) =>
		sorted.reduce((acc, next) => {
			return acc.concat(
				next.attrs.map((a, i, arr) =>
					attrToColumn(a, i === arr.length - 1)
				)
			);
		}, [] as ColumnMeta[])
);

// 1. give every cell in header pos: sticky top: 0 z-Index: 1
// 2. Give 'id' header cell (only) pos: sticky, left: 0, and z-index: 2 (to be above other header cells)
// 3. Give left-column in body position: sticky, left: 0, z-index 1 (so that ID header cell floats above them)

// id, cat, qty, event
const IndividualsModule: FunctionComponent = () => {
	const { preparedData, ...individualsLoadState } =
		useActiveIndividualsMeta();

	const appDispatch = useAppDispatch();

	const cols = useMemo(() => {
		if (preparedData) {
			const { attributes } = preparedData;

			const primaryId = attributes.find(
				(a) => resolveAttrType(a) === 'primaryIdentity'
			) as BaseAttribute;

			const hasSecondaryIds = attributes.some(
				(a) => resolveAttrType(a) === 'secondaryIdentity'
			);

			const colsMinusPrimaryId = attrsToColumns(preparedData.attributes);

			return [
				// if secondary Ids are present, don't treat primary id cell as last of its type
				attrToColumn(primaryId, !hasSecondaryIds),
				...colsMinusPrimaryId,
			];
		}

		return [] as ColumnMeta[];
	}, [preparedData]);

	// const [searchTerm, setSearchTerm] = useState<string>('');

	const [filterTerm, setFilterTerm] = useState<string>('');

	const filtered = useMemo(() => {
		if (!preparedData) {
			return [];
		}

		if (isNonEmptyString(filterTerm)) {
			return preparedData.data.filter(datumMatchesSearchTerm(filterTerm));
		}

		return preparedData.data;
	}, [preparedData, filterTerm]);

	const debounced = useMemo(
		() =>
			debounce(
				1000,
				(term: string) => {
					setFilterTerm(term);
				},
				{ atBegin: false }
			),
		[]
	);

	const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
		debounced(e.target.value);
	};

	return (
		<StyledVizPaper>
			<StyledSubmoduleHeader>
				<label htmlFor="indvidual-table-search">
					Search
					<input
						type="text"
						style={{ margin: '0 8px' }}
						// value={searchTerm}
						onChange={onChange}
					/>
				</label>
				{filtered.length} rows
			</StyledSubmoduleHeader>
			<StyledSubmoduleContent style={{ overflow: 'auto' }}>
				<DisplayOnLoad {...individualsLoadState}>
					<table
						style={{
							display: 'inline-block',
							borderCollapse: 'collapse',
						}}
					>
						<StyledTableHead>
							<StyledHeaderRow cols={cols}>
								{/* NB: we assume first item of array will always be the primary ID item */}
								{cols.map((col, i) =>
									i === 0 ? (
										<IDCell
											{...col}
											showIcon={false}
											className="header-cell"
											key={col.name}
										>
											{col.header}
										</IDCell>
									) : (
										<StyledHeaderCell
											alignRight={col.alignRight}
											showDivider={col.showDivider}
											key={`header-${col.header}`}
											className="header-cell"
										>
											{col.header}
										</StyledHeaderCell>
									)
								)}
							</StyledHeaderRow>
						</StyledTableHead>

						<tbody>
							{filtered.map((datum, di) => (
								<StyledRow cols={cols} key={di}>
									{cols.map((col, ci) =>
										ci === 0 ? (
											<IDCell
												{...col}
												showIcon={true}
												onClick={() =>
													appDispatch(
														setActiveIndividual({
															datum,
															attributes:
																preparedData
																	? preparedData.attributes
																	: [],
														})
													)
												}
												className="body-cell"
												key={`${di}-${ci}`}
											>
												{datum[col.name]}
											</IDCell>
										) : (
											<StyledCell
												alignRight={col.alignRight}
												showDivider={col.showDivider}
												key={`${di}-${ci}`}
												className="body-cell"
											>
												{datum[col.name]}
											</StyledCell>
										)
									)}
								</StyledRow>
							))}
						</tbody>
					</table>
				</DisplayOnLoad>
			</StyledSubmoduleContent>
		</StyledVizPaper>
	);
};

export default IndividualsModule;
