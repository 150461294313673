import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useAppDispatch } from 'app/hooks';
import FlexContainer from 'common/FlexContainer';
import Heading from 'common/Heading';
import Typography from 'common/Typography';
import theme from 'common/theme/theme';
import { pipe } from 'common/utils/functionUtils';
import { warnInDev } from 'common/utils/reactUtils';
import useClientRect from 'common/utils/useClientRect';
import { setModalType } from 'features/HUD/state/HUDSlice';
import {
	useGetAccountGraphQuery,
	useGetDomainGraphQuery,
	useImportEntityMutation,
} from 'features/api';
import { parseQueryError } from 'features/api/helpers';
import { toUIGraph } from 'features/forceGraphs/helpers';
import useActiveAccountData from 'features/ontology/hooks/useActiveAccountData';
import useActiveDomainData from 'features/ontology/hooks/useActiveDomainData';
import { BaseDomain } from 'features/ontology/types/domainTypes';
import { BaseEntity } from 'features/ontology/types/entityTypes';
import {
	useEffect,
	FunctionComponent,
	useRef,
	useCallback,
	useMemo,
	useState,
} from 'react';
import { default as ForceGraph2D } from 'react-force-graph-2d';
import styled from 'styled-components';

const StyledMain = styled.main`
	width: 100%;
	height: 100%;
	overflow: hidden;
`;

const ImportEntityGraph: FunctionComponent = () => {
	const appDispatch = useAppDispatch();

	const closeModal = () => pipe(setModalType(null), appDispatch);

	const [selectedDomain, setSelectedDomain] = useState<null | BaseDomain>(
		null
	);

	const { activeItem: activeDomain } = useActiveDomainData();

	const { activeItem: activeAccount } = useActiveAccountData();

	const { data: accountGraphData } = useGetAccountGraphQuery(
		activeAccount ? { accountId: activeAccount._id } : skipToken
	);

	const { data: domainGraphData } = useGetDomainGraphQuery(
		selectedDomain ? { domainId: selectedDomain._id } : skipToken
	);

	const [importEntity, importEntityResult] = useImportEntityMutation();

	const graphData = useMemo(() => {
		const defaultGraphData = {
			nodes: [],
			links: [],
		};

		if (selectedDomain) {
			return domainGraphData
				? toUIGraph(domainGraphData)
				: defaultGraphData;
		}

		return accountGraphData
			? toUIGraph(accountGraphData)
			: defaultGraphData;
	}, [accountGraphData, domainGraphData, selectedDomain]);

	const handleNodeClick = useCallback(
		(node: BaseEntity | BaseDomain) => {
			if (!activeDomain) {
				return;
			}

			// If user has selected a domain, clicking an entity should
			// trigger the import
			if (selectedDomain) {
				return importEntity({
					domainId: activeDomain._id,
					entityId: node._id,
				});
			}

			// If user hasn't selected a domain yet, don't allow them to select the domain
			// that's already active--it wouldn't make sense to import an entity that's already
			// present on the current domain.
			if (node._id === activeDomain._id) {
				warnInDev(
					'attempted to select same domain as currently active',
					'warn'
				);
				return;
			}
			return setSelectedDomain(node as BaseDomain);
		},
		[activeDomain, selectedDomain, importEntity]
	);

	const selectNodeColor = useCallback(
		(node: BaseEntity | BaseDomain) => {
			if (!activeDomain) {
				return theme.palette.primary.main;
			}

			if (!selectedDomain && node._id === activeDomain._id) {
				return 'gray';
			}

			return theme.palette.primary.main;
		},
		[selectedDomain, activeDomain]
	);

	const [dims, setDims] = useClientRect();

	const timerRef = useRef<ReturnType<typeof setTimeout>>();

	// Close modal on success after a delay
	useEffect(() => {
		if (importEntityResult.isSuccess) {
			timerRef.current = setTimeout(() => closeModal(), 2000);
		}

		return () => {
			if (timerRef.current) {
				clearTimeout(timerRef.current);
			}
		};
	}, [importEntityResult, closeModal]);

	const getStatus = () => {
		if (importEntityResult.error) {
			return parseQueryError(importEntityResult.error).message;
		}

		if (importEntityResult.isLoading) {
			return 'Loading...';
		}

		if (importEntityResult.isSuccess) {
			return 'Success!';
		}

		if (selectedDomain) {
			return `Selecting from ${selectedDomain.label}`;
		}

		return null;
	};

	return (
		<StyledMain ref={setDims as any}>
			<FlexContainer
				flexDirection="column"
				alignItems="center"
				style={{ position: 'absolute', width: '100%', zIndex: 1 }}
			>
				<Heading component="h2" alignment="center">
					Select {selectedDomain ? 'Entity' : 'Domain'}
				</Heading>
				<Typography
					color={
						importEntityResult.error
							? 'error'
							: importEntityResult.isSuccess
							? 'primary'
							: undefined
					}
					paragraph
				>
					{getStatus()}
				</Typography>
			</FlexContainer>
			<ForceGraph2D
				width={dims.width}
				height={dims.height}
				nodeId="_id"
				graphData={graphData}
				nodeColor={selectNodeColor as any}
				onNodeClick={handleNodeClick as any}
			/>
		</StyledMain>
	);
};

export default ImportEntityGraph;
