import { pipe } from '../../../common/utils/functionUtils';
import {
	boolStringToBool,
	emptyStringToNull,
	resetDataValue,
	resetIdentifyingFields,
} from '../common/helpers';
import {
	PrependAttrFormValues,
	PrependAttrPayload,
} from './prependAttributeTypes';

export const prependAttrFormDefaults = (): PrependAttrFormValues => ({
	operationType: 'prepend',
	singular: '',
	plural: '',
	isDerivation: 'false',
	delimiter: 'none',
	otherId: 0,
	otherValue: '',
	definition: '',
	usingStaticReference: 'true',
});

export const prependAttrFormToPayload = (
	formValues: PrependAttrFormValues
): PrependAttrPayload => {
	const baseValues = {
		...formValues,
		isDerivation: boolStringToBool(formValues.isDerivation),
		definition: emptyStringToNull(formValues.definition),
		usingStaticReference: boolStringToBool(formValues.usingStaticReference),
		delimiter:
			formValues.delimiter === 'none' ? null : formValues.delimiter,
	};

	return pipe(
		baseValues,
		resetIdentifyingFields(baseValues.isDerivation),
		resetDataValue(baseValues.usingStaticReference)
	);
};
