import { hasOwnProperty } from '../../../../common/utils/typeUtils';
import DomainCreationForm from '../AccountGraph/DomainCreationForm';
import EntityCreationForm from '../DomainGraph/EntityCreationForm';
import { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';

const CreationForm: FunctionComponent = () => {
	const params = useParams();
	if (hasOwnProperty(params, 'domain')) {
		return <EntityCreationForm />;
	}
	if (hasOwnProperty(params, 'account')) {
		return <DomainCreationForm />;
	}

	return null;
};

export default CreationForm;
