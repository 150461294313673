import Button from '../../../common/Button';
import { FormResults } from '../../../common/Form';
import Typography from '../../../common/Typography';
import { EntityActionFormProps } from '../common/commonTypes';
import { StyledFlexContainer, StyledPaper } from '../common/styledComponents';
import { StyledTextArea } from '../common/styledComponents';
import { removeImportFormToPayload } from './removeImportHelpers';
import {
	RemoveImportFormValues,
	RemoveImportParams,
} from './removeImportTypes';
import { FunctionComponent } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

const RemoveImportForm: FunctionComponent<EntityActionFormProps> = (props) => {
	const { _id, setOpenAction } = props;

	const { handleSubmit, register, formState } =
		useForm<RemoveImportFormValues>({
			defaultValues: {
				question: '',
			},
		});

	//  Stubbed out until an endpoint is available
	const [persist, persistResult] = [
		(params: RemoveImportParams) => {
			console.log(params.body);
		},
		{
			isUninitialized: true,
			isError: false,
			isLoading: false,
			isFetching: false,
			isSuccess: false,
		},
	];

	const onSubmit: SubmitHandler<RemoveImportFormValues> = (vals, e) => {
		e?.preventDefault();
		persist({ entityId: _id, body: removeImportFormToPayload(vals) });
	};

	const onSuccessfulPersist = () => setOpenAction(null);

	return (
		<StyledPaper>
			<form onSubmit={handleSubmit(onSubmit)}>
				<StyledFlexContainer flexDirection="column" alignItems="center">
					<label htmlFor="question">
						<Typography>Remove import stub</Typography>
					</label>
					<StyledTextArea
						{...register('question', {
							required: 'A question is required for submission',
						})}
						id="question"
					/>

					<Button type="submit">Submit</Button>

					<FormResults
						{...persistResult}
						validationErrors={formState.errors}
						onSuccess={onSuccessfulPersist}
						onSuccessDelay={1500}
					/>
				</StyledFlexContainer>
			</form>
		</StyledPaper>
	);
};

export default RemoveImportForm;
