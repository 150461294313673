import IconButton from '../../../../common/IconButton';
import PopoverBase from '../../../../common/Popover/PopoverBase';
import DeleteEntityForm from '../../../futuremodelActions/deleteEntity/DeleteEntityForm';
import { BaseEntity } from '../../../ontology/types/entityTypes';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { nanoid } from '@reduxjs/toolkit';
import { FunctionComponent, useState, useCallback } from 'react';

interface EntityActionListItemProps extends BaseEntity {}

const DeleteEntityButton: FunctionComponent<EntityActionListItemProps> = ({
	...entity
}) => {
	const [anchorEl, setAnchorEl] = useState<HTMLLIElement | null>(null);

	const [menuOpen, setMenuOpen] = useState<string | null>(null);

	const [popperUpdateKey, setPopperUpdateKey] = useState<string | null>(null);

	const updatePopperKey = useCallback(() => setPopperUpdateKey(nanoid()), []);

	return (
		<>
			<IconButton
				ref={setAnchorEl as any}
				icon={faTrash}
				size="xs"
				baseOpacity="80%"
				fillColor="error"
				tooltip={`remove ${entity.singular ?? 'entity'}`}
				tooltipPlacement="bottom"
				onClick={() => setMenuOpen((p) => (p ? null : 'open'))}
				type="button"
				showTooltip={!menuOpen}
				aria-label="delete entity"
			/>
			<PopoverBase
				anchorEl={anchorEl}
				open={!!menuOpen}
				offset={0}
				placement="right"
				forceUpdateKey={popperUpdateKey}
			>
				<DeleteEntityForm
					{...entity}
					updatePopper={updatePopperKey}
					setOpenAction={setMenuOpen}
				/>
			</PopoverBase>
		</>
	);
};

export default DeleteEntityButton;
