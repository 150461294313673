import useMemberMgmtOpen from '../hooks/useMemberMgmtOpen';
import RightDrawer from './RightDrawer';
import UserManagementPanel from 'features/userManagement/components/UserManagementPanel';
import { FunctionComponent } from 'react';

const UserManagementDrawer: FunctionComponent = () => {
	const drawerIsOpen = useMemberMgmtOpen();

	return (
		<RightDrawer drawerOpen={drawerIsOpen} showControls={true}>
			<UserManagementPanel />
		</RightDrawer>
	);
};

export default UserManagementDrawer;
