import FlexContainer from '../../common/FlexContainer';
import Spinner from '../../common/Spinner';
import Typography from '../../common/Typography';
import { extractQueryErrMessage, QueryStateTracker } from './helpers';
import { FunctionComponent, ReactNode } from 'react';

export interface DisplayOnLoadProps extends QueryStateTracker {
	toggleOnRefetch?: boolean;
	toggleOnUninitialized?: boolean;
	spinnerDiameter?: number;
	children?: ReactNode;
}

const DisplayOnLoad: FunctionComponent<DisplayOnLoadProps> = ({
	toggleOnRefetch = false,
	toggleOnUninitialized = true,
	isLoading,
	error,
	isError,
	isUninitialized,
	isFetching,
	spinnerDiameter,
	children,
}) => {
	const isInLoadingState = () => {
		if (toggleOnRefetch && toggleOnUninitialized) {
			return !!isFetching || isUninitialized || isLoading;
		}

		if (toggleOnRefetch) {
			return !!isFetching || isLoading;
		}

		if (toggleOnUninitialized) {
			return isUninitialized || isLoading;
		}

		return isLoading;
	};

	if (isInLoadingState()) {
		return (
			<FlexContainer
				justifyContent="center"
				style={{
					position: 'fixed',
					top: '50%',
					left: '45%',
					height: spinnerDiameter ? `${spinnerDiameter}px` : '100%',
				}}
			>
				<Spinner />
			</FlexContainer>
		);
	}

	if (isError && error) {
		return (
			<Typography color="error">
				{extractQueryErrMessage(error)}
			</Typography>
		);
	}

	return <>{children}</>;
};

export default DisplayOnLoad;
