import useHUDIndividual from '../hooks/useHUDIndividual';
import IndividualCard from './IndividualCard';
import RightDrawer from './RightDrawer';
import { isNonNullable } from 'common/utils/typeGuards';
import { FunctionComponent } from 'react';

const IndividualDrawer: FunctionComponent = () => {
	const hudIndividual = useHUDIndividual();

	return (
		<RightDrawer drawerOpen={!!hudIndividual?.datum} showControls={false}>
			{isNonNullable(hudIndividual) ? (
				<IndividualCard {...hudIndividual} />
			) : null}
		</RightDrawer>
	);
};

export default IndividualDrawer;
