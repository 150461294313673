import { nanoid } from '@reduxjs/toolkit';
import { ListItem, ListItemAvatar, ListItemContent } from 'common/List';
import PopoverBase from 'common/Popover/PopoverBase';
import theme from 'common/theme/theme';
import { ActionDescriptor } from 'features/futuremodelActions/common/commonTypes';
import { BaseAttribute } from 'features/ontology/types/attributeTypes';
import {
	FunctionComponent,
	MouseEventHandler,
	useState,
	useCallback,
} from 'react';
import styled from 'styled-components';

const StyledListItem = styled(ListItem)`
	font-weight: ${(p) => p.theme.typography.fontWeightBold};
	text-transform: capitalize;
`;

const StyledSquare = styled.div`
	width: ${(p) => p.theme.spacing(1)};
	height: ${(p) => p.theme.spacing(1)};
	background-color: ${(p) => p.theme.palette.primary.main};
`;

interface ActionListItemProps extends ActionDescriptor, BaseAttribute {
	actionWithActiveSubmenu: string | null;
	setOpenAction: (actionTitle: string | null) => void;
	setOpenAttribute: (attrId: number | null) => void;
}

const ActionListItem: FunctionComponent<ActionListItemProps> = ({
	actionTitle,
	FormComponent,
	actionWithActiveSubmenu,
	setOpenAction,
	setOpenAttribute,
	...sourceAttr
}) => {
	const [anchorEl, setAnchorEl] = useState<HTMLLIElement | null>(null);

	const [popperUpdateKey, setPopperUpdateKey] = useState<string | null>(null);

	const updatePopperKey = useCallback(() => setPopperUpdateKey(nanoid()), []);

	const submenuOpen = actionWithActiveSubmenu === actionTitle;

	const onItemClick: MouseEventHandler = (e) => {
		e.stopPropagation();
		setOpenAction(actionTitle);
	};

	const isDelete = actionTitle === 'Delete attribute';

	const accentColor = isDelete
		? theme.palette.error.main
		: theme.palette.common.white;

	return (
		<>
			<StyledListItem
				highlightOnHover={true}
				highlightColor={isDelete ? accentColor : undefined}
				ref={setAnchorEl as any}
				onClick={onItemClick}
				button
			>
				<ListItemAvatar>
					<StyledSquare />
					{/*<FontAwesomeIcon*/}
					{/*	size="sm"*/}
					{/*	icon={isDelete ? faTrash : faCube}*/}
					{/*/>*/}
				</ListItemAvatar>
				<ListItemContent>{actionTitle}</ListItemContent>
			</StyledListItem>

			<PopoverBase
				anchorEl={anchorEl}
				open={submenuOpen}
				offset={0}
				placement="right"
				forceUpdateKey={popperUpdateKey}
			>
				<FormComponent
					setOpenAction={setOpenAction}
					{...sourceAttr}
					updatePopper={updatePopperKey}
					setOpenAttribute={setOpenAttribute}
				/>
			</PopoverBase>
		</>
	);
};

export default ActionListItem;
