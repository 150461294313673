import { RequireAtLeastOne } from '../utils/typeUtils';
import { FunctionComponent, HTMLAttributes } from 'react';
import styled from 'styled-components';

const StyledForm = styled.form`
	width: 25%;
	min-width: 300px;
	border-radius: ${(p) => p.theme.spacing(0.5)};
	padding: ${(p) => p.theme.spacing(2)};
	color: ${(p) => p.theme.palette.text.primary};
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	gap: ${(p) => p.theme.spacing(2)};
	align-items: center;
`;

type FormBodyProps = RequireAtLeastOne<
	HTMLAttributes<HTMLFormElement>,
	'aria-labelledby' | 'aria-label'
>;

const ModalFormBody: FunctionComponent<FormBodyProps> = ({
	children,
	...props
}) => {
	// TODO: clean this up
	return (
		// @ts-ignore
		<StyledForm {...props}>{children}</StyledForm>
	);
};

export default ModalFormBody;
