import FlexContainer from '../../../common/FlexContainer';
import {
	ListItem,
	ListItemContent,
	ListItemAvatar,
} from '../../../common/List';
import Spinner from '../../../common/Spinner';
import Typography from '../../../common/Typography';
import { useAcceptAnswerMutation } from '../../api';
import QuestionContext from '../state/QuestionContext';
import { BaseAnswer } from '../types/questionTypes';
import { StyledQuestionListButton } from './styledComponents';
import { faCheck, faReply } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FunctionComponent, useContext } from 'react';

interface AnswerItemProps extends BaseAnswer {
	answerExists: boolean;
}

const AnswerItem: FunctionComponent<AnswerItemProps> = ({
	_id,
	isAccepted,
	content,
	answerExists,
	questionId,
}) => {
	const { parentObjectId, parentObjectType, canEdit } =
		useContext(QuestionContext);

	const [accept, acceptanceResult] = useAcceptAnswerMutation();

	const renderControl = () =>
		acceptanceResult.isLoading ? (
			<Spinner />
		) : (
			<StyledQuestionListButton
				onClick={() =>
					accept({
						parentObjectId,
						parentObjectType,
						questionId,
						body: { answerId: _id },
					})
				}
				variant="outline"
			>
				Accept
			</StyledQuestionListButton>
		);

	return (
		<div>
			<ListItem key={_id}>
				<ListItemAvatar>
					<FontAwesomeIcon
						icon={isAccepted ? faCheck : faReply}
						size="xs"
					/>
				</ListItemAvatar>
				<ListItemContent>
					<Typography variant="body2">{content}</Typography>
				</ListItemContent>
			</ListItem>
			{!answerExists && canEdit && (
				<FlexContainer justifyContent="flex-end">
					{renderControl()}
				</FlexContainer>
			)}
		</div>
	);
};

export default AnswerItem;
