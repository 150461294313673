import Button from '../../../common/Button';
import FlexContainer from '../../../common/FlexContainer';
import { FormResults } from '../../../common/Form';
import Spinner from '../../../common/Spinner';
import Typography from '../../../common/Typography';
import { useGetAttributesQuery, useLoadAttributeMutation } from '../../api';
import { mergeErrorStates, extractQueryErrMessage } from '../../api/helpers';
import { isRelation } from '../../ontology/typeGuards/attributeGuards';
import { GetEntityAttrsResponse } from '../../ontology/types/attributeTypes';
import { AttrActionFormProps } from '../common/commonTypes';
import { renderOtherIdSelect } from '../common/jsxHelpers';
import { StyledFlexContainer, StyledPaper } from '../common/styledComponents';
import {
	loadAttrFormDefaults,
	loadAttrFormToPayload,
} from './loadAttributeHelpers';
import { LoadAttrFormValues } from './loadAttributeTypes';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { FunctionComponent, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

const LoadAttributeForm: FunctionComponent<AttrActionFormProps> = (props) => {
	const { _id: sourceAttrId, updatePopper } = props;

	const objectEntityId = isRelation(props) ? props.objectEntityId : null;

	const formDefaults = loadAttrFormDefaults();

	const { handleSubmit, register, formState } = useForm<LoadAttrFormValues>({
		defaultValues: formDefaults,
	});

	const queryRes = useGetAttributesQuery(
		objectEntityId === null ? skipToken : { entityId: objectEntityId }
	);

	useEffect(() => {
		if (queryRes.isSuccess) {
			updatePopper();
		}
	}, [queryRes.isSuccess, updatePopper]);

	const [loadAttr, loadAttrResult] = useLoadAttributeMutation();

	const mergedErrs = mergeErrorStates(queryRes, loadAttrResult);

	const onSubmit: SubmitHandler<LoadAttrFormValues> = (vals, e) => {
		e?.preventDefault();
		loadAttr({
			sourceAttrId,
			body: loadAttrFormToPayload(vals),
		});
	};

	if (queryRes.isUninitialized) {
		return (
			<FlexContainer justifyContent="center">
				<Typography color="warn" paragraph>
					Waiting to receive target entity Id... If this message does
					not clear, it is likely that no entity id is available for
					the target entity of this relation.
				</Typography>
				<Spinner />
			</FlexContainer>
		);
	}

	if (queryRes.isLoading) {
		return (
			<FlexContainer justifyContent="center">
				<Typography paragraph>Loading attributes...</Typography>
				<Spinner />
			</FlexContainer>
		);
	}

	if (queryRes.isError) {
		return (
			<FlexContainer justifyContent="center">
				<Typography color="error" paragraph>
					{extractQueryErrMessage(queryRes.error)}
				</Typography>
			</FlexContainer>
		);
	}

	return (
		<StyledPaper>
			<form onSubmit={handleSubmit(onSubmit)}>
				<StyledFlexContainer flexDirection="column" alignItems="center">
					{renderOtherIdSelect(
						'attributeId',
						register,
						formState,
						//   Ok to cast--if it's not loading and not errored, files is present
						queryRes.data as GetEntityAttrsResponse
					)}

					<Button type="submit">Submit</Button>

					<FormResults
						isError={!!mergedErrs}
						error={mergedErrs}
						validationErrors={formState.errors}
					/>
				</StyledFlexContainer>
			</form>
		</StyledPaper>
	);
};

export default LoadAttributeForm;
