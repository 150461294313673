import { LinePoint } from './types';
import theme from 'common/theme/theme';
import { resolveAttrType } from 'features/ontology/typeGuards/attributeGuards';
import {
	BaseAttribute,
	ResolvedAttributeType,
} from 'features/ontology/types/attributeTypes';
import { PointEnhancer } from 'features/viz/types';

export const filterAttrsForYMenu = (
	xAttr: string,
	attrs: BaseAttribute[],
	desiredTypes: ResolvedAttributeType[]
) =>
	attrs.filter(
		(a) => a.name !== xAttr && desiredTypes.includes(resolveAttrType(a))
	);

const availablePointColors = [
	theme.palette.primary.main,
	theme.palette.error.main,
	theme.palette.secondary.main,
];

export const linePointEnhancer: PointEnhancer<string, number, LinePoint> = (
	point,
	xAttrName,
	yAttrNames,
	currentYName,
	idx
) => {
	const colorIdx =
		yAttrNames.indexOf(currentYName) % availablePointColors.length;

	return {
		...point,
		individualIdx: idx,
		color: availablePointColors[colorIdx],
		uniqueKey: `${xAttrName}-${currentYName}-${idx}`,
	};
};
