import styled from 'styled-components';

interface PaperProps {
	translucent?: boolean;
}

const Paper = styled.div<PaperProps>`
	background: ${(p) =>
		p.theme.palette.background[
			p.translucent ? 'transparentDark' : 'paper'
		]};
	border-radius: 4px;
	box-shadow: ${(p) => p.theme.shadows[4]};
	color: ${(p) => p.theme.palette.common.white};

	@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
		-webkit-backdrop-filter: blur(1px);
		backdrop-filter: blur(1px);
	}
`;

// const Paper: FunctionComponent = ({ children }) => {
// 	return <StyledPaper>{children}</StyledPaper>;
// };
export default Paper;
