import {
	LiveDataColumns,
	GetLiveDataSetPreviewResponse,
	LiveDataSet,
} from '../types/dataTypes';
import { isLiveDataSet } from '../types/uiTypes';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import FlexContainer from 'common/FlexContainer';
import IconButton from 'common/IconButton';
import Typography from 'common/Typography';
import { flow } from 'common/utils/functionUtils';
import {
	useGetDataSetPreviewQuery,
	useGetSourceSummariesQuery,
} from 'features/api';
import useActiveAccountData from 'features/ontology/hooks/useActiveAccountData';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
	VariableSizeList as List,
	ListProps,
	ListChildComponentProps,
} from 'react-window';
import styled from 'styled-components';

const StyledTableToggle = styled(IconButton)`
	box-shadow: none;
	border: none;

	&:hover {
		background: #ffffff20;
	}
`;

const StyledGridItem = styled.div<{ $itemCount: number }>`
	background-color: transparent;
	display: grid;
	grid-template-columns: repeat(${(p) => p.$itemCount}, 1fr);
	grid-gap: ${(p) => p.theme.spacing(1)};
	align-items: center;
	border-bottom: 1px solid black;
	font-size: 1rem;
	overflow: auto;

	&:hover {
		background-color: #ffffff20;
	}
`;

interface RowProps {
	items: any[];
}
const Row: FunctionComponent<RowProps> = ({ items }) => {
	return (
		<>
			{items.map((item, i) => (
				<div key={`${item}--${i}`}>{String(item)}</div>
			))}
		</>
	);
};

interface PreviewListItemProps<T> extends ListChildComponentProps<T> {}

const PreviewListItem: FunctionComponent<
	PreviewListItemProps<LiveDataColumns | GetLiveDataSetPreviewResponse>
> = ({ data, style, index }) => {
	const itemCount = Object.keys(data[0]).length;

	return (
		<StyledGridItem style={style} $itemCount={itemCount}>
			<Row items={Object.values(data[index]) as string[]} />
			{/* {Row(Object.values(files[index]) as string[])} */}
		</StyledGridItem>
	);
};

interface DataPreviewProps {
	onDisplay: LiveDataSet;
}

type DataPreviewListProps<T> = Pick<
	ListProps<T>,
	'width' | 'height' | 'itemData'
> & { itemData: T };

const getMaxItemLength = (rowData: Record<any, any>) =>
	Object.keys(rowData).reduce((acc, key) => {
		const underTest = rowData[key];

		if (typeof underTest !== 'string') {
			return acc;
		}

		return underTest.length > acc ? underTest.length : acc;
	}, 0);

const calculateHeightFromStringLength = (stringLength: number) => {
	let size = 50;

	if (stringLength === 0) return size;

	const multiplier = Math.round(stringLength / 15);

	const newSize = size + multiplier * 10;

	return newSize;
};

const calcItemSize = flow(getMaxItemLength, calculateHeightFromStringLength);

const DataPreviewList: FunctionComponent<
	DataPreviewListProps<LiveDataSet | GetLiveDataSetPreviewResponse>
> = ({ width, height, itemData }) => {
	const data = isLiveDataSet(itemData)
		? itemData.tableSchema.columns
		: itemData;

	const listRef = useRef<List | null>(null);

	useEffect(() => {
		listRef.current?.resetAfterIndex(0);
	});

	if (data.length === 0) {
		return <Typography>Data unavailable</Typography>;
	}

	const getItemSize = (idx: number) => calcItemSize(data[idx]);

	return (
		<List
			itemData={data}
			itemCount={data.length}
			itemSize={getItemSize}
			height={height}
			width={width}
			ref={listRef}
		>
			{PreviewListItem}
		</List>
	);
};

const DataPreview: FunctionComponent<DataPreviewProps> = ({ onDisplay }) => {
	const [previewMode, setPreviewMode] = useState<'overview' | 'sample'>(
		'overview'
	);

	const { activeItem } = useActiveAccountData();

	// get all the user sources for filtering by name.  Query for
	// preview files needs a source _id to ask for correct preview files.
	const { data: sourcesData, isSuccess: isSourcesSuccess } =
		useGetSourceSummariesQuery(
			activeItem ? { accountId: activeItem._id } : skipToken
		);

	const [sourceId, setSourceId] = useState<number | null>(null);

	// set a source id if we can match exactly one to the sourceName
	// field of the dataset in question.
	useEffect(() => {
		if (isSourcesSuccess && sourcesData) {
			const source = sourcesData.filter(
				(src) => src.name === onDisplay.sourceName
			);

			// TODO: fix this
			if (source.length !== 1) {
				throw new Error(
					'DataPreview was unable to match selected DataSet to exactly one source id'
				);
			}

			setSourceId(source[0]._id);
		}
	}, [onDisplay, isSourcesSuccess, sourcesData]);

	const [activeData, setActiveData] = useState<
		LiveDataSet | GetLiveDataSetPreviewResponse
	>(onDisplay);

	const { data: previewData } = useGetDataSetPreviewQuery(
		sourceId
			? {
					catalogName: onDisplay.catalogName,
					sourceId: sourceId,
					datasetName: onDisplay.name,
			  }
			: skipToken
	);

	// The type of activeData determines what gets rendered.
	useEffect(() => {
		if (previewMode === 'overview') {
			return setActiveData({ ...onDisplay });
		}

		if (previewData) {
			return setActiveData([...previewData]);
		}
	}, [previewMode, previewData, onDisplay]);

	const togglePreviewMode = () =>
		setPreviewMode((prev) => (prev === 'sample' ? 'overview' : 'sample'));

	return (
		<FlexContainer flexDirection="column" style={{ height: '100%' }}>
			<FlexContainer justifyContent="flex-end">
				<StyledTableToggle
					icon={faUndo}
					// size="sm"
					variant="transparent"
					shape="round"
					aria-label="toggle dataset view"
					onClick={togglePreviewMode}
				></StyledTableToggle>
			</FlexContainer>
			<div style={{ flexGrow: 1, padding: '.5rem' }}>
				<AutoSizer>
					{({ width, height }) => {
						return (
							<DataPreviewList
								itemData={activeData}
								height={height}
								width={width}
							/>
						);
					}}
				</AutoSizer>
			</div>
		</FlexContainer>
	);
};

export default DataPreview;
