import { QuestionableObject } from '../types/questionTypes';
import { createContext } from 'react';

const QuestionContext = createContext({
	parentObjectId: 0,
	parentObjectType: '' as QuestionableObject,
	canEdit: false,
});

export default QuestionContext;
