import useAuthStatus from '../../../authentication/hooks/useAuthStatus';
import { FunctionComponent } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const LogoLink = styled(Link)`
	display: grid;
	align-items: center;
	max-width: ${(p) => p.theme.drawerWidth - 8}px;
	padding: 0 ${(p) => p.theme.spacing(0.5)};
`;


const StyledLogo = styled.img`
	width: 100%;
	max-height: 30vh;
`;

const NavLogo: FunctionComponent = () => {
	const { pathname } = useLocation();

	const isAuthenticated = useAuthStatus();

	// If we're on the teaser/reserve user page, don't display link/logo
	if (pathname === '/' && !isAuthenticated) {
		return null;
	} else if (
		((
			pathname === '/login' ||
			pathname === '/reset-password-request' ||
			pathname === '/reset-password' ||
			pathname === '/complete-registration'
		) && !isAuthenticated) || (
			pathname === '/terms-of-service' && isAuthenticated
		)
	) {
		return (
		<LogoLink to="/">
			<StyledLogo src="https://storage.googleapis.com/davis-associates.appspot.com/futuremodel-teaser.svg" alt="Futuremodel logo" />
		</LogoLink>
	);
	}

	return (
		<LogoLink to="/">
			<img src='https://storage.googleapis.com/davis-associates.appspot.com/futuremodel.svg'
				 width={'100%'}
			/>
		</LogoLink>
	);
};

export default NavLogo;
