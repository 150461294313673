import Button from '../../../common/Button';
import FlexContainer from '../../../common/FlexContainer';
import {
	formFieldHasErrors,
	FormResults,
	genErrorIdFromLabel,
} from '../../../common/Form';
import Spinner from '../../../common/Spinner';
import Typography from '../../../common/Typography';
import { useCreateLocationMutation, useGetAttributesQuery } from '../../api';
import { extractQueryErrMessage } from '../../api/helpers';
import { GetEntityAttrsResponse } from '../../ontology/types/attributeTypes';
import { EntityActionFormProps } from '../common/commonTypes';
import { renderDerivationSubfields } from '../common/jsxHelpers';
import { StyledFlexContainer, StyledPaper } from '../common/styledComponents';
import {
	createLocationFormDefaults,
	createLocationFormToPayload,
} from './createLocationHelpers';
import { CreateLocationFormValues } from './createLocationTypes';
import { FunctionComponent, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

const CreateLocationForm: FunctionComponent<EntityActionFormProps> = (
	props
) => {
	const { _id } = props;

	const formDefaults = createLocationFormDefaults();

	const { handleSubmit, register, formState, watch } =
		useForm<CreateLocationFormValues>({
			defaultValues: formDefaults,
		});

	const watchedLatitudeId = watch('latitudeId');

	const [createLocation, createLocationResult] = useCreateLocationMutation();

	const onSubmit: SubmitHandler<CreateLocationFormValues> = (vals) => {
		createLocation({
			entityId: _id,
			body: createLocationFormToPayload(vals),
		});
	};

	const {
		isLoading,
		isError,
		error,
		data: attrData,
		isUninitialized,
	} = useGetAttributesQuery({ entityId: _id });

	//  lat and long can't be the same attribute
	const longitudeCandidates = useMemo(() => {
		if (attrData) {
			return attrData.filter((attr) => attr._id !== watchedLatitudeId);
		}

		return [];
	}, [watchedLatitudeId, attrData]);

	if (isLoading || isUninitialized) {
		return (
			<FlexContainer justifyContent="center">
				<Typography paragraph>Loading attributes...</Typography>
				<Spinner />
			</FlexContainer>
		);
	}

	if (isError) {
		return (
			<FlexContainer justifyContent="center">
				<Typography color="error" paragraph>
					{extractQueryErrMessage(error)}
				</Typography>
			</FlexContainer>
		);
	}

	return (
		<StyledPaper>
			<form onSubmit={handleSubmit(onSubmit)}>
				<StyledFlexContainer flexDirection="column" alignItems="center">
					{renderDerivationSubfields(true, register, formState)}

					<label htmlFor="latitudeId">
						<Typography>
							Select an attribute to provide latitude
						</Typography>
					</label>
					<select
						{...register('latitudeId', {
							valueAsNumber: true,
							validate: (v: string | number) => {
								if (typeof v === 'string') {
									const maybeInt = parseInt(v, 10);

									return isNaN(maybeInt)
										? 'Invalid value passed to otherId'
										: maybeInt > 0
										? true
										: 'An attribute must be selected to provide latitude';
								}

								return v > 0
									? true
									: 'A reference attribute must be selected if you are not using a static files value';
							},
						})}
						id={'latitudeId'}
						aria-errormessage={genErrorIdFromLabel('latitudeId')}
						aria-invalid={formFieldHasErrors(
							'latitudeId',
							formState
						)}
					>
						{(attrData as GetEntityAttrsResponse).map((attr) => (
							<option
								value={attr._id}
								key={attr._id}
							>{`${attr.entity.plural}: ${attr.plural}`}</option>
						))}
					</select>

					<label htmlFor="longitudeId">
						<Typography>
							Select an attribute to provide longitude
						</Typography>
					</label>
					<select
						{...register('longitudeId', {
							valueAsNumber: true,
							validate: (v: string | number) => {
								if (typeof v === 'string') {
									const maybeInt = parseInt(v, 10);

									return isNaN(maybeInt)
										? 'Invalid value passed to otherId'
										: maybeInt > 0
										? true
										: 'An attribute must be selected to provide longitude';
								}

								return v > 0
									? true
									: 'A reference attribute must be selected if you are not using a static files value';
							},
						})}
						id={'longitudeId'}
						aria-errormessage={genErrorIdFromLabel('longitudeId')}
						aria-invalid={formFieldHasErrors(
							'longitudeId',
							formState
						)}
					>
						{longitudeCandidates.map((attr) => (
							<option
								value={attr._id}
								key={attr._id}
							>{`${attr.entity.plural}: ${attr.plural}`}</option>
						))}
					</select>

					<Button type="submit">Submit</Button>
					<FormResults
						isError={createLocationResult.isError}
						error={createLocationResult.error}
						validationErrors={formState.errors}
					/>
				</StyledFlexContainer>
			</form>
		</StyledPaper>
	);
};

export default CreateLocationForm;
