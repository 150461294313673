import {
	StyledVizPaper,
	StyledSubmoduleHeader,
	StyledSubmoduleContent,
} from '../styledComponents';
import { faHashtag, faPercentage } from '@fortawesome/free-solid-svg-icons';
import FlexContainer from 'common/FlexContainer';
import SlideToggle, { SlideToggleCheckDescriptor } from 'common/SlideToggle';
import Typography from 'common/Typography';
import useElementSize from 'common/hooks/useSize';
import { isAppError } from 'common/utils/typeGuards';
import DisplayOnLoad from 'features/api/DisplayOnLoad';
import useEntitySearchParams from 'features/compositeViews/EntityViews/hooks/useEntitySearchParams';
import useDispatchableErr from 'features/errorHandling/hooks/useDispatchableErr';
import useActiveIndividualsMeta from 'features/ontology/hooks/useActiveIndividualsMeta';
import HorizontalBarChart from 'features/viz/HorizontalBarChart';
import { FunctionComponent, useMemo, useState } from 'react';

const toggleDefs: SlideToggleCheckDescriptor<boolean>[] = [
	{
		label: 'percentage',
		icon: faPercentage,
		value: true,
		tooltip: 'Percentage',
	},
	{ label: 'value', icon: faHashtag, value: false, tooltip: 'Count' },
];

const BarChartModule: FunctionComponent = () => {
	const dispatchError = useDispatchableErr();

	const [size, setSizeTarget] = useElementSize();

	const { getActiveAttributeName } = useEntitySearchParams();

	const [showPercentage, setShowPercentage] = useState(true);

	const attributeName = getActiveAttributeName();

	const { preparedData, ...individualsLoadState } =
		useActiveIndividualsMeta();

	const facts = useMemo(() => {
		if (preparedData && attributeName) {
			const facts = preparedData.facts<string>(attributeName);

			if (isAppError(facts)) {
				dispatchError(facts);
				return [] as string[];
			}

			return facts.reduce(
				(acc, f) => acc.concat(f.values),
				[] as string[]
			);
		}

		return [] as string[];
	}, [attributeName, preparedData, dispatchError]);

	return (
		<StyledVizPaper>
			<StyledSubmoduleHeader>
				<FlexContainer justifyContent="space-between">
					<Typography>Count by {attributeName}</Typography>
					<SlideToggle
						onActiveValueMismatch={dispatchError}
						activeValue={showPercentage}
						legend="select bar chart mode"
						onCheck={setShowPercentage}
						checkboxes={toggleDefs}
					/>
					<div></div>
				</FlexContainer>
			</StyledSubmoduleHeader>
			<DisplayOnLoad {...individualsLoadState} spinnerDiameter={50}>
				<StyledSubmoduleContent ref={setSizeTarget}>
					<HorizontalBarChart
						facts={facts}
						width={size.width}
						height={size.height}
						showPercentage={showPercentage}
					/>
				</StyledSubmoduleContent>
			</DisplayOnLoad>
		</StyledVizPaper>
	);
};

export default BarChartModule;
