import {
	Empty,
	EMPTY_TAG,
	keyExists,
	NonEmptyString,
	Nullable,
} from './typeUtils';
import {
	AppError,
	APP_ERROR_TAG,
} from 'features/errorHandling/types/errorTypes';

export const isValidEmail = (email: string) => {
	const exp =
		/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

	return exp.test(email) ? true : 'Please enter a valid email address';
};

/**NB this more sensitive than specific, i.e. there are some false positive cases.
 *
 * Taken from : https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
 */
export const isValidURL = (s: string) => {
	let url: URL;

	try {
		url = new URL(s);
	} catch (e) {
		return false;
	}

	return url.protocol === 'http:' || url.protocol === 'https:';
};

export const isMinMax = (s: unknown): s is [number, number] =>
	Array.isArray(s) && s.length === 2 && s.every(isNumber) && s[0] <= s[1];

export const isIncludedValues = (as: unknown): as is string[] =>
	Array.isArray(as) && as.length > 0 && as.every(isNonEmptyString);

export const isString = (u: unknown): u is string => typeof u === 'string';

export const isNonEmptyString = (u: unknown): u is NonEmptyString =>
	isString(u) && u.length > 0;

export const isNumber = (v: unknown): v is number =>
	typeof v === 'number' && !isNaN(v);

export const isEmpty = (u: unknown): u is Empty =>
	keyExists(u, '__tag') ? (u.__tag === EMPTY_TAG ? true : false) : false;

export const isPopulated = (u: unknown) => !isEmpty(u);

export const isAppError = (u: unknown): u is AppError =>
	keyExists(u, '__tag') && u.__tag === APP_ERROR_TAG;

export const isNonNullObject = (u: unknown): u is {} =>
	typeof u === 'object' && u !== null;

export const isNonNullable = <T extends Nullable<any>>(
	t: T
): t is NonNullable<T> => t !== null && t !== undefined;

export const isReadonlyStringArray = (u: unknown): u is readonly string[] =>
	Array.isArray(u) &&
	(typeof u[0] === 'string' || typeof u[0] === 'undefined');
