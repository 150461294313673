import styled from 'styled-components';

export interface ImageAvatarProps {
	src: string;
}

const ImageAvatar = styled.div<ImageAvatarProps>`
	margin-top: 3px;
	border-radius: 50%;
	border: 3px solid ${(p) => p.theme.palette.darkBaby};
	/* magic number alert: IconButtonBase sets button width/height to theme.spacing(4) when
 button's size prop is 'sm'.  We want this button to be the same size. */
	height: ${(p) => p.theme.spacing(4)};
	width: ${(p) => p.theme.spacing(4)};
	background: url(${(p) => p.src}) center/cover;
	&:hover {
		cursor: pointer;
	}
`;

export default ImageAvatar;
