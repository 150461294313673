import Button from 'common/Button';
import { FormBody, FormHeading, FormResults } from 'common/Form';
import Textbox from 'common/Textbox';
import { useCompleteRegistrationMutation } from 'features/api';
import React, { FunctionComponent } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Navigate, useSearchParams, useNavigate } from 'react-router-dom';
import styled from "styled-components";
import FlexContainer from "../../../common/FlexContainer";
import theme from "../../../common/theme/theme";

interface RegisterFormValues {
	firstName: string;
	lastName: string;
	username: string;
	password: string;
}

const StyledLogo = styled.img`
	width: 100%;
	max-height: 30vh;
	margin-top: ${(p) => p.theme.spacing(1)};
`;

const StyledMain = styled.div`
	background-color: ${(p) => p.theme.palette.darkBaby};
	width: 100%;
	height: 100vh;
	color: ${(p) => p.theme.palette.lightBaby};
`;

const StyledContainer = styled(FlexContainer)`
	margin: auto;
	position: fixed;
	top: 200px;
	left: 0;
	right: 0;
	padding-top: ${(p) => 2 * p.theme.navHeight}px;
	width: 40%;
	gap: ${(p) => p.theme.spacing(4)};

	@media screen and (max-width: 600px) {
		width: 80%;
	}
`;


// TODO: in the near future we will likely want to push user to /feed
// or something like that to prevent excessive re-routing/rendering
// by pushing to root then hitting a bunch of re-routes based on the
// fact that user authenticated after completing this form.
const RegisterPage: FunctionComponent = () => {
	const navigate = useNavigate();

	const searchParams = useSearchParams()[0];

	const { handleSubmit, register, formState } = useForm<RegisterFormValues>({
		defaultValues: {
			firstName: '',
			lastName: '',
			username: '',
			password: '',
		},
	});

	const [registerUser, registrationResult] =
		useCompleteRegistrationMutation();

	const onSubmit: SubmitHandler<RegisterFormValues> = (vals) => {
		registerUser({
			registrationToken: searchParams.get('registrationToken') as string,
			...vals,
		});
	};

	const onSuccess = () => navigate('/');

	if (!searchParams.get('registrationToken')) {
		return <Navigate to="/" />;
	}

	return (
		<StyledMain>
			<StyledContainer flexDirection="column">
				<FormBody
					onSubmit={handleSubmit(onSubmit)}
					aria-labelledby="complete-registration-heading"
				>
					<FormHeading component="h1" id="complete-registration-heading">
						Complete Registration
					</FormHeading>
					<Textbox
						style={{
							height: '42px',
						}}
						labelText="First Name"
						error={formState.errors.firstName}
						{...register('firstName', {
							required: 'Please enter your first name',
						})}
					/>
					<Textbox
						style={{
							height: '42px',
						}}
						labelText="Last Name"
						error={formState.errors.lastName}
						{...register('lastName', {
							required: 'Please enter your last name',
						})}
					/>
					<Textbox
						style={{
							height: '42px',
						}}
						labelText="Username"
						error={formState.errors.username}
						{...register('username', {
							required: 'Please enter a username',
						})}
					/>
					<Textbox
						style={{
							height: '42px',
						}}
						type="password"
						labelText="Password"
						error={formState.errors.password}
						{...register('password', {
							required: 'Please enter a password',
						})}
					/>
					<Button
						style={{
							color: theme.palette.darkerBaby,
							height: '42px',
							width: '100%',
						}}
						type="submit">
						Submit
					</Button>
					<FormResults
						{...registrationResult}
						validationErrors={{}}
						onSuccess={onSuccess}
						onSuccessDelay={1000}
					/>
				</FormBody>
				<StyledLogo
						style={{
							position: 'relative'
						}}
						src='https://storage.googleapis.com/davis-associates.appspot.com/fm-hero-figure.svg'
						role="presentation"
					/>
			</StyledContainer>
		</StyledMain>
	);
};

export default RegisterPage;
