import { List } from '../List';
import Spinner from '../Spinner';
import Success from '../Success';
import Typography from '../Typography';
import { genErrorIdFromLabel } from './helpers';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { isNonNullObject } from 'common/utils/typeGuards';
import { hasOwnProperty } from 'common/utils/typeUtils';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { FieldErrors } from 'react-hook-form';
import styled from 'styled-components';

const StyledResultBox = styled.div`
	padding: ${(p) => p.theme.spacing(2)};
`;

interface FormResultsProps {
	successMessage?: string;
	validationErrors?: FieldErrors;
	isError?: boolean;
	isLoading?: boolean;
	isSuccess?: boolean;
	error?: FetchBaseQueryError | SerializedError | undefined;
	onSuccess?: () => void;
	onSuccessDelay?: number;
}

type SetTimeoutResult = ReturnType<typeof setTimeout>;

const runOnSuccess = (setter: () => void, cb?: () => void, delay?: number) => {
	// run callback after a delay and reset success UI
	if (delay && cb) {
		return setTimeout(() => {
			setter();
			cb();
		}, delay);
	}

	//  just reset success UI after a delay
	if (delay) {
		return setTimeout(() => setter(), delay);
	}

	//  immediately call onSuccess callback (no delay) on success
	if (cb) {
		cb();
	}

	return null;
};

const FormResults: FunctionComponent<FormResultsProps> = ({
	successMessage,
	validationErrors,
	isError,
	isLoading,
	isSuccess,
	error,
	onSuccess,
	onSuccessDelay,
}) => {
	const [inSuccessState, setInSuccessState] = useState(false);

	const timerRef = useRef<SetTimeoutResult | null>(null);

	const clearTimer = () => {
		if (timerRef.current) {
			clearTimeout(timerRef.current);
		}
	};

	const setTimerRef = (r: SetTimeoutResult | null) => (timerRef.current = r);

	// TODO: add tests for this behavior
	useEffect(() => {
		clearTimer();

		if (isSuccess) {
			setInSuccessState(true);
			const setter = () => undefined;

			setTimerRef(runOnSuccess(setter, onSuccess, onSuccessDelay));
		} else {
			setInSuccessState(false);
		}

		return clearTimer;
	}, [isSuccess, onSuccess, onSuccessDelay]);

	let result: any;

	if (validationErrors) {
		const activeValidationErrors = Object.entries(validationErrors);

		//   TODO: the 'id' field associates this node's text content with the
		// aria-errormessage field of the relevant input.  However, this will
		// fail for dynamically-generated fields managed through via react-hook-form.
		// Could update something here to accept an 'index' to disambiguate.
		if (activeValidationErrors.length !== 0) {
			result = (
				<List>
					{activeValidationErrors.map(([key, val]) => (
						<li key={key}>
							<Typography
								id={genErrorIdFromLabel(key)}
								color="error"
								paragraph
								style={{ padding: '8px 0' }}
							>
								{val.message}
							</Typography>
						</li>
					))}
				</List>
			);
		}
	}

	// errors from AJAX results override any validation err messages, though having both validation
	// errors and an HTTP result should
	// (hopefully) be an impossible state...
	if (isError) {
		let message = 'There was a problem with form submission.';

		if (error && hasOwnProperty(error, 'data')) {
			if (
				isNonNullObject(error.data) &&
				hasOwnProperty(error.data, 'message')
			) {
				message = error.data.message as string;
			}
		}

		result = <Typography color="error">{message}</Typography>;
	}

	if (isLoading) {
		result = <Spinner />;
	}

	if (inSuccessState) {
		result = <Success>{successMessage ?? 'Success!'}</Success>;
	}

	return result ? <StyledResultBox>{result}</StyledResultBox> : null;
};

export default FormResults;
