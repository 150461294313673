import Button from '../../../common/Button';
import FlexContainer from '../../../common/FlexContainer';
import {
	formFieldHasErrors,
	FormResults,
	genErrorIdFromLabel,
} from '../../../common/Form';
import Spinner from '../../../common/Spinner';
import Typography from '../../../common/Typography';
import {
	useGetAttributesQuery,
	useAggregateAttributeMutation,
} from '../../api';
import { mergeErrorStates, extractQueryErrMessage } from '../../api/helpers';
import { isRelation } from '../../ontology/typeGuards/attributeGuards';
import { GetEntityAttrsResponse } from '../../ontology/types/attributeTypes';
import { AttrActionFormProps } from '../common/commonTypes';
import {
	renderDerivationSubfields,
	renderOtherIdSelect,
} from '../common/jsxHelpers';
import { StyledFlexContainer, StyledPaper } from '../common/styledComponents';
import {
	aggregateAttrFormDefaults,
	aggregateAttrFormToPayload,
	getAvailableAggregationTypes,
} from './aggregateAttributeHelpers';
import { AggregateAttrFormValues } from './aggregateAttributeTypes';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

const AggregateAttributeForm: FunctionComponent<AttrActionFormProps> = (
	props
) => {
	const { _id, updatePopper } = props;

	const objectEntityId = isRelation(props) ? props.objectEntityId : null;

	const formDefaults = aggregateAttrFormDefaults();

	const { handleSubmit, register, formState, watch, resetField } =
		useForm<AggregateAttrFormValues>({
			defaultValues: formDefaults,
		});

	const [aggTypeRef, setAggTypeRef] = useState<HTMLSelectElement | null>(
		null
	);

	// we register aggregationType OUTSIDE of an element so that we can
	// control ref assignment inside aggregationType <select/>. We
	// need to set two refs for this element: one for react-hook-form,
	// and another (aggTypeRef)
	const { ref: registerRef, ...aggTypeRegisterProps } = register(
		'aggregationType',
		{
			required: 'AggregationType is a required field',
			validate: (v) =>
				v === 'none' ? 'An aggregation must be selected' : true,
		}
	);

	const queryRes = useGetAttributesQuery(
		objectEntityId === null ? skipToken : { entityId: objectEntityId }
	);

	const watchedAggregatingId = watch('aggregatingId');

	//  only re-find/filter when user's attr selection changes
	const availableAggregations = useMemo(() => {
		if (watchedAggregatingId === 0) {
			return [];
		}

		if (!queryRes.data) {
			return [];
		}

		const selectedAttr = queryRes.data.find(
			(attr) => attr._id === watchedAggregatingId
		);

		if (!selectedAttr) {
			return [];
		}

		return getAvailableAggregationTypes(selectedAttr);
	}, [queryRes.data, watchedAggregatingId]);

	// When user's attr selection changes, reset form value
	// and form display value to 'none' to avoid conflicts
	// between internal formstate and the display value of
	// uncontrolled input
	useEffect(() => {
		resetField('aggregationType');

		if (aggTypeRef) {
			aggTypeRef.value = 'none';
		}
	}, [aggTypeRef, availableAggregations, resetField]);

	useEffect(() => {
		if (queryRes.isSuccess) {
			updatePopper();
		}
	}, [queryRes.isSuccess, updatePopper]);

	const [aggregateAttr, aggregateAttrResult] =
		useAggregateAttributeMutation();

	const mergedErrs = mergeErrorStates(queryRes, aggregateAttrResult);

	const onSubmit: SubmitHandler<AggregateAttrFormValues> = (vals, e) => {
		e?.preventDefault();
		aggregateAttr({
			attributeId: _id,
			body: aggregateAttrFormToPayload(vals),
		});
	};

	if (queryRes.isUninitialized) {
		return (
			<FlexContainer justifyContent="center">
				<Typography color="warn" paragraph>
					Waiting to receive target entity Id...
				</Typography>
				<Spinner />
			</FlexContainer>
		);
	}

	if (queryRes.isLoading) {
		return (
			<FlexContainer justifyContent="center">
				<Typography paragraph>Loading attributes...</Typography>
				<Spinner />
			</FlexContainer>
		);
	}

	if (queryRes.isError) {
		return (
			<FlexContainer justifyContent="center">
				<Typography color="error" paragraph>
					{extractQueryErrMessage(queryRes.error)}
				</Typography>
			</FlexContainer>
		);
	}

	return (
		<StyledPaper>
			<form onSubmit={handleSubmit(onSubmit)}>
				<StyledFlexContainer flexDirection="column" alignItems="center">
					{renderDerivationSubfields(true, register, formState)}

					{renderOtherIdSelect(
						'aggregatingId',
						register,
						formState,
						//   Ok to cast--if it's not loading and not errored, files is present
						queryRes.data as GetEntityAttrsResponse
					)}

					<label htmlFor="aggregationType">
						<Typography>
							Select an aggregation to perform:
						</Typography>
					</label>
					<select
						{...aggTypeRegisterProps}
						ref={(r) => {
							setAggTypeRef(r);
							registerRef(r);
						}}
						id="aggregationType"
						aria-errormessage={genErrorIdFromLabel(
							'aggregationType'
						)}
						aria-invalid={formFieldHasErrors(
							'aggregationType',
							formState
						)}
					>
						<option value="none">
							{availableAggregations.length > 0
								? 'No aggregation selected'
								: 'No aggregations are available'}
						</option>
						{availableAggregations.map((agg) => (
							<option value={agg} key={agg}>
								{agg}
							</option>
						))}
					</select>

					<Button type="submit">Submit</Button>

					<FormResults
						isError={!!mergedErrs}
						error={mergedErrs}
						validationErrors={formState.errors}
					/>
				</StyledFlexContainer>
			</form>
		</StyledPaper>
	);
};

export default AggregateAttributeForm;
