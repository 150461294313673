import Typography from '../Typography';
import { forwardRef, CSSProperties, InputHTMLAttributes } from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import styled from 'styled-components';

const genShadow = (color: string) => `-1px -1px 3px 0px ${color}90`;

 // 1px solid

const StyledTextbox = styled.input<{ $error?: boolean }>`
	border: 2px solid ${(p) =>
			p.$error ? p.theme.palette.error.main : p.theme.palette.darkBaby};
	color: ${(p) => p.theme.palette.darkerBaby};
	border-radius: ${(p) => p.theme.spacing(1)};
	font-size: ${(p) => p.theme.typography.h6.fontSize};
	line-height: ${(p) => p.theme.typography.h6.lineHeight};
	font-weight: ${(p) => p.theme.typography.h6.fontWeight};
	letter-spacing: 0;
	font-family: ${(p) => p.theme.typography.body1.fontFamily};
	padding: 0 ${(p) => p.theme.spacing(1)};
	box-shadow: ${(p) =>
		p.$error ? genShadow(p.theme.palette.error.main) : 'none'};
	width: 100%;
	&:focus {
		box-shadow: ${(p) =>
			genShadow(
				p.$error
					? p.theme.palette.error.main
					: p.theme.palette.primary.main
			)};
		outline: none;
	}
`;

// const StyledLabel = styled.label<{ $error?: boolean; $inputHeight: number }>`
// 	background: transparent;
// 	transition: all 0.2s ease;
// 	transform: ${(p) => `translateY(${p.$inputHeight / 1.25}px)`};
// 	padding-left: ${(p) => p.theme.spacing(1)};
// 	text-transform: capitalize;
// 	color: ${(p) =>
// 		p.$error ? p.theme.palette.error.main : p.theme.palette.darkBaby};
// 	cursor: text;

// 	&.textbox-has-entries,
// 	&.textbox-has-focus {
// 		cursor: default;
// 		transform: ${(p) => `translateX(-${p.$inputHeight}px)`};
// 		color: ${(p) =>
// 			p.$error
// 				? p.theme.palette.error.main
// 				: p.theme.palette.primary.main};
// 	}
// `;

interface Overrides {
	root?: CSSProperties;
	label?: CSSProperties;
}

// Prevent any conflicts between attributes passed in from {...register}
// and 'normal' attributes expected  by <input/>
type WithoutRegisterProps<T> = Omit<T, keyof UseFormRegisterReturn>;

// Omit 'ref' from props component expects to receive via {...register},
// since 'ref' will be stripped out by React.forwardRef
interface TextboxProps
	extends Omit<UseFormRegisterReturn, 'ref'>,
		WithoutRegisterProps<InputHTMLAttributes<HTMLInputElement>> {
	dirty?: boolean;
	labelText?: string;
	error?: FieldError;
	overrides?: Overrides;
	type?: 'password' | 'email' | 'text';

	// This is needed to keep error message IDs unique in case form is dynamically-generated
	// by react-hook-form useFieldArray()
	fieldGroupIndex?: number;

	//  capture will never be used on this component since it only applies to file inputs,
	// but being explicit here defeats type-widening to string, which causes an error
	// when passing all excess props to the HTML input.
	capture?: boolean | 'user' | 'environment' | undefined;
	Control?: JSX.Element;
}

const Textbox = forwardRef<HTMLInputElement, TextboxProps>(
	(
		{
			labelText,
			error,
			overrides,
			type = 'text',
			name,
			fieldGroupIndex = 0,
			...rest
		},
		ref
	) => {
		// const [dims, setDims] = useClientRect();

		// const { height } = dims;

		// const inputRef = useRef<HTMLInputElement>();

		// const combinedRef = useForkRef(setDims, ref, inputRef);

		// const [hasFocus, setHasFocus] = useState(false);

		// const [hasValues, setHasValues] = useState(false);

		// const onFocus = () => setHasFocus(true);

		// const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		// 	if (hasValues && e.target.value.length === 0) {
		// 		setHasValues(false);
		// 	}

		// 	if (!hasValues && e.target.value.length !== 0) {
		// 		setHasValues(true);
		// 	}
		// };
		// const handleBlur = () => setHasFocus(false);

		// const handleLabelClick: MouseEventHandler = () => {
		// 	if (inputRef.current && hasFocus === false) {
		// 		setHasFocus(true);
		// 		inputRef.current.focus();
		// 	}
		// };

		const errMessageId = `${name}-${fieldGroupIndex}-error-label`;
		const ariaLabelId = `${name}-${fieldGroupIndex}-label-label`;

		return (
			<div style={{ width: '100%' }}>
				<StyledTextbox
					// onBlur={(e: FocusEvent<HTMLInputElement>) => {
					// 	handleBlur();
					// 	onBlur(e);
					// }}
					id={ariaLabelId}
					name={name}
					$error={!!error}
					style={{ ...overrides?.root }}
					// onFocus={onFocus}
					ref={ref}
					{...rest}
					type={type}
					aria-invalid={!!error}
					aria-errormessage={errMessageId}
					placeholder={labelText}
					aria-label={labelText}
				/>

				{error && (
					<>
						<Typography color="error" paragraph id={errMessageId}>
							{error.message ?? `Problem submitting ${labelText}`}
						</Typography>
					</>
				)}
			</div>
		);
	}
);

export default Textbox;
