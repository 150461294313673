import styled from 'styled-components';

export const StyledInput = styled.textarea`
	position: relative;
	background-color: transparent;
	font-family: ${(p) => p.theme.typography.body1.fontFamily};
	font-size: ${(p) => p.theme.typography.body1.fontSize};
	font-weight: ${(p) => p.theme.typography.fontWeightRegular};
	color: inherit;
	outline: none;
	border: 1px solid transparent;
	padding: ${(p) => p.theme.spacing(0.5)};
	width: 100%;
	height: 150px;
	overflow: hidden;

	&:hover {
		border: 1px solid ${(p) => p.theme.palette.divider};
		cursor: pointer;
	}

	&:focus {
		border: 1px solid ${(p) => p.theme.palette.divider};
		cursor: pointer;
		overflow: auto;
	}

	&:focus,
	&.is-dirty {
		border: 1px solid ${(p) => p.theme.palette.divider};
		cursor: text;
		/* background-color: ${(p) => p.theme.palette.background.paper};
		color: ${(p) => p.theme.palette.common.white}; */
	}
`;
