import { isAppError, isNonEmptyString } from 'common/utils/typeGuards';
import {
	SCATTERPLOT_Y,
	ATTRIBUTE,
	QTY_RANGE_FILTER_TYPE,
	CAT_RANGE_FILTER_TYPE,
	EVENT_LINE_Y,
} from 'features/compositeViews/EntityViews/CONSTANTS';
import {
	catRangeKey,
	createCatRangeFilter,
	createQtyRangeFilter,
	parseCatRangeFilter,
	parseQtyRangeFilter,
	qtyRangeKey,
} from 'features/compositeViews/EntityViews/helpers';
import {
	CatRangeFilter,
	QtyRangeFilter,
} from 'features/compositeViews/EntityViews/types';
import useDispatchableErr from 'features/errorHandling/hooks/useDispatchableErr';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

// NB: identity of these helper functions can trigger expensive calculations--important to memoize all of them
const useEntitySearchParams = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const dispatchErr = useDispatchableErr();

	return useMemo(() => {
		// Scatterplot
		const appendScatterplotY = (attrName: string) => {
			searchParams.append(SCATTERPLOT_Y, attrName);
			setSearchParams(searchParams);
		};

		const removeScatterplotY = (toRemove: string) => {
			const remainingVals = searchParams
				.getAll(SCATTERPLOT_Y)
				.filter((v) => v !== toRemove);

			searchParams.delete(SCATTERPLOT_Y);

			remainingVals.forEach((v) => searchParams.append(SCATTERPLOT_Y, v));

			setSearchParams(searchParams);
		};

		const getAllScatterplotY = () => searchParams.getAll(SCATTERPLOT_Y);

		// Event line chart
		const appendLineChartY = (attrName: string) => {
			searchParams.append(EVENT_LINE_Y, attrName);
			setSearchParams(searchParams);
		};

		const removeLineChartY = (toRemove: string) => {
			const remainingVals = searchParams
				.getAll(EVENT_LINE_Y)
				.filter((v) => v !== toRemove);

			searchParams.delete(EVENT_LINE_Y);

			remainingVals.forEach((v) => searchParams.append(EVENT_LINE_Y, v));

			setSearchParams(searchParams);
		};

		const getAllLineChartY = () => searchParams.getAll(EVENT_LINE_Y);

		// general
		const setActiveAttribute = (
			attrName: string,
			clearChartParams: boolean = false
		) => {
			searchParams.set(ATTRIBUTE, attrName);

			if (clearChartParams) {
				searchParams.delete(SCATTERPLOT_Y);
			}

			setSearchParams(searchParams);
		};

		const getActiveAttributeName = () => searchParams.get(ATTRIBUTE);

		// filters
		const setQtyRangeFilter = (
			attributeName: string,
			minMax: [number, number]
		) => {
			const filter = createQtyRangeFilter(attributeName)(minMax);

			//   if creation fails for some reason, dispatch to central err handler and return.
			if (isAppError(filter)) {
				dispatchErr(filter);
				return;
			}

			searchParams.set(filter.searchParamKey, filter.searchParamValue);

			setSearchParams(searchParams);
		};

		const setCatRangeFilter = (
			attributeName: string,
			includedValues: string[]
		) => {
			const filter = createCatRangeFilter(attributeName)(includedValues);

			//   if creation fails for some reason, dispatch to central err handler and return.
			if (isAppError(filter)) {
				dispatchErr(filter);
				return;
			}

			searchParams.set(filter.searchParamKey, filter.searchParamValue);

			setSearchParams(searchParams);
		};

		const getCatRangeFilter = (attributeName: string) => {
			const searchParamKey = catRangeKey(attributeName);

			const filterValue = searchParams.get(searchParamKey);

			if (!filterValue) {
				return null;
			}

			const filter = parseCatRangeFilter([searchParamKey, filterValue]);

			if (isAppError(filter)) {
				dispatchErr(filter);
				return;
			}

			return filter;
		};

		const getQtyRangeFilter = (attributeName: string) => {
			const searchParamKey = qtyRangeKey(attributeName);

			const filterValue = searchParams.get(searchParamKey);

			if (!filterValue) {
				return null;
			}

			const filter = parseQtyRangeFilter([searchParamKey, filterValue]);

			if (isAppError(filter)) {
				dispatchErr(filter);
				return;
			}

			return filter;
		};

		const clearAttributeFilters = (
			attributeName: string | null | undefined
		) => {
			if (attributeName === null || attributeName === undefined) {
				return;
			}

			const catFilterName = catRangeKey(attributeName);
			const qtyFilterName = qtyRangeKey(attributeName);
			searchParams.delete(catFilterName);
			searchParams.delete(qtyFilterName);
			setSearchParams(searchParams);
		};

		const clearAllAttributeFilters = () => {
			const filtered = Array.from(searchParams.entries()).filter((kv) => {
				const [key] = kv;

				const maybeFilterType = key.split(':')[1];

				if (isNonEmptyString(maybeFilterType)) {
					return false;
				}

				return true;
			});

			setSearchParams(filtered);
		};

		const attributeHasFilters = (
			attributeName: string | null | undefined
		) => {
			if (attributeName === null || attributeName === undefined) {
				return false;
			}

			const catFilterName = catRangeKey(attributeName);
			const qtyFilterName = qtyRangeKey(attributeName);
			return (
				!!searchParams.get(catFilterName) ||
				!!searchParams.get(qtyFilterName)
			);
		};

		//  TODO: need a better way to add filter types to this logic
		// TODO: it may be worth memoizing this separately based on the actual filter values,
		// since when it changes it kicks off iteration over entire 'individuals' dataset.
		const getAllFilters = () =>
			Array.from(searchParams.entries())
				.map(([k, v]) => {
					const qtyMatch = new RegExp(
						`:${QTY_RANGE_FILTER_TYPE}`
					).test(k);

					if (qtyMatch) {
						return parseQtyRangeFilter([k, v]);
					}

					const catMatch = new RegExp(
						`:${CAT_RANGE_FILTER_TYPE}`
					).test(k);

					if (catMatch) {
						return parseCatRangeFilter([k, v]);
					}

					return null;
				})
				.filter(
					(maybeFilter) => !!maybeFilter && !isAppError(maybeFilter)
				) as Array<CatRangeFilter | QtyRangeFilter>;

		return {
			clearAllAttributeFilters,
			appendLineChartY,
			removeLineChartY,
			attributeHasFilters,
			clearAttributeFilters,
			getAllFilters,
			getQtyRangeFilter,
			getCatRangeFilter,
			setCatRangeFilter,
			setQtyRangeFilter,
			getAllScatterplotY,
			getActiveAttributeName,
			appendScatterplotY,
			removeScatterplotY,
			setActiveAttribute,
			getAllLineChartY,
		};
	}, [searchParams, setSearchParams, dispatchErr]);
};

export default useEntitySearchParams;
