import Button from '../../../../common/Button';
import { List, ListItem, ListItemContent } from '../../../../common/List';
import Popover from '../../../../common/Popover/PopoverBase';
import ReactRouterLink from '../../../../common/RouterLink';
import { getPluralKind } from '../../helpers';
import { ContextLevels } from '../../types/navigationContextTypes';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from 'common/Spinner';
import Typography from 'common/Typography';
import theme from 'common/theme/theme';
import { sortByString } from 'common/utils/functionUtils';
import { FunctionComponent, useMemo, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import styled from 'styled-components';

const StyledIconContainer = styled.div`
	width: ${(p) => p.theme.spacing(1)};
	display: grid;
	align-content: center;
	margin: 0 ${(p) => p.theme.spacing(0.5)};
`;

const BreadcrumbButton = styled(Button)`
	color: ${(p) => p.theme.palette.darkBaby};
	font-size: 14px;
	border: none;
	background: transparent;
	border: 1px solid transparent;
	padding: ${(p) => p.theme.spacing(0.75, 0.75)};
	font-weight: ${(p) => p.theme.typography.fontWeightBold};
	text-transform: capitalize;
	&:hover {
		border: 1px solid ${(p) => p.theme.palette.darkBaby};
		color: ${(p) => p.theme.palette.darkBaby};
	}
`;

export interface BreadcrumbLink {
	url: string;
	label: string;
	name: string;
}

interface BaseBreadcrumbProps {
	kind: ContextLevels;
	links: BreadcrumbLink[];
	status: 'loading' | 'success' | 'error';
	errorMessage?: string | null;
	isTip: boolean;
	activeName: string | null;
}

const Breadcrumb: FunctionComponent<BaseBreadcrumbProps> = ({
	kind,
	links,
	isTip,
	status,
	activeName,
	errorMessage,
}) => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	const [open, setOpen] = useState(false);

	const sortedLinks = useMemo(
		() => sortByString(links, (l) => l.label),
		[links]
	);

	const handleClick = () => {
		return setOpen((p) => !p);
	};

	const onClickAway = () => {
		if (open) {
			return setOpen(false);
		}

		return null;
	};

	const activeLink = links.find((l) => l.name === activeName);

	const getTipBtnText = () => {
		switch (status) {
			case 'loading':
				return 'Loading...';

			case 'error':
				return 'Error';

			case 'success':
				return kind === 'entity' && typeof activeName === 'string'
					? activeLink?.label
					: getPluralKind(kind);
		}
	};

	const renderButton = () => {
		if (isTip) {
			return (
				<ClickAwayListener onClickAway={onClickAway}>
					<BreadcrumbButton
						onClick={handleClick}
						ref={setAnchorEl as any}
					>
						{getTipBtnText()}
					</BreadcrumbButton>
				</ClickAwayListener>
			);
		}

		return (
			<>
				<ReactRouterLink
					to={activeLink?.url ?? '/'}
					preserveSearchParams={false}
				>
					<BreadcrumbButton>
						{activeLink?.label ?? getPluralKind(kind)}
					</BreadcrumbButton>
				</ReactRouterLink>
				<StyledIconContainer data-testid="breadcrumb-chevron">
					<FontAwesomeIcon
						size="sm"
						icon={faChevronRight}
						color={theme.palette.darkBaby}
					/>
				</StyledIconContainer>
			</>
		);
	};

	const renderDropdown = () => {
		switch (status) {
			case 'loading':
				return <Spinner data-testid="breadcrumb-dropdown-loading" />;
			case 'error':
				return (
					<Typography paragraph color="error">
						{errorMessage || 'Something went wrong.'}
					</Typography>
				);
			case 'success':
				return (
					<List style={{ maxHeight: '50vh', overflowY: 'auto' }}>
						{sortedLinks.map(({ url, label }) => (
							<ReactRouterLink
								to={url}
								key={url}
								preserveSearchParams={false}
							>
								<ListItem
									onClick={() => setAnchorEl(null)}
									style={{ cursor: 'pointer' }}
								>
									<ListItemContent>{label}</ListItemContent>
								</ListItem>
							</ReactRouterLink>
						))}
					</List>
				);
		}
	};

	return (
		<>
			{renderButton()}
			<Popover anchorEl={anchorEl} open={open} placement="bottom">
				{renderDropdown()}
			</Popover>
		</>
	);
};

export default Breadcrumb;
