import Button from '../../../common/Button';
import { FormResults } from '../../../common/Form';
import Typography from '../../../common/Typography';
import { useCreateQuestionMutation } from '../../api';
import { CreateQuestionFormValues } from '../../questions/types/questionTypes';
import { EntityActionFormProps } from '../common/commonTypes';
import { StyledFlexContainer, StyledPaper } from '../common/styledComponents';
import { StyledTextArea } from '../common/styledComponents';
import { FunctionComponent } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

const CreateQuestionForm: FunctionComponent<EntityActionFormProps> = (
	props
) => {
	const { _id, setOpenAction } = props;

	const { handleSubmit, register, formState } =
		useForm<CreateQuestionFormValues>({
			defaultValues: {
				question: '',
			},
		});

	const [ask, askResult] = useCreateQuestionMutation();

	const onSubmit: SubmitHandler<CreateQuestionFormValues> = (vals) => {
		// ask({ entityId: _id, body: createQuestionFormToPayload(vals) });
	};

	const onAskSuccess = () => setOpenAction(null);

	return (
		<StyledPaper>
			<form onSubmit={handleSubmit(onSubmit)}>
				<StyledFlexContainer flexDirection="column" alignItems="center">
					<label htmlFor="question">
						<Typography>What is your question?</Typography>
					</label>
					<StyledTextArea
						{...register('question', {
							required: 'A question is required for submission',
						})}
						id="question"
					/>

					<Button>Submit</Button>

					<FormResults
						{...askResult}
						validationErrors={formState.errors}
						onSuccess={onAskSuccess}
						onSuccessDelay={1500}
					/>
				</StyledFlexContainer>
			</form>
		</StyledPaper>
	);
};

export default CreateQuestionForm;
