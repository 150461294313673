import { useAppDispatch } from '../../../../app/hooks';
import Button from '../../../../common/Button';
import {
	FormHeading,
	ModalFormBody,
	FormResults,
	formFieldHasErrors,
	genErrorIdFromLabel,
} from '../../../../common/Form';
import Textbox from '../../../../common/Textbox';
import useModalType from '../../../HUD/hooks/useModalType';
import { setModalType } from '../../../HUD/state/HUDSlice';
import { useCreateDomainMutation } from '../../../api';
import useActiveAccountData from '../../../ontology/hooks/useActiveAccountData';
import { FunctionComponent, useEffect, useRef } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

interface DomainCreationFormProps {
	accountId: number | null;
}

// Accept accountId as a prop to avoid needing complicated route matching in tests for this form
export const DomainCreationFormBase: FunctionComponent<
	DomainCreationFormProps
> = ({ accountId }) => {
	const {
		register,
		handleSubmit,
		formState,
		reset: resetForm,
	} = useForm({
		defaultValues: {
			label: '',
		},
	});

	const appDispatch = useAppDispatch();

	const [createDomain, mutationResult] = useCreateDomainMutation({
		selectFromResult: (res) => ({
			isLoading: res.isLoading,
			isSuccess: res.isSuccess,
			error: res.error,
			isError: res.isError,
		}),
	});

	const syncMutationResult = useRef<any>(null);

	const modalType = useModalType();

	const creationFormOpen = modalType === 'creationForm';

	useEffect(() => {
		if (formState.isDirty && !creationFormOpen) {
			resetForm();
		}
	}, [formState, creationFormOpen, resetForm]);

	const handleClose = () => {
		if (syncMutationResult.current) {
			syncMutationResult.current.unsubscribe();
			syncMutationResult.current = null;
		}
		appDispatch(setModalType(null));
	};

	const onSubmit: SubmitHandler<{ label: string }> = ({ label }, e) => {
		e?.preventDefault();

		if (accountId === null) {
			console.error(
				'Attempted to submit domain creation form when no active user was matched in URL!'
			);
			return;
		}

		// cache this return value to reset the mutation result every time form is opened or closed.
		syncMutationResult.current = createDomain({ label, accountId });
	};

	return (
		<ModalFormBody
			aria-labelledby="create-domain-form"
			onSubmit={(e) => {
				e.preventDefault();
			}}
		>
			<FormHeading
				id="create-domain-form"
				backgroundVariant="paper"
				component="h1"
			>
				Create new domain
			</FormHeading>
			<Textbox
				{...register('label', {
					required: {
						value: true,
						message: 'Label is required',
					},
				})}
				// TODO: formFieldHasErrors called twice for same check
				error={formState.errors.label}
				labelText="Domain label"
				aria-errormessage={genErrorIdFromLabel('label')}
				aria-invalid={formFieldHasErrors('label', formState)}
			/>
			<Button
				onClick={handleSubmit(onSubmit)}
				disabled={accountId === null ? true : false}
			>
				Submit
			</Button>
			<FormResults
				{...mutationResult}
				validationErrors={formState.errors}
				onSuccess={handleClose}
				onSuccessDelay={1500}
			></FormResults>
		</ModalFormBody>
	);
};

const DomainCreationForm: FunctionComponent = () => {
	const { activeItem } = useActiveAccountData();

	const accountId = activeItem ? activeItem._id : null;

	return <DomainCreationFormBase accountId={accountId} />;
};

export default DomainCreationForm;
