import Button from '../../../common/Button';
import FlexContainer from '../../../common/FlexContainer';
import { FormResults } from '../../../common/Form';
import Heading from '../../../common/Heading';
import Typography from '../../../common/Typography';
import { usePersistEntityMutation } from '../../api';
import { EntityActionFormProps } from '../common/commonTypes';
import { StyledFlexContainer, StyledPaper } from '../common/styledComponents';
import { FunctionComponent, MouseEventHandler } from 'react';

const PersistAttributeForm: FunctionComponent<EntityActionFormProps> = (
	props
) => {
	const { _id, plural: label, setOpenAction } = props;

	const [persist, persistResult] = usePersistEntityMutation();

	const onClick: MouseEventHandler = () => {
		persist({ entityId: _id });
	};

	const onSuccessfulPersist = () => setOpenAction(null);

	return (
		<StyledPaper>
			<StyledFlexContainer flexDirection="column" alignItems="center">
				<Heading component="h2">
					<Typography>
						Permanently save {label} to FutureModel database?
					</Typography>
				</Heading>
				<FlexContainer
					style={{ width: '50%' }}
					justifyContent="space-between"
				>
					<Button
						variant="outline"
						onClick={() => setOpenAction(null)}
					>
						Go Back
					</Button>
					<Button onClick={onClick}>Persist</Button>
				</FlexContainer>
				<FormResults
					{...persistResult}
					validationErrors={{}}
					onSuccess={onSuccessfulPersist}
					onSuccessDelay={1500}
				/>
			</StyledFlexContainer>
		</StyledPaper>
	);
};

export default PersistAttributeForm;
