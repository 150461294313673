import { RequireAtLeastOne } from '../utils/typeUtils';
import { FunctionComponent, HTMLAttributes } from 'react';
import styled from 'styled-components';

	// width: 20%;
const StyledForm = styled.form`
	min-width: 300px;
	max-width: 600px;
	border-radius: ${(p) => p.theme.spacing(1)};
	padding: ${(p) => p.theme.spacing(2)};
	color: ${(p) => p.theme.palette.text.paper};
	margin: auto;
	display: flex;
	flex-direction: column;
	gap: ${(p) => p.theme.spacing(0.5)};
	align-items: start;
	border: 1px solid ${(p) => p.theme.palette.divider};
	background: ${(p) => p.theme.palette.darkerBaby};
	position: relative;
`;

type FormBodyProps = RequireAtLeastOne<
	HTMLAttributes<HTMLFormElement>,
	'aria-labelledby' | 'aria-label'
>;

const FormBody: FunctionComponent<FormBodyProps> = ({ children, ...props }) => {
	return <StyledForm {...props}>{children}</StyledForm>;
};

export default FormBody;
