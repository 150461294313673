import { filter, flow } from 'common/utils/functionUtils';
import {
	sortGroupedAttrs,
	groupAttrs,
} from 'features/ontology/helpers/attributeHelpers';
import { BaseAttribute } from 'features/ontology/types/attributeTypes';

export const groupAttrsForIndividualCard = flow(
	filter<BaseAttribute>((a) => a.type !== 'identity'),
	groupAttrs('base'),
	sortGroupedAttrs
);
