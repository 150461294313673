import QuestionContext from '../state/QuestionContext';
import { QuestionableObject } from '../types/questionTypes';
import AnswerList from './AnswerList';
import CreateQuestionForm from './CreateQuestionForm';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, ListItem, ListItemContent, ListItemAvatar } from 'common/List';
import Typography from 'common/Typography';
import { useGetQuestionsQuery } from 'features/api';
import { FunctionComponent, useState } from 'react';
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from 'react-accessible-accordion';
import { ID } from 'react-accessible-accordion/dist/types/components/ItemContext';

interface QuestionListProps {
	objectType: QuestionableObject;
	objectId: number;
	canEdit: boolean;
}

const QuestionList: FunctionComponent<QuestionListProps> = ({
	canEdit,
	objectId,
	objectType,
}) => {
	const [expanded, setExpanded] = useState<ID[]>([]);

	const { data } = useGetQuestionsQuery({
		objectType,
		objectId,
	});

	if (!data) {
		return (
			<div>
				<Typography>Loading...</Typography>
			</div>
		);
	}

	return (
		<QuestionContext.Provider
			value={{
				parentObjectId: objectId,
				parentObjectType: objectType,
				canEdit,
			}}
		>
			<Accordion
				allowZeroExpanded={true}
				onChange={(ids) => setExpanded(ids)}
			>
				<List
					style={{
						maxHeight: '40vh',
						overflowY: 'auto',
						maxWidth: '400px',
					}}
				>
					<CreateQuestionForm />
					{data.map((q) => (
						<AccordionItem key={q._id} uuid={q._id}>
							<AccordionItemHeading>
								<AccordionItemButton>
									<ListItem button>
										<ListItemAvatar>
											<FontAwesomeIcon
												icon={faQuestion}
											></FontAwesomeIcon>
										</ListItemAvatar>
										<ListItemContent
											truncate={expanded.every(
												(v) => v !== q._id
											)}
										>
											<Typography>{q.content}</Typography>
										</ListItemContent>
									</ListItem>
								</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<AnswerList
									answers={q.answers.items}
									questionId={q._id}
								/>
							</AccordionItemPanel>
						</AccordionItem>
					))}
				</List>
			</Accordion>
		</QuestionContext.Provider>
	);
};

export default QuestionList;
