import { ListItemContent, ListItemControls } from '../../../common/List';
import Typography from '../../../common/Typography';
import useSourceListItem from '../hooks/useSourceListItem';
import { LiveDataSet } from '../types/dataTypes';
import { SelectedSourceData, Setter } from '../types/uiTypes';
import ImportButton from './ImportButton';
import { SourceListItem } from './styledComponents';
import clsx from 'clsx';
import { FunctionComponent } from 'react';

interface DataSetListItemProps extends LiveDataSet {
	onDisplay: LiveDataSet;
	setter: Setter;
	currentlySelected: SelectedSourceData;
}

const DataSetListItem: FunctionComponent<DataSetListItemProps> = ({
	onDisplay,
	setter,
	currentlySelected,
	...datasetProps
}) => {
	const { active, onClick, itemName } = useSourceListItem({
		onDisplay,
		currentlySelected,
		setter,
	});

	return (
		<SourceListItem
			button
			className={clsx(active && 'browser-item-is-open')}
			onClick={onClick}
		>
			<ListItemContent>
				<Typography>{itemName}</Typography>
			</ListItemContent>
			<ListItemControls>
				<ImportButton importable={datasetProps} />
			</ListItemControls>
		</SourceListItem>
	);
};

export default DataSetListItem;
