import { StyledPropsSelector } from './utils/typeUtils';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Placement } from '@popperjs/core';
import clsx from 'clsx';
import TooltipBody from 'common/Tooltip';
import Typography from 'common/Typography';
import { ColorProp } from 'common/Typography';
import { forkRef } from 'common/utils/reactUtils';
import { ButtonHTMLAttributes, CSSProperties, forwardRef } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';
import styled from 'styled-components';

type SizeProp =
	| 'xs'
	| 'lg'
	| 'sm'
	| '1x'
	| '2x'
	| '3x'
	| '4x'
	| '5x'
	| '6x'
	| '7x'
	| '8x'
	| '9x'
	| '10x';

const mapSizePropToSpacing = (size: SizeProp) => {
	switch (size) {
		case 'xs':
			return 2.75;

		case 'sm':
			return 4;

		case 'lg':
			return 5;

		default:
			return 7;
	}
};

type IconButtonBaseProps = Omit<IconButtonProps, 'icon' | 'overrides'>;

const buttonBackground: StyledPropsSelector<IconButtonBaseProps> =
	(state?) => (props) => {
		const {
			variant = 'base',
			theme: { palette },
		} = props;

		const baseColor = palette.background.paper;

		// May want some other color to represent transparency at
		// some point.
		const transparentColor = 'transparent';

		// On hover, add an alpha value to background to give it
		// some transparency.
		const colorOnHover = baseColor + '95';

		return state === 'hover'
			? colorOnHover
			: variant === 'base'
			? palette.background.paper
			: transparentColor;
	};

const buttonShape: StyledPropsSelector<IconButtonBaseProps> = () => (props) => {
	const { shape = 'base', theme } = props;

	return shape === 'base' ? `${theme.spacing(0.5)}` : '50%';
};

const buttonBorder: StyledPropsSelector<IconButtonBaseProps> =
	() => (props) => {
		const { variant = 'base', shape = 'base' } = props;

		return variant === 'transparent' || shape === 'round' ? 'none' : 'none';
	};

// box-shadow: ${(p) => p.theme.shadows[1]};
export const IconButtonBase = styled.button<
	IconButtonBaseProps & {
		size: SizeProp;
		fillColor: ColorProp;
		baseOpacity: string;
	}
>`
	border-radius: ${buttonShape()};
	background: ${buttonBackground()};
	border: ${buttonBorder()};
	width: ${(p) => p.theme.spacing(mapSizePropToSpacing(p.size))};
	height: ${(p) => p.theme.spacing(mapSizePropToSpacing(p.size))};
	cursor: pointer;
	box-shadow: none;
	opacity: ${(p) => p.baseOpacity};
	color: ${(p) => {
		switch (p.fillColor) {
			case 'default':
				return p.theme.palette.oldManGray;

			case 'error':
				return p.theme.palette.error.main;

			case 'warn':
				return p.theme.palette.warning.main;

			case 'primary':
				return p.theme.palette.primary.main;
		}
	}};

	transition: color ${(p) => p.theme.transitions.duration.shorter}ms linear;
	transition: opacity ${(p) => p.theme.transitions.duration.short}ms linear;
	transition: background ${(p) => p.theme.transitions.duration.short}ms linear;

	&:hover {
		opacity: 100%;
	}

	&.disabled {
		color: ${(p) => p.theme.palette.common.white};
		background-color: ${(p) => p.theme.palette.divider};
		box-shadow: none;
	}

	&:hover.disabled {
		opacity: 80%;
		background-color: ${(p) => p.theme.palette.divider};
		cursor: default;
	}
`;

interface Overrides {
	root?: CSSProperties;
	icon?: CSSProperties;
}

export interface IconButtonProps
	extends ButtonHTMLAttributes<HTMLButtonElement> {
	tooltip?: string;
	tooltipPlacement?: Placement;
	icon?: typeof faChevronRight;
	fillColor?: ColorProp;
	baseOpacity?: string;
	variant?: 'base' | 'transparent';
	shape?: 'base' | 'round';
	overrides?: Overrides;
	size?: SizeProp;
	showTooltip?: boolean;
}

/**
 * Forwards any ref to button element.
 */
const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
	(props, ref) => {
		const {
			icon,
			fillColor = 'default',
			baseOpacity = '100%',
			overrides,
			size = 'sm',
			tooltip,
			tooltipPlacement,
			children,
			disabled,
			...rest
		} = props;

		const showTooltip = props.showTooltip ?? tooltip ? true : false;

		const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
			usePopperTooltip({ placement: tooltipPlacement ?? 'auto' });

		const combinedRefs = forkRef(setTriggerRef, ref);

		const renderButtonContent = () =>
			icon ? <FontAwesomeIcon icon={icon} size={size} /> : children;

		return (
			<>
				<IconButtonBase
					className={clsx(disabled && 'disabled')}
					style={{ ...overrides?.root }}
					ref={combinedRefs as any}
					{...rest}
					size={size}
					fillColor={fillColor}
					baseOpacity={baseOpacity}
					disabled={!!disabled}
				>
					{renderButtonContent()}
				</IconButtonBase>
				{tooltip && visible && showTooltip && (
					<TooltipBody
						{...getTooltipProps()}
						ref={setTooltipRef as any}
					>
						<Typography
							style={{ whiteSpace: 'nowrap', fontSize: '.85rem' }}
						>
							{tooltip}
						</Typography>
					</TooltipBody>
				)}
			</>
		);
	}
);

export default IconButton;
