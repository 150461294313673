import Button from './Button';
import TooltipBody from './Tooltip';
import Typography from './Typography';
import { Placement } from '@popperjs/core';
import { ButtonHTMLAttributes, FunctionComponent } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import styled from 'styled-components';

export const StyledPaperButton = styled(Button)`
	width: 100%;
	border: none;
	background-color: ${(p) => p.theme.palette.darkBaby};
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	padding: ${(p) => p.theme.spacing(1)};
	&:hover {
		background-color: ${(p) => p.theme.palette.darkBaby};
	}
`;

interface PaperButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	tooltip?: string;
	showTooltip?: boolean;
	tooltipPlacement?: Placement;
}

const PaperButton: FunctionComponent<PaperButtonProps> = ({
	tooltip,
	showTooltip = true,
	children,
	tooltipPlacement,
	...restProps
}) => {
	const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
		usePopperTooltip({ placement: tooltipPlacement ?? 'auto' });

	const doShowTooltip = showTooltip ?? tooltip ? true : false;

	return (
		<>
			<StyledPaperButton {...restProps} ref={setTriggerRef as any}>
				{children}
			</StyledPaperButton>

			{tooltip && visible && doShowTooltip && (
				<TooltipBody {...getTooltipProps()} ref={setTooltipRef as any}>
					<Typography
						style={{ whiteSpace: 'nowrap', fontSize: '.85rem' }}
					>
						{tooltip}
					</Typography>
				</TooltipBody>
			)}
		</>
	);
};
export default PaperButton;
