import { IconButtonBase } from 'common/IconButton';
import ImageAvatar from 'common/ImageAvatar';
import Typography from 'common/Typography';
import { isEmpty } from 'common/utils/typeGuards';
import { Empty } from 'common/utils/typeUtils';
import { forwardRef } from 'react';

interface AvatarProps {
	onClick: () => void;
	initial: string;
	profileImageURL: Empty | string;
}

const ProfileAvatar = forwardRef<HTMLButtonElement, AvatarProps>(
	({ onClick, profileImageURL, initial }, ref) => {
		return isEmpty(profileImageURL) ? (
			<IconButtonBase
				size="sm"
				fillColor="default"
				baseOpacity="100%"
				ref={ref as any}
				onClick={onClick}
				aria-label="user profile menu"
			>
				<Typography
					style={{
						fontWeight: 'bold',
					}}
				>
					{initial}
				</Typography>
			</IconButtonBase>
		) : (
			<ImageAvatar
				as="button"
				aria-label="user profile menu"
				src={profileImageURL}
				ref={ref as any}
				onClick={onClick}
				data-testid="image"
			/>
		);
	}
);

export default ProfileAvatar;
