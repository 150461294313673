import { useGetDomainMembersQuery, useGetDomainStewardsQuery } from '../../api';
import { BaseUser } from '../../authentication/types/userTypes';
import { filterDomainMembers } from '../helpers';
import { DomainAuthorityLevel } from '../types/MemberManagementTypes';
import useCombinedAccountMembers from './useCombinedAccountMembers';
import { useMemo } from 'react';

const emptyMembers: BaseUser[] = [];
const emptyStewards: BaseUser[] = [];

const useFilteredDomainMembers = (
	domainId: number,
	accountId: number,
	authorityLevel: DomainAuthorityLevel
) => {
	const combinedAccountMembers = useCombinedAccountMembers(accountId);

	const { members } = useGetDomainMembersQuery(
		{ domainId },
		{ selectFromResult: ({ data }) => ({ members: data ?? emptyMembers }) }
	);

	const { stewards } = useGetDomainStewardsQuery(
		{ domainId },
		{
			selectFromResult: ({ data }) => ({
				stewards: data ?? emptyStewards,
			}),
		}
	);

	return useMemo(
		() =>
			filterDomainMembers(
				authorityLevel,
				{ members, stewards },
				combinedAccountMembers
			),
		[members, stewards, authorityLevel, combinedAccountMembers]
	);
};

export default useFilteredDomainMembers;
