import useModalType from '../../hooks/useModalType';
import { setModalType } from '../../state/HUDSlice';
import { ModalType } from '../../types/HUDTypes';
import HUDModalHeader from './HUDModalHeader';
import { HUDModalBody } from './styledComponents';
import { useAppDispatch } from 'app/hooks';
import Modal from 'common/Modal';
import SourceBrowser from 'features/SourceBrowser';
import AttributeLineageGraph from 'features/forceGraphs/components/AttributeLineageGraph';
import ImportEntityGraph from 'features/forceGraphs/components/ImportEntityGraph';
import CreationForm from 'features/forceGraphs/components/common/CreationForm';
import { FunctionComponent } from 'react';

const HUDModal: FunctionComponent = () => {
	const appDispatch = useAppDispatch();
	const modalType = useModalType();

	const closeModal = () => appDispatch(setModalType(null));

	const getModalContents = (type: ModalType) => {
		switch (type) {
			case null:
				return null;

			case 'sourceBrowser':
				return <SourceBrowser />;

			case 'creationForm':
				return <CreationForm />;

			case 'importEntity':
				return <ImportEntityGraph />;

			case 'attributeLineage':
				return <AttributeLineageGraph />;
		}
	};

	const getModalHeadingText = () => {
		switch (modalType) {
			case null:
				return '';
			case 'sourceBrowser':
				return 'Data Browser';

			case 'creationForm':
				return 'Create Object';

			case 'importEntity':
				return 'Import Entity';

			case 'attributeLineage':
				return 'Lineage';
		}
	};

	return (
		<Modal
			isOpen={!!modalType}
			shouldCloseOnOverlayClick={true}
			onRequestClose={closeModal}
			contentLabel="Source Browser"
		>
			<HUDModalBody>
				<HUDModalHeader
					handleClose={closeModal}
					headingText={getModalHeadingText()}
				/>
				{getModalContents(modalType)}
			</HUDModalBody>
		</Modal>
	);
};

export default HUDModal;
