import { skipToken } from '@reduxjs/toolkit/dist/query';
import theme from 'common/theme/theme';
import { useGetDomainGraphQuery } from 'features/api';
import GraphPopover from 'features/forceGraphs/components/common/GraphPopover';
import {
	StyledGraphContainer,
	StyledHeading,
} from 'features/forceGraphs/components/common/styledComponents';
import { drawNode, drawLink } from 'features/forceGraphs/helpers';
import useGraphDataLoader from 'features/forceGraphs/hooks/useGraphDataLoader';
import useGraphHandlers from 'features/forceGraphs/hooks/useGraphHandlers';
import GraphContextProvider from 'features/forceGraphs/state/GraphContextProvider';
import useActiveDomainData from 'features/ontology/hooks/useActiveDomainData';
import useActiveEntityData from 'features/ontology/hooks/useActiveEntityData';
import { FunctionComponent, useMemo } from 'react';
import { default as ForceGraph2D, GraphData } from 'react-force-graph-2d';

const EntityGraphBody: FunctionComponent = () => {
	const { activeItem: activeEntity } = useActiveEntityData();
	const { activeItem: activeDomain } = useActiveDomainData();

	const queryRes = useGetDomainGraphQuery(
		activeDomain ? { domainId: activeDomain._id } : skipToken
	);

	useGraphDataLoader(queryRes);

	const {
		selectLink,
		selectNode,
		width,
		height,
		setFgm,
		graphState: { isLoadingData, graphData },
	} = useGraphHandlers();

	const neighbors: GraphData = useMemo(() => {
		if (activeEntity) {
			const entityId = activeEntity._id;
			const touchedNodes = new Set<number>();
			touchedNodes.add(entityId);
			const { links, nodes } = graphData;

			const neighborLinks = links.filter(({ source, target }) => {
				if (source === entityId || target === entityId) {
					//  Set prevents duplicates
					touchedNodes.add(source as number);
					touchedNodes.add(target as number);
					return true;
				}

				return false;
			});

			const neighborNodes = (nodes as { _id: number }[]).filter(
				({ _id }) => touchedNodes.has(_id)
			);

			return {
				links: neighborLinks,
				nodes: neighborNodes,
			};
		}

		return {
			links: [],
			nodes: [],
		};
	}, [activeEntity, graphData]);

	return (
		<StyledGraphContainer>
			<div>
				{isLoadingData && (
					<StyledHeading component="h2">
						Preparing your data...
					</StyledHeading>
				)}
				<ForceGraph2D
					ref={setFgm as any}
					backgroundColor={theme.palette.background.default}
					width={width}
					height={height}
					graphData={neighbors}
					onNodeClick={selectNode as any}
					linkCanvasObject={drawLink}
					onLinkClick={selectLink as any}
					nodeId="_id"
					nodeLabel={() => ''}
					linkCanvasObjectMode={() => 'replace'}
					nodeCanvasObject={drawNode()}
				/>
			</div>
			<GraphPopover />
		</StyledGraphContainer>
	);
};

const EntityGraph: FunctionComponent = () => {
	return (
		<GraphContextProvider>
			<EntityGraphBody />
		</GraphContextProvider>
	);
};

export default EntityGraph;
