import IconButton from '../../../../common/IconButton';
import PopoverBase from '../../../../common/Popover/PopoverBase';
import DeleteDomainForm from '../../../futuremodelActions/deleteDomain/DeleteDomainForm';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import {
	FunctionComponent,
	Dispatch,
	SetStateAction,
	MouseEventHandler,
	useState,
} from 'react';

interface DeleteDomainListItemProps {
	domainId: number;
	domainLabel: string;

	setOpenActionSubmenu: Dispatch<SetStateAction<string | null>>;
	openActionSubmenu: string | null;
}

const DeleteDomainButton: FunctionComponent<DeleteDomainListItemProps> = ({
	domainId,
	domainLabel,
	setOpenActionSubmenu,
	openActionSubmenu,
}) => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	const onItemClick: MouseEventHandler = (e) => {
		e.stopPropagation();
		setOpenActionSubmenu((p) => {
			if (p === 'delete') {
				return null;
			}

			return 'delete';
		});
	};

	const deleteFormOpen = openActionSubmenu === 'delete';

	return (
		<>
			<IconButton
				icon={faTrash}
				size="xs"
				fillColor="error"
				baseOpacity="80%"
				tooltip="Destroy domain"
				aria-label="destroy domain"
				onClick={onItemClick}
				ref={setAnchorEl as any}
				showTooltip={!deleteFormOpen}
				tooltipPlacement="top"
				type="button"
			/>

			<PopoverBase
				anchorEl={anchorEl}
				open={deleteFormOpen}
				offset={0}
				placement="right"
			>
				<DeleteDomainForm
					setOpenAction={setOpenActionSubmenu}
					domainId={domainId}
					domainLabel={domainLabel}
				/>
			</PopoverBase>
		</>
	);
};

export default DeleteDomainButton;
