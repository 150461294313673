import timezones from '../../../assets/tzs.json';
import Button from '../../../common/Button';
import FlexContainer from '../../../common/FlexContainer';
import {
	formFieldHasErrors,
	FormResults,
	genErrorIdFromLabel,
} from '../../../common/Form';
import Spinner from '../../../common/Spinner';
import Typography from '../../../common/Typography';
import { useCreateEventMutation, useGetAttributesQuery } from '../../api';
import { extractQueryErrMessage } from '../../api/helpers';
import { GetEntityAttrsResponse } from '../../ontology/types/attributeTypes';
import { EntityActionFormProps } from '../common/commonTypes';
import { renderDerivationSubfields } from '../common/jsxHelpers';
import { StyledFlexContainer, StyledPaper } from '../common/styledComponents';
import {
	createEventFormDefaults,
	createEventFormToPayload,
	validateTimeResolutionSubmission,
} from './createEventHelpers';
import { CreateEventFormValues, timeResolutions } from './createEventTypes';
import { FunctionComponent } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';

const StyledFieldContainer = styled(FlexContainer)`
	width: 100%;
	padding: 0 ${(p) => p.theme.spacing(1)};
`;
const CreateEventForm: FunctionComponent<EntityActionFormProps> = (props) => {
	const { _id } = props;

	const formDefaults = createEventFormDefaults();

	const { handleSubmit, register, formState, setError } =
		useForm<CreateEventFormValues>({
			defaultValues: formDefaults,
		});

	const [createEvent, createEventResult] = useCreateEventMutation();

	const onSubmit: SubmitHandler<CreateEventFormValues> = (vals) => {
		const canSubmit = validateTimeResolutionSubmission(setError, vals);

		if (canSubmit) {
			return createEvent({
				entityId: _id,
				body: createEventFormToPayload(vals),
			});
		}

		return;
	};

	const {
		isLoading,
		isError,
		error,
		data: attrData,
		isUninitialized,
	} = useGetAttributesQuery({ entityId: _id });

	if (isLoading || isUninitialized) {
		return (
			<FlexContainer justifyContent="center">
				<Typography paragraph>Loading attributes...</Typography>
				<Spinner />
			</FlexContainer>
		);
	}

	if (isError) {
		return (
			<FlexContainer justifyContent="center">
				<Typography color="error" paragraph>
					{extractQueryErrMessage(error)}
				</Typography>
			</FlexContainer>
		);
	}

	return (
		<StyledPaper>
			<form onSubmit={handleSubmit(onSubmit)}>
				<StyledFlexContainer flexDirection="column" alignItems="center">
					{renderDerivationSubfields(true, register, formState)}

					{timeResolutions.map((tr) => {
						return (
							<StyledFieldContainer
								justifyContent="space-between"
								key={tr}
							>
								<label htmlFor={tr}>
									<Typography
										style={{ textTransform: 'capitalize' }}
									>
										{tr.slice(0, -2)}:
									</Typography>
								</label>
								<div style={{ flex: '0 1 70%' }}>
									<select
										style={{ width: '100%' }}
										{...register(tr, {
											valueAsNumber: true,
										})}
										id={tr}
										aria-errormessage={genErrorIdFromLabel(
											tr
										)}
										aria-invalid={formFieldHasErrors(
											tr,
											formState
										)}
									>
										<option value={0} key={0}>
											None
										</option>
										{(
											attrData as GetEntityAttrsResponse
										).map((attr) => (
											<option
												value={attr._id}
												key={attr._id}
											>{`${attr.entity.plural}: ${attr.plural}`}</option>
										))}
									</select>
								</div>
							</StyledFieldContainer>
						);
					})}
					<StyledFieldContainer justifyContent="space-between">
						<label htmlFor="timezone">
							<Typography style={{ textTransform: 'capitalize' }}>
								timezone:
							</Typography>
						</label>
						<div style={{ flex: '0 1 70%' }}>
							<select
								style={{ width: '100%' }}
								{...register('timezone')}
								id="timezone"
								aria-errormessage={genErrorIdFromLabel(
									'timezone'
								)}
								aria-invalid={formFieldHasErrors(
									'timezone',
									formState
								)}
							>
								<option key="none" value="">
									None
								</option>
								{timezones.map((tz) => (
									<option key={tz} value={tz}>
										{tz}
									</option>
								))}
							</select>
						</div>
					</StyledFieldContainer>

					<Button type="submit">Submit</Button>
					<FormResults
						isError={createEventResult.isError}
						error={createEventResult.error}
						validationErrors={formState.errors}
					/>
				</StyledFlexContainer>
			</form>
		</StyledPaper>
	);
};

export default CreateEventForm;
