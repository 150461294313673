import CreationGroupButton from './CreationGroupButton';
import DeleteEntityButton from './DeleteEntityButton';
import EntityPopoverControls from './EntityPopoverControls';
import EntityPopoverDropdown from './EntityPopoverDropdown';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import FlexContainer from 'common/FlexContainer';
import IconButton from 'common/IconButton';
import {
	PopoverMainContent,
	PopoverHeader,
	PopoverSubhead,
	PopoverCardBody,
	PopoverBottomDrawer,
	PopoverHeading,
} from 'common/Popover';
import SubtleTextArea from 'common/SubtleTextArea';
import { SubtleTextbox } from 'common/SubtleTextbox';
import { warnInDev } from 'common/utils/reactUtils';
import { isNonEmptyString } from 'common/utils/typeGuards';
import { deriveEntityViews } from 'features/HUD/helpers';
import { useDescribeEntityMutation, useGetAttributesQuery } from 'features/api';
import { canEdit } from 'features/authentication/helpers';
import { nullifyDefinitionPlaceholder } from 'features/forceGraphs/helpers';
import { DEFINITION_PLACEHOLDER } from 'features/forceGraphs/helpers';
import { DescribeEntityFormValues } from 'features/futuremodelActions/describeEntity/describeEntityTypes';
import useActiveDomainData from 'features/ontology/hooks/useActiveDomainData';
import { DomainGraphNode } from 'features/ontology/types/domainTypes';
import CreateQuestionButton from 'features/questions/components/CreateQuestionButton';
import {
	FormEventHandler,
	FunctionComponent,
	MouseEventHandler,
	useEffect,
	useState,
} from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

export interface EntityPopoverBodyProps extends DomainGraphNode {}

export const EntityPopoverBody: FunctionComponent<EntityPopoverBodyProps> = (
	props
) => {
	const {
		_id: entityId,
		definition,
		singular,
		plural,
		plural: label,
		name,
		...flagProps
	} = props;

	const { register, handleSubmit, formState, resetField } =
		useForm<DescribeEntityFormValues>({
			defaultValues: {
				singular,
				definition: definition ?? DEFINITION_PLACEHOLDER,
				plural,
			},
		});

	const {
		singular: singularIsDirty,
		definition: definitionIsDirty,
		plural: pluralIsDirty,
	} = formState.dirtyFields;

	const [describe, describeResult] = useDescribeEntityMutation();

	useEffect(() => {
		if (describeResult.error) {
			warnInDev(JSON.stringify(describeResult.error), 'error');
		}
	}, [describeResult.error]);

	const onSubmit: SubmitHandler<DescribeEntityFormValues> = (vals) => {
		describe({
			entityId,
			body: {
				...vals,
				definition: nullifyDefinitionPlaceholder(vals.definition),
			},
		});
	};

	const conditionalSubmit: FormEventHandler = (e) => {
		e.preventDefault();

		if (formState.isDirty) {
			handleSubmit(onSubmit)();
		}

		return null;
	};

	const { data: entityAttrs, ...attrQueryLoadingState } =
		useGetAttributesQuery({ entityId });

	const { activeItem: activeDomain } = useActiveDomainData();

	const canEditDomain = activeDomain ? canEdit(activeDomain) : false;

	const [menuOpen, setMenuOpen] = useState(false);

	const toggleDropdown: MouseEventHandler = () => {
		setMenuOpen((p) => !p);
	};

	return (
		<PopoverCardBody>
			<PopoverMainContent>
				<PopoverHeader>
					<PopoverHeading>{label}</PopoverHeading>
					<PopoverSubhead>
						<form onSubmit={conditionalSubmit}>
							<SubtleTextbox
								{...register('singular')}
								showLabel
								isDirty={Boolean(singularIsDirty)}
								label="Sg"
								onReset={() => resetField('singular')}
								overrides={{
									root: {
										display: 'block',
										textOverflow: 'ellipsis',
										whiteSpace: 'nowrap',
										overflow: 'hidden',
									},
									container: {
										maxWidth: '250px',
									},
								}}
								disabled={!canEditDomain}
							/>
							<SubtleTextbox
								{...register('plural')}
								isDirty={Boolean(pluralIsDirty)}
								label="Pl"
								showLabel
								onReset={() => resetField('plural')}
								overrides={{
									root: {
										display: 'block',
										textOverflow: 'ellipsis',
										whiteSpace: 'nowrap',
										overflow: 'hidden',
									},
									container: {
										maxWidth: '250px',
									},
								}}
								disabled={!canEditDomain}
							/>
							{/* Always show this field if user has edit permission.  Otherwise,
                            only show it if there is user-created content to populate it. 
                            */}
							{(canEditDomain || isNonEmptyString(definition)) &&
								(canEditDomain ? (
									<SubtleTextArea
										{...register('definition')}
										isDirty={Boolean(definitionIsDirty)}
										label="definition"
										onReset={() => resetField('definition')}
										rows={2}
										overrides={{
											root: {
												resize: 'vertical',
											},
										}}
										disabled={!canEditDomain}
									/>
								) : (
									<p>{definition}</p>
								))}
						</form>
					</PopoverSubhead>
					<FlexContainer
						justifyContent="flex-start"
						style={{
							padding: '8px',
							gap: '8px',
							paddingLeft: 0,
						}}
					>
						{canEditDomain && (
							<>
								<IconButton
									icon={faCheck}
									fillColor="primary"
									size="xs"
									baseOpacity="80%"
									tooltip={`update ${label}`}
									tooltipPlacement="bottom"
									disabled={!formState.isDirty}
									showTooltip={formState.isDirty}
									onClick={(e) => conditionalSubmit(e)}
									aria-label="submit changes"
								/>
								<CreationGroupButton entityId={entityId} />
							</>
						)}
						<CreateQuestionButton
							objectId={entityId}
							objectType="Entity"
							canEdit={canEditDomain}
						/>
						{canEditDomain && <DeleteEntityButton {...props} />}
					</FlexContainer>
				</PopoverHeader>
			</PopoverMainContent>
			<PopoverBottomDrawer open={menuOpen}>
				<EntityPopoverDropdown
					{...attrQueryLoadingState}
					attributes={entityAttrs}
					hasIdentities={flagProps.hasIdentities}
					canEdit={canEditDomain}
				/>
			</PopoverBottomDrawer>
			<EntityPopoverControls
				attributeLoadingStatus={
					attrQueryLoadingState.isError
						? 'error'
						: attrQueryLoadingState.isLoading
						? 'loading'
						: attrQueryLoadingState.isSuccess
						? 'success'
						: 'loading'
				}
				canEdit={canEditDomain}
				entityName={name}
				availableViews={deriveEntityViews(flagProps)}
				entityId={entityId}
				toggleDropdown={toggleDropdown}
				entityHasIdentities={flagProps.hasIdentities}
				menuOpen={menuOpen}
			/>
		</PopoverCardBody>
	);
};

export default EntityPopoverBody;
