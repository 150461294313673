import Paper from '../../../../common/Paper';
import styled from 'styled-components';

export const HUDModalBody = styled(Paper)`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
`;

export const ModalHeader = styled.header`
	border-bottom: 2px solid ${(p) => p.theme.palette.divider};
	padding: ${(p) => p.theme.spacing(1, 2)};
	display: flex;
	justify-content: space-between;
`;
export const HUDModalHeaderText = styled.h1`
	font-size: 1rem;
	display: flex;
	align-items: center;
`;
