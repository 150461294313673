import { flow } from 'common/utils/functionUtils';
import {
	displayableAttrSubtypes,
	filterSubtypes,
	groupAttrs,
	sortGroupedAttrs,
} from 'features/ontology/helpers/attributeHelpers';

// NB: we move this to its own file so we can use same grouping function
// in UI tests
export const groupForAttrSelect = flow(
	groupAttrs('base'),
	filterSubtypes(displayableAttrSubtypes),
	sortGroupedAttrs
);
