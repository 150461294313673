import Button from '../../../common/Button';
import Container from '../../../common/Container';
import { FormResults } from '../../../common/Form';
import SubtleSelect from '../../../common/SubtleSelect';
import {
	NonInputLine,
	SubtleTextbox,
	harvestErrors,
} from '../../../common/SubtleTextbox';
import { useGetAccountsQuery, useUpdateUserMutation } from '../../api';
import useUserProfile from '../../authentication/hooks/useUserProfile';
import ProfileHeader from '../common/ProfileHeader';
import { FunctionComponent, useEffect, useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';

// TODO: this is re-used from AttributeProfile.  Eventually we'll want
// to extract a lot of this for sharing.
const StyledUserPropsContainer = styled.section`
	margin: 0;
	width: 75%;
	padding: ${(p) => p.theme.spacing(1)};
	color: ${(p) => p.theme.palette.darkBaby};
`;

const StyledButton = styled(Button)`
	margin-top: ${(p) => p.theme.spacing(1)};
`;

interface UpdateUserFormValues {
	firstName: string;
	lastName: string;
	username: string;
	defaultAccount: string;
}

const UserProfile: FunctionComponent = () => {
	const user = useUserProfile();

	const { data: accountsData } = useGetAccountsQuery({ userId: user._id });

	const realName = `${user.firstName} ${user.lastName}`;

	const { register, formState, resetField, handleSubmit, reset } =
		useForm<UpdateUserFormValues>({
			defaultValues: user,
		});

	// this is needed to update react-hook-form's default values with new
	// values from a successful PUT request.
	useEffect(() => {
		reset(user);
	}, [user, reset]);

	const validatingRegister = useCallback(
		(inputName: keyof UpdateUserFormValues) =>
			register(inputName, {
				required: `${inputName} is required`,
			}),
		[register]
	);

	const [updateUser, mutationResults] = useUpdateUserMutation();

	const onSubmit: SubmitHandler<UpdateUserFormValues> = (formVals) => {
		const { __tag, ...userProps } = user;

		updateUser({
			userId: userProps._id,
			body: Object.assign(userProps, formVals),
		});
	};

	return (
		<Container>
			<ProfileHeader headingText={realName} subheadText={user.username} />
			<StyledUserPropsContainer>
				<form onSubmit={handleSubmit(onSubmit)}>
					<SubtleTextbox
						{...validatingRegister('firstName')}
						label="First Name"
						isDirty={Boolean(formState.dirtyFields.firstName)}
						onReset={() => resetField('firstName')}
						error={harvestErrors(
							'firstName',
							formState,
							mutationResults
						)}
						showLabel
					/>
					<SubtleTextbox
						{...validatingRegister('lastName')}
						label="Last Name"
						isDirty={Boolean(formState.dirtyFields.lastName)}
						onReset={() => resetField('lastName')}
						error={harvestErrors(
							'lastName',
							formState,
							mutationResults
						)}
						showLabel
					/>
					<SubtleTextbox
						{...validatingRegister('username')}
						showLabel
						label="Username"
						isDirty={Boolean(formState.dirtyFields.username)}
						onReset={() => resetField('username')}
						error={harvestErrors(
							'username',
							formState,
							mutationResults
						)}
					/>
					<SubtleSelect
						label="Default Account"
						onReset={() => resetField('defaultAccount')}
						isDirty={Boolean(formState.dirtyFields.defaultAccount)}
						{...validatingRegister('defaultAccount')}
					>
						{accountsData &&
							accountsData.map((acct) => (
								<option key={acct._id} value={acct.name}>
									{acct.label}
								</option>
							))}
					</SubtleSelect>
					{/* {user.jobTitle && (
						<SubtleTextbox
							{...validatingRegister('jobTitle')}
							label="Last Name"
							isDirty={Boolean(formState.dirtyFields.jobTitle)}
							onReset={() => resetField('jobTitle')}
							error={harvestErrors(
								'jobTitle',
								formState,
								mutationResults
							)}
							showLabel
						/>
					)}
					{user.department && (
						<SubtleTextbox
							{...validatingRegister('department')}
							label="Deparment"
							isDirty={Boolean(formState.dirtyFields.department)}
							onReset={() => resetField('department')}
							error={harvestErrors(
								'department',
								formState,
								mutationResults
							)}
							showLabel
						/>
					)}
					<NonInputLine
						label="Do Not Mail"
						value={String(user.doNotMail)}
					/> */}
					<NonInputLine label="Joined" value={user.memberSince} />
					<FormResults
						{...mutationResults}
						validationErrors={formState.errors}
						onSuccessDelay={2000}
					/>
					<StyledButton disabled={!formState.isDirty}>
						Submit
					</StyledButton>
				</form>
			</StyledUserPropsContainer>
		</Container>
	);
};

export default UserProfile;
