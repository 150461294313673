import { ObjectType } from '../ontology/types/commonTypes';
import {
	CreateQuestionFormValues,
	CreateQuestionPayload,
	GetQuestionsParams,
	QuestionableObject,
} from './types/questionTypes';

export const createQuestionFormToPayload = (
	formValues: CreateQuestionFormValues,
	objectId: number,
	objectType: ObjectType
): CreateQuestionPayload => ({
	question: formValues.question,
	objectId,
	objectType,
});

export const getURLStumpFromObjectType = (objectType: QuestionableObject) => {
	switch (objectType) {
		case 'User':
			return 'users';
		case 'DataSource':
			return 'sources';
		case 'DataCatalog':
			return 'catalogs';
		case 'Dataset':
			return 'datasets';
		case 'DataColumn':
			return 'columns';
		case 'DataRow':
			return 'rows';
		case 'DataValue':
			return 'values';
		case 'DataType':
			return 'types';
		case 'Metric':
			return 'metrics';
		case 'Fact':
			return 'facts';
		case 'Insight':
			return 'insights';
		case 'Domain':
			return 'domains';
		case 'Entity':
			return 'entities';
		case 'Attribute':
			return 'attributes';
		case 'Individual':
			return 'individuals';
	}
};

export const buildQuestionsQueryURL = ({
	objectId,
	objectType,
}: GetQuestionsParams) =>
	`/${getURLStumpFromObjectType(objectType)}/${objectId}/questions`;

export const buildParametrizedQuestionsQueryURL = (
	objectType: QuestionableObject
) => `/${getURLStumpFromObjectType(objectType)}/:objectId/questions`;
