import FlexContainer from '../../../common/FlexContainer';
import Spinner from '../../../common/Spinner';
import Typography from '../../../common/Typography';
import { BaseAttribute } from '../../ontology/types/attributeTypes';
import { RelateAttrFormValues } from '../relateAttribute/relateAttributeTypes';
import { ChangeEventHandler, FunctionComponent, Fragment } from 'react';
import { UseFormRegister } from 'react-hook-form';

interface TargetAttrSelectProps {
	selectableAttrs: BaseAttribute[];
	setTargetAttr: (attr: BaseAttribute | 'deriveNew' | null) => void;
	loading: boolean;
	register: UseFormRegister<RelateAttrFormValues>;
	labelText: string;
}

const TargetAttrSelect: FunctionComponent<TargetAttrSelectProps> = ({
	loading,
	selectableAttrs,
	setTargetAttr,
	register,
	labelText,
}) => {
	if (loading) {
		return (
			<FlexContainer justifyContent="center">
				<Typography paragraph>Loading attributes...</Typography>
				<Spinner />
			</FlexContainer>
		);
	}

	const handleChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
		const { value } = e.target;

		if (value === 'deriveNew') {
			return setTargetAttr(value);
		}

		if (value !== 'null') {
			return setTargetAttr(
				// call setter with complete attribute object for the selected attribute.
				// ok to cast the 'find' result b/c we know the input array has been pre-filtered
				selectableAttrs.find(
					(attr) => attr._id === parseInt(value, 10)
				) as BaseAttribute
			);
		}

		return setTargetAttr(null);
	};

	return (
		<>
			<label htmlFor="target-attr-select">
				<Typography>{labelText}</Typography>
			</label>
			<select
				id="target-attr-select"
				{...register('otherId', {
					onChange: handleChange,
					validate: (value) =>
						value === 'null'
							? 'A target attribute selection is required'
							: true,
				})}
			>
				<option value="null" key="initial">
					Select target attribute:
				</option>
				<option value="deriveNew" key="deriveNew">
					Derive a new entity
				</option>
				{selectableAttrs.map((attr) => (
					<option value={attr._id} key={attr._id}>
						{`${attr.entity.plural}: ${attr.plural}`}
					</option>
				))}
			</select>
		</>
	);
};

export default TargetAttrSelect;
