import { UseIndividualsConfig } from '../types/hookTypes';
import useActiveEntityData from './useActiveEntityData';
import useIndividualsMeta, { defaultConfig } from './useIndividualsMeta';

const useActiveIndividualsMeta = (
	config: UseIndividualsConfig = defaultConfig
) => {
	const { activeItem } = useActiveEntityData();

	const res = useIndividualsMeta(activeItem?._id, config);

	return res;
};

export default useActiveIndividualsMeta;
