import { useGetAccountsQuery } from '../../api';
import useUserID from '../../authentication/hooks/useUserID';
import { ContextParams } from '../../navigation/types/navigationContextTypes';
import { BaseAccount } from '../types/accountTypes';
import { createSelector } from '@reduxjs/toolkit';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { isEmpty } from 'common/utils/typeGuards';
import { useParams } from 'react-router-dom';

// TODO: figure out why this doesn't type correctly in selector fn below
type QueryResponse = Omit<ReturnType<typeof useGetAccountsQuery>, 'refetch'>;

type ActiveAccountName = string | undefined;

const selectActiveAccount = createSelector(
	(res: QueryResponse) => res.data,
	(res: QueryResponse, activeAccountName: ActiveAccountName) =>
		activeAccountName,
	(data: QueryResponse['data'], activeAccountName: ActiveAccountName) =>
		data?.find((acct: BaseAccount) => acct.name === activeAccountName) ??
		null
);
/**
 * @returns RTK Query files related to fetching all available accounts for a user, as well
 * as an activeAccount property that corresponds to the files for the user specified
 * in the application URL.
 */
const useActiveAccountData = () => {
	const params = useParams() as ContextParams;

	const userId = useUserID();

	// won't run as long as skipToken is its argument
	const fetchData = useGetAccountsQuery(
		isEmpty(userId) ? skipToken : { userId },
		{
			selectFromResult: (res) => ({
				...res,
				// important to use a memoizing selector here: selectFromResult
				// uses shallow-equal comparison to determine whether or not a re-render
				// should occur. The only way to get a stable reference for the value of
				// activeItem is to memoize based on inputs.  RTK query takes care of
				// memoizing the value of res.data for us.
				activeItem: selectActiveAccount(
					res,
					params.account
				) as null | BaseAccount,
			}),
		}
	);

	return fetchData;
};

export default useActiveAccountData;
