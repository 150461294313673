import Button from '../../../common/Button';
import { useMeltEntityMutation } from '../../api';
import { EntityActionFormProps } from '../common/commonTypes';
import { renderDerivationSubfields } from '../common/jsxHelpers';
import { StyledFlexContainer, StyledPaper } from '../common/styledComponents';
import {
	meltEntityFormDefaults,
	meltEntityFormToPayload,
	meltEntityPayloadToParams,
} from './meltEntityHelpers';
import { MeltEntityFormValues } from './meltEntityTypes';
import { FunctionComponent } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

const MeltEntityForm: FunctionComponent<EntityActionFormProps> = (props) => {
	const { _id: meltedEntityId } = props;

	const formDefaults = meltEntityFormDefaults(props);

	const { handleSubmit, register, formState } = useForm<MeltEntityFormValues>(
		{
			defaultValues: formDefaults,
		}
	);

	const [melt, meltResult] = useMeltEntityMutation();

	const onSubmit: SubmitHandler<MeltEntityFormValues> = (vals, e) => {
		e?.preventDefault();
		melt(
			meltEntityPayloadToParams(
				meltEntityFormToPayload(vals),
				meltedEntityId
			)
		);
	};

	return (
		<StyledPaper>
			<form onSubmit={handleSubmit(onSubmit)}>
				<StyledFlexContainer flexDirection="column" alignItems="center">
					{renderDerivationSubfields(true, register, formState)}

					<Button type="submit">Submit </Button>
				</StyledFlexContainer>
			</form>
		</StyledPaper>
	);
};

export default MeltEntityForm;
