import { useAppDispatch } from 'app/hooks';
import { pipe } from 'common/utils/functionUtils';
import { deriveEntityViews } from 'features/HUD/helpers';
import useHUDIndividual from 'features/HUD/hooks/useHUDIndividual';
import { viewsAvailable } from 'features/HUD/state/HUDSlice';
import { clearActiveIndividual } from 'features/HUD/state/HUDSlice';
import EntityGraph from 'features/forceGraphs/components/EntityGraph';
import EntityGeospatial from 'features/geoSpatial/components/EntityGeospatial';
import useRenderView, {
	RenderViewConfig,
} from 'features/navigation/hooks/useRenderView';
import useActiveEntityData from 'features/ontology/hooks/useActiveEntityData';
import { default as EntityProfile } from 'features/profilePages/EntityProfile';
import { FunctionComponent, useEffect } from 'react';

const configs: RenderViewConfig = {
	viewDefault: 'profile',
	views: [
		{ match: 'profile', element: <EntityProfile /> },
		{ match: 'graph', element: <EntityGraph /> },
		{ match: 'geospatial', element: <EntityGeospatial /> },
	],
};

// TODO: the only way I can think of to integration test this is complicated
// to setup, fragile, and tests implementation rather than behavior.
// A functional test would be appropriate here: render the whole app,
// and test clicking through the Entity popover view buttons, then test
// that view toggle buttons render correct UI.
const EntityViews: FunctionComponent = () => {
	const { activeItem } = useActiveEntityData();

	const individualInRightDrawer = !!useHUDIndividual();

	const appDispatch = useAppDispatch();

	const render = useRenderView(configs);

	useEffect(() => {
		if (activeItem) {
			pipe(activeItem, deriveEntityViews, viewsAvailable, appDispatch);
		}

		return () => pipe([], viewsAvailable, appDispatch) as void;
	}, [activeItem, appDispatch]);

	// when entity views unmount, clear any individual data that may
	// be on display in right HUD drawer.
	useEffect(() => {
		return () => {
			if (individualInRightDrawer) {
				appDispatch(clearActiveIndividual());
			}
		};
	}, [individualInRightDrawer, appDispatch]);

	return <>{render()}</>;
};

export default EntityViews;
