import React, { FunctionComponent, HTMLAttributes } from 'react';
import styled from 'styled-components';

interface Props extends HTMLAttributes<HTMLHeadingElement> {
	component: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
	alignment?: 'left' | 'right' | 'center';
}

const StyledHeading = styled.h1<Props>`
	/* TODO: figure out why these font sizes are so bonkers... */
	font-size: ${(p) => p.theme.typography[p.component].fontSize};
	line-height: ${(p) => p.theme.typography[p.component].lineHeight};
	font-family: ${(p) => p.theme.typography[p.component].fontFamily};
	font-weight: ${(p) => p.theme.typography[p.component].fontWeight};
	text-align: ${(p) => (p.alignment ? p.alignment : 'left')};
	color: ${(p) => p.theme.palette.oldManGray};
`;

const Heading: FunctionComponent<Props & Record<string, any>> = ({
	children,
	component,
	...props
}) => (
	<StyledHeading component={component} as={component} {...props}>
		{children}
	</StyledHeading>
);

export default Heading;
