import Button from '../../../common/Button';
import FlexContainer from '../../../common/FlexContainer';
import {
	formFieldHasErrors,
	FormResults,
	genErrorIdFromLabel,
} from '../../../common/Form';
import Spinner from '../../../common/Spinner';
import Typography from '../../../common/Typography';
import { useGetAttributesQuery, useRestrictEntityMutation } from '../../api';
import { mergeErrorStates, extractQueryErrMessage } from '../../api/helpers';
import { EntityActionFormProps } from '../common/commonTypes';
import { getRestrictionOperators } from '../common/helpers';
import {
	renderDerivationSubfields,
	renderReferenceField,
} from '../common/jsxHelpers';
import { StyledFlexContainer, StyledPaper } from '../common/styledComponents';
import useAttrNeighbors from '../hooks/useAttrNeighbors';
import {
	renderRelationCountSubfield,
	restrictEntityFormDefaults,
	restrictEntityFormToParams,
} from './restrictEntityHelpers';
import {
	RestrictEntityFormValues,
	RestrictionKind,
} from './restrictEntityTypes';
import { FunctionComponent, useEffect, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

// pass RestrictionKind prop to determine correct endpoint
const RestrictEntityForm: FunctionComponent<
	EntityActionFormProps & { restrictionKind: RestrictionKind }
> = (props) => {
	const {
		_id: parentEntityId,
		singular,
		updatePopper,
		restrictionKind,
	} = props;

	const formDefaults = restrictEntityFormDefaults();

	const { handleSubmit, register, formState, watch } =
		useForm<RestrictEntityFormValues>({
			defaultValues: formDefaults,
		});

	const [
		watchedUsingStaticReference,
		watchedRestrictionType,
		watchedRestrictedId,
	] = watch(['usingStaticReference', 'restrictionType', 'restrictedId']);

	const relationCountNeeded = useMemo(
		() =>
			watchedRestrictionType === 'hasAtLeastNRelationsWith' ||
			watchedRestrictionType === 'hasAtMostNRelationsWith',
		[watchedRestrictionType]
	);

	//  prevent filtering from running every render
	const filter = useMemo(() => {
		return () => true;
	}, []);

	const { data: attributes } = useGetAttributesQuery({
		entityId: parentEntityId,
	});

	const queryRes = useAttrNeighbors(
		watchedRestrictedId === 0 ? null : watchedRestrictedId,
		filter
	);

	const restrictedAttribute = useMemo(() => {
		if (attributes) {
			return (
				attributes.find((attr) => attr._id === watchedRestrictedId) ??
				null
			);
		}

		return null;
	}, [watchedRestrictedId, attributes]);

	useEffect(() => {
		if (queryRes.isSuccess) {
			updatePopper();
		}
	}, [queryRes.isSuccess, updatePopper]);

	const [restrict, restrictResult] = useRestrictEntityMutation();

	const mergedErrs = mergeErrorStates(queryRes, restrictResult);

	const onSubmit: SubmitHandler<RestrictEntityFormValues> = (vals, e) => {
		e?.preventDefault();

		if (restrictedAttribute !== null) {
			return restrict(
				restrictEntityFormToParams(
					parentEntityId,
					restrictionKind,
					vals
				)
			);
		}
	};

	if (queryRes.isLoading) {
		return (
			<FlexContainer justifyContent="center">
				<Typography paragraph>Loading attributes...</Typography>
				<Spinner />
			</FlexContainer>
		);
	}

	if (queryRes.isError) {
		return (
			<FlexContainer justifyContent="center">
				<Typography color="error" paragraph>
					{extractQueryErrMessage(queryRes.error)}
				</Typography>
			</FlexContainer>
		);
	}

	return (
		<StyledPaper>
			<form onSubmit={handleSubmit(onSubmit)}>
				<StyledFlexContainer flexDirection="column" alignItems="center">
					<label htmlFor="restrictedId">
						<Typography id="restrictionId-label">
							Select an attribute to{' '}
							{restrictionKind === 'specialization'
								? 'specialize'
								: 'restrict'}{' '}
							{singular} on:
						</Typography>
					</label>
					<select
						{...register('restrictedId', {
							required:
								'An attribute to restrict on must be selected',
							valueAsNumber: true,
							validate: (v) =>
								v !== 0
									? true
									: 'An attribute to restrict on must be selected',
						})}
						id="restrictedId"
						aria-errormessage={genErrorIdFromLabel('restrictedId')}
						aria-invalid={formFieldHasErrors(
							'restrictedId',
							formState
						)}
					>
						<option value={0}>none</option>
						{attributes &&
							attributes.map((attr) => (
								<option key={attr._id} value={attr._id}>
									{attr.plural}
								</option>
							))}
					</select>
					{renderDerivationSubfields(
						restrictionKind === 'specialization',
						register,
						formState
					)}

					<label htmlFor="restrictionType">
						<Typography>
							What comparison should be used to
							{` ${
								restrictionKind === 'specialization'
									? 'specialize'
									: 'restrict'
							} ${singular}`}
						</Typography>
					</label>
					<select
						{...register('restrictionType', {
							required: 'A comparison operator must be selected',
						})}
						id="restrictionType"
						aria-errormessage={genErrorIdFromLabel(
							'restrictionType'
						)}
						aria-invalid={formFieldHasErrors(
							'restrictionType',
							formState
						)}
					>
						{restrictedAttribute ? (
							getRestrictionOperators(
								restrictedAttribute.type
							).map(({ operator, displayValue }) => (
								<option value={operator} key={operator}>
									{displayValue}
								</option>
							))
						) : (
							<option value="none">
								First select attribute above
							</option>
						)}
					</select>

					{renderRelationCountSubfield(
						relationCountNeeded,
						register,
						formState
					)}

					<Typography id="usingStaticReference">
						Should {restrictedAttribute?.singular} be compared with
						a related attribute or a static value?
					</Typography>
					<div
						role="radiogroup"
						aria-errormessage={genErrorIdFromLabel(
							'usingStaticReference'
						)}
						aria-invalid={formFieldHasErrors(
							'usingStaticReference',
							formState
						)}
						aria-labelledby="usingStaticReference"
					>
						<div>
							<input
								id="dv-false"
								type="radio"
								value="false"
								{...register('usingStaticReference', {
									required: {
										value: true,
										message:
											'A value for usingStaticReference is required',
									},
								})}
							/>
							<label htmlFor="dv-false">
								<Typography>Related attribute</Typography>
							</label>
						</div>
						<div>
							<input
								id="dv-true"
								type="radio"
								value="true"
								{...register('usingStaticReference', {
									required: {
										value: true,
										message:
											'A value for usingStaticReference is required',
									},
								})}
							/>
							<label htmlFor="dv-true">
								<Typography>Static value</Typography>
							</label>
						</div>
					</div>

					{renderReferenceField(
						'restrictionId',
						'restrictionValue',
						watchedUsingStaticReference === 'true',
						register,
						queryRes.attrNeighbors,
						formState
					)}

					<Button type="submit">Submit</Button>

					<FormResults
						isError={!!mergedErrs}
						error={mergedErrs}
						validationErrors={formState.errors}
					/>
				</StyledFlexContainer>
			</form>
		</StyledPaper>
	);
};

export default RestrictEntityForm;
