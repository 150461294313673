// Need this in its own file for helper functions that use JSX,
// since JSX syntax clashes with generic type declarations in arrow function,
// and I can't stand the look of any of the workarounds...
import { formFieldHasErrors, genErrorIdFromLabel } from '../../../common/Form';
import Typography from '../../../common/Typography';
import { BaseAttribute } from '../../ontology/types/attributeTypes';
import { createBasicValidation } from './helpers';
import { StyledTextArea } from './styledComponents';
import { FormState, UseFormRegister } from 'react-hook-form';

/**
 * Render sub-fields needed if attribute operation is a derivation--
 * these sub-fields provide identifiers for the newly-derived attribute.
 */
export const renderDerivationSubfields = (
	isDerivation: boolean,
	// TODO: figure out this type.
	register: UseFormRegister<any>,
	formState: FormState<any>
) => {
	if (isDerivation) {
		return (
			<>
				<label htmlFor="singular">
					<Typography>
						What should one of the new attribute be called?
					</Typography>
				</label>
				<input
					type="text"
					{...register('singular', {
						required: {
							value: true,
							message: `Singular is a required field`,
						},
						maxLength: {
							value: 200,
							message: `A maximum of 200 characters is allowed for singular`,
						},
					})}
					id="singular"
					aria-errormessage={genErrorIdFromLabel('singular')}
					aria-invalid={formFieldHasErrors('singular', formState)}
				/>

				<label htmlFor="plural">
					<Typography>
						What should many of the new attribute be called?
					</Typography>
				</label>
				<input
					type="text"
					{...register('plural', createBasicValidation('plural'))}
					id="plural"
					aria-errormessage={genErrorIdFromLabel('plural')}
					aria-invalid={formFieldHasErrors('plural', formState)}
				/>

				<label htmlFor="definition">
					<Typography>Define the new attribute</Typography>
				</label>
				<StyledTextArea
					{...register('definition')}
					aria-errormessage={genErrorIdFromLabel('definition')}
					aria-invalid={formFieldHasErrors('definition', formState)}
					id="definition"
				/>
			</>
		);
	}

	return null;
};

/**
 * Render reference attribute <select> menu
 */
export const renderOtherIdSelect = (
	fieldName: string,
	register: UseFormRegister<any>,
	formState: FormState<any>,
	selectableAttrs: BaseAttribute[] | null
) => {
	if (selectableAttrs === null || selectableAttrs.length === 0) {
		return (
			<Typography paragraph color="warn">
				No eligible attributes for this operation
			</Typography>
		);
	}
	return (
		<>
			<label htmlFor={fieldName}>
				<Typography>Select a reference attribute</Typography>
			</label>
			<select
				{...register(fieldName, {
					valueAsNumber: true,
					validate: (v: string | number) => {
						if (typeof v === 'string') {
							const maybeInt = parseInt(v, 10);

							return isNaN(maybeInt)
								? 'Invalid value passed to otherId'
								: maybeInt > 0
								? true
								: 'A reference attribute must be selected if you are not using a static files value';
						}

						return v > 0
							? true
							: 'A reference attribute must be selected if you are not using a static files value';
					},
				})}
				id={fieldName}
				aria-errormessage={genErrorIdFromLabel(fieldName)}
				aria-invalid={formFieldHasErrors(fieldName, formState)}
			>
				{selectableAttrs.map((attr) => (
					<option
						value={attr._id}
						key={attr._id}
					>{`${attr.entity.singular}: ${attr.singular}`}</option>
				))}
			</select>
		</>
	);
};

/**
 * Render sub-fields needed if user opts to use a files value
 */
export const renderReferenceField = (
	attrSelectFieldName: string,
	staticValueFieldName: string,
	needsDataValue: boolean,
	register: UseFormRegister<any>,
	selectableAttrs: BaseAttribute[] | null,
	formState: FormState<any>
) => {
	if (needsDataValue) {
		return (
			<>
				<label htmlFor={staticValueFieldName}>
					<Typography>
						If not using a second attribute, enter a static value to
						use
					</Typography>
				</label>
				<input
					type="text"
					{...register(
						staticValueFieldName,
						createBasicValidation(staticValueFieldName)
					)}
					id={staticValueFieldName}
					aria-errormessage={genErrorIdFromLabel(
						staticValueFieldName
					)}
					aria-invalid={formFieldHasErrors(
						staticValueFieldName,
						formState
					)}
				/>
			</>
		);
	}

	return renderOtherIdSelect(
		attrSelectFieldName,
		register,
		formState,
		selectableAttrs
	);
};
