import theme from './theme/theme';
import { FunctionComponent, HTMLAttributes, ReactNode } from 'react';
import { DefaultTheme } from 'styled-components';

const selectThemeColor = (color: ColorProp, theme: DefaultTheme) => {
	switch (color) {
		case 'default':
			return 'inherit';

		case 'error':
			return theme.palette.error.main;

		case 'warn':
			return theme.palette.warning.main;
		case 'primary':
			return theme.palette.primary.main;
		case 'darkBaby':
			return theme.palette.darkBaby;
		case 'oldManGray':
			return theme.palette.oldManGray;
		case 'cyan':
			return theme.palette.cyan;
	}
};

export type ColorProp =
	| 'default'
	| 'error'
	| 'warn'
	| 'primary'
	| 'darkBaby'
	| 'oldManGray'
	| 'cyan';

export type TypographyVariant =
	| 'h1'
	| 'h2'
	| 'h3'
	| 'h4'
	| 'h5'
	| 'h6'
	| 'body1'
	| 'body2'
	| 'subtitle1'
	| 'caption';

interface TypographyProps extends HTMLAttributes<any> {
	color?: ColorProp;
	paragraph?: boolean;
	variant?: TypographyVariant;
	children?: ReactNode;
}

// TODO: using 'style' prop directly overrides composition with styled-components.
// Ideally we would preserve that ability.
const Typography: FunctionComponent<TypographyProps> = (props) => {
	const {
		paragraph = false,
		color = 'default',
		variant = 'body1',
		style,
		...rest
	} = props;

	const Component = paragraph ? 'p' : 'span';

	return (
		<Component
			{...rest}
			style={{
				...theme.typography[variant],
				textAlign: 'inherit',
				// cast the theme type b/c the compiler doesn't like readonly value we've given breakpoints.keys
				color: selectThemeColor(color, theme),
				...style,
			}}
		>
			{props.children}
		</Component>
	);
};

export default Typography;
