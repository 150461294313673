import { QTY_RANGE_FILTER_TYPE, CAT_RANGE_FILTER_TYPE } from './CONSTANTS';

export type QsParam = [string, string];

type AttrFilterType =
	| typeof QTY_RANGE_FILTER_TYPE
	| typeof CAT_RANGE_FILTER_TYPE;

interface BaseAttributeFilter {
	attributeName: string;
	filterType: AttrFilterType;
}

export interface QtyRangeFilter extends BaseAttributeFilter {
	filterType: typeof QTY_RANGE_FILTER_TYPE;
	min: number;
	max: number;
}

export const isQtyRangeFilter = (f: AttributeFilter): f is QtyRangeFilter =>
	f.filterType === QTY_RANGE_FILTER_TYPE;

export interface CatRangeFilter extends BaseAttributeFilter {
	filterType: typeof CAT_RANGE_FILTER_TYPE;
	includedValues: string[];
}

export const isCatRangeFilter = (f: AttributeFilter): f is CatRangeFilter =>
	f.filterType === CAT_RANGE_FILTER_TYPE;

export type FilterValidator<T> = { [K in keyof T]: (p: unknown) => boolean };

export type AttributeFilter = QtyRangeFilter | CatRangeFilter;

export type AttributeFilters = AttributeFilter[];
