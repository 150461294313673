import {
	useFollowEntityMutation,
	useUnfollowEntityMutation,
	useGetEntityQuery,
} from '../../../api';
import { mergeErrorStates, mergeLoadingStates } from '../../../api/helpers';
import { userIsFollowing } from '../../../authentication/types/userTypes';
import FollowButton from 'features/forceGraphs/components/common/FollowButton';
import { FunctionComponent } from 'react';

interface EntityFollowButtonProps {
	entityId: number;
}

const EntityFollowButton: FunctionComponent<EntityFollowButtonProps> = ({
	entityId,
}) => {
	const queryResults = useGetEntityQuery({ entityId });

	const [followEntity, followResults] = useFollowEntityMutation();

	const [unfollowEntity, unfollowResults] = useUnfollowEntityMutation();

	const error = mergeErrorStates(
		queryResults,
		followResults,
		unfollowResults
	);

	const isLoading = mergeLoadingStates(
		queryResults,
		followResults,
		unfollowResults
	);

	const entityData = queryResults.data;

	const objectAlreadyFollowed = entityData
		? userIsFollowing(entityData)
		: false;

	return (
		<FollowButton
			handleFollow={() => {
				followEntity({ entityId });
			}}
			handleUnfollow={() => {
				unfollowEntity({ entityId });
			}}
			objectIdentifier={
				queryResults.data ? queryResults.data.singular : null
			}
			error={error}
			loading={isLoading}
			objectAlreadyFollowed={objectAlreadyFollowed}
		/>
	);
};

export default EntityFollowButton;
