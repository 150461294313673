import { AttributeScatterPoint } from './types';
import theme from 'common/theme/theme';
import { BaseAttribute } from 'features/ontology/types/attributeTypes';
import { PointEnhancer } from 'features/viz/types';

export const filterAttrsForYMenu = (xAttr: string, attrs: BaseAttribute[]) =>
	attrs.filter((a) => a.name !== xAttr && a.type === 'quantity');

const availablePointColors = [
	theme.palette.primary.main,
	theme.palette.error.main,
	theme.palette.secondary.main,
];

export const attrPointEnhancer: PointEnhancer<
	number,
	number,
	AttributeScatterPoint
> = (point, xAttrName, yAttrNames, currentYName, idx) => {
	const colorIdx =
		yAttrNames.indexOf(currentYName) % availablePointColors.length;

	return {
		...point,
		individualIdx: idx,
		color: availablePointColors[colorIdx],
	};
};
