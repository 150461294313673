import { VIEW_MODE_SEARCH_PARAM } from 'features/navigation/CONSTANTS';
import { ViewMode } from 'features/navigation/types/navigationTypes';
import { useSearchParams } from 'react-router-dom';

const useViewModeParams = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const getViewMode = () =>
		searchParams.get(VIEW_MODE_SEARCH_PARAM) as ViewMode | null;

	const setViewMode = (mode: ViewMode) => {
		searchParams.set(VIEW_MODE_SEARCH_PARAM, mode);
		setSearchParams(searchParams);
	};

	return { getViewMode, setViewMode };
};

export default useViewModeParams;
