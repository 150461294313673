import FlexContainer from '../../../common/FlexContainer';
import Typography from '../../../common/Typography';
import { renderCards } from '../helpers';
import { Setter, SourceNavItemList } from '../types/uiTypes';
import { FunctionComponent } from 'react';
import styled from 'styled-components';

const StyledFlexContainer = styled(FlexContainer)`
	gap: ${(p) => p.theme.spacing(1)};
	align-items: flex-start;
	flex-wrap: wrap;
	padding: ${(p) => p.theme.spacing(1)};
`;

interface CardDisplayProps {
	setter: Setter;
	cardData: SourceNavItemList | null;
}

const CardDisplay: FunctionComponent<CardDisplayProps> = ({
	setter,
	cardData,
}) => {
	if (cardData === null) {
		return <Typography>No data to display.</Typography>;
	}
	return (
		<StyledFlexContainer>
			{renderCards(cardData, setter)}
		</StyledFlexContainer>
	);
};

export default CardDisplay;
