import PaperButton from '../../../../common/PaperButton';
import Spinner from '../../../../common/Spinner';
import { parseQueryError } from '../../../api/helpers';
import { QueryErrResult } from '../../../api/types';
import { FunctionComponent } from 'react';

export interface FollowButtonProps {
	loading?: boolean;
	handleFollow: () => void;
	handleUnfollow: () => void;
	objectAlreadyFollowed: boolean;
	objectIdentifier?: string | null;
	error?: QueryErrResult;
}

export const FollowButton: FunctionComponent<FollowButtonProps> = ({
	loading = false,
	handleFollow,
	handleUnfollow,
	objectAlreadyFollowed,
	objectIdentifier,
	error,
}) => {
	const onClick = () => {
		// Prevent user from doing anything while previous interaction is in-process
		if (loading) {
			return null;
		}

		if (objectAlreadyFollowed) {
			return handleUnfollow();
		}

		return handleFollow();
	};

	const renderContents = () => {
		// we presume loading/error will come from an RTK mutation; therefore
		// loading and error states are mutually exclusive.
		if (loading) {
			return <Spinner />;
		}

		if (error) {
			return parseQueryError(error).message;
		}

		if (objectAlreadyFollowed) {
			return `Unfollow`;
		}

		return `Follow`;
	};

	return <PaperButton onClick={onClick}>{renderContents()}</PaperButton>;
};

export default FollowButton;
