import Heading from 'common/Heading';
import theme from 'common/theme/theme';
import Fade from 'common/transitions/Fade';
import { forkRef } from 'common/utils/reactUtils';
import DomainPopoverBody from 'features/forceGraphs/components/AccountGraph/DomainPopoverBody';
import EntityPopoverBody from 'features/forceGraphs/components/DomainGraph/EntityPopoverBody';
import { generateGetBoundingClientRect } from 'features/forceGraphs/helpers';
import { ForceGraphContext } from 'features/forceGraphs/state/GraphContextProvider';
import { SET_POPUP_ELEMENT } from 'features/forceGraphs/state/actions';
import { isUIDomainGraphNode } from 'features/forceGraphs/types/domainGraphTypes';
import {
	isUILinkObject,
	SelectedGraphObject,
	UILinkObject,
	UIGraphNode,
	isUINodeObject,
	SelectedObjectWithVC,
} from 'features/forceGraphs/types/graphTypes';
import {
	FunctionComponent,
	useRef,
	useState,
	useEffect,
	useCallback,
	useContext,
} from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { usePopper } from 'react-popper';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';

const StyledListItem = styled.li`
	color: inherit;
	display: flex;
	align-items: stretch;
`;

const StyledHeading = styled(Heading)`
	color: ${(p) => p.theme.palette.common.white};
	font-size: ${(p) => p.theme.typography.body1};
`;

const renderNodeContent = (node: UIGraphNode) => {
	if (isUIDomainGraphNode(node)) {
		return <EntityPopoverBody {...node} />;
	}
	return <DomainPopoverBody {...node} />;
};

const renderLinkContent = (link: UILinkObject) => {
	const labelProp = isUIDomainGraphNode(link.target) ? 'name' : 'label';

	return (
		<div>
			<StyledHeading
				component="h4"
				id={`link-${link.source}-${link.target}-heading`}
			>
				Link
			</StyledHeading>
			<ul aria-labelledby={`link-${link.source}-${link.target}-heading`}>
				<StyledListItem>
					Source: {(link.source as any)[labelProp]}
				</StyledListItem>
				<StyledListItem>
					Target: {(link.target as any)[labelProp]}
				</StyledListItem>
			</ul>
		</div>
	);
};

const renderPopoverContent = (graphObject: SelectedGraphObject) => {
	if (isUINodeObject(graphObject)) {
		return renderNodeContent(graphObject);
	}

	if (isUILinkObject(graphObject)) {
		return renderLinkContent(graphObject);
	}

	return null;
};

interface GraphPopoverProps {
	graphObject: SelectedObjectWithVC | null;
	handleClose: () => void;
	// TODO: hacking this in for now to make the popover positioning adjustable
	// for use on attr profile/lineage view.
	popperOffSet?: [number, number];
}

const GraphPopover: FunctionComponent<GraphPopoverProps> = ({
	handleClose,
	graphObject,
	popperOffSet = [-100, -350],
}) => {
	// Create a virtual element for Popper to bind to
	const virtualEl = useRef({
		getBoundingClientRect: generateGetBoundingClientRect(graphObject),
	});

	const [popperElement, setPopperElement] = useState(null);

	const transitionRef = useRef<HTMLElement>();

	// react-transition-group and Popper both need a ref to the containing
	// DOM element
	const setRefs = forkRef(transitionRef, setPopperElement as any);

	//  TODO: update this component to use the base Popover
	// We use a bottom placement to cause the added height from menu accordion
	// to move DOWN the page instead of up.  Then use offset to move the popover
	// itself up the page so the dropdown menu isn't too low on the viewport.
	const { styles, attributes, update } = usePopper(
		virtualEl.current,
		popperElement,
		{
			placement: 'bottom-end',
			modifiers: [
				{
					name: 'offset',
					options: {
						offset: popperOffSet,
					},
				},
			],
		}
	);

	const updatePopper = useCallback(() => {
		virtualEl.current.getBoundingClientRect =
			generateGetBoundingClientRect(graphObject);

		if (update) {
			update();
		}
	}, [graphObject, update]);

	// update popper position on component mount.
	useEffect(() => {
		updatePopper();
	}, [updatePopper]);

	return (
		<CSSTransition
			classNames="fade-transition"
			in={!!graphObject}
			timeout={theme.transitions.duration.shortest}
			unmountOnExit
			nodeRef={transitionRef}
		>
			<Fade
				style={Object.assign(styles.popper, {
					zIndex: theme.zIndex.controlSurface,
				})}
				{...attributes.popper}
				ref={setRefs as any}
				$duration={theme.transitions.duration.shortest}
			>
				<ClickAwayListener onClickAway={handleClose}>
					<div>{renderPopoverContent(graphObject)}</div>
				</ClickAwayListener>
			</Fade>
		</CSSTransition>
	);
};

interface GraphPopoverContainerProps {
	popperOffset?: [number, number];
}

const GraphPopoverContainer: FunctionComponent<GraphPopoverContainerProps> = ({
	popperOffset,
}) => {
	const [graphState, graphDispatch] = useContext(ForceGraphContext);

	const { popupElement } = graphState;

	const handleClose = () =>
		graphDispatch({ type: SET_POPUP_ELEMENT, payload: null });

	return (
		<GraphPopover
			handleClose={handleClose}
			graphObject={popupElement}
			popperOffSet={popperOffset}
		/>
	);
};

export default GraphPopoverContainer;
