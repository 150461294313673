import EntityFollowButton from './EntityFollowButton';
import { faMap, faUser } from '@fortawesome/free-regular-svg-icons';
import {
	faChevronDown,
	faMapMarker,
	faShareNodes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import FlexContainer from 'common/FlexContainer';
import PaperButton from 'common/PaperButton';
import { PopoverControlsGrid, DropdownIcon } from 'common/Popover';
import RouterLink from 'common/RouterLink';
import Typography from 'common/Typography';
import useLinkTruncater from 'features/navigation/hooks/useLinkTruncater';
import { ViewMode } from 'features/navigation/types/navigationTypes';
import { FunctionComponent, MouseEventHandler } from 'react';
import { createSearchParams } from 'react-router-dom';
import styled from 'styled-components';

const StyledSingleControl = styled.div`
	display: flex;
	padding: ${(p) => p.theme.spacing(1)};
`;

export interface PopoverControlsGridProps {
	toggleDropdown: MouseEventHandler;
	entityHasIdentities: boolean;
	menuOpen: boolean;
	attributeLoadingStatus: 'success' | 'error' | 'loading';
	entityId: number;
	availableViews: ViewMode[];
	entityName: string;
	canEdit: boolean;
}

const iconMap = {
	geospatial: faMap,
	profile: faUser,
	location: faMapMarker,
	graph: faShareNodes,
};

const EntityPopoverPopoverControls: FunctionComponent<
	PopoverControlsGridProps
> = ({
	availableViews,
	entityName,
	toggleDropdown,
	entityHasIdentities,
	menuOpen,
	entityId,
	attributeLoadingStatus,
	canEdit,
}) => {
	const linkBuilder = useLinkTruncater();

	if (entityHasIdentities) {
		return (
			<PopoverControlsGrid>
				<EntityFollowButton entityId={entityId} />
				<FlexContainer style={{ gap: '4px' }}>
					{availableViews.map((v) => (
						<RouterLink
							to={`${linkBuilder(
								'entity',
								entityName
							)}?${createSearchParams({
								view: v,
							})}`}
							key={v}
							style={{ flex: 1 }}
							preserveSearchParams={false}
						>
							<PaperButton
								aria-label={`${v} view`}
								tooltip={`view ${v}`}
								tooltipPlacement="bottom"
							>
								<FontAwesomeIcon icon={iconMap[v]} />
							</PaperButton>
						</RouterLink>
					))}
				</FlexContainer>
				<PaperButton onClick={toggleDropdown} tooltip="toggle drawer">
					<DropdownIcon
						className={clsx(menuOpen && 'popover-menu-open')}
						icon={faChevronDown}
					/>
				</PaperButton>
			</PopoverControlsGrid>
		);
	}

	if (attributeLoadingStatus === 'success') {
		return (
			<StyledSingleControl>
				<PaperButton onClick={toggleDropdown}>
					{canEdit ? 'Choose an identity' : 'View Attributes'}
				</PaperButton>
			</StyledSingleControl>
		);
	}

	if (attributeLoadingStatus === 'error') {
		return (
			<StyledSingleControl>
				<PaperButton onClick={toggleDropdown}>
					<Typography color="error">View errors</Typography>
				</PaperButton>
			</StyledSingleControl>
		);
	}
	// don't display identity selection button until we have some attributes
	// to offer the user
	return null;
};

export default EntityPopoverPopoverControls;
