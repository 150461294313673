import { FunctionComponent } from 'react';
import styled from "styled-components";
import IconButton from '../../../../common/IconButton';
import Typography from '../../../../common/Typography';
import { ModalHeader, HUDModalHeaderText } from './styledComponents';
import { faTimes } from '@fortawesome/free-solid-svg-icons';


import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface ModalHeaderProps {
	handleClose: () => void;
	headingText: string;
}

const StyledSquare = styled.div`
	width: ${(p) => p.theme.spacing(3)};
	height: ${(p) => p.theme.spacing(3)};
	background-color: ${(p) => p.theme.palette.primary.main};
`;

const StyledTypography = styled(Typography)`
	display: inline;
	font-weight: ${(p) => p.theme.typography.fontWeightBold};
`;

const StyledIcon = styled(FontAwesomeIcon)`
	padding-right: ${(p) => p.theme.spacing(1)};
`;

const HUDModalHeader: FunctionComponent<ModalHeaderProps> = ({
	headingText,
	handleClose,
}) => {
	return (
		<ModalHeader>
			<HUDModalHeaderText>
				<StyledSquare/>
				<span style={{paddingLeft: '.75rem'}}>
					<Typography>{headingText}</Typography>
				</span>
			</HUDModalHeaderText>
			<IconButton
				onClick={handleClose}
				icon={faTimes}
				aria-label="close source browser"
			/>
		</ModalHeader>
	);
};

export default HUDModalHeader;
