import { Point } from 'features/viz/types';
import { ReactElement } from 'react';

export interface EventPoint extends Point<Date, number> {
	originalIndices: number[];
	lineIdx: number;
}

interface DrawnEventPoint extends EventPoint {
	drawX: number;
	drawY: number;
}

export interface Line {
	line: EventPoint[];
	xMax: Date;
	xMin: Date;
	yMax: number;
}

// B/c event line chart may be asked to draw any number of lines at once,
// it accepts line definitions as an Array of arrays: each nested array contains the sequence
// of points that make up a single line.
export type Lines = Array<Line>;

// represents a single line that includes drawing coordinates/path information
export interface EventLineArguments {
	path: string;
	line: DrawnEventPoint[];
	// if multiple lines are passed to chart, line-drawing fn
	// needs to know which line is currently being drawn in order to set
	// color/position/event handlers/whatever
	lineIdx: number;
	lineId: string;
}

export interface EventPointDrawFn {
	(point: DrawnEventPoint): ReactElement<any, any>;
}

export interface EventLineDrawFn {
	(lineArgs: EventLineArguments): ReactElement<any, any>;
}

export interface EventCardArgs {
	x: number;
	y: number;
	xValue: string;
	yValue: string;
	visible: boolean;
	drawWidth: number;
	drawHeight: number;
}

export interface EventCardDrawFn {
	(cardArgs: EventCardArgs): ReactElement<any, any> | null;
}

export const eventGroupIntervals = [
	'FullYear',
	'Month',
	'Date',
	'Hours',
	'Minutes',
	'Seconds',
	'Milliseconds',
] as const;

export type TimeInterval = typeof eventGroupIntervals[number];

export type TimeIntervals = TimeInterval[];

export interface AttributeTransformer {
	map?: (a: any) => any;
	aggregate?: (a: any[]) => number;
}

export type Transformers = Record<string, AttributeTransformer>;
