import Button from '../../../common/Button';
import { FormResults } from '../../../common/Form';
import Typography from '../../../common/Typography';
import { useCreateAttributeMutation } from '../../api';
import { EntityActionFormProps } from '../common/commonTypes';
import { renderDerivationSubfields } from '../common/jsxHelpers';
import { StyledFlexContainer, StyledPaper } from '../common/styledComponents';
import {
	createAttrFormDefaults,
	createAttrFormToPayload,
} from './createAttributeHelpers';
import { CreateAttrFormValues } from './createAttributeTypes';
import { FunctionComponent, useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

const quantitySubtypeOptions = [
	{ displayValue: 'None', value: '' },
	{ displayValue: 'Distance', value: 'distance' },
	{ displayValue: 'Duration', value: 'duration' },
	{ displayValue: 'Currency', value: 'currency' },
	{ displayValue: 'Ratio', value: 'ratio' },
	{ displayValue: 'Rate', value: 'rate' },
];

const locationSubtypeOptions = [
	{ displayValue: 'Geography', value: 'geography' },
	{ displayValue: 'None', value: '' },
];

const CreateAttributeForm: FunctionComponent<EntityActionFormProps> = (
	props
) => {
	const formDefaults = createAttrFormDefaults();

	const { handleSubmit, register, formState, watch, resetField } =
		useForm<CreateAttrFormValues>({
			defaultValues: formDefaults,
		});

	// watch type field to determine what to display in
	// subtype menu
	const watchedAttrType = watch('type', formDefaults.type);

	// when type changes we reset the subtype to make sure we don't get a mismatch between
	// type and subtype values.
	useEffect(() => {
		resetField('otherType');
	}, [watchedAttrType, resetField]);

	const { _id: parentEntityId, plural: label } = props;

	const [createAttr, createAttrResults] = useCreateAttributeMutation();

	const onSubmit: SubmitHandler<CreateAttrFormValues> = (vals, e) => {
		e?.preventDefault();
		createAttr({
			body: createAttrFormToPayload(vals, parentEntityId),
		});
	};

	// only present option to choose a subtype if main type is appropriate
	const renderSubtypeSelect = useCallback(() => {
		const shouldRender =
			watchedAttrType === 'quantity' || watchedAttrType === 'location';

		if (!shouldRender) {
			return null;
		}

		const opts =
			watchedAttrType === 'quantity'
				? quantitySubtypeOptions
				: locationSubtypeOptions;

		return (
			<>
				<label htmlFor="otherType">
					<Typography>
						What is the subtype of this attribute?
					</Typography>
				</label>
				<select {...register('otherType')}>
					{opts.map(({ value, displayValue }) => (
						<option value={value} key={value}>
							{displayValue}
						</option>
					))}
				</select>
			</>
		);
	}, [watchedAttrType, register]);

	return (
		<StyledPaper>
			<form onSubmit={handleSubmit(onSubmit)}>
				<StyledFlexContainer flexDirection="column" alignItems="center">
					{renderDerivationSubfields(true, register, formState)}

					<label htmlFor="type">
						<Typography>
							What type of attribute is {label}?
						</Typography>
					</label>
					<select
						{...register('type', {
							required: 'Type is a required field',
						})}
					>
						<option value="identity">Identity</option>
						<option value="relation">Relation</option>
						<option value="category">Category</option>
						<option value="quantity">Quantity</option>
						<option value="event">Event</option>
						<option value="location">Location</option>
						<option value="media">Media</option>
					</select>

					{renderSubtypeSelect()}

					<label htmlFor="default-value">
						<Typography>
							What is the default value for this attribute?
						</Typography>
					</label>
					<input
						type="text"
						{...register('defaultValue')}
						id="default-value"
					/>

					<Button type="submit">Submit</Button>

					<FormResults
						{...createAttrResults}
						validationErrors={formState.errors}
					/>
				</StyledFlexContainer>
			</form>
		</StyledPaper>
	);
};

export default CreateAttributeForm;
