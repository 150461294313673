import MemberPanel from './MemberPanel';
import {
	StyledMemberMgmtHeading,
	StyledHeadingContainer,
} from './styledComponents';
import Typography from 'common/Typography';
import { mergeQueryStates, parseQueryError } from 'features/api/helpers';
import { canEdit as canEditDomain } from 'features/authentication/helpers';
import useActiveAccountData from 'features/ontology/hooks/useActiveAccountData';
import useActiveDomainData from 'features/ontology/hooks/useActiveDomainData';
import { BaseAccount } from 'features/ontology/types/accountTypes';
import { FunctionComponent } from 'react';

const DomainConsole: FunctionComponent = () => {
	const { activeItem: activeAccount, ...accountQueryRest } =
		useActiveAccountData();

	const { activeItem: activeDomain, ...domainQueryRest } =
		useActiveDomainData();

	const canEdit = activeDomain ? canEditDomain(activeDomain) : false;

	const { isError, error, isLoading, isUninitialized } = mergeQueryStates(
		accountQueryRest,
		domainQueryRest
	);

	if (isError && error) {
		return (
			<Typography color="error">
				{parseQueryError(error).message}
			</Typography>
		);
	}

	if (isLoading || isUninitialized) {
		return <Typography>Loading...</Typography>;
	}

	return (
		<>
			<StyledHeadingContainer>
				<StyledMemberMgmtHeading component="h2">
					{activeDomain?.label}
				</StyledMemberMgmtHeading>
			</StyledHeadingContainer>
			{/* OK to cast Account: the 'if' blocks above return early if it's not available */}
			<MemberPanel
				canEdit={canEdit}
				authorityLevel="steward"
				contextLevel="domain"
				domainId={activeDomain?._id}
				accountId={(activeAccount as BaseAccount)._id}
			/>
			<MemberPanel
				canEdit={canEdit}
				authorityLevel="member"
				contextLevel="domain"
				domainId={activeDomain?._id}
				accountId={(activeAccount as BaseAccount)._id}
			/>
		</>
	);
};

export default DomainConsole;
