import Paper from 'common/Paper';
import styled from 'styled-components';

export const ControlButtonWrapper = styled.div<{ displaying: boolean }>`
	display: ${(p) => (p.displaying ? 'block' : 'none')};
	padding: 0;
	margin: 0;
`;

export const StyledLeftDrawerPanel = styled(Paper)`
	min-height: 135px;
	height: 135px;
	padding: ${(p) => p.theme.spacing(1)};
`;

export const StyledLeftDrawerContainer = styled.div`
	display: flex;
	flex-direction: column;
	overflow: auto;
	max-height: calc(
		100vh - ${(p) => p.theme.navHeight * 2 + p.theme.panelGutter * 3}px
	);
	gap: ${(p) => p.theme.spacing(1)};
	scrollbar-width: none;
`;
