import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  body {
    background: ${(p) => p.theme.palette.background.default};
	margin: 0;
	box-sizing: border-box;
  }

  #root {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
  }
`;

export default GlobalStyles;
