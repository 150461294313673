import { groupForAttrSelect } from './helpers';
import { faShareNodes } from '@fortawesome/free-solid-svg-icons';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useAppDispatch } from 'app/hooks';
import FlexContainer from 'common/FlexContainer';
import Heading from 'common/Heading';
import IconButton from 'common/IconButton';
import { List, ListItem, ListItemContent, ListItemControls } from 'common/List';
import Typography from 'common/Typography';
import theme from 'common/theme/theme';
import { pipe } from 'common/utils/functionUtils';
import { setModalType } from 'features/HUD/state/HUDSlice';
import { useGetAttributesQuery } from 'features/api';
import DisplayOnLoad from 'features/api/DisplayOnLoad';
import useEntitySearchParams from 'features/compositeViews/EntityViews/hooks/useEntitySearchParams';
import {
	SortedAttrList,
	pluralizeType,
} from 'features/ontology/helpers/attributeHelpers';
import useActiveEntityData from 'features/ontology/hooks/useActiveEntityData';
import { BaseAttribute } from 'features/ontology/types/attributeTypes';
import { Fragment, FunctionComponent, useEffect } from 'react';
import styled from 'styled-components';

const StyledAttributeListContainer = styled(FlexContainer)`
	max-height: calc(100vh - ${(p) => p.theme.navHeight}px * 2);
	overflow-y: auto;
	margin-left: 10px;
	margin-right: 5px;
`;

const StyledHeading = styled(Heading)`
	padding: ${(p) => p.theme.spacing(0.5)};
	border-bottom: 1px solid ${(p) => p.theme.palette.divider};
	font-weight: ${(p) => p.theme.typography.fontWeightBold};
	text-transform: capitalize;
`;

interface AttrSelectItemProps extends BaseAttribute {
	activeAttrName: string | null;
	onClick: () => void;
}

const AttrSelectItem: FunctionComponent<AttrSelectItemProps> = ({
	_id,
	onClick,
	activeAttrName,
	singular,
	name,
}) => {
	const appDispatch = useAppDispatch();

	const isSelected = activeAttrName === name;

	return (
		<ListItem
			button
			background="default"
			key={_id}
			onClick={onClick}
			aria-current={isSelected}
		>
			<ListItemContent>
				<Typography
					style={{ fontWeight: 'bold' }}
					color={isSelected ? 'primary' : 'darkBaby'}
				>
					{singular}
				</Typography>
			</ListItemContent>
			{isSelected && (
				<ListItemControls>
					<IconButton
						icon={faShareNodes}
						size="xs"
						shape="round"
						tooltip="view lineage"
						aria-label="view lineage"
						onClick={() =>
							appDispatch(setModalType('attributeLineage'))
						}
					/>
				</ListItemControls>
			)}
		</ListItem>
	);
};

const AttributeSelect: FunctionComponent = () => {
	const entitySearchParams = useEntitySearchParams();

	const { activeItem: activeEntity } = useActiveEntityData();

	const activeAttrName = entitySearchParams.getActiveAttributeName();

	const { data: attrData, ...attrLoadState } = useGetAttributesQuery(
		activeEntity ? { entityId: activeEntity._id } : skipToken
	);

	const primaryIdAttr = attrData
		? attrData.find((a) => a.isPrimary === true) ?? null
		: null;

	const makeOnClick = (name: string) => () => {
		entitySearchParams.setActiveAttribute(name, true);
	};

	const grouped = attrData
		? pipe(attrData, groupForAttrSelect)
		: ([] as SortedAttrList);

	useEffect(() => {
		if (primaryIdAttr && !activeAttrName) {
			entitySearchParams.setActiveAttribute(primaryIdAttr.name);
		}
	}, [entitySearchParams, activeAttrName, primaryIdAttr]);

	return (
		<StyledAttributeListContainer flexDirection="column">
			<DisplayOnLoad {...attrLoadState} spinnerDiameter={20}>
				<List style={{ color: theme.palette.darkBaby }}>
					<ListItem
						button
						background="default"
						onClick={() => {
							if (primaryIdAttr) {
								entitySearchParams.setActiveAttribute(
									primaryIdAttr.name
								);
							}
						}}
						// style={{ marginBottom: '16px' }}
					>
						<ListItemContent>
							<Typography
								color={
									primaryIdAttr?.name === activeAttrName
										? 'primary'
										: 'darkBaby'
								}
								style={{
									fontWeight: 'bold',
								}}
							>
								View{' '}
								{activeEntity
									? activeEntity.plural
									: 'individuals'}
							</Typography>
						</ListItemContent>
					</ListItem>

					{grouped.map(({ type, attrs }) => (
						<Fragment key={type}>
							<StyledHeading
								style={{ color: theme.palette.darkBaby }}
								component="h6"
							>
								{pluralizeType(type)}
							</StyledHeading>
							{attrs.map((a) => (
								<AttrSelectItem
									{...a}
									onClick={makeOnClick(a.name)}
									activeAttrName={activeAttrName}
									key={a._id}
								/>
							))}
						</Fragment>
					))}
				</List>
			</DisplayOnLoad>
		</StyledAttributeListContainer>
	);
};

export default AttributeSelect;
