import { BaseAccount } from '../../ontology/types/accountTypes';
import {
	CatalogSummaries,
	DataSourceSummaries,
	LiveDataSets,
} from '../types/dataTypes';
import {
	isLiveDataSet,
	SelectedSourceData,
	SourceBrowserDisplayMode,
} from '../types/uiTypes';
import CardDisplay from './CardDisplay';
import DataPreview from './DataPreview';
import FormDisplay from './FormDisplay';
import { FunctionComponent } from 'react';
import styled from 'styled-components';

interface ContextualDisplayProps {
	setOnDisplay: (selectable: SelectedSourceData) => void;
	onDisplay: SelectedSourceData;
	displayMode: SourceBrowserDisplayMode;
	activeItem: BaseAccount | null;
	closeForm: () => void;
	cardData: DataSourceSummaries | CatalogSummaries | LiveDataSets | null;
}

const StyledContextualDisplay = styled.section`
	padding: ${(p) => p.theme.spacing(1)};
	position: relative;
`;

const ContextualDisplay: FunctionComponent<ContextualDisplayProps> = ({
	setOnDisplay,
	onDisplay,
	displayMode,
	closeForm,
	activeItem,
	cardData,
}) => {
	const renderDisplay = () => {
		if (displayMode === 'form') {
			return (
				<FormDisplay
					handleBackRequest={closeForm}
					accountId={activeItem ? activeItem._id : null}
				/>
			);
		}

		if (isLiveDataSet(onDisplay)) {
			return <DataPreview onDisplay={onDisplay} />;
		}

		return (
			<CardDisplay
				setter={(selectable: SelectedSourceData) =>
					setOnDisplay(selectable)
				}
				cardData={cardData}
			/>
		);
	};

	return <StyledContextualDisplay>{renderDisplay()}</StyledContextualDisplay>;
};

export default ContextualDisplay;
