import { selectNavItemName } from '../helpers';
import { SourceListItemProps } from '../types/uiTypes';
import {
	useState,
	useEffect,
	MouseEventHandler,
	useCallback,
	useMemo,
} from 'react';

const useSourceListItem = ({
	onDisplay,
	currentlySelected,
	setter,
}: SourceListItemProps) => {
	const [open, setOpen] = useState(false);

	const [active, setActive] = useState(false);

	const [queryShouldRun, setQueryShouldRun] = useState<boolean>(false);

	// handle opening/closing the accordion effect
	useEffect(() => {
		if (currentlySelected === onDisplay) {
			setOpen(true);
			setActive(true);
		} else {
			setActive(false);
		}
	}, [currentlySelected, onDisplay]);

	// the first time a nav item is clicked, run the query to fetch its
	// children.  Query should remain active thereafter; if we pass skipToken
	// back in when item becomes inactive, the query returns to uninitialized
	// state.
	useEffect(() => {
		if (active && !queryShouldRun) {
			setQueryShouldRun(true);
		}
	}, [queryShouldRun, active]);

	const onClick = useCallback<MouseEventHandler>(
		(e) => {
			e.stopPropagation();
			setter(onDisplay);
			setOpen((prev) => !prev);
		},
		[setter, onDisplay]
	);

	// const onClick: MouseEventHandler = (e) => {
	// 	e.stopPropagation();
	// 	setter(onDisplay);
	// 	// setOpen((prev) => !prev);
	// 	// setOpen(true);
	// };
	const itemName = useMemo(() => selectNavItemName(onDisplay), [onDisplay]);

	return {
		queryShouldRun,
		open,
		active,
		onClick,
		itemName,
	};
};

export default useSourceListItem;
