import FlexContainer from 'common/FlexContainer';
import { List, ListItem } from 'common/List';
import { ListItemContent } from 'common/List';
import Spinner from 'common/Spinner';
import Typography from 'common/Typography';
import { useGetEntitiesQuery } from 'features/api';
import { parseQueryError } from 'features/api/helpers';
import { BaseEntity } from 'features/ontology/types/entityTypes';
import { FunctionComponent } from 'react';
import styled from 'styled-components';

interface DomainDropdownProps {
	domainId: number;
	domainLabel: string;
	actionSubmenu: any;
	setActionSubmenu: any;
}

export const ListItemAvatar = styled.div`
	flex: 0 0 5%;
	margin-left: 20px;
`;

const StyledSquare = styled.div`
	width: ${(p) => p.theme.spacing(1)};
	height: ${(p) => p.theme.spacing(1)};
	background-color: ${(p) => p.theme.palette.primary.main};
`;

const DomainPopoverDropdown: FunctionComponent<DomainDropdownProps> = ({
	domainId,
}) => {
	const {
		isLoading,
		isError,
		error,
		data: entities,
	} = useGetEntitiesQuery({ domainId });

	if (isLoading) {
		return (
			<FlexContainer justifyContent="center">
				<Spinner />
			</FlexContainer>
		);
	}

	if (isError && error) {
		return (
			<Typography color="error">
				{parseQueryError(error).message}
			</Typography>
		);
	}

	// cast type of 'entities' since compiler doesn't know about mutually-exclusive
	// query states.
	return (
		<div style={{ overflow: 'auto', maxHeight: '300px' }}>
			<List>
				{(entities as BaseEntity[]).map((e) => (
					<ListItem key={e._id} style={{ fontWeight: 'bold' }}>
						<ListItemAvatar>
							<StyledSquare />
						</ListItemAvatar>
						<ListItemContent>{e.plural}</ListItemContent>
					</ListItem>
				))}
			</List>
		</div>
	);
};

export default DomainPopoverDropdown;
