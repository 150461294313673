import {
	RemoveImportFormValues,
	RemoveImportPayload,
} from './removeImportTypes';

export const removeImportFormToPayload = (
	formValues: RemoveImportFormValues
): RemoveImportPayload => ({
	question: formValues.question,
});
