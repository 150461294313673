import { BreadcrumbLink } from '../components/Breadcrumbs/BreadcrumbBase';
import useOntologyParams from './useOntologyParams';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { isAppError, isEmpty } from 'common/utils/typeGuards';
import {
	useGetAccountsQuery,
	useGetDomainsQuery,
	useGetEntitiesQuery,
} from 'features/api';
import { mergeQueryStates, QueryStateTracker } from 'features/api/helpers';
import useUserID from 'features/authentication/hooks/useUserID';
import { ContextLevels } from 'features/navigation/types/navigationContextTypes';
import useActiveAccountData from 'features/ontology/hooks/useActiveAccountData';
import useActiveDomainData from 'features/ontology/hooks/useActiveDomainData';
import { useMemo } from 'react';

const useBreadcrumbQuery = (kind: ContextLevels) => {
	const { linkBuilder, ...urlParams } = useOntologyParams(kind);

	const userId = useUserID();

	// TODO: there is a case maybe for making these all a single hook.
	const { activeItem: activeAccount } = useActiveAccountData();

	const { activeItem: activeDomain } = useActiveDomainData();

	const shouldQueryAccounts = kind === 'account' && !isEmpty(userId);

	//  NB: These queries look 'heavier' than they are. Because the 'useActive*'
	// hooks above query for all relevant objects then filter (instead of hitting an endpoint
	// for a single object), the data for the logic below is going to end up in the cache one way
	// or another.
	const accounts = useGetAccountsQuery(
		shouldQueryAccounts ? { userId } : skipToken
	);

	const shouldQueryDomains = kind === 'domain' && !!activeAccount;

	const domains = useGetDomainsQuery(
		shouldQueryDomains ? { accountId: activeAccount._id } : skipToken
	);

	const shouldQueryEntities = kind === 'entity' && !!activeDomain;

	const entities = useGetEntitiesQuery(
		shouldQueryEntities ? { domainId: activeDomain._id } : skipToken
	);

	const { data, ...loadingState } =
		kind === 'account' ? accounts : kind === 'domain' ? domains : entities;

	const linkData: BreadcrumbLink[] = useMemo(() => {
		if (data) {
			const builder = isAppError(linkBuilder) ? () => '/' : linkBuilder;

			return data.map((d) => ({
				label: d._object === 'Entity' ? d.plural : d.label,
				url: builder(d.name),
				name: d.name,
			}));
		}

		return [];
	}, [data, linkBuilder]);

	//  by expressing errors generated outside of RTK Query in the same format, we can programatically
	// combine outside error/success states with query errors.
	const synchronousLoadingState: QueryStateTracker = {
		isLoading: false,
		isError: isAppError(linkBuilder),
		error: isAppError(linkBuilder)
			? { message: linkBuilder.message }
			: undefined,
		isUninitialized: false,
		isSuccess: !isAppError(linkBuilder),
	};

	return {
		...mergeQueryStates(loadingState, synchronousLoadingState),
		links: linkData,
		// cast here b/c if there's a problem with URLparams, linkBuilder will be an error anyway
		activeName: urlParams[kind] as string,
	};
};

export default useBreadcrumbQuery;
