import {
	AttributeBaseType,
	AttributeSubtype,
	BaseAttribute,
} from '../ontology/types/attributeTypes';
import { IAttributeMeta } from './dataPreparationTypes';
import { resolveAttrType } from 'features/ontology/typeGuards/attributeGuards';

class AttributeMeta implements IAttributeMeta {
	factCount: number = 0;
	futuremodelName: string;
	referenceAttr: BaseAttribute;
	nullAt: Set<number> = new Set<number>();
	missingAt: Set<number> = new Set<number>();
	isInitialized: boolean = false;
	type: AttributeBaseType;
	subtype: AttributeSubtype | null;
	resolvedType: AttributeBaseType | AttributeSubtype;

	ingest(value: any, idx: number) {
		if (this.isInitialized === false) {
			this.isInitialized = true;
		}

		if (value === undefined) {
			this.missingAt.add(idx);
			return;
		}

		if (value === null) {
			this.nullAt.add(idx);
		}

		this.factCount++;
	}

	//  Unused by this most-general class
	finalize() {}

	canUse(idx: number) {
		if (this.nullAt.has(idx) || this.missingAt.has(idx)) {
			return false;
		}

		return true;
	}

	constructor(attr: BaseAttribute) {
		this.futuremodelName = attr.name;
		this.referenceAttr = attr;
		this.type = attr.type;
		this.subtype = attr.subtype ?? null;
		this.resolvedType = resolveAttrType(attr);
	}
}

export default AttributeMeta;
