import { AttributeBaseType } from '../../ontology/types/attributeTypes';
import { RestrictionOperatorDescriptor } from './commonTypes';
import { RegisterOptions } from 'react-hook-form';

const isVowel = (char: string): boolean =>
	['a', 'A', 'e', 'E', 'i', 'I', 'o', 'O', 'u', 'U', 'y', 'Y'].includes(char);

const selectArticle = (str: string) => (isVowel(str[0]) ? 'an' : 'a');

export const withArticle = (noun: string) => `${selectArticle(noun)} ${noun}`;

export const nullStringToNull = <T extends string>(value: T) =>
	(value === 'null' ? null : value) as Exclude<T, 'null'>;

export const nullishToNullString = <T>(value: T) => value ?? 'null';

export const nullishToEmptyString = <T>(value: T) => value ?? '';

export const emptyStringToNull = <T extends string>(value: T) =>
	value === '' ? null : value;

export const zeroToNull = (value: number) => (value === 0 ? null : value);

export const boolStringToBool = (str: 'true' | 'false') =>
	str === 'true' ? true : false;

export const createBasicValidation = (
	fieldName: string
): RegisterOptions<any> => ({
	required: {
		value: true,
		message: `${fieldName} is a required field`,
	},
	maxLength: {
		value: 200,
		message: `A maximum of 200 characters is allowed for ${fieldName}`,
	},
});

// for fields that may have form value of 'null' to indicate an unpopulated
// state, but MUST have a user-defined value before submission
export const createNonNullStringValidation = (
	fieldName: string
): RegisterOptions<any> => {
	const baseOptions = createBasicValidation(fieldName);

	return Object.assign(baseOptions, {
		validate: (v: string | null) =>
			v === 'null'
				? `You must enter a custom value for ${fieldName}`
				: true,
	});
};

export const resetIdentifyingFields =
	(requiresIdentifiers: boolean) =>
	<T extends { singular: string; plural: string; definition: string | null }>(
		baseObject: T
	) => {
		if (!requiresIdentifiers) {
			return {
				...baseObject,
				singular: null,
				plural: null,
				definition: null,
			};
		}

		return baseObject;
	};

export const resetDataValue =
	(requiresDataValue: boolean) =>
	<T extends { otherValue: string; otherId: number }>(baseObject: T) => {
		if (requiresDataValue) {
			return { ...baseObject, otherId: null };
		}

		return {
			...baseObject,
			otherValue: null,
		};
	};

export const stripFormOnlyProps = <T extends { usingStaticReference: any }>(
	obj: T
) => {
	// eslint-disable-next-line unused-imports/no-unused-vars
	const { usingStaticReference, ...rest } = obj;

	return rest;
};

export const resetRestrictionReference =
	(requiresReferenceValue: boolean) =>
	<
		T extends {
			restrictionId: number;
			restrictionValue: string;
		}
	>(
		baseObject: T
	) => {
		if (requiresReferenceValue) {
			return { ...baseObject, restrictionId: null };
		}

		return {
			...baseObject,
			restrictionValue: null,
		};
	};

export const resetRelationCount =
	(requiresRelationCount: boolean) =>
	<
		T extends {
			nRelationCount: number;
		}
	>(
		baseObject: T
	) => {
		if (requiresRelationCount) {
			return baseObject;
		}

		return {
			...baseObject,
			nRelationCount: null,
		};
	};

export const restrictIdentityOperators: readonly RestrictionOperatorDescriptor[] =
	[
		{
			operator: 'is',
			displayValue: 'is',
		},
		{
			operator: 'contains',
			displayValue: 'contains',
		},
		{
			operator: 'startsWith',
			displayValue: 'starts with',
		},
		{
			operator: 'endsWith',
			displayValue: 'ends with',
		},
		{
			operator: 'isMissing',
			displayValue: 'is missing',
		},
		{
			operator: 'isNull',
			displayValue: 'is null',
		},
		{
			operator: 'isNot',
			displayValue: 'is not',
		},
		{
			operator: 'doesNotContain',
			displayValue: 'does not contain',
		},
		{
			operator: 'doesNotStartWith',
			displayValue: 'does not start with',
		},
		{
			operator: 'doesNotEndWith',
			displayValue: 'does not end with',
		},
		{
			operator: 'isNotMissing',
			displayValue: 'is not missing',
		},
		{
			operator: 'isNotNull',
			displayValue: 'is not null',
		},
	] as const;

// 'Category' and 'Identity' share the same operators. Create a new name for
// consistency.
export const restrictCategoryOperators: readonly RestrictionOperatorDescriptor[] =
	restrictIdentityOperators;

export const restrictQuantityOperators: readonly RestrictionOperatorDescriptor[] =
	[
		{
			operator: 'isEqualTo',
			displayValue: 'is equal to',
		},
		{
			operator: 'isNotEqualTo',
			displayValue: 'is not equal to',
		},
		{
			operator: 'isGreaterThan',
			displayValue: 'is greater than',
		},
		{
			operator: 'isGreaterThanOrEqualTo',
			displayValue: 'is greater than or equal to',
		},
		{
			operator: 'isLessThan',
			displayValue: 'is less than',
		},
		{
			operator: 'isLessThanOrEqualTo',
			displayValue: 'is less than or equal to',
		},
		{
			operator: 'isBetween',
			displayValue: 'is between',
		},
		{
			operator: 'isOutside',
			displayValue: 'is outside',
		},
		{
			operator: 'isMissing',
			displayValue: 'is missing',
		},
		{
			operator: 'isNotMissing',
			displayValue: 'is not missing',
		},
	] as const;

export const restrictRelationOperators: readonly RestrictionOperatorDescriptor[] =
	[
		{
			operator: 'isEqualTo',
			displayValue: 'is equal to',
		},
		{
			operator: 'isNotEqualTo',
			displayValue: 'is not equal to',
		},
		{
			operator: 'isGreaterThan',
			displayValue: 'is greater than',
		},
		{
			operator: 'isGreaterThanOrEqualTo',
			displayValue: 'is greater than or equal to',
		},
		{
			operator: 'isLessThan',
			displayValue: 'is less than',
		},
		{
			operator: 'isLessThanOrEqualTo',
			displayValue: 'is less than or equal to',
		},
		{
			operator: 'isBetween',
			displayValue: 'is between',
		},
		{
			operator: 'isOutside',
			displayValue: 'is outside',
		},
		{
			operator: 'isMissing',
			displayValue: 'is missing',
		},
		{
			operator: 'isNotMissing',
			displayValue: 'is not missing',
		},
	] as const;

export const getRestrictionOperators = (attrType: AttributeBaseType) => {
	switch (attrType) {
		case 'identity':
			return restrictIdentityOperators;
		case 'category':
			return restrictCategoryOperators;
		case 'quantity':
			return restrictQuantityOperators;
		case 'relation':
			return restrictRelationOperators;
		default:
			return [];
	}
};
