import {
	StyledInput,
	StyledInputContainer,
	StyledLabel,
} from './styledComponents';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import IconButton from 'common/IconButton';
import Typography from 'common/Typography';
import { forkRef } from 'common/utils/reactUtils';
import { CSSProperties, forwardRef, MouseEventHandler, useRef } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

interface TextboxOverrides {
	root?: CSSProperties;
	label?: CSSProperties;
	button?: CSSProperties;
	container?: CSSProperties;
}
export interface SubtleTextboxProps extends UseFormRegisterReturn {
	isDirty: boolean;
	label: string;
	showLabel?: boolean;
	onReset?: () => void;
	error?: string;
	overrides?: TextboxOverrides;
}

const SubtleTextbox = forwardRef<HTMLInputElement, SubtleTextboxProps>(
	(
		{
			overrides,
			isDirty,
			onReset,
			label,
			showLabel = false,
			error,
			...rest
		},
		forwardedRef
	) => {
		const resetterRef = useRef<HTMLInputElement>();

		const setRefs = forkRef(forwardedRef, resetterRef);

		const onContainerClick: MouseEventHandler<HTMLDivElement> = () => {
			if (resetterRef.current) {
				resetterRef.current.focus();
			}
		};

		return (
			<div>
				<StyledInputContainer
					justifyContent="space-between"
					alignItems="center"
					onClick={onContainerClick}
					data-testid="subtle-input-container"
					style={{ ...overrides?.container, position: 'relative' }}
				>
					{showLabel && (
						<StyledLabel
							htmlFor={`${label}-subtle-input`}
							style={overrides?.label}
						>
							{`${label}:`}
						</StyledLabel>
					)}
					<StyledInput
						aria-label={label}
						spellCheck={false}
						style={overrides?.root}
						id={`${label}-subtle-input`}
						className={clsx(isDirty && 'is-dirty')}
						aria-invalid={Boolean(error)}
						aria-errormessage={`${label}-error-message`}
						// @ts-ignore
						ref={setRefs}
						{...rest}
					/>
					{isDirty && (
						<div>
							<IconButton
								style={{
									...overrides?.button,
								}}
								fillColor="error"
								size="xs"
								icon={faTimes}
								aria-label={`reset ${label} field`}
								onClick={(e) => {
									e.preventDefault();
									onReset && onReset();
								}}
							/>
						</div>
					)}
				</StyledInputContainer>
				<div>
					{error && (
						<Typography id={`${label}-error-message`} color="error">
							{error}
						</Typography>
					)}
				</div>
			</div>
		);
	}
);

export default SubtleTextbox;
