import { UserContext } from '../../authentication/types/userTypes';
import { BaseAttribute, ResolvedAttributeType } from './attributeTypes';
import { BaseDomain } from './domainTypes';
import { LINEAGE_GRAPH } from 'features/forceGraphs/components/AttributeLineageGraph/CONSTANTS';
import { INDIVIDUALS_TABLE } from 'features/viz/CONSTANTS';
import { VizKind, VizMap } from 'features/viz/types';
import { FunctionComponent } from 'react';

const entityViewFlags = [
	'hasIdentities',
	'hasRelations',
	'hasQuantities',
	'hasCategories',
	'hasLocations',
	'hasEvents',
	'hasImages',
	'hasVideos',
	'hasStart',
	'hasEnd',
] as const;

export type EntityViewFlag = typeof entityViewFlags[number];

export type EntityViewFlags = { [K in EntityViewFlag]: boolean };
/**
 * Base type for a single entity
 */
export interface BaseEntity extends EntityViewFlags {
	_id: number;
	_object: 'Entity';
	definition: null | string;
	name: string;
	plural: string;
	singular: string;
	context: string;
	userContext: UserContext;
	// 'domain' is _id of entity's parent domain
	domain: number;
}

// types for mapping data derived from entity's attributes to particular visualizations
export type EntityVizKind =
	| VizKind
	| typeof LINEAGE_GRAPH
	| typeof INDIVIDUALS_TABLE;

export type AttrTypeCounts = Partial<Record<ResolvedAttributeType, number>>;

export interface EntityVizMapper {
	(counts: AttrTypeCounts, activeAttribute: BaseAttribute): EntityVizKind[];
}

export interface EntityVizMap extends VizMap {
	[LINEAGE_GRAPH]: FunctionComponent;
}

// network data types
export interface GetEntityParams {
	entityId: BaseEntity['_id'];
}

export interface GetEntitiesParams {
	domainId: BaseDomain['_id'];
}

export type EntitiesDataResponse = BaseEntity[];

export interface EntityDataResponse {
	meta: {
		status: number;
	};
	response: BaseEntity;
}

export interface CreateEntityParams {
	singular: string;
	plural: string;
	domainId: number;
}

export interface CreateEntityDataResponse extends BaseEntity {}

export interface FollowEntityParams {
	entityId: number;
}

export interface FollowEntityResponse {}

export interface UnfollowEntityParams {
	entityId: number;
}

export interface UnfollowEntityResponse {}

export interface IdentifyEntityParams {
	entityId: number;
	attributeId: number;
}

export interface IdentifyEntityResponse extends EntityDataResponse {}

export interface UpdateEntityParams {
	entityId: number;
	body: {
		singular: string;
		plural: string;
		definition: string | null;
	};
}

export interface UpdateEntityResponse {}

export interface DeleteImportedEntityParams {
	entityId: number;
	domainId: number;
	isImport: true;
}

export interface DeleteNormalEntityParams {
	entityId: number;
	isImport: false;
}

export type DeleteEntityParams =
	| DeleteImportedEntityParams
	| DeleteNormalEntityParams;

export const isDeleteImportParams = (
	p: DeleteEntityParams
): p is DeleteImportedEntityParams => p.isImport;

export interface DeleteEntityResponse {}

export interface ImportEntityParams {
	entityId: number;
	domainId: number;
}

export interface ImportEntityResponse {}

export interface GetIndividualsParams {
	entityId: number;
}

export type GetIndividualsResponse = Record<string, any>[];
