import { GraphMargins } from './types';

export const QTY_HISTO = 'quantity-histogram';
export const QTY_SCATTERPLOT = 'quantity-scatterplot';
export const CATEGORY_BAR_CHART = 'category-bar-chart';
export const EVENT_LINE_CHART = 'event-line-chart';
export const INDIVIDUALS_TABLE = 'individuals-table';

export const defaultGraphMargins: Required<GraphMargins> = {
	top: 8,
	right: 8,
	bottom: 24,
	left: 32,
};
