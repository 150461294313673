import FlexContainer from '../FlexContainer';
import { PopoverImage as PopoverImageBase } from './styledComponents';
import { FunctionComponent } from 'react';

const PopoverImage: FunctionComponent = () => {
	return (
		<FlexContainer justifyContent="center">
			<PopoverImageBase />
		</FlexContainer>
	);
};

export default PopoverImage;
