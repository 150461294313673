// TODO: extend to include deeper levels
export type ContextLevels = 'account' | 'domain' | 'entity' | 'attribute';

export const contextLadder = [
	'account',
	'domain',
	'entity',
	'attribute',
] as const;

export type ContextParamLadder = string[];

export type ContextLadder = typeof contextLadder[number][];

export enum ContextLadderMap {
	'account',
	'domain',
	'entity',
	'attribute',
}

export interface ContextParams extends Record<string, any> {
	account?: string;
	domain?: string;
	entity?: string;
	attribute?: string;
	individual?: string;
}

export interface ContextLinkBuilder {
	(targetCtxLevel: ContextLadder[number], value: string): string;
}
