import {
	AccountAuthorityLevel,
	ContextLevel,
	DomainAuthorityLevel,
} from '../types/MemberManagementTypes';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
	useGetAccountAdminsQuery,
	useGetAccountGovernorsQuery,
	useGetAccountMembersQuery,
	useGetDomainMembersQuery,
	useGetDomainStewardsQuery,
} from 'features/api';

const selectDomainQueryHook = (authorityLevel: DomainAuthorityLevel) =>
	authorityLevel === 'steward'
		? useGetDomainStewardsQuery
		: useGetDomainMembersQuery;

const selectAccountQueryHook = (authorityLevel: AccountAuthorityLevel) => {
	switch (authorityLevel) {
		case 'admin':
			return useGetAccountAdminsQuery;
		case 'governor':
			return useGetAccountGovernorsQuery;
		case 'member':
			return useGetAccountMembersQuery;
	}
};

const useMemberQueryHook = (
	contextLevel: ContextLevel,
	authorityLevel: DomainAuthorityLevel | AccountAuthorityLevel,
	objectId?: number
) => {
	if (contextLevel === 'account') {
		return selectAccountQueryHook(authorityLevel as AccountAuthorityLevel)(
			objectId
				? {
						accountId: objectId,
				  }
				: skipToken
		);
	}

	return selectDomainQueryHook(authorityLevel as DomainAuthorityLevel)(
		objectId
			? {
					domainId: objectId,
			  }
			: skipToken
	);
};

export default useMemberQueryHook;
