import {
	AttributeSubtype,
	AttributeTypeData,
	CategoryAttribute,
	CategoryTypeData,
	EventTypeData,
	FunctionalRelationTypeData,
	IdentityAttribute,
	IdentitySubtype,
	IdentityTypeData,
	LatLongTypeData,
	LocationTypeData,
	PrimaryIdentityAttribute,
	QuantityAttribute,
	QuantityTypeData,
	RelationSubtype,
	RelationTypeData,
	ResolvedAttributeType,
} from '../types/attributeTypes';

// type relation
export const isRelation = (attr: AttributeTypeData): attr is RelationTypeData =>
	attr.type === 'relation';

export const isFunctionalRelation = (
	attr: AttributeTypeData
): attr is FunctionalRelationTypeData =>
	isRelation(attr) && attr.isFunctional === true;

export const resolveRelationSubtype = (
	attr: RelationTypeData
): RelationSubtype => {
	if (isFunctionalRelation(attr)) {
		return 'functional';
	}

	return 'nonFunctional';
};

// type location
export const isLocation = (attr: AttributeTypeData): attr is LocationTypeData =>
	attr.type === 'location';

export const isLatLong = (attr: AttributeTypeData): attr is LatLongTypeData =>
	isLocation(attr) && attr.subtype === 'latlong';

export const resolveLocationSubtype = (
	attr: LocationTypeData
): 'location' | AttributeSubtype => {
	const { subtype } = attr;

	switch (subtype) {
		case 'latlong':
			return 'latlong';
		default:
			return 'location';
	}
};

// type identity
export const isIdentity = (a: AttributeTypeData): a is IdentityAttribute =>
	a.type === 'identity';

export const isPrimaryIdentity = (
	a: AttributeTypeData
): a is PrimaryIdentityAttribute => a.type === 'identity' && a.isPrimary;

export const resolveIdentitySubtype = (a: IdentityTypeData): IdentitySubtype =>
	isPrimaryIdentity(a) ? 'primaryIdentity' : 'secondaryIdentity';

// type quantity
export const isQuantity = (a: AttributeTypeData): a is QuantityAttribute =>
	a.type === 'quantity';

export const resolveQuantitySubtype = (a: QuantityTypeData) => a.type;

// type category
export const isCategory = (a: AttributeTypeData): a is CategoryAttribute =>
	a.type === 'category';

export const resolveCategorySubtype = (a: CategoryTypeData) => a.type;

// type ISODateString (string data format of 'event')
// NB: There will be many attributes of type 'event' that are not composite,
// and not of subtype 'ISODateString'.  We do not visualize anything with them at
// this time.
export const isEvent = (a: AttributeTypeData): a is EventTypeData =>
	a.type === 'event';

export const resolveEventSubtype = (a: EventTypeData) =>
	//  if all of below conditions aren't met, we can't process the data
	// as ISOstrings fit for visualization, so default to the less-specific 'event' type.
	a.subtype === 'ISODateString' ? a.subtype : a.type;

// narrow by type, then subtype, and default subtype to base type if no
// subtype is matched
export const resolveAttrType = (
	attr: AttributeTypeData
): ResolvedAttributeType => {
	if (isIdentity(attr)) {
		return resolveIdentitySubtype(attr);
	}
	if (isEvent(attr)) {
		return resolveEventSubtype(attr);
	}

	if (isLocation(attr)) {
		return resolveLocationSubtype(attr);
	}

	if (isRelation(attr)) {
		return resolveRelationSubtype(attr);
	}

	if (isQuantity(attr)) {
		return resolveQuantitySubtype(attr);
	}

	if (isCategory(attr)) {
		return resolveCategorySubtype(attr);
	}

	return attr.type;
};
