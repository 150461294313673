import { ControlButtonWrapper } from '../styledComponents';
import { faFilterCircleXmark } from '@fortawesome/free-solid-svg-icons';
import IconButton from 'common/IconButton';
import useEntitySearchParams from 'features/compositeViews/EntityViews/hooks/useEntitySearchParams';
import { FunctionComponent } from 'react';

const ClearFiltersButton: FunctionComponent = () => {
	const { clearAllAttributeFilters, getAllFilters } = useEntitySearchParams();

	const hasFilters = getAllFilters().length > 0;

	return (
		<ControlButtonWrapper displaying={hasFilters}>
			<IconButton
				icon={faFilterCircleXmark}
				onClick={() => clearAllAttributeFilters()}
				aria-label="clear active filters"
				key="clear-filters"
				size="sm"
				tooltip="clear filters"
				showTooltip={true}
				tooltipPlacement="left"
			/>
		</ControlButtonWrapper>
	);
};

export default ClearFiltersButton;
