import { Tagged } from '../../../common/utils/typeUtils';
import { AppError } from '../../errorHandling/types/errorTypes';
import { DateTime } from '../../ontology/types/commonTypes';
import { JWT } from './tokenTypes';

/**
 * Base type for a single user's profile files
 */
export interface BaseUser {
	_links: {
		followed: string;
		followers: string;
		self: string;
	};
	apiPath: string;
	avatar: {
		medium: UserGravatarData;
		small: UserGravatarData;
		thumb: UserGravatarData;
		tiny: UserGravatarData;
	} | null;
	department: null | string;
	defaultAccount: string;
	doNotMail: boolean;
	_id: number;
	isInternal: boolean;
	jobTitle: null | string;
	firstName: string;
	lastName: string;
	lastSeen: DateTime;
	locale: null | string;
	location: null | string;
	social: {
		facebookId: string;
		instagramId: string;
		linkedin: {
			username: string | null | undefined;
			oauthTokenExp: number | null;
		};
	};
	tosAccepted: boolean;
	isOnboarded: boolean;
	memberSince: DateTime;
	invitesRemaining: number;
	stats: {
		acceptedAnnotations: number;
		attributesCreated: number;
		domainsCreated: number;
		entitiesCreated: number;
		entitiesImported: number;
		followers: number;
		following: number;
		metricsCreated: number;
		unreviewedAnnotations: number;
		verifiedAnnotations: number;
	};
	_object: 'User';
	username: string;
	userContext: {
		excludedPermissions: [];
		interactions: {
			following: boolean;
			pyong: boolean;
		};
		iqByAction: {
			editMetadata: {
				primary: {
					applicable: boolean;
					base: number;
					multiplier: number;
				};
			};
		};
		permissions: [];
		relationships: {
			pinnedRole: null;
		};
	};
	emailConfirmed: boolean;
}

export interface UserGravatarData {
	bounding_box: {
		height: number;
		width: number;
	};
	url: string;
}

export const AUTHENTICATED_USER_TAG = '__authenticatedUser__';

/**
 * Shape of user in redux store when user is logged-in
 */
export interface AuthenticatedUser extends Tagged, BaseUser {
	__tag: typeof AUTHENTICATED_USER_TAG;
}

export const UNAUTHENTICATED_USER_TAG = '__unauthenticatedUser__';

/**
 * Shape of redux user slice when no user is logged in
 */
export interface UnauthenticatedUser extends Tagged {
	__tag: typeof UNAUTHENTICATED_USER_TAG;
}

/**
 * Represents every possible state of the 'user' slice in Redux store:
 * either authenticated or unauthenticated.
 */
export type User = AuthenticatedUser | UnauthenticatedUser;

export const isAuthenticatedUser = (u: User): u is AuthenticatedUser =>
	u.__tag === AUTHENTICATED_USER_TAG;

/**
 * JSON payload sent to server on a login or signup attempts
 */
export interface UserAuthCredentials {
	username: string;
	password: string;
}

/**
 * Payload passed to mutation query to retrieve a token on signup or login
 */
export interface AuthenticateUserParams {
	kind: 'signup' | 'login';
	credentials: UserAuthCredentials;
}

/**
 * Payload to request user's profile information
 */
export interface GetUserParams {
	userId: number;
}

/**
 * JSON payload of successful query for user profile files
 */
export interface UserDataResponse {
	meta: {
		status: number;
	};

	response: BaseUser;
}

export interface GetUsersParams {}

/**Raw API response shape.  RTK Query endpoing takes care of extracting 'items' */
export interface GetUsersFullResponse {
	items: BaseUser[];
}

/**API response that code will actually receive from RTK Query, due to 'transformResponse'
 * setup for endpoing.
 */
export type GetUsersResponse = BaseUser[];

/**
 * When application receives user files as a response to a successful authentication request,
 * convert user files from type received from server
 * to type used in client application.
 */
export const userResponseToAuthenticatedUser = (
	res: UserDataResponse
): AuthenticatedUser | AppError => {
	// TODO: might be a good idea to do some validation here, eventually.
	return {
		__tag: AUTHENTICATED_USER_TAG,
		...res.response,
	};
};

// export type UpdateUserParams = Omit<AuthenticatedUser, '__tag'>;
export interface UpdateUserParams {
	userId: number;
	body: Omit<AuthenticatedUser, '__tag'>;
}

export type UpdateUserDataResponse = UserDataResponse;

/**
 * Grouping of properties that define the relationship between the
 * current user and a given object.
 */
export interface UserContext {
	interactions: {
		following: boolean;
	};

	permissions: { admin: boolean; edit: boolean; view: boolean };
	relationships: {};
}

export interface Contextualized {
	userContext: UserContext;
}

export const userIsFollowing = (object: Contextualized) =>
	object.userContext.interactions.following;

export interface RequestPWResetParams {
	email: string;
}
export interface RequestPWResetResponse {}

export interface ResetPasswordResponse {}

export interface ResetPasswordParams {
	password: string;
	resetToken: string;
}

export interface RegisterUserResponse extends BaseUser {
	token: JWT;
}

export interface RegisterUserParams {
	firstName: string;
	lastName: string;
	username: string;
	password: string;
	registrationToken: string;
}

export interface InviteUserResponse {}

export interface InviteUserParams {
	email: string;
}
