import Button from '../../../common/Button';
import FlexContainer from '../../../common/FlexContainer';
import Paper from '../../../common/Paper';
import styled from 'styled-components';

export const StyledFlexContainer = styled(FlexContainer)`
	gap: ${(p) => p.theme.spacing(2)};
	max-height: 500px;
	overflow-y: auto;
`;

export const StyledPaper = styled(Paper)`
	padding: ${(p) => p.theme.spacing(1)};
	min-width: 400px;
`;

export const StyledTextArea = styled.textarea`
	width: 80%;
	min-height: 100px;
	font-family: ${(p) => p.theme.typography.body1.fontFamily};
	font-size: ${(p) => p.theme.typography.body1.fontSize};
	font-weight: ${(p) => p.theme.typography.body1.fontWeight};
	padding: ${(p) => p.theme.spacing(0.5)};
`;

export const StyledDeleteButton = styled(Button)`
	color: ${(p) => p.theme.palette.common.white};
	background-color: ${(p) => p.theme.palette.error.main};
	border: none;

	&:hover {
		color: ${(p) => p.theme.palette.common.white};
		background-color: ${(p) => p.theme.palette.error.main};
	}
`;
