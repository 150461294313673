import environment from './environment';
import theme from './theme/theme';
import React, { FunctionComponent, useEffect } from 'react';
import ReactModal from 'react-modal';

interface ModalProps extends ReactModal.Props {}

const Modal: FunctionComponent<ModalProps> = ({ children, ...rest }) => {
	useEffect(() => ReactModal.setAppElement('#root'), []);

	return (
		<ReactModal
			{...rest}
			style={{
				overlay: {
					backgroundColor: 'rgba(0, 0, 0, 0.2)',
					zIndex: theme.zIndex.modal,
				},
				content: {
					// inset: `${theme.spacing(2)}`,
					// backgroundColor: 'transparent',
					// borderColor: 'transparent',
					// marginTop: theme.navHeight,
					top: `${theme.navHeight}px`,
					marginLeft: 'auto',
					marginRight: 'auto',
					display: 'flex',
					width: '70vw',
					minWidth: '400px',
					height: '60vh',
					left: 0,
					right: 0,
					padding: 0,
					border: 'none',
					borderRadius: '5px',
				},
			}}
			// this prevents complaints when Modal is rendered in tests
			ariaHideApp={environment.NODE_ENV === 'test' ? false : true}
		>
			{children}
		</ReactModal>
	);
};

export default Modal;
