import { useGetEntitiesQuery } from '../../api';
import { ContextParams } from '../../navigation/types/navigationContextTypes';
import { BaseEntity } from '../../ontology/types/entityTypes';
import useActiveDomainData from './useActiveDomainData';
import { createSelector } from '@reduxjs/toolkit';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useParams } from 'react-router-dom';

type QueryResponse = Omit<ReturnType<typeof useGetEntitiesQuery>, 'refetch'>;

type ActiveEntityName = string | undefined;

const selectActiveEntity = createSelector(
	(res: QueryResponse) => res.data,
	(res: QueryResponse, activeEntityName: ActiveEntityName) =>
		activeEntityName,
	(data: QueryResponse['data'], activeEntityName: ActiveEntityName) =>
		data?.find((entity: BaseEntity) => entity.name === activeEntityName) ??
		null
);

/**
 * @returns RTK Query data related to fetching activeEntity property that corresponds to the data for the entity specified
 * in the application URL.
 */
const useActiveEntityData = () => {
	const params = useParams() as ContextParams;

	const { activeItem: activeDomain } = useActiveDomainData();

	const fetchData = useGetEntitiesQuery(
		activeDomain ? { domainId: activeDomain._id } : skipToken,
		{
			selectFromResult: (res) => ({
				...res,
				activeItem: selectActiveEntity(
					res,
					params.entity
				) as null | BaseEntity,
			}),
		}
	);

	return fetchData;
};

export default useActiveEntityData;
