import { resolveAttrType } from '../ontology/typeGuards/attributeGuards';
import { BaseAttribute } from '../ontology/types/attributeTypes';
import { Individual } from '../ontology/types/individualTypes';
import AttributeMeta from './AttributeMeta';
import DataPreparer from './DataPreparer';
import LatLongMeta from './LatLongMeta';
import { IGetMetaClass } from './dataPreparationTypes';

/**
 * Get the correct class to handle processing of individual data for a given attribute
 * type. Inject this via the constructor so we can test.
 */
const getMetaClass: IGetMetaClass = (attr: BaseAttribute) => {
	const narrowestType = resolveAttrType(attr);

	switch (narrowestType) {
		case 'latlong':
			return new LatLongMeta(attr);

		default:
			return new AttributeMeta(attr);
	}
};

export const createDataPreparer =
	(getMetaClass: IGetMetaClass) =>
	(attributes: BaseAttribute[], individuals: Individual[]) => {
		return new DataPreparer(getMetaClass)._init(attributes, individuals);
	};

const dataPrepFactory = createDataPreparer(getMetaClass);

export default dataPrepFactory;
