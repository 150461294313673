import {
	StyledAttributeList,
	StyledVizPaper,
	StyledPoint,
	StyledScatterplotContent,
	StyledSubmoduleHeader,
} from '../styledComponents';
import { attrPointEnhancer, filterAttrsForYMenu } from './helpers';
import { AttributeScatterPoint, AttributeScatterPoints } from './types';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch } from 'app/hooks';
import { List, ListItemContent, ListItem, ListItemAvatar } from 'common/List';
import { PopoverBase } from 'common/Popover';
import Typography from 'common/Typography';
import useElementSize from 'common/hooks/useSize';
import { isAppError } from 'common/utils/typeGuards';
import { setActiveIndividual } from 'features/HUD/state/HUDSlice';
import DisplayOnLoad from 'features/api/DisplayOnLoad';
import useEntitySearchParams from 'features/compositeViews/EntityViews/hooks/useEntitySearchParams';
import useDispatchableErr from 'features/errorHandling/hooks/useDispatchableErr';
// for now, we're using the same popover as we use on the map
import { IndividualCardBase } from 'features/geoSpatial/components/EntityGeospatial/IndividualCard';
import useActiveIndividualsMeta from 'features/ontology/hooks/useActiveIndividualsMeta';
import Scatterplot from 'features/viz/Scatterplot';
import { createScatterPoints } from 'features/viz/Scatterplot/helpers';
import { PointDrawFn, WithDrawCoords } from 'features/viz/types';
import { MouseEventHandler, useMemo, useState } from 'react';

export const SP_POINT_TEST_ID = 'scatterplot-module-test-id';

export const SP_POPOVER_TEST_ID = 'attr-scatter-popover-test-id';

interface SPTooltipProps {
	anchorEl: SVGCircleElement | null;
	datum: Record<string, any> | null;
	open: boolean;
}

const ScatterplotModule = () => {
	const dispatchErr = useDispatchableErr();

	const appDispatch = useAppDispatch();

	const {
		getAllScatterplotY,
		appendScatterplotY,
		removeScatterplotY,
		getActiveAttributeName,
	} = useEntitySearchParams();

	const yAttrs = getAllScatterplotY();

	const makeMenuHandler =
		(attrName: string): MouseEventHandler =>
		() => {
			if (yAttrs.includes(attrName)) {
				return removeScatterplotY(attrName);
			}

			return appendScatterplotY(attrName);
		};

	const activeAttributeName = getActiveAttributeName();

	const { preparedData, ...individualsLoadState } =
		useActiveIndividualsMeta();

	//  Set up for cleaning up and formatting scatterplot data points
	const scatterPoints = useMemo(() => {
		if (preparedData && activeAttributeName) {
			const attrKeys = [activeAttributeName, ...yAttrs];
			const facts = preparedData.facts<number>(...attrKeys);

			if (isAppError(facts)) {
				dispatchErr(facts);
				return [] as AttributeScatterPoints;
			}

			return createScatterPoints(attrKeys, facts, attrPointEnhancer);
		}

		return [] as AttributeScatterPoints;
	}, [preparedData, activeAttributeName, yAttrs, dispatchErr]);

	//  Point setup
	const [tooltipProps, setTooltipProps] = useState<SPTooltipProps>({
		anchorEl: null,
		datum: null,
		open: false,
	});

	const drawPoint: PointDrawFn<
		number,
		number,
		WithDrawCoords<AttributeScatterPoint>
	> = ({ drawX, drawY, pointId, color, individualIdx }) => (
		<StyledPoint
			hoverScaleFactor={1.5}
			cx={drawX}
			cy={drawY}
			key={pointId}
			fill={color}
			fillOpacity={0.75}
			r={3.5}
			data-testid={pointId}
			// onClick={(e) => {
			// 	// important to stop propagation so that event-bubbling doesn't close
			// 	// an already-open tooltip.  We just want it to update position in that case.
			// 	e.stopPropagation();
			// 	setTooltipProps((p) => ({
			// 		...p,
			// 		anchorEl: e.currentTarget,
			// 		datum: preparedData
			// 			? preparedData.data[individualIdx]
			// 			: null,
			// 		open: true,
			// 	}));
			// }}
			onClick={() => {
				if (preparedData) {
					appDispatch(
						setActiveIndividual({
							datum: preparedData.data[individualIdx],
							attributes: preparedData.attributes,
						})
					);
				}
			}}
		/>
	);

	//  General formatting stuff
	const [size, setSizeTarget] = useElementSize();

	const getMenuText = () =>
		yAttrs.length > 0
			? {
					color: 'cyan' as const,
					text: 'Y-Axis',
			  }
			: {
					color: 'primary' as const,
					text: 'select Y variable',
			  };

	const menuText = getMenuText();

	return (
		<StyledVizPaper>
			<StyledSubmoduleHeader>
				<Typography color='cyan'>{activeAttributeName}</Typography>
			</StyledSubmoduleHeader>
			<StyledScatterplotContent>
				<PopoverBase
					open={tooltipProps.open}
					anchorEl={tooltipProps.anchorEl}
					handleClickAway={() =>
						setTooltipProps((p) => ({ ...p, open: false }))
					}
				>
					{preparedData && tooltipProps.datum && (
						<div
							style={{ maxWidth: '400px' }}
							data-testid={SP_POPOVER_TEST_ID}
						>
							<IndividualCardBase
								attributes={preparedData.attributes}
								datum={tooltipProps.datum}
							/>
						</div>
					)}
				</PopoverBase>
				{/* chart resizeObserver will never be triggered on window resize if 
                container allowed to overflow */}
				<div ref={setSizeTarget} style={{ overflow: 'hidden' }}>
					<DisplayOnLoad
						{...individualsLoadState}
						spinnerDiameter={50}
					>
						<Scatterplot
							width={size.width}
							height={size.height}
							facts={scatterPoints}
							drawPoint={drawPoint}
						/>
					</DisplayOnLoad>
				</div>
				<StyledAttributeList>
					<StyledSubmoduleHeader>
						<Typography color={menuText.color}>
							{menuText.text}
						</Typography>
					</StyledSubmoduleHeader>
					<List style={{ overflowY: 'auto', height: '300px' }}>
						{preparedData &&
							activeAttributeName &&
							filterAttrsForYMenu(
								activeAttributeName,
								preparedData.attributes
							).map((a) => {
								const current = getAllScatterplotY().includes(
									a.name
								);

								return (
									<ListItem
										button
										key={a.name}
										aria-current={current}
										onClick={makeMenuHandler(a.name)}
									>
										{current && (
											<ListItemAvatar>
												<FontAwesomeIcon
													icon={faCheck}
												/>
											</ListItemAvatar>
										)}
										<ListItemContent>
											{a.plural}
										</ListItemContent>
									</ListItem>
								);
							})}
					</List>
				</StyledAttributeList>
			</StyledScatterplotContent>
		</StyledVizPaper>
	);
};

export default ScatterplotModule;
