import FlexContainer from '../../../../common/FlexContainer';
import IconButton from '../../../../common/IconButton';
import {
	PopoverBottomDrawer,
	PopoverSubhead,
	PopoverCardBody,
	PopoverMainContent,
} from '../../../../common/Popover';
import SubtleTextArea from '../../../../common/SubtleTextArea';
import { SubtleTextbox } from '../../../../common/SubtleTextbox';
import { warnInDev } from '../../../../common/utils/reactUtils';
import { useDescribeDomainMutation } from '../../../api';
import { joinOnSeparator } from '../../../api/urlBuilder';
import DeleteDomainButton from './DeleteDomainButton';
import DomainPopoverControls from './DomainPopoverControls';
import DomainPopoverDropdown from './DomainPopoverDropdown';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { isNonEmptyString } from 'common/utils/typeGuards';
import { canEdit } from 'features/authentication/helpers';
import {
	DEFINITION_PLACEHOLDER,
	nullifyDefinitionPlaceholder,
} from 'features/forceGraphs/helpers';
import useActiveAccountData from 'features/ontology/hooks/useActiveAccountData';
import { BaseDomain } from 'features/ontology/types/domainTypes';
import {
	FormEventHandler,
	FunctionComponent,
	MouseEventHandler,
	useEffect,
	useState,
} from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

export interface DomainPopoverBodyProps extends BaseDomain {
	// domainId: number;
	// domainName: string;
	// domainLabel: string;
}

interface DescribeFormValues {
	definition: string;
	label: string;
}

export const DomainPopoverBody: FunctionComponent<DomainPopoverBodyProps> = ({
	_id,
	name,
	label,
	userContext,
	definition,
}) => {
	const { register, handleSubmit, formState, resetField } = useForm<{
		label: string;
		definition: string;
	}>({
		defaultValues: {
			label,
			definition: definition ?? DEFINITION_PLACEHOLDER,
		},
	});

	const { isDirty: formIsDirty, dirtyFields } = formState;

	const { label: labelIsDirty, definition: definitionIsDirty } = dirtyFields;

	const [describe, describeResult] = useDescribeDomainMutation();

	useEffect(() => {
		if (describeResult.error) {
			warnInDev(JSON.stringify(describeResult.error), 'error');
		}
	}, [describeResult.error]);

	const onSubmit: SubmitHandler<DescribeFormValues> = (vals) => {
		describe({
			domainId: _id,
			body: {
				...vals,
				definition: nullifyDefinitionPlaceholder(vals.definition),
			},
		});
	};

	const conditionalSubmit: FormEventHandler = (e) => {
		e.preventDefault();

		if (formIsDirty) {
			handleSubmit(onSubmit)();
		}

		return null;
	};

	const [menuOpen, setMenuOpen] = useState(false);

	// Handle open popover menu state here in parent to prevent multiple
	// submenus from being open at once
	const [actionSubmenu, setActionSubmenu] = useState<string | null>(null);

	const { pathname } = useLocation();

	const { activeItem: activeAccount } = useActiveAccountData();

	const canEditAccount = activeAccount ? canEdit(activeAccount) : false;

	const canEditDomain = canEdit({ userContext });

	// useEffect(() => {
	// 	if (isSuccess && currentDomain) {
	// 		const { definition, label } = currentDomain;
	// 		reset(
	// 			{ label, definition: definition ?? DEFINITION_PLACEHOLDER },
	// 			{ keepDefaultValues: false }
	// 		);
	// 	}
	// }, [reset, isSuccess, currentDomain]);

	const toggleDropdown: MouseEventHandler = () => {
		setMenuOpen((p) => !p);
	};

	const pathToProfile = '/' + joinOnSeparator('/')(pathname, name);

	return (
		<PopoverCardBody>
			<PopoverMainContent>
				<form onSubmit={conditionalSubmit}>
					<SubtleTextbox
						{...register('label', {
							required: "A value for 'label' is required",
						})}
						isDirty={Boolean(labelIsDirty)}
						label="label"
						onReset={() => resetField('label')}
						overrides={{
							root: {
								fontSize: '1.2rem',
								letterSpacing: '0.1em',
								fontWeight: '600',
								display: 'block',
								margin: 0,
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
								overflow: 'hidden',
							},
							container: {
								maxWidth: '250px',
							},
						}}
						disabled={!canEditDomain}
					/>
					<PopoverSubhead>
						{(canEditDomain || isNonEmptyString(definition)) &&
							(canEditDomain ? (
								<SubtleTextArea
									{...register('definition')}
									isDirty={Boolean(definitionIsDirty)}
									label="definition"
									onReset={() => resetField('definition')}
									rows={2}
									overrides={{
										root: {
											resize: 'vertical',
										},
									}}
									disabled={!canEditDomain}
								/>
							) : (
								<p>{definition}</p>
							))}
					</PopoverSubhead>
				</form>
			</PopoverMainContent>
			<FlexContainer
				justifyContent="space-between"
				style={{
					padding: 0,
					gap: '0px',
					paddingLeft: 10,
					margin: 0,
				}}
			>
				{canEditDomain && (
					<IconButton
						icon={faCheck}
						size="xs"
						fillColor="primary"
						baseOpacity="80%"
						tooltip={`update ${label}`}
						tooltipPlacement="bottom"
						disabled={!formState.isDirty}
						showTooltip={formIsDirty}
						aria-label="submit changes"
						onClick={(e) => conditionalSubmit(e)}
					/>
				)}
				{canEditAccount && (
					<DeleteDomainButton
						domainId={_id}
						domainLabel={label}
						openActionSubmenu={actionSubmenu}
						setOpenActionSubmenu={setActionSubmenu}
					/>
				)}
			</FlexContainer>
			<PopoverBottomDrawer open={menuOpen}>
				<DomainPopoverDropdown
					domainId={_id}
					domainLabel={label}
					actionSubmenu={actionSubmenu}
					setActionSubmenu={setActionSubmenu}
				/>
			</PopoverBottomDrawer>
			<DomainPopoverControls
				userContext={userContext}
				domainLabel={label}
				domainId={_id}
				toggleDropdown={toggleDropdown}
				menuOpen={menuOpen}
				pathToProfile={pathToProfile}
			/>
		</PopoverCardBody>
	);
};

export default DomainPopoverBody;
