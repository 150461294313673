import {
	useGetAccountAdminsQuery,
	useGetAccountMembersQuery,
	useGetAccountGovernorsQuery,
} from '../../api';
import { BaseUser } from '../../authentication/types/userTypes';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useMemo } from 'react';

const emptyAdmins: BaseUser[] = [];
const emptyGovs: BaseUser[] = [];
const emptyMembers: BaseUser[] = [];

const useCombinedAccountMembers = (accountId: number | null | undefined) => {
	//  selectorize these to prevent a ton of unneeded re-renders as query loads/re-loads
	const { admins } = useGetAccountAdminsQuery(
		accountId ? { accountId } : skipToken,
		{ selectFromResult: ({ data }) => ({ admins: data ?? emptyAdmins }) }
	);
	const { governors } = useGetAccountGovernorsQuery(
		accountId ? { accountId } : skipToken,
		{ selectFromResult: ({ data }) => ({ governors: data ?? emptyGovs }) }
	);
	const { members } = useGetAccountMembersQuery(
		accountId ? { accountId } : skipToken,
		{ selectFromResult: ({ data }) => ({ members: data ?? emptyMembers }) }
	);

	return useMemo(
		() => ({
			admins,
			governors,
			members,
		}),
		[admins, governors, members]
	);
};

export default useCombinedAccountMembers;
