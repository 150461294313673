import { useAppDispatch } from '../../../../app/hooks';
import IconButton from '../../../../common/IconButton';
import { pipe } from '../../../../common/utils/functionUtils';
import { recenterGraph } from '../../state/HUDSlice';
import { ControlButtonWrapper } from '../styledComponents';
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';

const RecenterButton: FunctionComponent = () => {
	const appDispatch = useAppDispatch();

	const { domain, account, entity } = useParams();

	const recenter = () => pipe(recenterGraph(), appDispatch);

	const displaying = (!!domain || !!account) && !entity;

	return (
		<ControlButtonWrapper displaying={displaying}>
			<IconButton
				icon={faUpRightAndDownLeftFromCenter}
				onClick={recenter}
				aria-label={`Recenter graph`}
				key="recenter-graph"
				size="sm"
				tooltip={`Recenter Graph`}
				tooltipPlacement="left"
			/>
		</ControlButtonWrapper>
	);
};

export default RecenterButton;
