import { useGetDomainAttributesQuery } from '../../api';
import { mergeErrorStates, mergeLoadingStates } from '../../api/helpers';
import useActiveDomainData from '../../ontology/hooks/useActiveDomainData';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useMemo } from 'react';

const useAttrActionData = () => {
	const { activeItem: activeDomain } = useActiveDomainData();

	const domainAttrs = useGetDomainAttributesQuery(
		activeDomain ? { domainId: activeDomain._id } : skipToken
	);

	return useMemo(() => {
		const isLoading = mergeLoadingStates(domainAttrs);

		const errors = mergeErrorStates(domainAttrs);

		return {
			isLoading,
			errors,
			domainAttrs: domainAttrs.data || null,
			domainAttrQueryResult: domainAttrs,
		};
	}, [domainAttrs]);
};

export default useAttrActionData;
