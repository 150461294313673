import {
	excludeFrom,
	dedupeOnId,
	pipe,
} from '../../common/utils/functionUtils';
import { BaseUser } from '../authentication/types/userTypes';
import {
	AccountAuthorityLevel,
	CombinedAccountMembers,
	CombinedDomainMembers,
	DomainAuthorityLevel,
} from './types/MemberManagementTypes';

export const composeName = (member: BaseUser) =>
	`${member.firstName} ${member.lastName}`;

export const getAcctUsersNotInDomain = (
	acctUsers: CombinedAccountMembers,
	domainUsers: CombinedDomainMembers
) => {
	//  explicitly declare these array types so that the compiler will catch it
	// if type of Combined{Account,Domain}Members ever changes.
	const acctKeys: Array<keyof CombinedAccountMembers> = [
		'admins',
		'governors',
		'members',
	];

	const domainKeys: Array<keyof CombinedDomainMembers> = [
		'stewards',
		'members',
	];

	const allAcctUsers = acctKeys.reduce(
		(acc, next) => acc.concat(acctUsers[next]),
		[] as BaseUser[]
	);

	const allDomainUsers = domainKeys.reduce(
		(acc, next) => acc.concat(domainUsers[next]),
		[] as BaseUser[]
	);

	return excludeFrom(allAcctUsers, allDomainUsers);
};

export const filterDomainMembers = (
	authorityLevel: DomainAuthorityLevel,
	combinedDomainMembers: CombinedDomainMembers,
	combinedAccountMembers: CombinedAccountMembers
) => {
	const { members: domainMembers, stewards: domainStewards } =
		combinedDomainMembers;

	if (authorityLevel === 'steward') {
		return excludeFrom(domainMembers, domainStewards);
	}

	// We can add a user to domain members if they are part of the account, but
	// not currently included at any authority level in the domain.
	return pipe(
		getAcctUsersNotInDomain(combinedAccountMembers, combinedDomainMembers),
		dedupeOnId
	);
};

export const filterAccountMembers = (
	authorityLevel: AccountAuthorityLevel,
	combinedMembers: CombinedAccountMembers
) => {
	const { governors, members, admins } = combinedMembers;

	if (authorityLevel === 'admin') {
		return pipe(
			excludeFrom([...governors, ...members], admins),
			dedupeOnId
		);
	}

	if (authorityLevel === 'governor') {
		return excludeFrom(members, governors);
	}

	return [];
};
