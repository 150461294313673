import ContextualDisplay from './components/ContextualDisplay';
import SourceNav from './components/SourceNav';
import { DataSourceSummaries } from './types/dataTypes';
import {
	SelectedSourceData,
	SourceBrowserDisplayMode,
	SourceNavItemList,
} from './types/uiTypes';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetSourceSummariesQuery } from 'features/api';
import { parseQueryError } from 'features/api/helpers';
import { canEdit as canEditAccount } from 'features/authentication/helpers';
import useActiveAccountData from 'features/ontology/hooks/useActiveAccountData';
import { FunctionComponent, useState, useEffect } from 'react';
import styled from 'styled-components';

const StyledMain = styled.div`
	display: grid;
	grid-template-columns: 3fr 6fr;
	grid-template-rows: 1fr;
	flex: 1;
	overflow: auto;
	background: ${(p) => p.theme.palette.background.paper};
	color: ${(p) => p.theme.palette.text.paper};
`;

const SourceBrowser: FunctionComponent = () => {
	const [onDisplay, setOnDisplay] = useState<SelectedSourceData>([]);

	const [displayMode, setDisplayMode] =
		useState<SourceBrowserDisplayMode>('display');

	const [cardData, setCardData] = useState<SourceNavItemList | null>(null);

	const { activeItem } = useActiveAccountData();

	const canEdit = activeItem ? canEditAccount(activeItem) : false;

	const {
		isError,
		isLoading,
		data,
		isFetching,
		error,
		isSuccess,
		isUninitialized,
	} = useGetSourceSummariesQuery(
		activeItem ? { accountId: activeItem._id } : skipToken
	);

	useEffect(() => {
		if (isSuccess && data) {
			setOnDisplay(data);
			setCardData(data);
		}
	}, [data, isSuccess]);

	// TODO: this causes flicker on cache invalidation.  Pass these props down
	// so children can render a spinner or something instead.
	if (isLoading || isFetching || isUninitialized) {
		return <p>Loading...</p>;
	}

	if (isError && error) {
		const { message } = parseQueryError(error);

		return <p>{message}</p>;
	}

	return (
		<StyledMain>
			<SourceNav
				canEdit={canEdit}
				sourceSummaries={data as DataSourceSummaries}
				setter={(toSelect: SelectedSourceData) =>
					setOnDisplay(toSelect)
				}
				currentlySelected={onDisplay}
				toggleFormMode={() => setDisplayMode('form')}
				setCardData={setCardData}
			/>
			<ContextualDisplay
				displayMode={displayMode}
				closeForm={() => setDisplayMode('display')}
				activeItem={activeItem}
				setOnDisplay={setOnDisplay}
				onDisplay={onDisplay}
				cardData={cardData}
			/>
		</StyledMain>
	);
};

export default SourceBrowser;
