import AttributeSelect from './components/AttributeSelect';
import HorizontalBarChartModule from './components/HorizontalBarChartModule';
import IndividualsModule from './components/IndividualsModule';
import ScatterplotModule from './components/ScatterplotModule';
import { StyledVizPaper } from './components/styledComponents';
import { useAppDispatch } from 'app/hooks';
import FlexContainer from 'common/FlexContainer';
import Heading from 'common/Heading';
import Spinner from 'common/Spinner';
import useHUDIndividual from 'features/HUD/hooks/useHUDIndividual';
import { clearActiveIndividual } from 'features/HUD/state/HUDSlice';
import useEntitySearchParams from 'features/compositeViews/EntityViews/hooks/useEntitySearchParams';
import { LINEAGE_GRAPH } from 'features/forceGraphs/components/AttributeLineageGraph/CONSTANTS';
import { deriveEntityProfileViz } from 'features/ontology/helpers/entityHelpers';
import useActiveEntityAttributes from 'features/ontology/hooks/useActiveEntityAttributes';
import { BaseAttribute } from 'features/ontology/types/attributeTypes';
import { EntityVizMap } from 'features/ontology/types/entityTypes';
import EventLineChartModule from 'features/profilePages/EntityProfile/components/EventLineChartModule';
import HistogramModule from 'features/profilePages/EntityProfile/components/HistogramModule';
import LineageModule from 'features/profilePages/EntityProfile/components/LineageModule';
import {
	CATEGORY_BAR_CHART,
	EVENT_LINE_CHART,
	INDIVIDUALS_TABLE,
	QTY_HISTO,
	QTY_SCATTERPLOT,
} from 'features/viz/CONSTANTS';
import { FunctionComponent } from 'react';
import styled from 'styled-components';

const StyledVizContainer = styled.div<{ fullscreen: boolean }>`
	/* display: flex; */
	/* flex-wrap: wrap; */
	/* align-items: flex-start; */
	/* justify-content: space-between; */
	display: grid;
	grid-template-columns: ${(p) => (p.fullscreen ? '1fr' : 'repeat(2, 1fr)')};
	grid-template-rows: ${(p) => (p.fullscreen ? '1fr' : 'repeat(2, 1fr)')};
	gap: ${(p) => p.theme.spacing(1)};
	padding: ${(p) => p.theme.spacing(0, 6, 1, 1)};
	height: calc(
		100vh - ${(p) => p.theme.navHeight * 2 + p.theme.panelGutter}px
	);
	/* border: 1px solid black; */
	overflow: hidden;
`;

const StyledEntityProfileGrid = styled.div`
	padding-top: ${(p) => p.theme.navHeight}px;
	display: grid;
	grid-template-columns: ${(p) => p.theme.drawerWidth}px 1fr;
`;

const entityVizMap: EntityVizMap = {
	[QTY_HISTO]: HistogramModule,
	[LINEAGE_GRAPH]: LineageModule,
	[QTY_SCATTERPLOT]: ScatterplotModule,
	[CATEGORY_BAR_CHART]: HorizontalBarChartModule,
	[EVENT_LINE_CHART]: EventLineChartModule,
	[INDIVIDUALS_TABLE]: IndividualsModule,
};

// TODO: Satan's code.  Always yields 4 modules, with any populated modules
// (which range in number from 0 - 2 based on current attribute) first.
// If current attribute is primary identity, only return a single (full-page)
// module.
const fillModules = (attrs: BaseAttribute[], currentAttr: string) => {
	const currentFullAttr = attrs.find(
		(a) => a.name === currentAttr
	) as BaseAttribute;

	if (currentFullAttr.isPrimary) {
		return <IndividualsModule />;
	}

	const vizModules = deriveEntityProfileViz(attrs, currentFullAttr).map(
		(kind) => {
			const Component = entityVizMap[kind];
			return <Component key={kind} />;
		}
	);

	let placeholderCount = 0;
	if (currentFullAttr.type === 'quantity') {
		placeholderCount = 4 - vizModules.length;
	}


	if (placeholderCount > 0) {
		for (let i = 1; i <= placeholderCount; i++) {
			vizModules.push(<StyledVizPaper key={`placeholder-${i}`} />);
		}
	}

	return vizModules;
};

const EntityProfile: FunctionComponent = () => {
	const appDispatch = useAppDispatch();

	const individualActive = useHUDIndividual();

	const { getActiveAttributeName } = useEntitySearchParams();

	const currentAttrName = getActiveAttributeName();

	const { attributes, ...loadState } = useActiveEntityAttributes();

	const activeIsPrimaryID = !!attributes?.find(
		(a) => a.name === currentAttrName
	)?.isPrimary;

	const activeType = attributes?.find(
		(a) => a.name === currentAttrName
	)?.type;

	const renderViz = () => {
		if (loadState.isSuccess && attributes && currentAttrName) {
			return (
				<StyledVizContainer
					fullscreen={activeIsPrimaryID || activeType !== 'quantity'}
					onClick={(e) => {
						e.stopPropagation();
						if (individualActive) {
							appDispatch(clearActiveIndividual());
						}
					}}
				>
					{fillModules(attributes, currentAttrName)}
				</StyledVizContainer>
			);
		}

		if (loadState.isLoading) {
			return (
				<div
					style={{
						position: 'fixed',
						top: '50%',
						left: '50%',
					}}
				>
					<FlexContainer justifyContent="center">
						<Spinner />
					</FlexContainer>
				</div>
			);
		}

		if (!currentAttrName) {
			return (
				<FlexContainer justifyContent="center">
					<div
						style={{
							position: 'fixed',
							top: '50%',
						}}
					>
						<Heading component="h1">Select an attribute</Heading>
					</div>
				</FlexContainer>
			);
		}
	};

	return (
		<StyledEntityProfileGrid>
			<AttributeSelect />
			{renderViz()}
		</StyledEntityProfileGrid>
	);
};

export default EntityProfile;
