import IconButton from '../../../common/IconButton';
import PopoverBase from '../../../common/Popover/PopoverBase';
import { QuestionableObject } from '../types/questionTypes';
import QuestionList from './QuestionList';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { FunctionComponent, useState } from 'react';

interface CreateQuestionButtonProps {
	objectType: QuestionableObject;
	objectId: number;
	canEdit: boolean;
}

const CreateQuestionButton: FunctionComponent<CreateQuestionButtonProps> = ({
	canEdit,
	objectId,
	objectType,
}) => {
	const [anchorEl, setAnchorEl] = useState<HTMLLIElement | null>(null);

	const [menuOpen, setMenuOpen] = useState<string | null>(null);

	return (
		<>
			<IconButton
				ref={setAnchorEl as any}
				icon={faQuestion}
				baseOpacity="80%"
				size="xs"
				tooltip="ask a question"
				tooltipPlacement="bottom"
				onClick={() => setMenuOpen((p) => (p ? null : 'open'))}
				type="button"
				showTooltip={!menuOpen}
				aria-label="view questions about this object"
			/>
			<PopoverBase
				anchorEl={anchorEl}
				open={!!menuOpen}
				offset={0}
				handleClickAway={() => setMenuOpen(null)}
			>
				<QuestionList
					objectId={objectId}
					objectType={objectType}
					canEdit={canEdit}
				/>
			</PopoverBase>
		</>
	);
};

export default CreateQuestionButton;
