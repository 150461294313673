import useViewportDimensions from 'features/HUD/hooks/useViewportDimensions';
import { useLayoutEffect, useState } from 'react';

const useClientRect = <T extends HTMLElement>() => {
	const dims = useViewportDimensions();

	const [node, setNode] = useState<T | null>(null);

	const [rect, setRect] = useState({ width: 0, height: 0 });

	// whever screen resizes, read the node again.  Very janky,
	// but ResizeObserver not working correctly for drawing charts atm.
	useLayoutEffect(() => {
		if (node) {
			setRect(node.getBoundingClientRect());
		}
	}, [dims, node]);

	return [rect, setNode] as const;
};

export default useClientRect;
