import { StyledInput } from './styledComponents';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import IconButton from 'common/IconButton';
import Typography from 'common/Typography';
import { forkRef } from 'common/utils/reactUtils';
import { CSSProperties, forwardRef, useRef } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

interface TextboxOverrides {
	root?: CSSProperties;
	button?: CSSProperties;
}

// TODO: create appropriate 'Omit' type to remove conflict between HTMLAttributes
// and UseFormRegisterReturn
export interface SubtleTextAreaProps
	extends UseFormRegisterReturn,
		Record<string, any> {
	isDirty: boolean;
	label: string;
	onReset?: () => void;
	error?: string;
	overrides?: TextboxOverrides;
}

const SubtleTextArea = forwardRef<HTMLTextAreaElement, SubtleTextAreaProps>(
	({ overrides, isDirty, onReset, label, error, ...rest }, forwardedRef) => {
		const resetterRef = useRef<HTMLTextAreaElement>();

		const setRefs = forkRef(forwardedRef, resetterRef);

		return (
			<div style={{ position: 'relative' }}>
				<StyledInput
					spellCheck={false}
					style={overrides?.root}
					id={`${label}-subtle-input`}
					className={clsx(isDirty && 'is-dirty')}
					aria-invalid={Boolean(error)}
					aria-errormessage={`${label}-error-message`}
					// @ts-ignore
					ref={setRefs}
					aria-label={label}
					{...rest}
				/>
				{isDirty && (
					<IconButton
						style={{
							...overrides?.button,
							position: 'absolute',
							right: '4px',
							top: '4px',
						}}
						fillColor="error"
						size="xs"
						icon={faTimes}
						aria-label={`reset ${label} field`}
						tooltipPlacement="top"
						onClick={(e) => {
							e.preventDefault();
							onReset && onReset();
						}}
					/>
				)}
				<div>
					{error && (
						<Typography id={`${label}-error-message`} color="error">
							{error}
						</Typography>
					)}
				</div>
			</div>
		);
	}
);

export default SubtleTextArea;
