import Spinner from '../../common/Spinner';
import Textbox from '../../common/Textbox';
import Typography from '../../common/Typography';
import { flow } from '../../common/utils/functionUtils';
import { parseQueryError } from '../api/helpers';
import { QueryErrResult } from '../api/types';
import Card from './components/Card';
import CatalogSummaryListItem from './components/CatalogSummaryListItem';
import DataSetListItem from './components/DataSetListItem';
import SourceSummaryListItem from './components/SourceSummaryListItem';
import {
	StyledList,
	StyledSourceFormLabel,
} from './components/styledComponents';
import {
	DisplaySourceType,
	CredsParser,
	SFTPFormValues,
	SFTPPayload,
	BigQueryPayload,
	BigQueryFormValues,
	GCSFormValues,
	GCSPayload,
	S3FormValues,
	S3Payload,
	MySQLPayload,
	MySQLFormValues,
	GHubPublicFormValues,
	GHubPublicPayload,
	CredentialFormValues,
	CredsSchema,
} from './types/credentialTypes';
import {
	DataCatalogSummaries,
	DataCatalogSummary,
	DataSourceSummaries,
	DataSourceSummary,
	LiveDataSet,
	LiveDataSets,
	SourceType,
} from './types/dataTypes';
import {
	isDataCatalogSummaries,
	isDataCatalogSummary,
	isLiveDataSets,
	Setter,
	isSourceSummaries,
	isSourceSummary,
	SelectedSourceData,
	SourceNavItemData,
	SourceNavItemList,
	CardSetter,
} from './types/uiTypes';
import {
	faUpload,
	faHourglassHalf,
	faTimes,
	faCheck,
} from '@fortawesome/free-solid-svg-icons';
import { faDatabase, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { isValidURL } from 'common/utils/typeGuards';
import { FormState, RegisterOptions, UseFormRegister } from 'react-hook-form';

interface CardProps {
	key: string;
	icon: IconDefinition;
	title: string;
	onClick: () => void;
}

const deriveCardProps =
	(setter: Setter) =>
	(data: SourceNavItemData): CardProps =>
		isDataCatalogSummary(data)
			? {
					key: data.name,
					icon: faDatabase,
					title: data.label ? data.label : data.name,
					onClick: () => setter(data),
			  }
			: isSourceSummary(data)
			? {
					key: String(data._id),
					icon: faDatabase,
					title: data.name,
					onClick: () => setter(data),
			  }
			: {
					key: `${data.catalogName}-${data.name}-dataset`,
					icon: faDatabase,
					title: data.name,
					onClick: () => setter(data),
			  };

const renderCard = (props: CardProps) => <Card {...props} />;

export const renderCards = (
	data: DataSourceSummaries | DataCatalogSummaries | LiveDataSets,
	setter: Setter
) => data.map(flow(deriveCardProps(setter), renderCard));

export const mapSourceSummariesToCards = (
	dataSources: DataSourceSummaries,
	setter: Setter
) =>
	dataSources.map((s) => (
		<Card
			key={s._id}
			icon={faDatabase}
			title={s.name}
			onClick={() => setter(s)}
		/>
	));

export const mapDataCatalogsToCards = (
	catalogs: DataCatalogSummaries,
	setter: Setter
) =>
	catalogs.map((summary) => (
		<Card
			key={summary.name + summary.sourceName}
			icon={faDatabase}
			title={summary.label ? summary.label : summary.name}
			onClick={() => setter(summary)}
		/>
	));

export const mapDataSetsToCards = (dataSets: LiveDataSets, setter: Setter) =>
	dataSets.map((dataSet) => (
		<Card
			key={`${dataSet.catalogName}-${dataSet.name}-dataSet`}
			icon={faDatabase}
			title={dataSet.label ? dataSet.label : dataSet.name}
			onClick={() => setter(dataSet)}
		/>
	));

export const getCardMapper = (onDisplay: SelectedSourceData) => {
	if (isSourceSummaries(onDisplay)) return mapSourceSummariesToCards;

	if (isSourceSummary(onDisplay)) return mapDataCatalogsToCards;

	if (isDataCatalogSummary(onDisplay)) return mapDataSetsToCards;
};

export const identifyNavItemData = (data: SourceNavItemData) => {
	if (isSourceSummary(data)) {
		return 'dataSource';
	}
	if (isDataCatalogSummary(data)) {
		return 'dataCatalog';
	}

	return 'dataSet';
};

export const identifyNavListData = (data: SourceNavItemList) => {
	if (isDataCatalogSummaries(data)) return 'catalogSummaries';

	if (isLiveDataSets(data)) return 'dataSets';

	return 'sourceSummaries';
};

export const selectNavItemName = (data: SourceNavItemData) => {
	const kind = identifyNavItemData(data);

	switch (kind) {
		case 'dataSource':
			return (data as DataSourceSummary).name;

		case 'dataSet':
			const { name: datasetName, label: datasetLabel } =
				data as LiveDataSet;
			return datasetLabel ? datasetLabel : datasetName;

		case 'dataCatalog':
			const { name: catalogName, label: catalogLabel } =
				data as DataCatalogSummary;
			return catalogLabel ? catalogLabel : catalogName;
	}
};

export const mapSourceSummariesToNavList = (
	data: DataSourceSummaries,
	setter: Setter,
	cardSetter: CardSetter,
	currentlySelected: SelectedSourceData
) =>
	data.map((summary) => (
		<SourceSummaryListItem
			onDisplay={summary}
			setter={setter}
			key={summary._id}
			currentlySelected={currentlySelected}
			cardSetter={cardSetter}
		/>
	));

export const mapCatalogSummariesToNavList = (
	data: DataCatalogSummaries,
	setter: Setter,
	cardSetter: CardSetter,
	currentlySelected: SelectedSourceData,
	sourceId: number
) =>
	data.map((catalog) => (
		<CatalogSummaryListItem
			sourceId={sourceId}
			onDisplay={catalog}
			currentlySelected={currentlySelected}
			setter={setter}
			key={catalog.name + catalog.sourceName}
			cardSetter={cardSetter}
			{...catalog}
		/>
	));

export const mapDataSetsToNavList = (
	data: LiveDataSets,
	setter: Setter,
	currentlySelected: SelectedSourceData
) =>
	data.map((dataSet) => (
		<DataSetListItem
			onDisplay={dataSet}
			currentlySelected={currentlySelected}
			setter={setter}
			key={`${dataSet.catalogName}-${dataSet.name}-dataSet`}
			{...dataSet}
		/>
	));

export const mapSourceDataToNav = (
	data: SourceNavItemList,
	setter: Setter,
	cardSetter: CardSetter,
	currentlySelected: SelectedSourceData,
	sourceId: number
) => {
	const kind = identifyNavListData(data);

	switch (kind) {
		case 'catalogSummaries':
			return mapCatalogSummariesToNavList(
				data as DataCatalogSummaries,
				setter,
				cardSetter,
				currentlySelected,
				sourceId
			);

		case 'dataSets':
			return mapDataSetsToNavList(
				data as LiveDataSets,
				setter,
				currentlySelected
			);

		case 'sourceSummaries':
			return mapSourceSummariesToNavList(
				data as DataSourceSummaries,
				setter,
				cardSetter,
				currentlySelected
			);

		default:
			return null;
	}
};

interface DropdownProps {
	isUninitialized: boolean;
	isError: boolean;
	isFetching: boolean;
	isLoading: boolean;
	isSuccess: boolean;
	error?: QueryErrResult;
	data?: SourceNavItemList;
	setter: Setter;
	cardSetter: CardSetter;
	currentlySelected: SelectedSourceData;
	sourceId: number;
}

export const renderDropdown = ({
	isUninitialized,
	isError,
	isFetching,
	isLoading,
	error,
	data,
	setter,
	cardSetter,
	currentlySelected,
	sourceId,
}: DropdownProps) => {
	if (isUninitialized) {
		return null;
	}

	if (isLoading || isFetching) {
		return <Spinner />;
	}

	if (isError && error) {
		const { message } = parseQueryError(error);

		return <Typography>{message}</Typography>;
	}

	return (
		<StyledList>
			{mapSourceDataToNav(
				data as SourceNavItemList,
				setter,
				cardSetter,
				currentlySelected,
				sourceId
			)}
		</StyledList>
	);
};

export const mapDisplaySourceTypeToCredentials = (
	sourceType: DisplaySourceType
): { schema: CredsSchema; sourceType: SourceType } => {
	switch (sourceType) {
		case 'bigquery':
			return { schema: 'service_account', sourceType: 'bigquery' };

		case 'google cloud storage':
			return {
				schema: 'service_account',
				sourceType: 'gcs',
			};

		case 's3':
			return { schema: 'access_key', sourceType: 's3' };

		case 'sftp':
			return { schema: 'basic_auth', sourceType: 'sftp' };

		case 'github':
			return { schema: null, sourceType: 'github' };

		case 'mysql':
			return { schema: 'basic_auth', sourceType: 'mysql' };
	}
};

export const renderSourceFormInputs = (
	displaySourceType: DisplaySourceType,
	register: UseFormRegister<any>,
	formState: FormState<any>
) => {
	const registerRequired = (name: string, opts?: RegisterOptions) =>
		register(name, {
			required: {
				value: true,
				message: `A value for ${name} is required`,
			},
			...opts,
		});

	switch (displaySourceType) {
		case 'bigquery':
			return (
				<>
					<StyledSourceFormLabel
						htmlFor="upload-json-bigquery-key"
						style={{ marginTop: '16px' }}
					>
						Upload a JSON service account key file:
					</StyledSourceFormLabel>
					<input
						{...registerRequired('keyFile', { value: null })}
						type="file"
						id="upload-json-bigquery-key"
						accept="application/json"
					/>
				</>
			);

		case 'google cloud storage':
			return (
				<>
					<StyledSourceFormLabel
						htmlFor="upload-json-gcs-key"
						style={{ marginTop: '16px' }}
					>
						Upload a JSON service account key file:
					</StyledSourceFormLabel>
					<input
						{...registerRequired('keyFile', { value: null })}
						type="file"
						id="upload-json-gcs-key"
						accept="application/json"
					/>
				</>
			);

		case 's3':
			return (
				<>
					<Textbox
						{...registerRequired('id', { value: '' })}
						error={formState.errors.id}
						labelText="Access Key ID"
						key="id"
					/>
					<Textbox
						{...registerRequired('secret')}
						error={formState.errors.secret}
						labelText="Access Key Secret"
						key="secret"
					/>
				</>
			);

		case 'sftp':
			return (
				<>
					<Textbox
						{...registerRequired('username', { value: '' })}
						error={formState.errors.username}
						labelText="Username"
						key="username"
					/>
					<Textbox
						{...registerRequired('password', { value: '' })}
						error={formState.errors.password}
						labelText="Password"
						type="password"
						key="password"
					/>
					<Textbox
						{...registerRequired('host', { value: '' })}
						error={formState.errors.host}
						labelText="host"
						key="host"
					/>
					<Textbox
						{...registerRequired('port', {
							value: '',
							valueAsNumber: true,
							validate: (v) =>
								isNaN(v)
									? 'invalid number provided to PORT'
									: true,
						})}
						error={formState.errors.port}
						labelText="Port"
						key="port"
					/>
				</>
			);

		case 'mysql':
			return (
				<>
					<Textbox
						{...registerRequired('database', { value: '' })}
						error={formState.errors.database}
						labelText="Database"
						key="database"
					/>
					<Textbox
						{...registerRequired('username', { value: '' })}
						error={formState.errors.username}
						labelText="Username"
						key="username"
					/>
					<Textbox
						{...registerRequired('password', { value: '' })}
						error={formState.errors.password}
						labelText="Password"
						type="password"
						key="password"
					/>
					<Textbox
						{...registerRequired('host', { value: '' })}
						error={formState.errors.host}
						labelText="host"
						key="host"
					/>
					<Textbox
						{...registerRequired('port', {
							value: '',
							valueAsNumber: true,
							validate: (v) =>
								isNaN(v)
									? 'invalid number provided to PORT'
									: true,
						})}
						error={formState.errors.port}
						labelText="Port"
						key="port"
					/>
				</>
			);

		// case 'github (password)':
		// 	return (
		// 		<>
		// 			<Textbox
		// 				{...registerRequired('remoteURL', { value: '' })}
		// 				error={formState.errors.host}
		// 				labelText="repository URL"
		// 				key="remoteURL"
		// 			/>
		// 			<Textbox
		// 				{...registerRequired('username', { value: '' })}
		// 				error={formState.errors.username}
		// 				labelText="Username"
		// 				key="username"
		// 			/>
		// 			<Textbox
		// 				{...registerRequired('password', { value: '' })}
		// 				error={formState.errors.password}
		// 				labelText="Password"
		// 				type="password"
		// 				key="password"
		// 			/>
		// 		</>
		// 	);
		case 'github':
			return (
				<Textbox
					{...registerRequired('remoteUrl', {
						value: '',
						validate: (v) =>
							isValidURL(v)
								? true
								: 'please enter a url of form "http[s]:<address>"',
					})}
					error={formState.errors.remoteUrl}
					labelText="repository URL"
					key="remoteUrl"
				/>
			);
	}
};

const fileDataToString = (file: File): Promise<string> =>
	new Promise((res, rej) => {
		const reader = new FileReader();

		reader.addEventListener('load', () => {
			res(reader.result as string);
		});

		try {
			reader.readAsText(file, 'UTF-8');
		} catch (e) {
			rej(e);
		}
	});

const parseBigQuery: CredsParser<BigQueryFormValues, BigQueryPayload> = (
	formValues,
	accountId
) => {
	return fileDataToString((formValues.keyFile as FileList)[0])
		.then(JSON.parse)
		.then((serviceAccountData) => ({
			type: formValues.sourceType,
			accountId,
			credsSchema: formValues.credsSchema,
			name: formValues.name,
			creds: serviceAccountData,
		}));
};

const parseGCS: CredsParser<GCSFormValues, GCSPayload> = (
	formValues,
	accountId
) => {
	return fileDataToString((formValues.keyFile as FileList)[0])
		.then(JSON.parse)
		.then((serviceAccountData) => ({
			type: formValues.sourceType,
			accountId,
			credsSchema: formValues.credsSchema,
			name: formValues.name,
			creds: serviceAccountData,
		}));
};

const parseS3: CredsParser<S3FormValues, S3Payload> = (formValues, accountId) =>
	Promise.resolve({
		type: formValues.sourceType,
		name: formValues.name,
		accountId,
		credsSchema: formValues.credsSchema,
		creds: {
			id: formValues.id,
			secret: formValues.secret,
		},
	});

const parseSFTP: CredsParser<SFTPFormValues, SFTPPayload> = (
	creds,
	accountId
) => {
	const portNumber = parseInt(creds.port, 10);

	if (isNaN(portNumber)) {
		return Promise.reject(
			'Unable to convert the provided port value to type "number"'
		);
	}

	return Promise.resolve({
		type: creds.sourceType,
		name: creds.name,
		accountId,
		credsSchema: creds.credsSchema,
		host: creds.host,
		port: portNumber,
		creds: {
			username: creds.username,
			password: creds.password,
		},
	});
};

const parseMySQL: CredsParser<MySQLFormValues, MySQLPayload> = (
	formValues,
	accountId
) => {
	const portNumber = parseInt(formValues.port, 10);

	if (isNaN(portNumber)) {
		return Promise.reject(
			'Unable to convert the provided port value to type "number"'
		);
	}

	return Promise.resolve({
		type: formValues.sourceType,
		name: formValues.name,
		accountId,
		credsSchema: formValues.credsSchema,
		database: formValues.database,
		host: formValues.host,
		port: portNumber,
		creds: {
			username: formValues.username,
			password: formValues.password,
		},
	});
};

const parseGHubPublic: CredsParser<GHubPublicFormValues, GHubPublicPayload> = (
	formValues,
	accountId
) => {
	return Promise.resolve({
		type: formValues.sourceType,
		name: formValues.name,
		accountId,
		credsSchema: null,
		remoteUrl: formValues.remoteUrl,
		creds: null,
	});
};

const parserMap: Record<DisplaySourceType, CredsParser<any, any>> = {
	bigquery: parseBigQuery,
	s3: parseS3,
	sftp: parseSFTP,
	'google cloud storage': parseGCS,
	mysql: parseMySQL,
	github: parseGHubPublic,
};

export const parseSubmittedCredentials = (
	submittedCreds: CredentialFormValues,
	accountId: number
) => {
	return parserMap[submittedCreds.displaySourceType](
		submittedCreds,
		accountId
	);
};

/**
 * Choose icon that populates generate source buttons
 */
interface GenerationStatus {
	isLoading: boolean;
	isError: boolean;
	isSuccess: boolean;
	isUninitialized: boolean;
}

export const selectButtonIcon = (status: Partial<GenerationStatus>) => {
	const { isLoading, isError, isSuccess } = status;

	if (isLoading) return faHourglassHalf;

	if (isError) return faTimes;

	if (isSuccess) return faCheck;

	return faUpload;
};
