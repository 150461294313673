import { ListItemContent, List } from '../../../common/List';
import Typography from '../../../common/Typography';
import { useGetCatalogSummariesQuery } from '../../api';
import { renderDropdown } from '../helpers';
import useSourceListItem from '../hooks/useSourceListItem';
import { DataSourceSummary } from '../types/dataTypes';
import { CardSetter, SourceListItemProps } from '../types/uiTypes';
import { SourceListItem } from './styledComponents';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import clsx from 'clsx';
import { FunctionComponent, useEffect } from 'react';

interface SourceSummaryListItemProps extends SourceListItemProps {
	onDisplay: DataSourceSummary;
	cardSetter: CardSetter;
}

const SourceSummaryListItem: FunctionComponent<SourceSummaryListItemProps> = ({
	onDisplay,
	currentlySelected,
	setter,
	cardSetter,
}) => {
	const { queryShouldRun, open, active, onClick, itemName } =
		useSourceListItem({
			onDisplay,
			currentlySelected,
			setter,
		});

	const queryResults = useGetCatalogSummariesQuery(
		queryShouldRun ? { sourceId: onDisplay._id } : skipToken
	);

	// pass list back up to the card display component
	useEffect(() => {
		const { isSuccess, data } = queryResults;
		if (isSuccess && data && active) {
			cardSetter(data);
		}
	}, [queryResults, cardSetter, active]);

	return (
		<List onClick={onClick}>
			<SourceListItem
				button
				className={clsx(active && 'browser-item-is-open')}
			>
				<ListItemContent>
					<Typography>{itemName}</Typography>
				</ListItemContent>
			</SourceListItem>
			{open &&
				renderDropdown({
					...queryResults,
					setter,
					cardSetter,
					currentlySelected,
					sourceId: onDisplay._id,
				})}
		</List>
	);
};

export default SourceSummaryListItem;
