import { List } from '../../../../common/List';
import actionMap from '../../../futuremodelActions';
import {
	isFunctionalRelation,
	isRelation,
} from '../../../ontology/typeGuards/attributeGuards';
import {
	AttributeBaseType,
	BaseAttribute,
	RelationTypeData,
} from '../../../ontology/types/attributeTypes';
import ActionListItem from './ActionListItem';
import { FunctionComponent, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';

const mapRelationToActions = (attr: RelationTypeData) =>
	isFunctionalRelation(attr)
		? actionMap.functionalRelation
		: actionMap.nonFunctionalRelation;

const mapAttrTypeToActions = (attr: BaseAttribute) => {
	if (isRelation(attr)) {
		return mapRelationToActions(attr);
	}

	return actionMap[attr.type as Exclude<AttributeBaseType, 'relation'>];
};

interface ActionListProps extends BaseAttribute {
	setOpenAttribute: (attrId: number | null) => void;
}

const ActionList: FunctionComponent<ActionListProps> = (props) => {
	const { _id, setOpenAttribute } = props;

	// keep track of which action list item has a sub-menu open.  Should
	// only have one open at a time.
	const [actionWithActiveSubmenu, setActionWithActiveSubmenu] = useState<
		string | null
	>(null);

	return (
		<ClickAwayListener onClickAway={() => setOpenAttribute(null)}>
			<List>
				{mapAttrTypeToActions(props).map((actionDescriptor) => (
					<ActionListItem
						actionWithActiveSubmenu={actionWithActiveSubmenu}
						setOpenAction={setActionWithActiveSubmenu}
						key={`${_id}-${actionDescriptor.actionTitle}`}
						{...actionDescriptor}
						{...props}
					/>
				))}
			</List>
		</ClickAwayListener>
	);
};

export default ActionList;
