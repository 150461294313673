import { useGetAttributesQuery } from '../../api';
import { BaseAttribute } from '../types/attributeTypes';
import { createSelector } from '@reduxjs/toolkit';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import useActiveEntityData from 'features/ontology/hooks/useActiveEntityData';
import { useSearchParams } from 'react-router-dom';

type QueryResponse = Omit<ReturnType<typeof useGetAttributesQuery>, 'refetch'>;

type SearchAttributeName = string | undefined;

const selectSearchAttribute = createSelector(
	(res: QueryResponse) => res.data,
	(res: QueryResponse, searchAttributeName: SearchAttributeName) =>
		searchAttributeName,
	(data: QueryResponse['data'], searchAttributeName: SearchAttributeName) =>
		data?.find((attr: BaseAttribute) => {
			if (attr.name === searchAttributeName) {
				return true;
			}

			return false;
		}) ?? null
);

/**
 * returns RTK Query data related to fetching activeAttribute property that corresponds to the data for the attribute specified
 * in the application URL.
 */
const useSearchAttributeData = () => {
	const searchParams = useSearchParams()[0];

	const { activeItem: activeEntity } = useActiveEntityData();

	const searchAttr = searchParams.get('attribute');

	const canQuery = activeEntity && searchAttr;

	const fetchData = useGetAttributesQuery(
		canQuery ? { entityId: activeEntity._id } : skipToken,
		{
			selectFromResult: (res) => ({
				...res,
				activeItem: selectSearchAttribute(
					res,
					searchAttr as string
				) as null | BaseAttribute,
				searchAttribute: searchAttr,
			}),
		}
	);

	return fetchData;
};

export default useSearchAttributeData;
