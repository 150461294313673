import styled from 'styled-components';

const TooltipBody = styled.div`
	border-radius: 4px;
	box-shadow: ${(p) => p.theme.shadows[4]};
	color: ${(p) => p.theme.palette.common.white};
	z-index: 1;
	padding: ${(p) => p.theme.spacing(0.5)};
	color: ${(p) => p.theme.palette.common.white};
	background-color: ${(p) => p.theme.palette.darkBaby};
	color: ${(p) => p.theme.palette.common.white};
	text-transform: capitalize;
`;

export default TooltipBody;
