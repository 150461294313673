import ExpandingListItem from './ExpandingListItem';
import IdentifyingListItem from './IdentifyingListItem';
import Heading from 'common/Heading';
import { List } from 'common/List';
import { default as theme } from 'common/theme/theme';
import { pipe } from 'common/utils/functionUtils';
import DisplayOnLoad from 'features/api/DisplayOnLoad';
import { QueryStateTracker } from 'features/api/helpers';
import {
	groupAttrs,
	pluralizeType,
	sortGroupedAttrs,
} from 'features/ontology/helpers/attributeHelpers';
import {
	BaseAttribute,
	GetEntityAttrsResponse,
} from 'features/ontology/types/attributeTypes';
import { FunctionComponent, useCallback, useState } from 'react';
import styled from 'styled-components';

const StyledHeading = styled(Heading)`
	padding: ${(p) => p.theme.spacing(1)};
	border-bottom: 1px solid ${(p) => p.theme.palette.divider};
	font-weight: ${(p) => p.theme.typography.fontWeightBold};
	text-transform: capitalize;
`;

interface DropdownProps {
	attrs: GetEntityAttrsResponse;
	mapAttrToUI: (attrs: BaseAttribute) => JSX.Element;
}

const Dropdown: FunctionComponent<DropdownProps> = ({ attrs, mapAttrToUI }) => {
	// Only attributes that DO have a source can be used as an identity; filter the rest.
	const attrsWithSource = attrs.filter((attr) => attr.hasSource);

	const groupedAndSorted = pipe(
		attrsWithSource,
		groupAttrs('base'),
		sortGroupedAttrs
	);

	return (
		<>
			{groupedAndSorted.map(({ type, attrs }) => {
				return (
					<div key={type}>
						<StyledHeading component="h6" style={{
							margin: '10px 0 10px 10px',
							color: theme.palette.lightBaby
						}}>
							{pluralizeType(type)}
						</StyledHeading>
						<List>{attrs.map(mapAttrToUI)}</List>
					</div>
				);
			})}
		</>
	);
};

interface EntityDropdownProps extends QueryStateTracker {
	hasIdentities: boolean;
	attributes?: GetEntityAttrsResponse;
	canEdit: boolean;
}

const EntityPopoverDropdown: FunctionComponent<EntityDropdownProps> = ({
	hasIdentities,
	attributes,
	canEdit,
	...loadingState
}) => {
	// keep track of which attribute list item has a sub-menu open.  Should
	// only have one open at a time.
	const [attrWithActiveSubmenu, setAttrWithActiveSubmenu] = useState<
		number | null
	>(null);

	// if entity doesn't yet have an identity, clicking on a list item
	// immediately launches a request to use the clicked attribute as
	// an identity.  Otherwise, clicked list item opens a sub-menu with
	// actions that can be performed on that entity.
	const mapAttrToUI = useCallback(
		(attr: BaseAttribute) =>
			hasIdentities ? (
				<ExpandingListItem
					{...attr}
					key={attr._id}
					attrWithActiveSubmenu={attrWithActiveSubmenu}
					setOpenAttribute={setAttrWithActiveSubmenu}
					canEdit={canEdit}
				/>
			) : (
				<IdentifyingListItem
					{...attr}
					key={attr._id}
					canEdit={canEdit}
				/>
			),
		[
			hasIdentities,
			attrWithActiveSubmenu,
			setAttrWithActiveSubmenu,
			canEdit,
		]
	);

	return (
		<DisplayOnLoad {...loadingState}>
			<Dropdown
				mapAttrToUI={mapAttrToUI}
				attrs={attributes as GetEntityAttrsResponse}
			/>
		</DisplayOnLoad>
	);
};

export default EntityPopoverDropdown;
