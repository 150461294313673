import AuthManager from '../features/authentication/components/AuthManager';
import ErrorAlert from '../features/errorHandling/ErrorAlert';
import NavPanel from '../features/navigation/components/Navbar';
import { FunctionComponent } from 'react';

const AppBase: FunctionComponent = () => {
	return (
		<>
			<NavPanel />
			<ErrorAlert />
			<AuthManager />
		</>
	);
};

export default AppBase;
