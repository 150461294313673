import FlexContainer from '../../../common/FlexContainer';
import SubtleTextArea from '../../../common/SubtleTextArea';
import Typography from '../../../common/Typography';
import { useCreateAnswerMutation } from '../../api';
import { parseQueryError } from '../../api/helpers';
import QuestionContext from '../state/QuestionContext';
import { CreateAnswerFormValues } from '../types/questionTypes';
import { StyledQuestionListButton } from './styledComponents';
import { FunctionComponent, useContext } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';

const ANSWER_PLACEHOLDER = 'Your answer...';

interface CreateAnswerItemProps {
	questionId: number;
}

const CreateAnswerItem: FunctionComponent<CreateAnswerItemProps> = ({
	questionId,
}) => {
	const { parentObjectId, parentObjectType, canEdit } =
		useContext(QuestionContext);

	const { register, resetField, formState, handleSubmit, reset } =
		useForm<CreateAnswerFormValues>({
			defaultValues: {
				content: ANSWER_PLACEHOLDER,
			},
		});

	const [createAnswer, createAnswerResult] = useCreateAnswerMutation();

	const onSubmit: SubmitHandler<CreateAnswerFormValues> = (vals) => {
		reset();
		createAnswer({
			parentObjectId,
			parentObjectType,
			body: vals,
			questionId,
		});
	};

	//  If there's a network error with a message, display that.  Otherwise,
	// display any form validation error messages.
	const contentErr = () => {
		if (createAnswerResult.isError) {
			return parseQueryError(createAnswerResult.error).message;
		}

		return formState.errors.content?.message;
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} aria-label="Add an answer">
			<SubtleTextArea
				{...register('content', {
					required: 'Answer must have some text content',
					validate: (v) =>
						v === ANSWER_PLACEHOLDER
							? 'Answer must have some text content'
							: true,
				})}
				label="content"
				error={contentErr()}
				onReset={() => resetField('content')}
				isDirty={formState.dirtyFields.content}
			/>
			{canEdit && (
				<>
					<input
						type="checkbox"
						{...register('isAccepted')}
						id={`accept-immediately-${questionId}`}
						style={{ marginRight: '8px' }}
					/>
					<label htmlFor={`accept-immediately-${questionId}`}>
						<Typography>Accept this answer</Typography>
					</label>
				</>
			)}
			<FlexContainer
				style={{ marginRight: '8px' }}
				justifyContent="flex-end"
			>
				<StyledQuestionListButton
					disabled={!formState.isDirty}
					variant="outline"
				>
					Submit
				</StyledQuestionListButton>
			</FlexContainer>
		</form>
	);
};
export default CreateAnswerItem;
