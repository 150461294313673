import styled from 'styled-components';

const Container = styled.div`
	padding-top: ${(p) => p.theme.navHeight}px;
	width: 80%;
	max-width: ${(p) => p.theme.breakpoints.values.lg}px;

	margin: 0 auto;

	/* Since navbar is absolutely-positioned, ensure no content renders
	beneath it.
	TODO: should update spacing function to give us a number if needed, as
	is the case here. */

	/* Very crude responsiveness */
	@media screen and (max-width: ${(p) => p.theme.breakpoints.values.md}px) {
		width: 90%;
	}
	@media screen and (max-width: ${(p) => p.theme.breakpoints.values.md}px) {
		width: 90%;
	}

	@media screen and (max-width: ${(p) => p.theme.breakpoints.values.sm}px) {
		width: 100%;
	}
`;

export default Container;
