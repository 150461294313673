import useGraphDataLoader from '../../hooks/useGraphDataLoader';
import useGraphHandlers from '../../hooks/useGraphHandlers';
import {
	StyledGraphContainer,
	StyledHeading,
} from '../common/styledComponents';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import theme from 'common/theme/theme';
import { useGetAccountGraphQuery } from 'features/api';
import GraphPopover from 'features/forceGraphs/components/common/GraphPopover';
import { drawNode, drawLink } from 'features/forceGraphs/helpers';
import GraphContextProvider from 'features/forceGraphs/state/GraphContextProvider';
import useActiveAccountData from 'features/ontology/hooks/useActiveAccountData';
import { FunctionComponent } from 'react';
import { default as ForceGraph2D } from 'react-force-graph-2d';

const AccountGraphBody: FunctionComponent = () => {
	const { activeItem } = useActiveAccountData();

	const queryRes = useGetAccountGraphQuery(
		activeItem ? { accountId: activeItem._id } : skipToken
	);

	useGraphDataLoader(queryRes);

	const { selectNode, width, height, setFgm, graphState } =
		useGraphHandlers();

	return (
		<StyledGraphContainer>
			<div>
				{graphState.isLoadingData && (
					<StyledHeading component="h2">
						Preparing your data...
					</StyledHeading>
				)}
				<ForceGraph2D
					ref={setFgm as any}
					backgroundColor={theme.palette.background.default}
					width={width}
					height={height}
					graphData={graphState.graphData}
					onNodeClick={selectNode as any}
					linkCanvasObject={drawLink}
					nodeId="_id"
					nodeLabel={() => ''}
					linkCanvasObjectMode={() => 'replace'}
					nodeCanvasObject={drawNode()}
				/>
			</div>
			<GraphPopover />
		</StyledGraphContainer>
	);
};

const AccountGraph: FunctionComponent = () => {
	return (
		<GraphContextProvider>
			<AccountGraphBody />
		</GraphContextProvider>
	);
};

export default AccountGraph;
