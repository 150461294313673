import {
	FlexDirectionProperties,
	FlexJustifyProperties,
	FlexAlignmentProperties,
} from './utils/typeUtils';
import styled from 'styled-components';

interface Props {
	justifyContent?: FlexJustifyProperties;
	flexDirection?: FlexDirectionProperties;
	alignItems?: FlexAlignmentProperties;
	alignContent?: FlexAlignmentProperties;
}

const FlexContainer = styled.div<Props & Record<string, any>>`
	display: flex;
	justify-content: ${(p) => p.justifyContent};
	flex-direction: ${(p) => p.flexDirection};
	align-items: ${(p) => p.alignItems};
	align-content: ${(p) => p.alignContent};
`;

export default FlexContainer;
