import { useAppDispatch } from 'app/hooks';
import Heading from 'common/Heading';
import theme from 'common/theme/theme';
import {
	setActiveIndividual,
	clearActiveIndividual,
} from 'features/HUD/state/HUDSlice';
import DataPreparer from 'features/dataPreparation/DataPreparer';
import LatLongMeta from 'features/dataPreparation/LatLongMeta';
import useActiveIndividualsMeta from 'features/ontology/hooks/useActiveIndividualsMeta';
import 'mapbox-gl/dist/mapbox-gl.css';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import Map, { MapRef, Marker, NavigationControl } from 'react-map-gl';
import styled from 'styled-components';

const StyledLoadingHeading = styled(Heading)`
	position: absolute;
	z-index: ${(p) => p.theme.zIndex.graphScreen};
	color: ${(p) => p.theme.palette.text.primary};
	top: 50%;
	left: 50%;
	transform: translateX(-50%);
`;

// interface PopupInfo {
// 	lat: number;
// 	long: number;
// 	datum: Record<string, any>;
// 	attributes: BaseAttribute[];
// 	parentEntityLabel: string;
// }

const LocationView: FunctionComponent = () => {
	const appDispatch = useAppDispatch();

	// const [popupData, setPopupData] = useState<PopupInfo | null>(null);

	const { preparedData, ...individualsLoadState } = useActiveIndividualsMeta({
		filter: true,
	});

	//    TODO: this should be made to accomodate multiple latlong attributes
	const latLongData = useMemo(
		() =>
			preparedData?.getAttributeData<LatLongMeta>(
				(a) => a.resolvedType === 'latlong'
			)[0].coords,
		[preparedData]
	);

	// use useState here b/c we want to trigger a re-render when this ref is populated
	// for the useEffect below
	const [mapRef, setMapRef] = useState<MapRef | null>(null);

	useEffect(() => {
		if (preparedData && mapRef) {
			const locations = preparedData.getAttributeData<LatLongMeta>(
				(a) => a.resolvedType === 'latlong'
			);

			//    TODO: this should be made to accomodate multiple latlong attributes
			mapRef.fitBounds(locations[0].boundingCoords, { padding: 50 });
		}
	}, [preparedData, mapRef]);

	return (
		<div className="container-gl">
			{(individualsLoadState.isLoading ||
				individualsLoadState.isFetching) && (
				<StyledLoadingHeading component="h2">
					Preparing your data...
				</StyledLoadingHeading>
			)}
			<Map
				reuseMaps
				initialViewState={{
					latitude: 37.7751,
					longitude: -122.4193,
					zoom: 11,
					bearing: 0,
					pitch: 0,
				}}
				style={{
					width: '100vw',
					height: '100vh',
				}}
				ref={setMapRef}
				mapStyle="mapbox://styles/mapbox/light-v10"
				onClick={() => appDispatch(clearActiveIndividual())}
			>
				{/* {popupData && (
					<Popup
						longitude={popupData.long}
						latitude={popupData.lat}
						anchor="top"
						onClose={() => setPopupData(null)}
						datum={popupData.datum}
						attributes={popupData.attributes}
					/>
				)} */}
				<NavigationControl
					visualizePitch={true}
					position="bottom-right"
					style={{ marginRight: `${theme.navHeight}px` }}
				/>
				{latLongData &&
					latLongData.map((coordData) => {
						// ok to cast here -- if latLongData exists, then preparedData does too.
						const preppedData = preparedData as DataPreparer;

						const {
							coord: [lat, long],
							ownerIdx,
						} = coordData;

						return (
							<Marker
								onClick={(e) => {
									e.originalEvent.stopPropagation();
									const datum = preppedData.data[ownerIdx];
									// setPopupData({
									// 	lat,
									// 	long,
									// 	datum,
									// 	attributes: preppedData.attributes,
									// 	parentEntityLabel:
									// 		preppedData.attributes[0].entity
									// 			.singular,
									// });
									appDispatch(
										setActiveIndividual({
											datum,
											attributes: preppedData.attributes,
										})
									);
								}}
								key={`${lat}-${long}-${ownerIdx}`}
								longitude={long}
								latitude={lat}
								color={theme.palette.cyan}
								scale={0.75}
							></Marker>
						);
					})}
			</Map>
		</div>
	);
};

export default LocationView;
